import {BusinessUnitModel} from '../business_units/business_units.model';
import {observer} from 'mobx-react';
import {Search, SearchLayout, Select} from '../../styles/table-header';
import {Layout} from '../../styles/layout';
import {DatePicker} from 'antd';
import {UsersModel} from '../users/users.model';
import {
    ProductBrandModel,
    ProductCategoryModel,
    ProductTypeModel,
} from '../products/products.model';
import {TransactionType} from './transactions.model';
import {StatusModel} from "../statuses/statuses.model";
import {Image} from "../../styles/image";
import SearchIcon from "../../assets/icons/search.svg";
import dayjs from "dayjs";
import TransactionsStore from "./transactions.store";

interface TransactionFiltersViewProps {
    setDates: (e: any) => void;
    productBrands: ProductBrandModel[] | undefined;
    productCategories: ProductCategoryModel[] | undefined;
    productTypes: ProductTypeModel[] | undefined;
    transactionTypes: TransactionType[] | undefined;
    transactionStatuses: StatusModel[] | undefined;
    users: UsersModel[] | undefined;
    handleFilterChange: (e: any, key: string) => void;
    businessUnits: BusinessUnitModel[] | undefined;
    inModal: boolean;
    handleKeyDown: (e: any) => void;
    cancelSearch: (e: any) => void;
}

const TransactionsFiltersView = observer(
    ({
         setDates,
         productBrands,
         productCategories,
         productTypes,
         transactionTypes,
         transactionStatuses,
         users,
         handleFilterChange,
         businessUnits,
         inModal,
         handleKeyDown,
         cancelSearch
     }: TransactionFiltersViewProps) => {
        const {RangePicker} = DatePicker;
        const {transactionsFilter} = TransactionsStore;
        return (
            <Layout vertical={inModal}>
                <Layout between={10} wrapped vAlign="center">
                    <RangePicker
                        onChange={(e) => {
                            setDates(e);
                        }}
                        placeholder={['Начало даты', 'Конец даты']}
                        style={{
                            height: 40,
                            width: 250,
                            borderRadius: 12,
                            border: 0,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            color: '#374151',
                            backgroundColor: '#FFFFFF',
                        }}
                       // defaultValue={[dayjs(transactionsFilter?.date_from, 'YYYY-MM-DD'), dayjs(transactionsFilter?.date_to, 'YYYY-MM-DD')]}
                    />
                    <SearchLayout>
                        <Search
                            type="search"
                            id="search"
                            placeholder="Поиск по ID"
                            onKeyDown={(e) => handleKeyDown(e)}
                            onChange={(e) => cancelSearch(e)}
                        >
                        </Search>
                        <Image src={SearchIcon} height="16px"/>
                    </SearchLayout>
                    {users?.length! > 0 && (
                        <Select
                            id="users-filter"
                            style={{width: 150}}
                            onChange={(e) => handleFilterChange(e, 'user_id')}
                        >
                            <option value="">Все сотрудники</option>
                            {users?.map((user, index) => (
                                <option key={index} value={user.id}>
                                    {user.name}
                                </option>
                            ))}
                        </Select>
                    )}

                    {businessUnits?.length! > 0 && (
                        <Select
                            id="business-unit-filter"
                            style={{width: 165}}
                            onChange={(e) => handleFilterChange(e, 'business_unit_id')}
                        >
                            <option value="">Все подразделения</option>
                            {businessUnits?.map((businessUnit, _) => (
                                <option value={businessUnit.id} key={businessUnit.id}>
                                    {businessUnit.name}
                                </option>
                            ))}
                        </Select>
                    )}
                    <Select
                        id="product-brand-filter"
                        style={{width: 130}}
                        onChange={(e) => handleFilterChange(e, 'brand_id')}
                    >
                        <option value="">Все бренды</option>
                        {productBrands?.map((productBrand, _) => (
                            <option value={productBrand.id} key={productBrand.id}>
                                {productBrand.name}
                            </option>
                        ))}
                    </Select>
                    <Select
                        id="product-category-filter"
                        style={{width: 150}}
                        onChange={(e) => handleFilterChange(e, 'category_id')}
                    >
                        <option value="">Все категории</option>
                        {productCategories?.map((productCategory, _) => (
                            <option value={productCategory.id} key={productCategory.id}>
                                {productCategory.name}
                            </option>
                        ))}
                    </Select>
                    <Select
                        id="product-type-filter"
                        onChange={(e) => handleFilterChange(e, 'product_type_id')}
                    >
                        <option value="">Все типы продуктов</option>
                        {productTypes?.map((productType, _) => (
                            <option value={productType.id} key={productType.id}>
                                {productType.name}
                            </option>
                        ))}
                    </Select>
                    <Select
                        id="trans-type-filter"
                        onChange={(e) => handleFilterChange(e, 'tran_type_id')}
                    >
                        <option value="">Все типы операции</option>
                        {transactionTypes?.map((transactionType, _) => (
                            <option value={transactionType.id} key={transactionType.id}>
                                {transactionType.description}
                            </option>
                        ))}
                    </Select>
                    <Select
                        id="status-filter"
                        onChange={(e) => handleFilterChange(e, 'status')}
                    >
                        <option value="">Все статусы</option>
                        {transactionStatuses?.map((transactionStatus, _) => (
                            <option value={transactionStatus.id} key={transactionStatus.id}>
                                {transactionStatus.description}
                            </option>
                        ))}
                    </Select>
                </Layout>
            </Layout>
        );
    }
);

export default TransactionsFiltersView;
