import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import UsersCreateEditStore from './users.create.edit.store';
import UsersStore from '../users.store';
import {useEffect} from "react";
import {renderInputs} from "../../../components/form/render.inputs";
import {Button} from "../../../styles/button";
import {isValid} from "../../../utils/isValid";
import {UsersModel} from "../users.model";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";

interface UsersCreateEditViewProps {
    initialValue: UsersModel | null;
    setModal: (isOpen: boolean) => void;
}

const UsersCreateEditView = observer(({initialValue, setModal}: UsersCreateEditViewProps) => {
    const {
        form,
        setField,
        init,
        businessUnitOptions,
        roleOptions,
        createOrEditUser,
        isLoading,
        destroy
    } = UsersCreateEditStore;
    const {
        name,
        surname,
        patronymic,
        login,
        phone,
        email,
        password,
        business_unit_id,
        role_id,
        salary
    } = form;
    const {usersReq} = UsersStore;
    const {destroyModalDictionary, getModalPlaceholders, dictionaryListReq} = DictionaryStore;

    useEffect(() => {
        destroyModalDictionary();
        const controller = new AbortController();
        void dictionaryListReq('USERS', 'MODAL', controller.signal);
        void init(initialValue);

        return () => {
            destroy();
            controller.abort();
        }
    }, [destroyModalDictionary, dictionaryListReq, destroy, init, initialValue]);

    const inputs = [
        {field: name, fieldName: 'name', placeholder: getModalPlaceholders('name'), type: 'text', required: true},
        {field: surname, fieldName: 'surname', placeholder: getModalPlaceholders('surname'), type: 'text', required: true},
        {field: patronymic, fieldName: 'patronymic', placeholder: getModalPlaceholders('patronymic'), type: 'text'},
        {field: login, fieldName: 'login', placeholder: getModalPlaceholders('login'), type: 'text', required: true},
        {field: email, fieldName: 'email', placeholder: getModalPlaceholders('email'), type: 'text'},
        {field: phone, fieldName: 'phone', placeholder: getModalPlaceholders('phone'), type: 'text', required: true},
        ...(!initialValue ? [{
            field: password,
            fieldName: 'password',
            placeholder: getModalPlaceholders('password'),
            type: 'text',
            required: true
        }] : []),
        {
            field: business_unit_id,
            options: businessUnitOptions,
            fieldName: 'business_unit_id',
            placeholder: getModalPlaceholders('business_unit_id'),
            type: 'select',
            required: true
        },
        {
            field: role_id,
            options: roleOptions,
            fieldName: 'role_id',
            placeholder: getModalPlaceholders('role_id'),
            type: 'select',
            required: true
        },
        {field: salary, fieldName: 'salary', placeholder: getModalPlaceholders('salary'), type: 'number'},
    ];

    const handleCreateEditUser = async () => {
        const res = await createOrEditUser(!!initialValue);
        if (res) {
            setModal(false);
            usersReq();
        }
    }

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setField)}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={isLoading}
                    onClick={handleCreateEditUser}
                    disabled={isValid([inputs])}
                >{isLoading ? '' : renderSubmitText}</Button>
            </Layout>
        </Layout>
    );
});

export default UsersCreateEditView;
