export const getKeyframes = (type: string) => {
    switch (type) {
        case 'fadeIn':
            return `
                @keyframes fadeIn {
                    to {
                        opacity: 1;
                    }
                }
            `;
        case 'scaleIn':
            return `
                opacity: .5;
                transform: scale(.6);
                @keyframes scaleIn {
                    to {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            `;
        case 'fadeOut':
            return `
                @keyframes fadeOut {
                    to {
                        opacity: 0;
                    }
                }
            `;
        case 'rotate':
            return `
                @keyframes infiniteRotate {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
            `;
        default:
            return '';
    }
}