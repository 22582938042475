import {ProductCategoryModel} from "./products.model";
import AppStore from "../../app.store";
import axios, { GenericAbortSignal } from "axios";
import {api} from "../../api/endpoints";
import {makeAutoObservable} from "mobx";

class ProductTypeStore {
    public productCategories ?: ProductCategoryModel[] = [];

    public productCategoriesInit = (signal: GenericAbortSignal) => {
        void this.productCategoriesReq(signal);
    }

    constructor() {
        makeAutoObservable(this);
    }

    setProductCategories(data: any) {
        const {categories} = data;
        this.productCategories = categories;
    }

    public productCategoriesReq = async (signal?: GenericAbortSignal) => {

        try {
            const {getToken} = AppStore

            const headers = {
                Authorization: "Bearer " + getToken()!
            }

            const response: any = await axios.get(api.product_categories, { headers, signal });

            this.setProductCategories(response.data)
        } catch (err) {
        }
    }
}

export default new ProductTypeStore();