import styled from "styled-components";

export const Badge = styled.span<{ online: boolean }>`
  background-color: ${({ online }) => online ? '#75c1a0' : '#ccc'};
  color: white;
  border-radius: 50px;
  padding: 4px 6px;
  font-size: 11px;
`;

export const StatusBadge = styled.span<{ color: string }>`
  background-color: ${({ color }) => color};
  color: white;
  white-space: nowrap;
  border-radius: 50px;
  padding: 5px 10px;
  text-align: center;
`;

const GetStatusBadgeColor = (status: string) => {
    switch (status) {
        case 'Успешно':
        return '#75c1a0';
        case 'В ожидании':
        return '#f9c851';
        case 'Ошибка':
        return '#f95c5c';
        default:
        return '#ccc';
    }
}