import {observer} from 'mobx-react';
import {Layout} from '../../../styles/layout';
import {modalTextColor, primaryColor} from '../../../utils/variables';
import {Text} from '../../../styles/text';
import {SelectView, TextInput} from '../../../styles/form';
import {useEffect, useState} from 'react';
import {Button} from '../../../styles/button';
import ModalView from '../../../components/modal/modal.view';
import StocksModalView from '../transfer.modal.view';
import business_unitsStore from '../../business_units/business_units.store';
import TransferCreateEditeStore from './transfer.create.edite.store';
import {hasPermission} from '../../../utils/hasPermission';
import AppStore from '../../../app.store';
import transferStore from '../transfer.store';
import DictionaryStore from "../../../utils/dictionary/dictionary.store";

interface TransferCreateEditViewProps {
    setModal: (isOpen: boolean) => void;
}

const TransferCreateEditView = observer(
    ({setModal}: TransferCreateEditViewProps) => {
        const {permissions} = AppStore;
        const {businessUnits, businessUnitsReq} = business_unitsStore;
        const {
            form,
            changeForm,
            setProductTotalSumm,
            createOrEditTransfers,
            destroy,
            deleteProduct,
            selectedProducts,
            isLoading,
        } = TransferCreateEditeStore;

        const {transfersReq} = transferStore;

        const [goodsModal, setGoodsModal] = useState(false);
        const getBuID = () => {
            if (hasPermission(permissions!, 'ALL_BU_TRANSACTIONS', 'CREATE')) {
                return form.from_business_unit_id
                    ? form.from_business_unit_id
                    : businessUnits![0].id;
            }
            return 0;
        };

        const handleAddGoodsButtonClick = async () => {
            setGoodsModal(true);
        };

        const handleSubmit = async () => {
            const resp = await createOrEditTransfers();
            if (resp) {
                destroy();
                setModal(false);
                void transfersReq();
            }
        };

        const {destroyModalDictionary, getModalPlaceholders, dictionaryListReq} = DictionaryStore;

        useEffect(() => {
            destroyModalDictionary();
            const controller = new AbortController();
            void dictionaryListReq('TRANSFERS', 'MODAL', controller.signal);
            void businessUnitsReq();
            return () => {
                destroy();
                controller.abort();
            };
        }, [destroyModalDictionary, dictionaryListReq, businessUnitsReq]);

        const totalQuantity = selectedProducts.reduce((acc: any, item: any) => {
            return Number(item.totalSumm) + acc;
        }, 0);

        return (
            <Layout vertical between={5}>
                <Layout vAlign="center" hAlign="space-between">
                    <Text extent="S" color={modalTextColor}>
                        {getModalPlaceholders('from_business_unit_id')}
                    </Text>
                    <SelectView
                        style={{maxWidth: '50%'}}
                        name="from-business-unit-options"
                        value={form.from_business_unit_id}
                        onChange={(event) =>
                            changeForm('from_business_unit_id', event.target.value)
                        }
                    >
                        {businessUnits?.map((bu, _) => (
                            <option key={bu.id} value={bu.id}>
                                {bu.name}
                            </option>
                        ))}
                    </SelectView>
                </Layout>

                <Layout vAlign="center" hAlign="space-between">
                    <Text extent="S" color={modalTextColor}>
                        {getModalPlaceholders('to_business_unit_id')}
                    </Text>
                    <SelectView
                        style={{maxWidth: '50%'}}
                        name="to-business-unit-options"
                        value={form.to_business_unit_id}
                        onChange={(event) =>
                            changeForm('to_business_unit_id', event.target.value)
                        }
                    >
                        {businessUnits?.map((bu, _) => (
                            <option key={bu.id} value={bu.id}>
                                {bu.name}
                            </option>
                        ))}
                    </SelectView>
                </Layout>
                <Layout vAlign="center" hAlign="space-between">
                    <Text extent="S" color={modalTextColor}>
                        {getModalPlaceholders('details')}
                    </Text>
                    <TextInput
                        style={{maxWidth: '50%'}}
                        type="string"
                        onChange={(e) => changeForm('details', e.target.value)}
                    />
                </Layout>

                <Layout vAlign="center" hAlign="center">
                    <Text color={primaryColor} weight={'bold'}>
                        {getModalPlaceholders('products_title')}
                    </Text>
                </Layout>

                <Layout
                    style={{
                        maxHeight: 180,
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                    }}
                    widthSize={'100%'}
                    vertical
                    between={5}
                >
                    {selectedProducts.map((product: any, _: any) => (
                        <Layout
                            vAlign="center"
                            hAlign="space-between"
                            style={{
                                border: '1px solid #d9d9d9',
                                padding: '5px',
                                borderRadius: '8px',
                            }}
                        >
                            <Text style={{fontSize: '14px', fontWeight: 'unset'}}>
                                {product.name}
                            </Text>
                            <Layout hAlign="flex-end" between={5} vAlign={'center'}>
                                <TextInput
                                    style={{maxWidth: '25%'}}
                                    onChange={(e) => setProductTotalSumm(product, e.target.value)}
                                />
                                <button
                                    onClick={() => deleteProduct(product.id)}
                                    style={{
                                        cursor: 'pointer',
                                        height: '20px',
                                        border: 'none',
                                    }}
                                >
                                    X
                                </button>
                            </Layout>
                        </Layout>
                    ))}
                </Layout>
                <Layout>
                    <Button
                        onClick={handleAddGoodsButtonClick}
                        style={{
                            width: '80%',
                            margin: '25px 45px',
                            marginBottom: 5,
                            boxSizing: 'border-box',
                            background: 'rgb(69,97,134)',
                            height: '35px',
                        }}
                        disabled={isLoading}
                    >
                        {getModalPlaceholders('add_product_button')}
                    </Button>
                </Layout>
                <Layout vAlign="center" hAlign="space-between">
                    <Layout>
                        <Text extent="S" color={modalTextColor}>
                            {getModalPlaceholders('total_quantity')}
                        </Text>
                    </Layout>
                    <Layout widthSize="50%">
                        <TextInput type="text" disabled value={totalQuantity}/>
                    </Layout>
                </Layout>

                <Button
                    onClick={handleSubmit}
                    style={{
                        alignSelf: 'center',
                    }}
                    isLoading={isLoading}
                    disabled={isLoading}
                >
                    {isLoading ? '' : 'Потвердить'}
                </Button>

                {goodsModal && (
                    <ModalView
                        title={'Выбирете товар'}
                        onClose={() => setGoodsModal(false)}
                    >
                        <StocksModalView
                            businessUnitID={getBuID()}
                            setModal={setGoodsModal}
                        />
                    </ModalView>
                )}
            </Layout>
        );
    }
);

export default TransferCreateEditView;
