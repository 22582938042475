import {makeAutoObservable} from "mobx";
import {BrandModel} from "../brands.model";
import AppStore from "../../../app.store";
import axios from "axios";
import {api} from "../../../api/endpoints";
import {toast} from "react-toastify";

const initialValues = {
    id: 0,
    name: '',
    description: '',
    country_of_origin_id: 0,
    country_of_origin: '',
    active: true,
    img: '',
    created_at: '',
    updated_at: '',
    formatted_date: ''
}

class BrandsCreateEditStore {
    private countries?: any[];

    public form: BrandModel = initialValues
    public isLoading = false;
    public error = '';
    public message = '';

    constructor() {
        makeAutoObservable(this)
    }

    public init = async (initialValue: BrandModel | null) => {
        await this.countriesReq()

        if (initialValue) {
            this.form = initialValue;
            this.form.country_of_origin_id = this.countriesOptions?.find((item) => this.form.country_of_origin === item.label)?.value;
        } else {
            this.form = initialValues;
        }
    }

    private countriesReq = async () => {
        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }

            const response = await axios.get(api.get_countries, {headers})
            this.countries = response.data.countries;
        } catch (err) {
        }
    }

    public createOrEditBrand = async (edit?: boolean) => {
        this.isLoading = true

        const payload = {
            id: this.form.id,
            active: this.form.active,
            country_of_origin_id: Number(this.form.country_of_origin_id),
            name: this.form.name,
        }

        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }
            let res;
            if (edit) {
                res = await axios.put(api.brands, payload, {headers});
            } else {
                res = await axios.post(api.brands, payload, {headers});
            }
            this.message = res?.data.message;
            toast.success(this.message);

            return res
        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.isLoading = false;
        }
    }

    public setField = (form: BrandModel) => {
        this.form = form;
    }

    public destroy = () => {
        this.form = initialValues;
    }

    public get countriesOptions() {
        if (!this.countries) return([]);
        return this.countries.map((country: any) => ({
            label: country.name,
            value: country.id
        }));
    }
}

export default new BrandsCreateEditStore()