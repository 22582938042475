import axios from "axios";
import { makeAutoObservable } from "mobx";
import { api } from "../../../api/endpoints";
import AppStore from "../../../app.store";
import { toast } from "react-toastify";

const formInitialValue = {
  product_id: 0,
  amount: 0,
  quantity: 0,
  details: "",
  client: 0,
  clients: [],
  recommended_price: 0,
};

class ProductsTransactionsModalStore {
  isLoading = false;
  form = formInitialValue;
  errorMessage = "";
  public error = "";
  public message = "";

  constructor() {
    makeAutoObservable(this);
  }

  public destroy = () => {
      this.form = formInitialValue;
      this.isLoading = false;
  }

  makeTransaction = async (callback: () => void) => {
    const { getToken } = AppStore;
    const url = new URL(api.transactions);

    const headers = {
      Authorization: "Bearer " + getToken(),
    };
    const payload = {
      product_id: this.form.product_id,
      amount: Number(this.form.amount),
      quantity: Number(this.form.quantity),
      details: this.form.details,
    };

    this.isLoading = true;
    try {
      const response = await axios.post(url.toString(), payload, {
        headers,
      });
      callback();
      // if (res?.code >= 400) {
      //     throw new Error(res)
      // }
      this.message = response?.data.message;
      toast.success(this.message);

    } catch (err: any) {
      this.error = err?.response.data.reason;
      toast.error(this.error);
      // this.errorMessage = err?.response.data.reason;
    } finally {
      this.isLoading = false;
    }
  };

  getClients = async () => {
    const { getToken } = AppStore;
    const url = new URL(process.env.REACT_APP_API + "/visor/clients");
    url.searchParams.set("page_limit", "10000");

    const headers = {
      Authorization: "Bearer " + getToken(),
    };

    try {
      const response = await axios.get<any>(url.toString(), { headers });
      console.log(response);
      this.form = { ...this.form, clients: response.data.clients };
    } catch (error: any) {

    }
  };

  setForm = (product: typeof this.form) => {
    this.form = product;
  };

  resetFormFields = () => {
    this.form = formInitialValue;
  };
}

export default new ProductsTransactionsModalStore();
