import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {useState} from "react";
import {Text} from "../../styles/text";
import {Button} from "../../styles/button";
import {EyeImgIcon, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../styles/table";
import EyeIcon from "../../assets/icons/eye.svg";
import {primaryColor} from "../../utils/variables";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import ModalView from "../../components/modal/modal.view";
import {Card} from "./cards.model";

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface ClientDocumentsMobileViewProps {
    loading: boolean;
    cards: Card[] | undefined;
    handleEditClick: (card: Card) => void;
    setConfirmDeleteModal: ({
                                isModalOpen,
                                id,
                                name,
                            }: setConfirmDeleteModalProps) => void;
    handleClickDownloadCardDocument: (card: Card, docType: string) => void;
}

const CardsMobileView = observer(({
                                      loading,
                                      cards,
                                      handleEditClick,
                                      setConfirmDeleteModal,
                                      handleClickDownloadCardDocument
                                  }: ClientDocumentsMobileViewProps) => {
            const [modal, setModal] = useState(false);
            const [initialValue, setInitialValue] = useState<Card | null>(null);

            const handleRowClick = (card: Card) => {
                setModal(true);
                setInitialValue(card);
            };

            const handleCloseModal = () => {
                setModal(false);
                setInitialValue(null);
            };

            const renderModalItems = (card: Card) => (
                <Layout vertical between={10}>
                    <Layout hAlign={'space-between'}>
                        <Text weight={'bold'}>Номер карты</Text>
                        <Text>{card.pan}</Text>
                    </Layout>
                    <Layout hAlign={'space-between'}>
                        <Text weight={'bold'}>Тип</Text>
                        <Text>{card.card_type}</Text>
                    </Layout>
                    <Layout hAlign={'space-between'}>
                        <Text weight={'bold'}>Банк</Text>
                        <Text>{card.bank}</Text>
                    </Layout>
                    <Layout hAlign={'center'} between={5}>
                        <Button onClick={() => handleEditClick(card)}>Изменить</Button>
                        <Button onClick={() => handleClickDownloadCardDocument(card, 'request')}>Заявление</Button>
                        <Button onClick={() => handleClickDownloadCardDocument(card, 'agreement')}>Договор</Button>
                        <Button
                            background={'rgb(146,4,4)'}
                            onClick={() =>
                                setConfirmDeleteModal({
                                    isModalOpen: true,
                                    id: card.id,
                                    name: `${initialValue?.pan}`,
                                })
                            }
                        >
                            Удалить
                        </Button>
                    </Layout>
                </Layout>
            );

            const renderedItems = () => (
                <>
                    <Table>
                        <TableHead>
                            <TRow tableHead>
                                <THeadCell>Номер карты</THeadCell>
                                <THeadCell>Тип</THeadCell>
                                <THeadCell>Банк</THeadCell>
                                <THeadCell></THeadCell>
                            </TRow>
                        </TableHead>
                        {!loading ? (
                            <TableBody>
                                {cards?.length ? (
                                    cards?.map((card, index) => (
                                        <TRow key={index} menu>
                                            <TCell>{card.pan}</TCell>
                                            <TCell>{card.card_type}</TCell>
                                            <TCell>{card.bank}</TCell>
                                            <TCell relative>
                                                <EyeImgIcon
                                                    src={EyeIcon}
                                                    onClick={() => handleRowClick(card)}
                                                    height="18px"
                                                    color={primaryColor}
                                                />
                                            </TCell>
                                        </TRow>
                                    ))
                                ) : (
                                    <NoResultTitle colspan={4}/>
                                )}
                            </TableBody>
                        ) : (
                            <TableSkeleton columns={4}/>
                        )}
                    </Table>
                    {modal && (
                        <ModalView
                            title={`Данные документа ${initialValue?.pan}`}
                            onClose={handleCloseModal}
                        >
                            {renderModalItems(initialValue!)}
                        </ModalView>
                    )}
                </>
            );

            return <Layout>{renderedItems()}</Layout>;
        }
    )
;

export default CardsMobileView;