import axios, { GenericAbortSignal } from 'axios';
import { makeAutoObservable } from 'mobx';
import { api } from '../../../api/endpoints';
import AppStore from '../../../app.store';
import { BusinessUnitModel } from './business_units.model';
import { toast } from 'react-toastify';
import TransactionsStore from '../../transactions/transactions.store';

const formInitialValue: Form = {
  product_id: 0,
  amount: 0,
  quantity: 0,
  client: 0,
  clients: [],
  recomdended_price: 0,
  set_price: 0,
  unit: [],
  company: '',
  bu_id: 0,
  total_amount: 0,
  client_id: 1,
  account_id: 1,
  payment_method_id: 1,
  details: '',
  account: '',
  type_id: 1,
  products: [],
};

interface Form {
  product_id: number;
  amount: number;
  quantity: number;
  client: number;
  clients: any[];
  recomdended_price: number;
  set_price: number;
  unit: any[];
  company: string;

  //
  bu_id: number;
  total_amount: number;
  client_id: number;
  account_id: number;
  payment_method_id: number;
  details: string;
  account: string;
  type_id: number;
  products: any[];
}

class ProductsAllSellStore {
  isLoading = false;
  form = formInitialValue;
  errorMessage = '';
  message = '';
  selectedValues = {};
  selectedProducts = [];
  products = [];
  selectedProduct: any = { goods_id: '' };
  transactions = TransactionsStore;
  public paymentMethods: any[] = [];
  serviceAccounts = [];

  setProduct(product: any) {
    this.selectedProduct = product;
  }

  changeForm = (name: string, value: string | number) => {
    // @ts-ignore
    this.form[name as keyof Form] = value;
  };

  setProductField = (product: any, value: any, name: any) => {
    product[name] = value;
    product.totalSumm = product.summ * product.set_price;
  };

  setProductTotalSumm = (product: any, value: any) => {
    product.totalSumm = value;
  };

  selectSubmit = (product: any) => {
    // @ts-ignore
    this.selectedProducts.push(product);
  };

  deleteProduct(id: any) {
    this.selectedProducts = this.selectedProducts.filter(
      (item: any) => item.id !== id
    );
  }
  constructor() {
    makeAutoObservable(this);
  }

  makeTransaction = async (callback: () => void) => {
    const { getToken } = AppStore;
    const url = new URL(api.transactions);

    const headers = {
      Authorization: 'Bearer ' + getToken(),
    };
    const payload = {
      product_id: this.form.product_id,
      amount: Number(this.form.amount),
      quantity: Number(this.form.quantity),
      details: this.form.details,
    };

    this.isLoading = true;
    try {
      const response = await axios.post<string>(url.toString(), payload, {
        headers,
      });
      callback();
    } catch (error: any) {
      this.errorMessage = error?.response.data.reason;
    } finally {
      this.isLoading = false;
    }
  };

  allSell = async () => {
    const { getToken } = AppStore;
    const url = new URL(api.transactions);

    const headers = {
      Authorization: 'Bearer ' + getToken(),
    };

    const payload = {
      bu_id: Number(this.form.bu_id),
      client_id: Number(this.form.client_id),
      account_id: Number(this.form.account_id),
      total_amount: Number(this.form.total_amount),
      details: this.form.details,
      payment_method_id: Number(this.form.payment_method_id),
      account: this.form.account,
      type_id: Number(this.form.type_id),
      products: [],
    };

    const products = this.selectedProducts.map((item: any) => ({
      account: item.account,
      amount: Number(item.summ),
      product_id: item.id,
      quantity: Number(item.summ),
      total_summ: Number(item.totalSumm),
    }));

    // @ts-ignore
    payload.products = products;

    this.isLoading = true;
    try {
      const response = await axios.post(url.toString(), payload, {
        headers,
      });

      this.message = response.data.message;
      toast.success(this.message);
    } catch (error: any) {
      this.errorMessage = error?.response.data.reason;
      toast.error(this.errorMessage);
    } finally {
      this.isLoading = false;
      void this.transactions.transactionsReq();
    }
  };

  getClients = async () => {
    const { getToken } = AppStore;
    const url = new URL(process.env.REACT_APP_API + '/clients');
    url.searchParams.set('page_limit', '10000');
    url.searchParams.set('technical', 'true');

    const headers = {
      Authorization: 'Bearer ' + getToken(),
    };

    try {
      const response = await axios.get<any>(url.toString(), { headers });
      this.form.clients = response.data.clients;
    } catch (error: any) {}
  };

  getProducts = async (signal?: AbortSignal) => {
    const { getToken } = AppStore;
    const url = new URL(api.get_business_goods);
    const headers = { Authorization: 'Bearer ' + getToken() };

    url.searchParams.set('page', '1');
    url.searchParams.set('page_limit', '9999');
    url.searchParams.set('order_descending', 'false');

    try {
      const response = await axios.get(url.toString(), { headers, signal });
      this.products = response.data.stocks;
    } catch (error) {}
  };

  public getServiceAccounts = async (client_id: number, service_id: number) => {
    const headers = {
      Authorization: 'Bearer ' + AppStore.getToken(),
    };
    try {
      const response = await axios.get(
        api.transactions_service_accounts +
          '?client_id=' +
          client_id +
          '&service_id=' +
          service_id,
        { headers }
      );
      const { accounts } = response.data;
      this.serviceAccounts = accounts;
    } catch (error) {}
  };

  setForm = (product: typeof this.form) => {
    this.form = product;
  };

  resetFormFields = () => {
    this.form = formInitialValue;
  };

  public businessUnits?: BusinessUnitModel[] = [];
  public loading = false;

  public businessUnitsInit = (signal: GenericAbortSignal) => {
    void this.businessUnitsReq(signal);
  };

  public paymentMethodsInit = (signal: GenericAbortSignal) => {
    void this.paymentMethodsReq(signal);
  };

  setBusinessUnits(data: any) {
    const { business_units } = data;
    this.businessUnits = business_units;
  }

  setPaymentMethods(data: any) {
    this.paymentMethods = data;
  }

  public businessUnitsReq = async (signal?: GenericAbortSignal) => {
    this.setLoading(true);
    try {
      const { getToken } = AppStore;

      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };

      const response = await axios.get(api.get_business_units, {
        headers,
        signal,
      });
      this.setBusinessUnits(response.data);
    } catch (err: any) {
    } finally {
      this.form.bu_id = this.businessUnits![0].id;
      this.setLoading(false);
    }
  };

  public paymentMethodsReq = async (signal?: GenericAbortSignal) => {
    this.setLoading(true);

    try {
      const { getToken } = AppStore;

      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };

      const response = await axios.get(api.payment_methods, {
        headers,
        signal,
      });
      this.setPaymentMethods(response.data);
    } catch (err) {
    } finally {
      this.setLoading(false);
    }
  };

  private setLoading(loadingState: boolean) {
    this.loading = loadingState;
  }

  public destroy = () => {
    this.selectedProducts = [];
    this.products = [];
    this.form = formInitialValue;
  };
}

export default new ProductsAllSellStore();
