import {ClientDocumentsModel} from "../client.documents.model";
import {makeAutoObservable} from "mobx";
import {api} from "../../../api/endpoints";
import AppStore from "../../../app.store";
import {toast} from "react-toastify";
import axios from "axios";
import dayjs from "dayjs";

const loadingInitialState: boolean = false;
const formInitialValue: ClientDocumentsModel = {
    id: 0,
    client_id: 0,
    name: '',
    surname: '',
    patronymic: '',
    series: '',
    number: '',
    inn: '',
    address: '',
    issued_by: '',
    issue_date_text: '',
    expiration_date_text: '',
    type_id: 1, //1 pass, 2-driver license, 3-military ticket, 4-pension doc, 5-other
    extra: '',
    blood_type: '',
    family_status: 0, //1-Состоит в браке 2-Не состоит в браке,
    gender: 0, //1 муж 2 жен
    birthday: '',
    birthday_text: '',
    issue_date: '',
    expiration_date: '',
}

class ClientDocumentCreateEditStore {
    public form = formInitialValue;
    public isLoading = loadingInitialState;
    message: string = '';
    error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public destroy = () => {
        this.form = formInitialValue;
        this.isLoading = loadingInitialState;
    }

    public initCreateEditPage = async (initialValue: ClientDocumentsModel | null) => {
        if (initialValue) {
            this.form = initialValue;
            const issueDate = dayjs(initialValue.issue_date);
            const expirationDate = dayjs(initialValue.expiration_date);
            const birthday = dayjs(initialValue.birthday);
            console.log('issueDate', issueDate);
            const issueDateFormatted = issueDate.format("YYYY-MM-DD");
            const expirationDateFormatted = expirationDate.format("YYYY-MM-DD");
            const birthdayFormatted = birthday.format("YYYY-MM-DD");
            console.log('issueDateFormatted', issueDateFormatted);
            this.form.issue_date = issueDateFormatted;
            this.form.expiration_date = expirationDateFormatted;
            this.form.birthday = birthdayFormatted;
            console.log('this.form', this.form);
        } else {
            this.form = formInitialValue;
        }
    }

    setLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    public setField = (form: ClientDocumentsModel) => {
        this.form = form
    }

    public documentCreateEditReq = async (clientID: number, edit?: boolean) => {
        this.setLoading(true);
        const url = api.client_documents
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        const payload = {
            id: Number(this.form.id),
            client_id: Number(clientID),
            name: this.form.name,
            surname: this.form.surname,
            patronymic: this.form.patronymic,
            series: this.form.series,
            number: this.form.number,
            inn: this.form.inn,
            address: this.form.address,
            issued_by: this.form.issued_by,
            issue_date_text: this.form.issue_date,
            expiration_date_text: this.form.expiration_date,
            gender: Number(this.form.gender),
            type_id: Number(this.form.type_id),
            family_status: Number(this.form.family_status),
            blood_type: this.form.blood_type,
            extra: this.form.extra,
            birthday_text: this.form.birthday,
        }

        try {
            let res;
            if (edit) {
                res = await axios.put(url, payload, {headers});
            } else {
                res = await axios.post(url, payload, {headers});
            }
            this.message = res?.data.message;
            toast.success(this.message);
            return res;
        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }
}

export default new ClientDocumentCreateEditStore();