import {ClientDocumentsModel} from "./client.documents.model";
import {makeAutoObservable} from "mobx";
import {api} from "../../api/endpoints";
import axios from "axios";
import AppStore from "../../app.store";
import {toast} from "react-toastify";

const documentsInitialState: ClientDocumentsModel[] = [];
const loadingInitialState: boolean = false;

class ClientDocumentsStore {
    public documents = documentsInitialState;
    public loading = loadingInitialState;
    message: string = '';
    error: string = '';


    constructor() {
        makeAutoObservable(this);
    }

    public destroy = () => {
        this.documents = documentsInitialState;
        this.loading = loadingInitialState
    }

    setLoading(isLoading: boolean) {
        this.loading = isLoading;
    }

    setDocuments(data: any) {
        if (data) {
            this.documents = data;
        }
    }

    public initDocumentsPage = async (clientID: number, signal: AbortSignal, typeID?: number,) => {
        await this.documentsReq(clientID, typeID, signal)
    }

    public documentsReq = async (clientID: number, typeID?: number, signal?: AbortSignal) => {
        this.setLoading(true);
        let url = api.client_documents + `/${clientID}`
        if (typeID) {
            url += `?type_id=${typeID}`
        }

        const headers = this.getHeaders();

        try {
            const response = await axios.get(url, {headers, signal})
            this.setDocuments(response.data);
        } catch (e: any) {
            console.log(e)
        } finally {
            this.setLoading(false);
        }
    }

    public deleteDocumentReq = async (docID: number) => {
        this.setLoading(true);

        const headers = this.getHeaders();

        try {
            const response = await axios.delete(api.client_documents + `/${docID}`, {headers});
            this.message = response?.data.message;
            toast.success(this.message);
            return response;
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }

    private getHeaders = () => {
        const {getToken} = AppStore;
        return {
            Authorization: 'Bearer ' + getToken()!
        };
    }
}

export default new ClientDocumentsStore();