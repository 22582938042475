import axios from "axios";

export const handleDownloadFile = async (url: string, token: string | null, fileName: string, fileType: string) => {
    let contentType: string = "";

    switch (fileType) {
        case 'pdf':
            contentType = 'application/pdf';
            break;
        case 'excel':
            contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            break;
    }

    try {
        const response = await axios({
            method: 'GET',
            url,
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        const contentDisposition =
            response.headers['content-disposition'];

        if (contentDisposition) {
            const parts = contentDisposition.split(';')[1].trim().split("=")[1]
            if (parts) {
                fileName = parts;
            }
        } else {
            fileName = fileName + '.' + fileType;
        }

        const link = document.createElement('a');
        link.href = URL.createObjectURL(new Blob([response.data], {type: contentType}));
        link.setAttribute('download', fileName);
        document.body.appendChild(link);

        link.click();
        link.remove();
    } catch {
        console.log("Error");
    }
}