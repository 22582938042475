import {ExpenseCategoriesFiltersModel, ExpenseCategoriesModel} from "./expense.categories.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import {toast} from "react-toastify";
import axios from "axios";
import {api} from "../../../api/endpoints";

const categoriesInitialState: ExpenseCategoriesModel[] = [];
const loadingInitialState: boolean = false;
const filtersInitialState: ExpenseCategoriesFiltersModel = {
    search: '',
    order_by: '',
    order_descending: '',
    pages: 0,
    page: 1,
    page_limit: 25
}

class ExpenseCategoriesStore {
    public categories? = categoriesInitialState;
    public isLoading = loadingInitialState;
    public categoriesFilters = filtersInitialState;
    public deleteLoading = loadingInitialState;
    error: string = '';
    message: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public expenseCategoriesPageInit = async (signal: AbortSignal) => {
        await this.expenseCategoriesReq(signal);
    }

    setCategories(data: any) {
        const {categories} = data;
        this.categories = categories;
    }

    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    setCategoriesPages(pages: number) {
        this.categoriesFilters.pages = pages;
    }

    public expenseCategoriesReq = async (signal?: AbortSignal) => {
        this.setLoading(true);

        const {getToken} = AppStore;

        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        const filterUrl = Object.keys(this.categoriesFilters).reduce((filters, filter) => {
            const symbol = filters ? '&' : '?';
            const filterKeyName = filter as keyof ExpenseCategoriesFiltersModel;
            if (this.categoriesFilters[filterKeyName] !== 0 &&
                this.categoriesFilters[filterKeyName] !== '') {
                filters += symbol + filter + '=' + this.categoriesFilters[filterKeyName];
            }

            return filters;
        }, '');

        try {
            const response = await axios.get(api.expenses + `/categories${filterUrl}`, {headers, signal});
            this.setCategories(response.data);
            this.setCategoriesPages(response.data.pages);
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }

    public setCategoriesFilter = ({search, page, page_limit}: ExpenseCategoriesFiltersModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.categoriesFilters = {
            ...this.categoriesFilters,
            search: isUndefined(search) ? this.categoriesFilters.search! : search!,
            page: isUndefined(page) ? this.categoriesFilters.page! : page!,
            page_limit: isUndefined(page_limit) ? this.categoriesFilters.page_limit! : page_limit!
        };

        if (page_limit) {
            this.categoriesFilters.page = 1;
        }

        void this.expenseCategoriesReq();
    }

    public deleteExpenseCategoryReq = async (id: number) => {
        this.deleteLoading = true;
        const {getToken} = AppStore
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        try {
            const response = await axios.delete(api.expenses + `/categories/${id}`, {headers})
            this.message = response.data.message;
            toast.success(this.message);

            if (response) {
                void this.expenseCategoriesReq();
            }
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.deleteLoading = false;
        }
    }

    public clearFilter = () => {
        this.categoriesFilters = filtersInitialState;
    }
}

export default new ExpenseCategoriesStore();