import {ExpenseOperationsFilterModel, ExpenseOperationsModel} from "./expense.operations.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import {toast} from "react-toastify";
import axios from "axios";
import {api} from "../../../api/endpoints";

const operationsInitialState: ExpenseOperationsModel[] = [];
const loadingInitialState: boolean = false;
const filtersInitialState: ExpenseOperationsFilterModel = {
    search: '',
    confirmed: '',
    order_by: '',
    order_descending: '',
    pages: 0,
    page: 1,
    page_limit: 25,
}

class ExpenseOperationsStore {
    public expenseOperations? = operationsInitialState;
    public isLoading = loadingInitialState;
    public confirmLoading = loadingInitialState;
    public expenseOperationFilters = filtersInitialState;
    message: string = '';
    error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public expenseOperationsPageInit = async (signal: AbortSignal) => {
        await this.expenseOperationsReq(signal);
    }

    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    setOperations(data: any) {
        const {operations} = data;
        this.expenseOperations = operations;
    }

    setExpenseOperationsPages(pages: number) {
        this.expenseOperationFilters.pages = pages;
    }

    public expenseOperationsReq = async (signal?: AbortSignal) => {
        this.setLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        const filterUrl = Object.keys(this.expenseOperationFilters).reduce((filters, filter) => {
            const symbol = filters ? '&' : '?';
            const filterKeyName = filter as keyof ExpenseOperationsFilterModel;
            if (this.expenseOperationFilters[filterKeyName] !== 0 &&
                this.expenseOperationFilters[filterKeyName] !== '') {
                filters += symbol + filter + '=' + this.expenseOperationFilters[filterKeyName];
            }

            return filters;
        }, '');

        try {
            const response = await axios.get(api.expenses + `/operations${filterUrl}`, {headers, signal});
            this.setOperations(response.data);
            this.setExpenseOperationsPages(response.data.pages);
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }

    public setExpenseOperationsFilter = ({search,  confirmed, page, page_limit}: ExpenseOperationsFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.expenseOperationFilters = {
            ...this.expenseOperationFilters,
            search: isUndefined(search) ? this.expenseOperationFilters.search! : search!,
            confirmed: isUndefined(confirmed) ? this.expenseOperationFilters.confirmed! : confirmed!,
            page: isUndefined(page) ? this.expenseOperationFilters.page! : page!,
            page_limit: isUndefined(page_limit) ? this.expenseOperationFilters.page_limit! : page_limit!
        };

        if (page_limit) {
            this.expenseOperationFilters.page = 1;
        }

        void this.expenseOperationsReq();
    }

    public confirmExpenseOperationReq = async (id: number) => {
        this.confirmLoading = true;

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        try {
            const response = await axios.put(api.expenses + `/operations/${id}`, {}, {headers});
            this.message = response.data.message;
            toast.success(this.message);

            if (response) {
                await this.expenseOperationsReq();
            }
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.confirmLoading = false;
        }
    }

    public clearFilter = () => {
        this.expenseOperationFilters = filtersInitialState;
    }
}

export default new ExpenseOperationsStore();