import {MainActions} from "./main.actions";
import DictionaryStore from "../../utils/dictionary/dictionary.store";

export function getModalTitle(entity: string) {
    const dictionary = DictionaryStore;

    switch (entity) {
        case 'TRANSACTIONS':
            return dictionary.getSidebarLabel('TRANSACTIONS').toLowerCase()
        case 'BUSINESS_UNITS':
            return dictionary.getSidebarLabel('BUSINESS UNITS').toLowerCase()
        case 'PRODUCT_MASS':
            return dictionary.getSidebarLabel('PRODUCTS').toLowerCase()
        case 'PRODUCT_SINGLE':
            return dictionary.getSidebarLabel('PRODUCTS').toLowerCase()
        case 'PRODUCT_BRANDS':
            return dictionary.getSidebarLabel('BRANDS').toLowerCase()
        case 'PRODUCT_CATEGORIES':
            return dictionary.getSidebarLabel('PRODUCT CATEGORIES').toLowerCase()
        case 'SUPPLIERS':
            return dictionary.getSidebarLabel('SUPPLIERS').toLowerCase()
        case 'BUSINESS_STOCKS':
            return dictionary.getSidebarLabel('BUSINESS STOCKS').toLowerCase()
        case 'TRANSFERS':
            return dictionary.getSidebarLabel('TRANSFERS').toLowerCase()
        case 'CLIENTS':
            return dictionary.getSidebarLabel('CLIENTS').toLowerCase()
        case 'USERS':
            return dictionary.getSidebarLabel('USERS').toLowerCase()
        case 'ROLES':
            return dictionary.getSidebarLabel('ROLES').toLowerCase()
        case 'EXPENSE_OPERATION':
            return dictionary.getSidebarLabel('EXPENSE OPERATIONS').toLowerCase()
        case 'EXPENSES':
            return dictionary.getSidebarLabel('PLANNED EXPENSES').toLowerCase()
        case 'EXPENSE_CATEGORY':
            return dictionary.getSidebarLabel('EXPENSE CATEGORIES').toLowerCase()
        case 'PAYROLLS':
            return dictionary.getSidebarLabel('PAYROLL PAYMENTS').toLowerCase()
        case 'EMPLOYEE_ALLOWANCE':
            return dictionary.getSidebarLabel('EMPLOYEE ALLOWANCES').toLowerCase()
        case 'EMPLOYEE_DEDUCTIONS':
            return dictionary.getSidebarLabel('EMPLOYEE DEDUCTIONS').toLowerCase()
        case 'ALLOWANCE':
            return dictionary.getSidebarLabel('ALLOWANCES').toLowerCase()
        case 'DEDUCTION':
            return dictionary.getSidebarLabel('DEDUCTIONS').toLowerCase()
        case 'STATUSES':
            return dictionary.getSidebarLabel('STATUSES').toLowerCase()
        default:
            return ''
    }
}