import {observer} from 'mobx-react';
import {Nav, NavList, Arrow} from '../../styles/nav';
import {Image} from '../../styles/image';
import AppStore from "../../app.store";
import {Layout} from '../../styles/layout';
import {headerSize, primaryColorGradient} from '../../utils/variables';
import Logo from '../../assets/visor-logo.png';
import SmallLogo from '../../assets/logo.svg';
import ArrowIcon from '../../assets/icons/caret-right.svg';
import React, {useRef} from "react";
import BurgerView from "./burger.view";
import {RenderNavItems} from "./navbar.list";

const Navbar = observer(() => {
    const {
        isNavOpen,
        toggleIssNavOpen
    } = AppStore;

    return (
        <>
            <Nav collapsed={isNavOpen}>
                <Layout centered heightSize={headerSize} background={primaryColorGradient}>
                    <Image
                        src={isNavOpen ? Logo : SmallLogo}
                        height={isNavOpen ? '60px' : '50px'}
                        padding={10}
                    />
                </Layout>
                <NavList>
                    <RenderNavItems
                        setOpen={() => false}
                    />
                </NavList>
                <Arrow
                    src={ArrowIcon}
                    height="26px"
                    rotate={isNavOpen ? 180 : 0}
                    onClick={toggleIssNavOpen}
                />
            </Nav>
            <BurgerView/>
        </>
    );
});

export default Navbar;
