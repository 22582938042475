import { observer } from 'mobx-react';
import AppStore from '../../app.store';
import BrandsStore from './brands.store';
import { useEffect, useState } from 'react';
import { Page } from '../../styles/page';
import { Headline } from '../../styles/headline';
import { primaryColor } from '../../utils/variables';
import {
  AddButton,
  Search,
  SearchLayout,
  TableHeader,
} from '../../styles/table-header';
import { Image } from '../../styles/image';
import SearchIcon from '../../assets/icons/search.svg';
import { Layout } from '../../styles/layout';
import {
  ContextMenu,
  ContextMenuItem,
  Table,
  TableBody,
  TableContainer,
  TableContainerMobile,
  TableHead,
  TCell,
  THeadCell,
  TRow,
} from '../../styles/table';
import NoResultTitle from '../../components/no_result_title/no_result_title.view';
import ContextMenuIcon from '../../assets/icons/context-menu.svg';
import Modal from '../../components/modal/modal.view';
import { BrandModel } from './brands.model';
import { Filters } from '../../styles/filters';
import ConfirmDelete, {
  DeleteItem,
} from '../../components/confirm/confirm.delete';
import { Badge } from '../../styles/badge';
import BrandsCreateEditView from './brands.modal/brands.create.edit.view';
import TableSkeleton from '../../utils/skeleton/table.skeleton';
import TablePaginationView from '../../utils/table.pagination.view';
import BrandsMobileView from './brands.mobile.view';
import { Button, FilterButton } from '../../styles/button';
import BrandsFiltersView from './brands.filters.view';
import DictionaryStore from "../../utils/dictionary/dictionary.store";

const BrandsView = observer(() => {
  const { isNavOpen } = AppStore;
  const {
    brands,
    brandsFilter,
    brandsPageInit,
    setBrandsFilter,
    loading,
    deleteBrand,
    clearFilter,
  } = BrandsStore;
  const [modal, setModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<DeleteItem>({
    isModalOpen: false,
  });
  const [initialValue, setInitialValue] = useState<BrandModel | null>(null);
  const [filterModal, setFilterModal] = useState(false);
  const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;

  useEffect(() => {
    destroyDictionary();
    const controller = new AbortController();
    dictionaryListReq('BRANDS', 'TABLE HEADERS', controller.signal);
    brandsPageInit(false, controller.signal);
    return () => {
      controller.abort();
    };
  }, [destroyDictionary, dictionaryListReq, brandsPageInit]);

  const handleFilterChange = (e: any, objectKey: string, index?: number) => {
    const {
      target: { options, selectedIndex },
    } = e;
    if (objectKey === 'page' && index !== undefined) {
      setBrandsFilter({ page: index + 1 });
      return;
    }
    setBrandsFilter({ [objectKey]: options[selectedIndex].value });
  };

  const cancelSearch = (e: any) => {
    if (e.target.value === '' && brandsFilter.search !== '') {
      setBrandsFilter({ search: '' });
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setBrandsFilter({ search: e.target.value });
    }
  };

  const handleEditClick = (brand: BrandModel) => {
    setModal(true);
    setInitialValue(brand);
  };

  const handleContext = (e: any) => {
    e.stopPropagation();
    document.querySelector('.context-menu-focused');
    if (e.target.classList.contains('context-menu-focused')) {
      e.target.classList.remove('context-menu-focused');
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
      e.target.classList.add('context-menu-focused');
    }
  };

  const handleDelete = async () => {
    await deleteBrand(confirmDeleteModal.id!);
    setConfirmDeleteModal({ isModalOpen: false });
  };

  const prevPage = () => {
    setBrandsFilter({ page: brandsFilter.page - 1 });
  };

  const nextPage = () => {
    setBrandsFilter({ page: brandsFilter.page + 1 });
  };

  const handleClearFilter = () => {
    clearFilter();
    setFilterModal(false);
  };

  const handleConfirmFilter = () => {
    setFilterModal(false);
  };

  const pageLimits = [25, 50, 100, 500];

  return (
    <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
      <>
        <Headline color={primaryColor}>{getPageTitle('BRANDS')}</Headline>
        <TableHeader>
          <Filters hAlign="space-between" wrapped between={10}>
            <Layout between={10} wrapped vAlign="center">
              <SearchLayout>
                <Search
                  type="search"
                  id="search"
                  placeholder="Поиск"
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={(e) => cancelSearch(e)}
                ></Search>
                <Image src={SearchIcon} height="16px" />
              </SearchLayout>
            </Layout>
          </Filters>
          <FilterButton
            id={'brands-filter-button'}
            onClick={() => setFilterModal(true)}
          >
            Фильтры
          </FilterButton>
          <AddButton
            type="button"
            id="add-brand"
            onClick={() => setModal(true)}
          >
            Создать
          </AddButton>
        </TableHeader>
        <TableContainer>
          <Table>
            <TableHead>
              <TRow tableHead>
                <THeadCell>{getTableHeaders('id')}</THeadCell>
                <THeadCell>{getTableHeaders('name')}</THeadCell>
                <THeadCell>{getTableHeaders('description')}</THeadCell>
                <THeadCell>{getTableHeaders('country_of_origin')}</THeadCell>
                <THeadCell>{getTableHeaders('active')}</THeadCell>
                <THeadCell>{getTableHeaders('img')}</THeadCell>
                <THeadCell>{getTableHeaders('formatted_date')}</THeadCell>
                <THeadCell></THeadCell>
              </TRow>
            </TableHead>
            {loading ? (
              <TableSkeleton columns={8} />
            ) : (
              <TableBody>
                {brands!.length ? (
                  brands?.map((brand, index) => (
                    <TRow key={index} menu>
                      <TCell>{brand.id}</TCell>
                      <TCell>{brand.name}</TCell>
                      <TCell>{brand.description}</TCell>
                      <TCell>{brand.country_of_origin}</TCell>
                      <TCell>
                        <Badge online={brand.active}>
                          {brand.active ? 'Активный' : 'Неактивный'}
                        </Badge>
                      </TCell>
                      <TCell>{brand.img}</TCell>
                      <TCell>{brand.formatted_date}</TCell>
                      <TCell relative>
                        <Image
                          src={ContextMenuIcon}
                          height="18px"
                          onClick={handleContext}
                        />
                        <ContextMenu anchorEl={anchorEl}>
                          <ContextMenuItem
                            onClick={() => handleEditClick(brand)}
                          >
                            Изменить
                          </ContextMenuItem>
                          <ContextMenuItem
                            onClick={() =>
                              setConfirmDeleteModal({
                                isModalOpen: true,
                                id: brand.id,
                                name: brand.name,
                              })
                            }
                          >
                            Удалить
                          </ContextMenuItem>
                        </ContextMenu>
                      </TCell>
                    </TRow>
                  ))
                ) : (
                  <NoResultTitle colspan={8} />
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TableContainerMobile>
          <BrandsMobileView
            brands={brands}
            loading={loading}
            handleEditClick={handleEditClick}
            setConfirmDeleteModal={setConfirmDeleteModal}
            tableHeaders={getTableHeaders}
          />
        </TableContainerMobile>
        <TablePaginationView
          prevPage={prevPage}
          nextPage={nextPage}
          pages={brandsFilter.pages}
          currentPage={brandsFilter.page}
          pageLimits={pageLimits}
          handleFilterChange={handleFilterChange}
        />
      </>
      {filterModal && (
        <Modal title={'Фильтры'} onClose={() => setFilterModal(false)}>
          <Layout vertical between={10}>
            <BrandsFiltersView
              handleKeyDown={handleKeyDown}
              cancelSearch={cancelSearch}
              handleFilterChange={handleFilterChange}
              inModal={true}
            />
            <Layout hAlign={'space-between'} between={10}>
              <Button
                extent={'L'}
                id={'clear-filter-button'}
                onClick={handleClearFilter}
                background={'#333'}
                color={'red'}
              >
                Очистить
              </Button>
              <Button
                extent={'L'}
                id={'confirm-filter-button'}
                onClick={handleConfirmFilter}
              >
                Потвердить
              </Button>
            </Layout>
          </Layout>
        </Modal>
      )}
      {modal && (
        <Modal
          title={initialValue ? 'Изменить бренд' : 'Создать бренд'}
          onClose={() => {
            setModal(false);
            setInitialValue(null);
          }}
        >
          <BrandsCreateEditView
            initialValue={initialValue}
            setModal={setModal}
          />
        </Modal>
      )}
      {confirmDeleteModal.isModalOpen && (
        <Modal
          title="Удалить бренд"
          onClose={() => {
            setConfirmDeleteModal({ isModalOpen: false });
          }}
        >
          <ConfirmDelete
            warningText={`Вы уверены, что хотите удалить бренд? ${confirmDeleteModal.name}?`}
            setModal={setConfirmDeleteModal}
            callback={handleDelete}
            isLoading={loading}
          />
        </Modal>
      )}
    </Page>
  );
});

export default BrandsView;
