import {FilterModel, SalesReportModel} from "./sales.report.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import axios from "axios";
import {api} from "../../../api/endpoints";
import {toast} from "react-toastify";

const reportsInitialState: SalesReportModel[] = [];
const loadingInitialState: boolean = false;
const salesReportFiltersInitialState: FilterModel = {
    report_type: 1,
    entity_id: 0,
    period: 1,
    date_from: '',
    date_to: '',
    order_by: '',
    order_asc: '',
}

const totalInitialState: SalesReportModel = {
    entity_name: '',
    turnover: 0,
    average: 0,
    quantity: 0,
}

class SalesReportStore {
    public salesReportTable = reportsInitialState;
    public loading = loadingInitialState;
    public salesReportGraph = reportsInitialState;
    public salesReportFilters = salesReportFiltersInitialState;
    public buSalesReports = reportsInitialState;
    public userSalesReports = reportsInitialState;
    public totals = totalInitialState;

    error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public clearFilter = () => {
        this.salesReportFilters = salesReportFiltersInitialState;
    }

    setTableLoading(loading: boolean) {
        this.loading = loading;
    }

    setTableReports(data: any) {
        const {resp} = data;
        this.salesReportTable = resp;
    }

    setGraphReports(data: any) {
        const {resp} = data;
        this.salesReportGraph = resp;
    }

    setBuReports(data: any) {
        const {resp} = data;
        this.buSalesReports = resp;
    }

    setUserReports(data: any) {
        const {resp} = data;
        this.userSalesReports = resp;
    }

    setTotals(data: any) {
        const {total} = data;
        this.totals = total
    }

    public getTableReportsReq = async (table: boolean, signal?: AbortSignal) => {
        this.setTableLoading(true)
        let url: string;
        if (table) {
            url = api.sales_reports
        } else {
            url = api.sales_reports + '_trend'
        }

        const {getToken} = AppStore

        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        const filterUrl = this.getFilterUrl();

        try {
            const response = await axios.get(url + filterUrl, {headers, signal})
            this.setTotals(response.data);
            if (table) {
                this.setTableReports(response.data);
            } else {
                this.setGraphReports(response.data)
            }
        } catch (e: any) {
            this.error = e?.response?.data.reason;
            toast.error(this.error)
        } finally {
            this.setTableLoading(false);
        }
    }

    public getBuSalesReportsForPieReq = async (signal?: AbortSignal) => {
        const {getToken} = AppStore

        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        this.salesReportFilters.report_type = 1;
        const filterUrl = this.getFilterUrl();

        try {
            const response = await axios.get(api.sales_reports + filterUrl, {headers, signal});
            this.setBuReports(response.data);
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {

        }
    }

    public getUserSalesReportsForPieReq = async (signal?: AbortSignal) => {
        const {getToken} = AppStore

        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        this.salesReportFilters.report_type = 2;
        const filterUrl = this.getFilterUrl();

        try {
            const response = await axios.get(api.sales_reports + filterUrl, {headers, signal});
            this.setUserReports(response.data);
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {

        }
    }

    private getFilterUrl = () => {
        return Object.keys(this.salesReportFilters).reduce((filters, filter) => {
            const symbol = filters ? '&' : '?';
            const filterKeyName = filter as keyof FilterModel;
            if (this.salesReportFilters[filterKeyName] !== 0 &&
                this.salesReportFilters[filterKeyName] !== '') {
                filters += symbol + filter + '=' + this.salesReportFilters[filterKeyName];
            }

            return filters;
        }, '')
    }

    public setSalesReportFilters = ({
                                        entity_id,
                                        report_type,
                                        date_to,
                                        date_from,
                                        period,
                                        order_asc,
                                        order_by
                                    }: FilterModel, table: boolean) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.salesReportFilters = {
            ...this.salesReportFilters,
            entity_id: isUndefined(entity_id) ? this.salesReportFilters.entity_id : entity_id!,
            report_type: isUndefined(report_type) ? this.salesReportFilters.report_type : report_type!,
            date_to: isUndefined(date_to) ? this.salesReportFilters.date_to : date_to!,
            date_from: isUndefined(date_from) ? this.salesReportFilters.date_from : date_from!,
            period: isUndefined(period) ? this.salesReportFilters.period : period!,
            order_asc: isUndefined(order_asc) ? this.salesReportFilters.order_asc : order_asc!,
            order_by: isUndefined(order_by) ? this.salesReportFilters.order_by : order_by!,
        }

        void this.getTableReportsReq(table)
    }
}

export default new SalesReportStore();