import {observer} from "mobx-react";
import {Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../styles/table";
import {Badge} from "../../styles/badge";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import {SerialNumbersModel} from "./product_serial_numbers.model";

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface SerialNumbersTableMobileViewProps {
    loading: boolean;
    serialNumbers: SerialNumbersModel[] | null
    handleContext: (e: any) => void;
    //setConfirmDeleteModal: ({isModalOpen, id, name}: setConfirmDeleteModalProps) => void;
    anchorEl: any;
    //tableHeaders: (key: string) => string;
}

const SerialNumbersTableDesktopView = observer(({
                                            loading,
                                            serialNumbers,
                                            handleContext,
                                            //setConfirmDeleteModal,
                                            anchorEl,
                                            //tableHeaders
                                        }: SerialNumbersTableMobileViewProps) => {
    return (
        <Table>
            <TableHead>
                <TRow tableHead>
                    {/*<THeadCell>{tableHeaders('number')}</THeadCell>
                    <THeadCell>{tableHeaders('created_at')}</THeadCell>
                    <THeadCell>{tableHeaders('sold')}</THeadCell>*/}
                    <THeadCell>Серийный номер</THeadCell>
                    <THeadCell>Статус</THeadCell>
                    <THeadCell>Дата создания</THeadCell>
                </TRow>
            </TableHead>
            {!loading ? (
                <TableBody>
                    {serialNumbers?.length ? (
                        serialNumbers?.map((serialNumber, index) => (
                            <TRow key={index} menu>
                                <TCell>{serialNumber.number}</TCell>
                                <TCell>
                                    <Badge online={!serialNumber.sold}>
                                        {serialNumber.sold ? "Продан" : "В наличии"}
                                    </Badge>
                                </TCell>
                                <TCell>{serialNumber.created_at_text}</TCell>
                            </TRow>
                        ))
                    ) : (
                        <NoResultTitle colspan={8}/>
                    )}
                </TableBody>
            ) : (
                <TableSkeleton columns={8}/>
            )}
        </Table>
    )
});

export default SerialNumbersTableDesktopView;