import { createGlobalStyle } from "styled-components";
import { textColorDark, textColorLight } from "../utils/variables";

export const GlobalStyles = createGlobalStyle`
  * {
    outline: none;
    box-sizing: border-box;
  }
  body {
    margin: 0;
    padding: 0;
    background-color: ${textColorLight};
    font-family: Open-Sans, Helvetica, Sans-Serif;
    color: ${textColorDark};
  }
  a {
    text-decoration: none;
  }
`;
