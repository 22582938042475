import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {EyeImgIcon, Table, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import FinReportsStore from "./fin.reports.store";
import TableSkeleton from "../../../utils/skeleton/table.skeleton";
import NoResultTitle from "../../../components/no_result_title/no_result_title.view";
import EyeIcon from "../../../assets/icons/eye.svg";
import {primaryColor} from "../../../utils/variables";
import {useState} from "react";
import {FinReportsModel} from "./fin.reports.model";
import ModalView from "../../../components/modal/modal.view";
import {Text} from "../../../styles/text";

interface FinReportsMobileViewProps {
    getTableHeaders: (key: string) => string;
}

const FinReportsMobileView = observer(({getTableHeaders}: FinReportsMobileViewProps) => {

    const {loading, finReports, finReportsFilters} = FinReportsStore;
    const [initialValue, setInitialValue] = useState<FinReportsModel | null>(null);
    const [modal, setModal] = useState(false)

    const handleRowClick = (report: FinReportsModel) => {
        setInitialValue(report);
        setModal(true);
    }

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    }

    const renderModal = (report: FinReportsModel) => (
        <Layout vertical between={10}>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{getTableHeaders('entity_name')}</Text>
                <Text>{report.entity_name}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{getTableHeaders('sales')}</Text>
                <Text>{report.sales}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{getTableHeaders('cost')}</Text>
                <Text>{report.cost}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{getTableHeaders('sales_profit')}</Text>
                <Text>{report.sales_profit}</Text>
            </Layout>
            {(finReportsFilters.report_type != 3 && finReportsFilters.report_type != 0) && (
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{getTableHeaders('salary')}</Text>
                    <Text>{report.salary}</Text>
                </Layout>
            )}
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{getTableHeaders('bonuses')}</Text>
                <Text>{report.bonuses}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{getTableHeaders('other_expenses')}</Text>
                <Text>{report.other_expenses}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{getTableHeaders('saldo')}</Text>
                <Text>{report.saldo}</Text>
            </Layout>
        </Layout>
    )

    const renderTable = () => (
        <>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>{getTableHeaders('entity_name')}</THeadCell>
                        <THeadCell>{getTableHeaders('sales')}</THeadCell>
                        <THeadCell>{getTableHeaders('saldo')}</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                {loading ? (<TableSkeleton columns={4}/>) : (
                    finReports?.length ? (
                        finReports.map((report, index) => (
                            <TRow key={index} menu>
                                <TCell>{report.entity_name}</TCell>
                                <TCell>{report.sales}</TCell>
                                <TCell>{report.saldo}</TCell>
                                <TCell relative>
                                    <EyeImgIcon
                                        src={EyeIcon}
                                        onClick={() => handleRowClick(report)}
                                        height="18px"
                                        color={primaryColor}
                                    />
                                </TCell>
                            </TRow>
                        ))
                    ) : (
                        <NoResultTitle colspan={4}/>
                    )
                )}
            </Table>
            {modal && (
                <ModalView
                    title='Подробнее'
                    onClose={handleCloseModal}
                >
                    {renderModal(initialValue!)}
                </ModalView>
            )}
        </>
    )
    return (
        <Layout>{renderTable()}</Layout>
    );
});

export default FinReportsMobileView;