import {ExpensesModel} from "../expenses.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../../app.store";
import axios from "axios";
import {api} from "../../../../api/endpoints";
import {toast} from "react-toastify";

const formInitialValues: ExpensesModel = {
    id: 0,
    category_id: 0,
    business_unit_id: 0,
    name: '',
    description: '',
    amount: 0,
    type: '',
    period: '',
    period_count: 1,
    start_date: '',
    end_date: '',
    post_check: false,
    operation_date: '',
    start_date_formatted: '',
    end_date_formatted: '',
    created_at: '',
    date: '',
    user_name: '',
    category: '',
    business_unit: '',
}
const loadingInitialValue: boolean = false;


class ExpensesCreateEditStore {
    public form = formInitialValues;
    public loading = loadingInitialValue;
    message: string = '';
    error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public init = async (initialValue: ExpensesModel | null, createOperation: boolean) => {
        if (initialValue) {
            this.form = initialValue;
        } else {
            this.form = formInitialValues;
        }
        if (!createOperation) {
            this.form.type = 'PERIODIC'
        }
    }

    public setField = (form: ExpensesModel) => {
        this.form = form;
    }

    public destroy = () => {
        this.form = formInitialValues;
    }

    public createOrEditExpense = async (edit?: boolean, createOperation?: boolean) => {
        this.loading = true;
        let url: string;

        if (createOperation) {
            url = api.expenses + `/operations`
        } else {
            url = api.expenses
        }

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        const payload = {
            id: this.form.id,
            category_id: this.form.category_id,
            business_unit_id: this.form.business_unit_id,
            name: this.form.name,
            description: this.form.description,
            amount: Number(this.form.amount),
            type: this.form.type,
            period: this.form.period,
            period_count: Number(this.form.period_count),
            start_date: this.form.start_date,
            end_date: this.form.end_date,
            post_check: this.form.post_check,
            operation_date: this.form.operation_date,
        }

        try {
            let response;

            if (edit) {
                response = await axios.put(url, payload, {headers});
            } else {
                response = await axios.post(url, payload, {headers});
            }

            this.message = response?.data.message;
            toast.success(this.message);
            return response;
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error)
        } finally {
            this.loading = false;
        }
    }
}

export default new ExpensesCreateEditStore();