import {EmployeeAllowancesModel} from "../employee.allowances.model";
import {makeAutoObservable} from "mobx";
import usersStore from "../../../users/users.store";
import allowancesStore from "../../allowances/allowances.store";
import {toast} from "react-toastify";
import AppStore from "../../../../app.store";
import axios from "axios";
import {api} from "../../../../api/endpoints";

const formInitialState: EmployeeAllowancesModel = {
    allowance: '',
    amount: 0,
    date: '',
    details: '',
    employee: '',
    id: 0,
    paid: false,
    user_name: '',
    employee_id: 0,
    allowance_id: 0,
}
const loadingInitialState: boolean = false;

class EmployeeAllowancesCreateEditStore {
    public form = formInitialState;
    public isLoading = loadingInitialState;
    message: string = '';
    error: string = '';
    usersStore = usersStore;
    allowancesStore = allowancesStore;

    constructor() {
        makeAutoObservable(this);
    }

    public destroy = () => {
        this.form = formInitialState;
        this.isLoading = loadingInitialState;
        this.allowancesStore.allowances = [];
    }

    public init = async (initialValue: EmployeeAllowancesModel | null) => {
        this.usersStore.setUsersFilter({page: 1, page_limit: 999999});
        this.allowancesStore.allowancesReq();
        if (initialValue) {
            this.form = initialValue;
            this.form.employee_id = this.usersOptions?.find((item) => this.form.employee === item.label)?.value!;
            this.form.allowance_id = this.allowancesOptions?.find((item) => this.form.allowance === item.label)?.value!;
        }
    }

    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    public employeeAllowanceCreateEditReq = async (edit: boolean) => {
        this.setLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        const payload = {
            employee_id: Number(this.form.employee_id),
            allowance_id: Number(this.form.allowance_id),
            amount: Number(this.form.amount),
            details: this.form.details,
        }

        try {
            let res;
            if (edit) {
                res = await axios.put(api.payrolls + '/employee/allowances', payload, {headers});
            } else {
                res = await axios.post(api.payrolls + '/employee/allowances', payload, {headers});
            }

            this.message = res.data.message;
            toast.success(this.message);
            return res;
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }

    public setField = (form: EmployeeAllowancesModel) => {
        this.form = form;
    }

    public get usersOptions() {
        if (!this.usersStore.users) return;
        return this.usersStore.users?.map((user) => ({
            label: `${user.surname} ${user.name}`,
            value: user.id,
        }));
    }

    public get allowancesOptions() {
        if (!this.allowancesStore.allowances) return;
        return this.allowancesStore.allowances.map((allowance) => ({
            label: allowance.name,
            value: allowance.id,
        }));
    }
}

export default new EmployeeAllowancesCreateEditStore();