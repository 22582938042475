import {GoodsMovementFilterModel, GoodsMovementModel} from "./goods_movement.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../app.store";
import {UserFilterModel} from "../users/users.model";
import axios from "axios";
import {api} from "../../api/endpoints";

const loadingInitialState: boolean = false;
const movementsInitialState: GoodsMovementModel[] = [];
const movementsFilterInitialState: GoodsMovementFilterModel = {
    id: 0,
    bu_id: 0,
    user_id: 0,
    product_id: 0,
    serial_number: '',
    movement_type_id: 0,
    category_id: 0,
    brand_id: 0,
    quantity: 0,
    inflow: '',
    date_from: '',
    date_to: '',
    search: '',

    order_by: '',
    order_descending: false,
    page: 1,
    pages: 0,
    page_limit: 25
}

class GoodsMovementStore {
    public loading = loadingInitialState;
    public message = '';
    public error = '';
    public movements = movementsInitialState;
    public movementsFilters = movementsFilterInitialState;
    public trackingHistory = movementsInitialState;
    public trackingHistoryLoading = loadingInitialState;

    constructor() {
        makeAutoObservable(this);
    }

    public destroy = () => {
        this.loading = loadingInitialState;
        this.message = '';
        this.error = '';
        this.movements = movementsInitialState;
        this.movementsFilters = movementsFilterInitialState;
    }

    public goodsMovementPageInit = async (signal: AbortSignal) => {
        await this.movementsReq(signal);
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    setTrackingHistoryLoading(loading: boolean) {
        this.trackingHistoryLoading = loading;
    }

    setMovements(data: any) {
        const {transactions} = data;
        this.movements = transactions;
    }

    setTrackingHistory(data: any) {
        const {transactions} = data;
        this.trackingHistory = transactions;
    }

    private setMovementFilterPages = (pages: number) => {
        this.movementsFilters.pages = pages;
    }

    public movementsReq = async (signal?: AbortSignal) => {
        this.setLoading(true);
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        const filterUrl = Object.keys(this.movementsFilters).reduce((filters, filter) => {
            const symbol = filters ? '&' : '?';
            const filterKeyName = filter as keyof GoodsMovementFilterModel;
            if (this.movementsFilters[filterKeyName] !== 0 &&
                this.movementsFilters[filterKeyName] !== '') {
                filters += symbol + filter + '=' + this.movementsFilters[filterKeyName];
            }

            return filters;
        }, '');

        try {
            const response = await axios.get(api.goods_movement + filterUrl, {headers, signal});
            this.setMovementFilterPages(response.data.pages);
            this.setMovements(response.data);
        } catch (e: any) {

        } finally {
            this.setLoading(false);
        }
    }

    public setMovementsFilter = ({
                                     id,
                                     bu_id,
                                     user_id,
                                     product_id,
                                     serial_number,
                                     movement_type_id,
                                     category_id,
                                     brand_id,
                                     quantity,
                                     inflow,
                                     date_from,
                                     date_to,
                                     search,
                                     page,
                                     page_limit
                                 }: GoodsMovementFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.movementsFilters = {
            ...this.movementsFilters,
            id: isUndefined(id) ? this.movementsFilters.id! : id!,
            bu_id: isUndefined(bu_id) ? this.movementsFilters.bu_id! : bu_id!,
            user_id: isUndefined(user_id) ? this.movementsFilters.user_id! : user_id!,
            product_id: isUndefined(product_id) ? this.movementsFilters.product_id! : product_id!,
            serial_number: isUndefined(serial_number) ? this.movementsFilters.serial_number! : serial_number!,
            movement_type_id: isUndefined(movement_type_id) ? this.movementsFilters.movement_type_id! : movement_type_id!,
            category_id: isUndefined(category_id) ? this.movementsFilters.category_id! : category_id!,
            brand_id: isUndefined(brand_id) ? this.movementsFilters.brand_id! : brand_id!,
            quantity: isUndefined(quantity) ? this.movementsFilters.quantity! : quantity!,
            inflow: isUndefined(inflow) ? this.movementsFilters.inflow! : inflow!,
            date_from: isUndefined(date_from) ? this.movementsFilters.date_from! : date_from!,
            date_to: isUndefined(date_to) ? this.movementsFilters.date_to! : date_to!,
            search: isUndefined(search) ? this.movementsFilters.search! : search!,
            page: isUndefined(page) ? this.movementsFilters.page! : page!,
            page_limit: isUndefined(page_limit) ? this.movementsFilters.page_limit! : page_limit!
        };

        if (page_limit) {
            this.movementsFilters.page = 1;
        }

        void this.movementsReq();
    }

    public trackingHistoryBySerialReq = async (serial: string) => {
        this.setTrackingHistoryLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        try {
            const response = await axios.get(api.goods_movement + `?serial_number=${serial}`, {headers});
            this.setTrackingHistory(response?.data);
        } catch (e: any) {

        } finally {
            this.setTrackingHistoryLoading(false);
        }
    }

    public destroyTrackingHistory = () => {
        this.trackingHistory = movementsInitialState;
    }

    public clearFilter = () => {
        this.movementsFilters = movementsFilterInitialState;
        void this.movementsReq();
    }
}

export default new GoodsMovementStore();