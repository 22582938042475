import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {Search, SearchLayout, Select} from "../../styles/table-header";
import {Image} from "../../styles/image";
import SearchIcon from "../../assets/icons/search.svg";

interface BrandsFilterViewProps {
    handleKeyDown: (e: any) => void
    cancelSearch: (e: any) => void
    handleFilterChange: (e: any, key: string) => void
    inModal: boolean
}

const BrandsFiltersView = observer((
    {
        handleKeyDown,
        cancelSearch,
        handleFilterChange,
        inModal
    }: BrandsFilterViewProps
) => {

    return (
        <>
            <Layout between={10} wrapped vAlign="center" vertical={inModal}>
                <SearchLayout>
                    <Search
                        type="search"
                        id="search"
                        placeholder="Поиск"
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={(e) => cancelSearch(e)}
                    ></Search>
                    <Image src={SearchIcon} height="16px" />
                </SearchLayout>
            </Layout>
        </>
    );
});

export default BrandsFiltersView;