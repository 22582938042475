import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {ProductsImportModel} from "./products.create.model";
import {Text} from "../../../styles/text";
import {deleteButton, errorColor, modalTextColor, primaryColor} from "../../../utils/variables";
import {TextInput} from "../../../styles/form";
import {Button} from "../../../styles/button";
import {BusinessUnitModel} from "../../business_units/business_units.model";
import ProductsImportExcelStore from "./products.create.store";
import React, {useState} from "react";
import ModalView from "../../../components/modal/modal.view";
import {Switch} from "antd";
import ProductsCreateExpiryModal from "./products.create.expiry.modal";

interface ProductsImportExcelQuantityViewProps {
    product: ProductsImportModel | null;
    setModal: (isOpen: boolean) => void;
    businessUnits: BusinessUnitModel[] | undefined;
    prodIndex: number;
}

const ProductsCreateQuantityView = observer(({
                                                 product,
                                                 setModal,
                                                 businessUnits,
                                                 prodIndex
                                             }: ProductsImportExcelQuantityViewProps) => {

    const [expiryModal, setExpiryModal] = useState(false);
    const [buInitialValue, setBuInitialValue] = useState<BusinessUnitModel | null>(null);
    const [buIndex, setBuIndex] = useState(0);

    const handleExpiryButtonClick = (bu: BusinessUnitModel, index: number) => {
        setBuInitialValue(bu)
        setExpiryModal(true)
        setBuIndex(index)
    }

    const handleCloseExpiryModal = () => {
        setExpiryModal(false);
        setBuIndex(0);
    }

    const {changeQuantity, changeProductInfo, deleteProduct, setQuantityForBu, form} = ProductsImportExcelStore;

    const handleDeleteProduct = () => {
        setModal(false);
        deleteProduct(prodIndex);
    }

    const handleSubmit = () => {
        setBuInitialValue(null);
        setModal(false);
    }

    const handleQuantityChange = (buID: number, quantity: number) => {
        changeQuantity(buID, quantity, prodIndex)
    }

    const handleQuantity = (buID: number) => {
        let value = 0;
        product!.quantities.forEach((item) => {
            if (item.business_unit_id === buID) {
                if (product?.has_expiry) {
                    item.expiry.forEach((expiry) => {
                        value += expiry.quantity
                    })
                } else {
                    value = item.quantity
                }
            }
        })

        setQuantityForBu(prodIndex, buID, value)
        return value
    }

    const handleProductInfoChange = (key: string, value: any) => {
        changeProductInfo(key, value, prodIndex)
    }

    return (
        <Layout vertical between={20}>
            <Layout vertical between={5}>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Layout>
                        <Text color={modalTextColor} extent={'S'}>Название</Text>
                    </Layout>
                    <Layout widthSize={'65%'}>
                        <TextInput
                            value={product?.name}
                            onChange={(e) => handleProductInfoChange('name', e.target.value)}
                        />
                    </Layout>
                </Layout>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Layout>
                        <Text color={modalTextColor} extent={'S'}>Описание</Text>
                    </Layout>
                    <Layout widthSize={'65%'}>
                        <TextInput
                            value={product?.description}
                            onChange={(e) => handleProductInfoChange('description', e.target.value)}
                        />
                    </Layout>
                </Layout>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Layout>
                        <Text color={modalTextColor} extent={'S'}>Номер</Text>
                    </Layout>
                    <Layout widthSize={'65%'}>
                        <TextInput
                            value={product?.part_number}
                            onChange={(e) => handleProductInfoChange('part_number', e.target.value)}
                        />
                    </Layout>
                </Layout>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Layout>
                        <Text color={modalTextColor} extent={'S'}>Категория</Text>
                    </Layout>
                    <Layout widthSize={'65%'}>
                        <TextInput
                            value={product?.category}
                            onChange={(e) => handleProductInfoChange('category', e.target.value)}
                        />
                    </Layout>
                </Layout>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Layout>
                        <Text color={modalTextColor} extent={'S'}>Бренд</Text>
                    </Layout>
                    <Layout widthSize={'65%'}>
                        <TextInput
                            value={product?.brand}
                            onChange={(e) => handleProductInfoChange('brand', e.target.value)}
                        />
                    </Layout>
                </Layout>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Layout>
                        <Text color={modalTextColor} extent={'S'}>Стоимость</Text>
                    </Layout>
                    <Layout widthSize={'65%'}>
                        <TextInput
                            value={product?.cost}
                            onChange={(e) => handleProductInfoChange('cost', +e.target.value)}
                        />
                    </Layout>
                </Layout>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Layout>
                        <Text color={modalTextColor} extent={'S'}>Цена</Text>
                    </Layout>
                    <Layout widthSize={'65%'}>
                        <TextInput
                            value={product?.price}
                            onChange={(e) => handleProductInfoChange('price', +e.target.value)}
                        />
                    </Layout>
                </Layout>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Layout>
                        <Text color={modalTextColor} extent={'S'}>Бонус</Text>
                    </Layout>
                    <Layout widthSize={'65%'}>
                        <TextInput
                            value={product?.bonus_percentage}
                            onChange={(e) => handleProductInfoChange('bonus_percentage', +e.target.value)}
                        />
                    </Layout>
                </Layout>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Layout>
                        <Text color={modalTextColor} extent={'S'}>Срок годности</Text>
                    </Layout>
                    <Layout widthSize={'65%'}>
                        <Switch
                            checked={product?.has_expiry}
                            onChange={(e) => handleProductInfoChange('has_expiry', e)}
                        />
                    </Layout>
                </Layout>
            </Layout>
            <Layout vertical between={10}>
                <Layout centered>
                    <Text color={primaryColor} extent={'L'} weight={'bold'}>Количество</Text>
                </Layout>
                <Layout
                    hAlign={"space-between"}
                    vAlign={"center"}
                >
                    <Layout>
                        <Text extent={'M'} weight={'bold'}>Подразделение</Text>
                    </Layout>
                    <Layout widthSize={'30%'}>
                        <Text extent={'M'} weight={'bold'}>Количество</Text>
                    </Layout>
                </Layout>
                <Layout vertical between={5}>
                    {businessUnits?.map((bu, index) => (
                        <Layout
                            hAlign={"space-between"}
                            key={index}
                            vAlign={"center"}
                        >
                            <Layout>
                                <Text color={modalTextColor} extent={'S'}>{bu.name}</Text>
                            </Layout>
                            <Layout widthSize={'30%'} hAlign={"space-between"} vAlign={"center"} between={5}>
                                <Layout widthSize={'50%'}>
                                    <TextInput
                                        style={{
                                            maxWidth: '100%'
                                        }}
                                        value={handleQuantity(bu.id)}
                                        onChange={(e) => handleQuantityChange(bu.id, +e.target.value)}
                                        readOnly={product?.has_expiry}
                                    />
                                </Layout>
                                {product?.has_expiry && (
                                    <Layout>
                                        <Button
                                            heightSize={'35px'}
                                            onClick={() => handleExpiryButtonClick(bu, index)}
                                        >
                                            Сроки
                                        </Button>
                                    </Layout>
                                )}
                            </Layout>
                        </Layout>
                    ))}
                </Layout>
            </Layout>

            <Layout centered between={10}>
                <Button
                    extent={'S'}
                    onClick={handleSubmit}
                >
                    Потвердить
                </Button>
                <Button
                    extent={'S'}
                    onClick={handleDeleteProduct}
                    background={deleteButton}
                >
                    Удалить из списка
                </Button>
            </Layout>
            {expiryModal && (
                <ModalView
                    title={`Сроки годности для продукта ${product?.name} в ${buInitialValue?.name}`}
                    onClose={handleCloseExpiryModal}
                >
                    <ProductsCreateExpiryModal
                        prodIndex={prodIndex}
                        buID={buInitialValue?.id!}
                        setModal={setExpiryModal}
                        buIndex={buIndex}
                    />
                </ModalView>
            )}
        </Layout>
    );
});

export default ProductsCreateQuantityView;