import {observer} from "mobx-react";
import {Layout} from "../../../../styles/layout";
import {ExpenseCategoriesModel} from "../expense.categories.model";
import ExpenseCategoriesCreateEditStore from "./expense.categories.create.edit.store";
import ExpenseCategoriesStore from "../expense.categories.store";
import {renderInputs} from "../../../../components/form/render.inputs";
import {Button} from "../../../../styles/button";
import {isValid} from "../../../../utils/isValid";
import {useEffect} from "react";
import DictionaryStore from "../../../../utils/dictionary/dictionary.store";

interface ExpenseCategoriesCreateEditViewProps {
    setModal: (isOpen: boolean) => void;
    initialValue: ExpenseCategoriesModel | null;
}

const ExpenseCategoriesCreateEditView = observer(({setModal, initialValue}: ExpenseCategoriesCreateEditViewProps) => {

    const {
        form,
        loading,
        setField,
        destroy,
        init,
        createOrEditExpenseCategoryReq
    } = ExpenseCategoriesCreateEditStore;
    const {destroyModalDictionary, dictionaryListReq, getModalPlaceholders} = DictionaryStore;

    useEffect(() => {
        destroyModalDictionary();
        const controller = new AbortController();
        void dictionaryListReq('EXPENSE CATEGORIES', 'MODAL', controller.signal);
        void init(initialValue);

        return () => {
            destroy();
            controller.abort();
        }
    }, [destroyModalDictionary, dictionaryListReq, destroy, init, initialValue]);

    const {expenseCategoriesReq} = ExpenseCategoriesStore;

    const {name, description} = form;

    const inputs = [
        {field: name, fieldName: 'name', placeholder: getModalPlaceholders('name'), type: 'text', required: true},
        {field: description, fieldName: 'description', placeholder: getModalPlaceholders('description'), type: 'text', required: false},
    ];

    const handleCreateEditExpenseCategory = async () => {
        const resp = await createOrEditExpenseCategoryReq(!!initialValue);

        if (resp) {
            setModal(false);
            destroy();
            await expenseCategoriesReq();
        }
    }

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setField)}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={loading}
                    onClick={handleCreateEditExpenseCategory}
                    disabled={isValid(inputs)}
                >
                    {loading ? '' : renderSubmitText}
                </Button>
            </Layout>
        </Layout>
    );
});

export default ExpenseCategoriesCreateEditView;