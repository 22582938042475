import {TableCellSkeleton} from "./skeleton.style";
import {TableBody, TCell, TRow} from "../../styles/table";

interface TableSkeletonProps {
    columns: number;
}

const TableSkeleton = (({columns}: TableSkeletonProps) => {
    const renderedCells = [...Array(columns)].map((_, index) => (
        <TCell style={{
            overflow: "hidden",
            padding: "8px 8px"
        }}>
            <TableCellSkeleton>
                <div className={"cell-skeleton"}></div>
            </TableCellSkeleton>
        </TCell>
    ));

    return (
      <TableBody>
          <TRow key={'skeleton-row-1'}>{renderedCells}</TRow>
          <TRow key={'skeleton-row-2'}>{renderedCells}</TRow>
          <TRow key={'skeleton-row-3'}>{renderedCells}</TRow>
          <TRow key={'skeleton-row-4'}>{renderedCells}</TRow>
          <TRow key={'skeleton-row-5'}>{renderedCells}</TRow>
          <TRow key={'skeleton-row-6'}>{renderedCells}</TRow>
          <TRow key={'skeleton-row-7'}>{renderedCells}</TRow>
          <TRow key={'skeleton-row-8'}>{renderedCells}</TRow>
          <TRow key={'skeleton-row-9'}>{renderedCells}</TRow>
          <TRow key={'skeleton-row-10'}>{renderedCells}</TRow>
      </TableBody>
    );
});

export default TableSkeleton;