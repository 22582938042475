import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {Card} from "../cards.model";
import CardsModalCreateEditStore from "./cards.modal.create.edit.store";
import React, {useEffect, useState} from "react";
import CardsStore from "../cards.store";
import {renderInputs} from "../../../components/form/render.inputs";
import {Button} from "../../../styles/button";
import {isValid} from "../../../utils/isValid";
import {Required, TextInput} from "../../../styles/form";
import {Text} from "../../../styles/text";
import {modalTextColor} from "../../../utils/variables";

interface CardsCreateEditViewProps {
    clientID: number;
    initialValue: Card | null;
    setModal: (isOpen: boolean) => void;
}

const CardsCreateEditView = observer(({clientID, initialValue, setModal}: CardsCreateEditViewProps) => {

    const {
        init,
        destroy,
        createEditCardReq,
        form,
        setField,
        loading,
        regexes,
        bankOptions,
        cardTypeOptions
    } = CardsModalCreateEditStore;
    const {cardReq} = CardsStore;
    const {card_type_id, bank_id} = form;
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        void init(initialValue);
    }, [init]);

    const handleSubmit = async () => {
        const res = await createEditCardReq(clientID, !!initialValue)

        if (res) {
            setModal(false);
            void cardReq(clientID)
            destroy();
        }
    }


    const handleCardRegex = (cardPan: string) => {
        for (const pattern of regexes) {
            const regex = new RegExp(pattern.regex); // Recreate the RegExp object
            if (regex.test(cardPan)) {
                return {
                    card_type_id: pattern.card_type_id,
                    bank_id: pattern.bank_id,
                    card_type: pattern.card_type,
                    bank: pattern.bank
                };
            }
        }
        return null;
    }

    const handleChangePan = (e: any) => {
      //  setField({...form, ['pan']: e.target.value})
        const matchedCard = handleCardRegex(e.target.value);
        if (matchedCard) {
            setDisabled(true);
            setField({...form,
                ['bank_id']: matchedCard.bank_id,
                ['card_type_id']: matchedCard.card_type_id,
                ['card_type']: matchedCard.card_type,
                ['bank']: matchedCard.bank,
                ['pan']: e.target.value
            })
        } else {
            // если по регексу никакая карта не подходит выставляем данные другое
            setDisabled(false);
            setField({...form,
                ['bank_id']: 39,
                ['card_type_id']: 8,
                ['card_type']: 'Другое',
                ['bank']: 'Другое',
                ['pan']: e.target.value
            })
        }
    }

    const inputs = [
        {
            field: card_type_id,
            fieldName: 'card_type_id',
            placeholder: 'Тип карты',
            type: 'select',
            required: true,
            disabled: disabled,
            options: cardTypeOptions,
        },
        {
            field: bank_id,
            fieldName: 'bank_id',
            placeholder: 'Банк',
            type: 'select',
            required: true,
            disabled: disabled,
            options: bankOptions,
        },
    ];

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                <Layout between={2} vAlign="center">
                    <Layout widthSize="35%" hAlign={"space-between"} vAlign={"center"}>
                        <Layout>
                            <Text extent="S" color={modalTextColor}>
                                Номер
                            </Text>
                            <Required>*</Required>
                        </Layout>
                    </Layout>
                    <Layout widthSize="65%">
                        <TextInput
                            maxLength={16}
                            minLength={16}
                            type={'text'}
                            value={form.pan}
                            onChange={(e) =>
                                handleChangePan(e)
                            }
                        />
                    </Layout>
                </Layout>
                {renderInputs(form, inputs, setField)}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={loading}
                    onClick={handleSubmit}
                    disabled={isValid([inputs])}
                >{loading ? '' : renderSubmitText}</Button>
            </Layout>
        </Layout>
    );
});

export default CardsCreateEditView;