import {observer} from "mobx-react";
import {ContextMenu, ContextMenuItem, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import TableSkeleton from "../../../utils/skeleton/table.skeleton";
import {Badge} from "../../../styles/badge";
import {Image} from "../../../styles/image";
import ContextMenuIcon from "../../../assets/icons/context-menu.svg";
import NoResultTitle from "../../../components/no_result_title/no_result_title.view";
import {ExpensesModel} from "./expenses.model";

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface ExpensesDesktopViewProps {
    isLoading: boolean;
    expenses: ExpensesModel[] | undefined;
    handleEditClick: (expense: ExpensesModel) => void;
    handleContext: (e: any) => void;
    setConfirmDeleteModal: ({isModalOpen, id, name}: setConfirmDeleteModalProps) => void;
    anchorEl: any;
    getPeriodLabel: (period: string) => string;
    tableHeaders: (key: string) => string;
}

const ExpensesDesktopView = observer(({
                                          isLoading,
                                          expenses,
                                          handleEditClick,
                                          handleContext,
                                          setConfirmDeleteModal,
                                          anchorEl,
                                          getPeriodLabel,
                                          tableHeaders
                                      }: ExpensesDesktopViewProps) => {
    return (
        <Table>
            <TableHead>
                <TRow tableHead>
                    <THeadCell>{tableHeaders('name')}</THeadCell>
                    <THeadCell>{tableHeaders('description')}</THeadCell>
                    <THeadCell>{tableHeaders('category')}</THeadCell>
                    <THeadCell>{tableHeaders('business_unit')}</THeadCell>
                    <THeadCell>{tableHeaders('amount')}</THeadCell>
                    <THeadCell>{tableHeaders('post_check')}</THeadCell>
                    <THeadCell>{tableHeaders('period')}</THeadCell>
                    <THeadCell>{tableHeaders('period_count')}</THeadCell>
                    <THeadCell>{tableHeaders('start_date_formatted')}</THeadCell>
                    <THeadCell>{tableHeaders('end_date_formatted')}</THeadCell>
                    <THeadCell></THeadCell>
                </TRow>
            </TableHead>
            {isLoading ? (
                <TableSkeleton columns={11}/>
            ) : (
                <TableBody>
                    {expenses?.length ? (
                        expenses.map((expense, index) => (
                            <TRow key={index}>
                                <TCell>{expense.name}</TCell>
                                <TCell>{expense.description}</TCell>
                                <TCell>{expense.category}</TCell>
                                <TCell>{expense.business_unit}</TCell>
                                <TCell>{expense.amount}</TCell>
                                <TCell>
                                    <Badge online={expense.post_check}>
                                        {expense.post_check ? 'Да' : 'Нет'}
                                    </Badge>
                                </TCell>
                                <TCell>{getPeriodLabel(expense.period)}</TCell>
                                <TCell>{expense.period_count}</TCell>
                                <TCell>{expense.start_date_formatted}</TCell>
                                <TCell>{expense.end_date_formatted}</TCell>
                                <TCell relative>
                                    <Image
                                        src={ContextMenuIcon}
                                        height="18px"
                                        onClick={handleContext}
                                    />
                                    <ContextMenu anchorEl={anchorEl}>
                                        <ContextMenuItem onClick={() => handleEditClick(expense)}>
                                            Изменить
                                        </ContextMenuItem>
                                        <ContextMenuItem
                                            onClick={() =>
                                                setConfirmDeleteModal({
                                                    isModalOpen: true,
                                                    id: expense.id,
                                                    name: expense.name,
                                                })
                                            }
                                        >
                                            Удалить
                                        </ContextMenuItem>
                                    </ContextMenu>
                                </TCell>
                            </TRow>
                        ))
                    ) : (
                        <NoResultTitle colspan={11}/>
                    )}
                </TableBody>
            )}
        </Table>
    );
});

export default ExpensesDesktopView;