import {AllowancesModel} from "../allowances.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../../app.store";
import {toast} from "react-toastify";
import axios from "axios";
import {api} from "../../../../api/endpoints";

const formInitialValues: AllowancesModel = {
    id: 0,
    name: '',
    description: '',
}

const loadingInitialState: boolean = false;

class AllowancesCreateEditStore {
    public isLoading = loadingInitialState;
    public form = formInitialValues;
    message: string = '';
    error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    public destroy = () => {
        this.isLoading = false;
        this.form = formInitialValues;
    }

    public setField = (form: AllowancesModel) => {
        this.form = form;
    }

    public init = (initialValue: AllowancesModel | null) => {
        if (initialValue) {
            this.form = initialValue;
        }
    }

    public allowanceCreateEditReq = async (edit: boolean) => {
        this.setLoading(true);
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        const payload = {
            id: this.form.id,
            name: this.form.name,
            description: this.form.description,
        }

        try {
            let res;

            if (edit) {
                res = await axios.put(api.payrolls + '/allowances', payload, {headers});
            } else {
                res = await axios.post(api.payrolls + '/allowances', payload, {headers});
            }

            this.message = res?.data.message;
            toast.success(this.message);
            return res;
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }
}

export default new AllowancesCreateEditStore();