import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GlobalStyles } from './styles/globalStyles';
import Navbar from './components/navbar/navbar';
import Header from './components/header';
import AppStore from './app.store';
import Routes from './routes';
import Firebase from './firebase';

const App = observer(() => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { init, isLoggedIn } = AppStore;

  useEffect(() => {
    // Needs refactor
    document.addEventListener('click', () => {
      document
        .querySelector('.context-menu-focused')
        ?.classList.remove('context-menu-focused');
    });
    void init();
    return () => {
      // Needs refactor
      document.removeEventListener('click', () => {
        document
          .querySelector('.context-menu-focused')
          ?.classList.remove('context-menu-focused');
      });
    };
  }, [init, isLoggedIn]);

  const { onMessageListener } = Firebase;

  onMessageListener();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  return (
    <>
      <GlobalStyles />
      {pathname !== '/login' && (
        <>
          <Navbar />
          <Header />
        </>
      )}
      <Routes />
    </>
  );
});

export default App;
