import { observer } from 'mobx-react';
import accountStore from './account.store';
import { useEffect } from 'react';
import { Layout } from '../../styles/layout';
import { renderInputs } from '../../components/form/render.inputs';
import { Button } from '../../styles/button';
import { isValid } from '../../utils/isValid';

interface BonusPaymentViewProps {
  id: number | null;
  entityID: string | null;
  paymentType: string;
  setModal: (isOpen: boolean) => void;
}

const BonusPaymentView = observer(
  ({ id, entityID, paymentType, setModal }: BonusPaymentViewProps) => {
    const { form, destroy, bonusPaymentReq, setField, bonusPaymentLoading } =
      accountStore;
    const { total_amount, details } = form;

    useEffect(() => {
      return () => {
        destroy();
      };
    }, [destroy, id]);

    const inputs = [
      {
        field: total_amount,
        fieldName: 'total_amount',
        placeholder: 'Сумма',
        type: 'number',
        required: true,
      },
      {
        field: details,
        fieldName: 'details',
        placeholder: 'Описание',
        type: 'text',
        required: false,
      },
    ];

    const handleBonusPayment = async () => {
      const res = await bonusPaymentReq(id!, entityID!, paymentType);

      if (res) {
        setModal(false);
        destroy();
      }
    };

    return (
      <Layout vertical between={20}>
        <Layout vertical between={10}>
          {renderInputs(form, inputs, setField)}
        </Layout>
        <Layout hAlign={'center'}>
          <Button
            extent="S"
            heightSize="35px"
            isLoading={bonusPaymentLoading}
            onClick={handleBonusPayment}
            disabled={isValid([inputs])}
          >
            {bonusPaymentLoading ? '' : 'Потвердить'}
          </Button>
        </Layout>
      </Layout>
    );
  }
);

export default BonusPaymentView;
