import {AccountRecords, AccountsModel} from "./account.model";
import {observer} from "mobx-react";
import {useState} from "react";
import {Layout} from "../../styles/layout";
import {Text} from "../../styles/text";
import {Button} from "../../styles/button";
import {EyeImgIcon, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../styles/table";
import {Image} from "../../styles/image";
import EyeIcon from "../../assets/icons/eye.svg";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import ModalView from "../../components/modal/modal.view";

interface AccountsMobileViewProps {
    accountsRecords: AccountRecords[] | undefined;
    loading: boolean;
    tableHeaders: (key: string) => string;
}

const AccountRecordsMobileView = observer(({
                                               accountsRecords,
                                               loading,
                                               tableHeaders
                                           }: AccountsMobileViewProps) => {

    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<AccountRecords | null>(null)

    const handleRowClick = (record: AccountRecords) => {
        setModal(true);
        setInitialValue(record);
    }

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    }

    const renderModalItems = (record: AccountRecords) => (
        <Layout vertical between={10}>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('tran_id')}</Text>
                <Text>{record.tran_id}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('tran_type')}</Text>
                <Text>{record.tran_type}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('balance_before')}</Text>
                <Text>{record.balance_before}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('balance_after')}</Text>
                <Text>{record.balance_after}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('amount')}</Text>
                <Text>{record.amount}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('details')}</Text>
                <Text>{record.details}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('description')}</Text>
                <Text>{record.description}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('reason')}</Text>
                <Text>{record.reason}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('created_at')}</Text>
                <Text>{record.created_at}</Text>
            </Layout>
        </Layout>
    );

    const renderItems = () => (
        <>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>{tableHeaders('tran_type')}</THeadCell>
                        <THeadCell>{tableHeaders('amount')}</THeadCell>
                        <THeadCell>{tableHeaders('description')}</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                {!loading ? (
                    <TableBody>
                        {accountsRecords?.length ? (
                            accountsRecords?.map((record, index) => (
                                <TRow key={index} menu>
                                    <TCell width="50">{record.tran_type}</TCell>
                                    <TCell width="50">{record.amount}</TCell>
                                    <TCell width="50">{record.description}</TCell>
                                    <TCell width="10" relative>
                                        <EyeImgIcon
                                            src={EyeIcon}
                                            height="18px"
                                            onClick={() => handleRowClick(record)}
                                        />
                                    </TCell>
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={4}/>
                        )}
                    </TableBody>
                ) : (<TableSkeleton columns={4}/>)}
            </Table>
            {modal && (
                <ModalView
                    title={`Данные операции ${initialValue?.tran_id}`}
                    onClose={handleCloseModal}
                >
                    {renderModalItems(initialValue!)}
                </ModalView>
            )}
        </>
    );

    return (
        <Layout>
            {renderItems()}
        </Layout>
    )
});

export default AccountRecordsMobileView;