import {AllowDedModel, PayrollsModel} from "../payrolls.model";
import {makeAutoObservable} from "mobx";
import usersStore from "../../../users/users.store";
import AppStore from "../../../../app.store";
import {toast} from "react-toastify";
import axios from "axios";
import {api} from "../../../../api/endpoints";
import EmployeeAllowancesStore from "../../employee.allowances/employee.allowances.store";
import EmployeeDeductionsStore from "../../employee.deductions/employee.deductions.store";
import ProductsAllSellStore from "../../../products_transactions/products_all_sell.modal(deprecated)/products_all_sell.modal.store";

const formInitialValues: PayrollsModel = {
    allowance: 0,
    date: '',
    deduction: 0,
    details: '',
    employee_id: 0,
    employee_name: '',
    id: 0,
    owner_payroll_id: 0,
    period: '',
    salary: 0,
    total_amount: 0,
    user_name: '',
    amount: 0,
    paid_amount: 0,
    allow_ded: [],
    month: '',
    year: '',
    account: '',
    financial_institution: '',
    payment_method: '',
    payment_method_id: 0,
}
const loadingInitialState: boolean = false;
const selectedItemInitialValue: AllowDedModel = {
    id: 0,
    type: '',
    name: '',
    amount: 0,
}

class PayrollsCreateEditStore {
    public form = formInitialValues;
    public isLoading = loadingInitialState;
    selectedItem = selectedItemInitialValue;
    userStore = usersStore;
    message: string = '';
    error: string = '';
    employeeAllowancesStore = EmployeeAllowancesStore;
    employeeDeductionsStore = EmployeeDeductionsStore;
    paymentMethods = ProductsAllSellStore;

    constructor() {
        makeAutoObservable(this);
    }

    public destroy = () => {
        this.form = formInitialValues;
        this.selectedItem = selectedItemInitialValue;
        this.isLoading = loadingInitialState;
        this.message = '';
        this.error = '';
        this.employeeAllowancesStore.clearFilter();
        this.employeeDeductionsStore.clearFilter();
        this.paymentMethods.paymentMethods = [];
    }

    public setField = (form: PayrollsModel) => {
        this.form = form;
    }

    public init = async (initialValue: PayrollsModel | null) => {
        usersStore.setUsersFilter({page: 1, page_limit: 999999});
        await this.paymentMethods.paymentMethodsReq();
        if (initialValue) {
            this.form = initialValue;
        }
    }

    public setSelectedItem = (itemID: number, name: string, amount: number, type: string) => {
        this.selectedItem.id = itemID;
        this.selectedItem.type = type;
        this.selectedItem.name = name;
        this.selectedItem.amount = amount;
        this.form.allow_ded.push(this.selectedItem);
        this.selectedItem = selectedItemInitialValue;
    }

    public deleteSelectedItem = (itemID: number, itemType: string) => {
        this.form.allow_ded = this.form.allow_ded.filter(
            (item) => item.id !== itemID && item.type !== itemType
        );
    }

    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    public payrollsCreateEditReq = async (edit: boolean) => {
        this.setLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        const payload = {
            id: this.form.id,
            employee_id: Number(this.form.employee_id),
            amount: Number(this.form.amount),
            details: this.form.details,
            paid_amount: Number(this.form.paid_amount),
            period: `${this.form.year}-${this.form.month}-01`,
            payment_method_id: Number(this.form.payment_method_id),
            financial_institution: this.form.financial_institution,
            account: this.form.account,
            allow_ded: this.form.allow_ded,
        }

        try {
            let res;
            if (edit) {
                res = await axios.put(api.payrolls, payload, {headers});
            } else {
                res = await axios.post(api.payrolls, payload, {headers});
            }
            this.message = res.data.message;
            toast.success(this.message);
            return res;
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }

    private getYears = () => {
        let currentYear = (new Date()).getFullYear() + 1
        const years = [];
        const startYear = currentYear - 10
        while (startYear <= currentYear) {
            years.push(currentYear--);
        }
        return years;
    }

    public get yearsOptions() {
        return this.getYears().map((year) => ({
            label: year,
            id: year,
        }))
    }

    public get employeeOptions() {
        if (!this.userStore.users) return;
        return this.userStore.users.map((user) => ({
            label: `${user.surname} ${user.name}`,
            value: user.id
        }))
    }

    public get paymentMethodOptions() {
        if (!this.paymentMethods.paymentMethods) return;
        return this.paymentMethods.paymentMethods.map((method) => ({
            label: method.name,
            value: method.id,
        }))
    }
}

export default new PayrollsCreateEditStore();