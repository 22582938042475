import { AccessCode, Permission } from "../app.model";

export const hasPermission = (
  permissions: Permission[],
  permission: string,
  type: AccessCode
) => {
  const accessGroup = permissions.filter(
    (access) => access?.access_group_code! === permission
  )[0];
  const access = accessGroup?.accesses?.filter(
    (access) => access?.access_code! === type
  )[0];
  return access?.active!;
};
