import {observer} from "mobx-react";
import AppStore from "../../app.store";
import {Page} from "../../styles/page";
import {useEffect, useState} from "react";
import ShipmentsStore from "./shipments.store";
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import {Headline} from "../../styles/headline";
import {primaryColor} from "../../utils/variables";
import {AddButton, TableHeader} from "../../styles/table-header";
import TableDesktopView from "../../components/table/render.table.desktop";
import TableMobileView from "../../components/table/render.table.mobile";
import TablePaginationView from "../../utils/table.pagination.view";
import BusinessUnitsStore from "../business_units/business_units.store";
import UsersStore from "../users/users.store";
import ProductsStore from "../products/products.store";
import {Filters} from "../../styles/filters";
import FilterView from "../../components/filters/filters.view";
import {FilterButton} from "../../styles/button";
import ModalView from "../../components/modal/modal.view";
import {ShipmentsModel} from "./shipments.model";
import ConfirmDelete, {DeleteItem} from "../../components/confirm/confirm.delete";
import Modal from "../../components/modal/modal.view";
import ShipmentsModalView from "./shipments.modal/shipments.modal.view";

const ShipmentsView = observer(() => {
    const {isNavOpen} = AppStore;
    const {
        shipments,
        shipmentsPageInit,
        loading,
        shipmentFilters,
        setShipmentsFilters,
        deleteShipmentReq
    } = ShipmentsStore;
    const {destroyDictionary, dictionaryListReq, getPageTitle, dictionaryList} = DictionaryStore;
    const {setBusinessUnitFilter, businessUnits} = BusinessUnitsStore;
    const {setUsersFilter, users} = UsersStore;
    const {setProductsFilter, products} = ProductsStore;
    const [filterModal, setFilterModal] = useState(false);
    const [createEditModal, setCreateEditModal] = useState(false);
    const [initialValue, setInitialValue] = useState<ShipmentsModel | null>(null);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<DeleteItem>({
        isModalOpen: false,
    });

    useEffect(() => {
        const controller = new AbortController();
        void shipmentsPageInit(controller.signal);
        destroyDictionary();
        void dictionaryListReq('SHIPMENTS', 'TABLE HEADERS', controller.signal);
        setBusinessUnitFilter({page: 1, page_limit: 100000});
        setUsersFilter({page: 1, page_limit: 100000});
        setProductsFilter({page: 1, page_limit: 100000, type_id: 1});

        return () => {
            controller.abort();
        }

    }, [shipmentsPageInit]);

    const businessUnitsOptions = businessUnits?.map(
        ({id, name}) => ({label: name, value: id})
    );

    const usersOptions = users?.map(
        ({id, full_name}) => ({label: full_name, value: id})
    )

    const productsOptions = products?.map(
        ({id, name}) => ({label: name, value: id})
    )


    const prevPage = () => {
        if (shipmentFilters.page! > 1) {
            setShipmentsFilters({page: shipmentFilters.page! - 1});
        }
    };

    const nextPage = () => {
        if (shipmentFilters.page! < shipmentFilters.pages!) {
            setShipmentsFilters({page: shipmentFilters.page! + 1});
        }
    };

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === 'page' && index !== undefined) {
            setShipmentsFilters({page: index + 1});
            return;
        }
        setShipmentsFilters({[objectKey]: options[selectedIndex].value});
    };

    const pageLimits = [25, 50, 100, 250, 500];

    const filterInputs: any[] = [
        {
            fieldName: 'user_id',
            placeholder: 'Все сотрудники',
            type: 'select',
            options: usersOptions,
        },
        {
            fieldName: 'bu_id',
            placeholder: 'Все подразделения',
            type: 'select',
            options: businessUnitsOptions,
        },
        {
            fieldName: 'product_id',
            placeholder: 'Все продукты',
            type: 'select',
            options: productsOptions,
        },
    ];

    const handleEditClick = (shipment: ShipmentsModel) => {
        setInitialValue(shipment);
        setCreateEditModal(true);
    }

    const handleClickDelete = (shipment: ShipmentsModel) => {
        setConfirmDeleteModal({
            id: shipment.id,
            name: shipment.product_name,
            isModalOpen: true
        })
    }

    const contextOptions = [
        {
            name: 'Изменить',
            onClick: (shipment: ShipmentsModel) => handleEditClick(shipment)
        },
        {
            name: 'Удалить',
            onClick: (shipment: ShipmentsModel) => handleClickDelete(shipment)
        },
    ]

    const handleDelete = async () => {
       await deleteShipmentReq(confirmDeleteModal.id!)
    }

    const handleCloseCreateEditModal = () => {
        setCreateEditModal(false);
        setInitialValue(null);
    }

    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
            <Headline color={primaryColor}>{getPageTitle('SHIPMENTS')}</Headline>
            <TableHeader>
                <Filters hAlign="space-between" wrapped between={10}>
                    <FilterView
                        filterInputs={filterInputs}
                        isInModal={false}
                        filters={shipmentFilters}
                        handleFilterChange={handleFilterChange}
                    />
                </Filters>
                <FilterButton
                    id={'movements-filters'}
                    onClick={() => setFilterModal(true)}
                >
                    Фильтры
                </FilterButton>
                <AddButton onClick={() => setCreateEditModal(true)}> Создать </AddButton>
            </TableHeader>
            <TableDesktopView
                data={shipments}
                headers={dictionaryList}
                loading={loading}
                hasContext
                contextOptions={contextOptions}
            />
            <TableMobileView
                data={shipments}
                headers={dictionaryList}
                loading={loading}
                hasContext
                contextOptions={contextOptions}
            />
            <TablePaginationView
                prevPage={prevPage}
                nextPage={nextPage}
                pages={shipmentFilters.pages}
                currentPage={shipmentFilters.page!}
                pageLimits={pageLimits}
                handleFilterChange={handleFilterChange}
            />
            {filterModal && (
                <ModalView onClose={() => setFilterModal(false)}>
                    <FilterView
                        filterInputs={filterInputs}
                        isInModal={true}
                        filters={shipmentFilters}
                        handleFilterChange={handleFilterChange}
                    />
                </ModalView>
            )}
            {confirmDeleteModal.isModalOpen && (
                <Modal
                    title="Удалить поставку"
                    onClose={() => {
                        setConfirmDeleteModal({ isModalOpen: false });
                    }}
                >
                    <ConfirmDelete
                        warningText={`Вы уверены, что хотите удалить поставку ${confirmDeleteModal.name}?`}
                        setModal={setConfirmDeleteModal}
                        callback={handleDelete}
                        isLoading={loading}
                    />
                </Modal>
            )}
            {createEditModal && (
                <ModalView
                    title={initialValue ? 'Изменить' : 'Создать'}
                    onClose={handleCloseCreateEditModal}
                >
                    <ShipmentsModalView initialValue={initialValue} setModal={setCreateEditModal}/>
                </ModalView>
            )}
        </Page>
    );
});

export default ShipmentsView;