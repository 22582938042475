import {observer} from "mobx-react";
import { Dispatch, SetStateAction } from "react";
import { Button } from "../../styles/button";
import { Layout } from "../../styles/layout";
import { Text } from "../../styles/text";
import { deleteButton } from "../../utils/variables";


interface UsersCreateEditViewProps {
    setModal: Dispatch<SetStateAction<LogoutItem>>;
    warningText?: string;
    callback?: () => void;
    isLoading?: boolean;
}

export interface LogoutItem {
    isModalOpen: boolean;
    id?: number;
    name?: string;
}

const UsersCreateEditView = observer(({setModal, warningText, callback, isLoading}: UsersCreateEditViewProps) => {
    return (
        <Layout vertical heightSize={100} between={20} vAlign="space-between">
            <Layout between={10} vAlign="center" centered>
                <Text extent="14px">Подтвердите данное действие.</Text>
            </Layout>
            <Layout hAlign="center" between={14}>
                <Button
                    extent="130px"
                    heightSize="30px"
                    background="grey"
                    onClick={() => setModal({ isModalOpen: false })}
                >Отмена</Button>
                <Button
                    extent="130px"
                    heightSize="30px"
                    background={deleteButton}
                    onClick={callback}
                    isLoading={isLoading}
                >{isLoading ? '' : 'Выход'}</Button>
            </Layout>
        </Layout>
    );
});

export default UsersCreateEditView;
