import {observer} from "mobx-react";
import {Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import {SalesReportModel} from "./sales.report.model";
import TableSkeleton from "../../../utils/skeleton/table.skeleton";
import NoResultTitle from "../../../components/no_result_title/no_result_title.view";

interface SalesReportTableViewProps {
    getTableHeaders: (key: string) => string;
    reports: SalesReportModel[] | undefined;
    loading: boolean;
}

const SalesReportTableView = observer(({
                                           getTableHeaders,
                                           reports,
                                           loading,
                                       }: SalesReportTableViewProps) => {
    return (
        <Table>
            <TableHead>
                <TRow tableHead>
                    <THeadCell>{getTableHeaders('entity_name')}</THeadCell>
                    <THeadCell>{getTableHeaders('turnover')}</THeadCell>
                    <THeadCell>{getTableHeaders('average')}</THeadCell>
                    <THeadCell>{getTableHeaders('quantity')}</THeadCell>
                </TRow>
            </TableHead>
            {loading ? (
                <TableSkeleton columns={4}/>
            ) : (
                <TableBody>
                    {reports?.length ? (
                        reports.map((report, index) => (
                            <TRow key={index} menu>
                                <TCell>{report.entity_name}</TCell>
                                <TCell>{report.turnover}</TCell>
                                <TCell>{report.average}</TCell>
                                <TCell>{report.quantity}</TCell>
                            </TRow>
                        ))
                    ) : (
                        <NoResultTitle colspan={4}/>
                    )}
                </TableBody>
            )}
        </Table>
    );
});

export default SalesReportTableView;