import {observer} from "mobx-react";
import {Layout} from "../../../../styles/layout";
import EmployeeAllowancesCreateEditStore from "./employee.allowances.create.edit.store";
import {useEffect} from "react";
import {EmployeeAllowancesModel} from "../employee.allowances.model";
import EmployeeAllowancesStore from "../employee.allowances.store";
import {renderInputs} from "../../../../components/form/render.inputs";
import {Button} from "../../../../styles/button";
import {isValid} from "../../../../utils/isValid";
import DictionaryStore from "../../../../utils/dictionary/dictionary.store";

interface EmployeeAllowancesCreateEditViewProps {
    setModal: (isOpen: boolean) => void;
    initialValue: EmployeeAllowancesModel | null;
}

const EmployeeAllowancesCreateEditView = observer(({setModal, initialValue}: EmployeeAllowancesCreateEditViewProps) => {
    const {
        form,
        destroy,
        init,
        employeeAllowanceCreateEditReq,
        usersOptions,
        allowancesOptions,
        setField,
        isLoading,
    } = EmployeeAllowancesCreateEditStore;
    const {employeeAllowancesReq} = EmployeeAllowancesStore;
    const {destroyModalDictionary, dictionaryListReq, getModalPlaceholders} = DictionaryStore;

    const {employee_id, allowance_id, amount, details} = form;

    useEffect(() => {
        destroyModalDictionary();
        const controller = new AbortController();
        void dictionaryListReq('EMPLOYEE ALLOWANCES', 'MODAL', controller.signal);
        void init(initialValue);

        return () => {
            destroy();
            controller.abort();
        }
    }, [destroyModalDictionary, dictionaryListReq, destroy ,init, initialValue]);

    const handleCreateEditEmployeeAllowance = async () => {
        const resp = await employeeAllowanceCreateEditReq(!!initialValue);

        if (resp) {
            setModal(false);
            destroy();
            await employeeAllowancesReq();
        }
    }

    const inputs = [
        {
            field: employee_id,
            options: usersOptions,
            fieldName: 'employee_id',
            placeholder: getModalPlaceholders('employee_id'),
            type: 'select',
            required: true
        },
        {
            field: allowance_id,
            options: allowancesOptions,
            fieldName: 'allowance_id',
            placeholder: getModalPlaceholders('allowance_id'),
            type: 'select',
            required: true
        },
        {
            field: amount,
            fieldName: 'amount',
            placeholder: getModalPlaceholders('amount'),
            type: 'text',
            required: true
        },
        {
            field: details,
            fieldName: 'details',
            placeholder: getModalPlaceholders('details'),
            type: 'text',
            required: false
        },
    ];

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setField)}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={isLoading}
                    onClick={handleCreateEditEmployeeAllowance}
                    disabled={isValid(inputs)}
                >
                    {isLoading ? '' : renderSubmitText}
                </Button>
            </Layout>
        </Layout>
    );
});

export default EmployeeAllowancesCreateEditView;