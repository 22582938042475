import { observer } from 'mobx-react';
import { Layout } from '../../styles/layout';
import { Search, SearchLayout, Select } from '../../styles/table-header';
import { Image } from '../../styles/image';
import SearchIcon from '../../assets/icons/search.svg';
import { RoleModel } from '../roles/roles.model';
import { BusinessUnitModel } from '../business_units/business_units.model';

interface UsersFilterViewProps {
  handleKeyDown: (e: any) => void;
  cancelSearch: (e: any) => void;
  handleFilterChange: (e: any, key: string) => void;
  roles: RoleModel[] | undefined;
  businessUnits: BusinessUnitModel[] | undefined;
  inModal: boolean;
}

const UsersFiltersView = observer(
  ({
    handleKeyDown,
    cancelSearch,
    handleFilterChange,
    roles,
    businessUnits,
    inModal,
  }: UsersFilterViewProps) => {
    return (
      <>
        <Layout between={10} wrapped vAlign="center" vertical={inModal}>
          <SearchLayout>
            <Search
              type="search"
              id="search"
              placeholder="Поиск по ФИО, номеру или логину"
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e) => cancelSearch(e)}
            ></Search>
            <Image src={SearchIcon} height="16px" />
          </SearchLayout>
          <Select
            id="roles-filter"
            onChange={(e) => handleFilterChange(e, 'role_id')}
          >
            <option value="">Все роли</option>
            {roles?.map((role, index) => (
              <option value={role.id} key={role.id}>
                {role.name}
              </option>
            ))}
          </Select>
          <Select
            id="business-unit-filter"
            onChange={(e) => handleFilterChange(e, 'business_unit_id')}
          >
            <option value="">Все подразделения</option>
            {businessUnits?.map((businessUnit, index) => (
              <option value={businessUnit.id} key={businessUnit.id}>
                {businessUnit.name}
              </option>
            ))}
          </Select>
          <Select
            id="status-filter"
            onChange={(e) => handleFilterChange(e, 'active')}
          >
            <option value="">Все статусы</option>
            <option value="true">Активный</option>
            <option value="false">Заблокирован</option>
          </Select>
        </Layout>
      </>
    );
  }
);

export default UsersFiltersView;
