import {PayrollsFilterModel, PayrollsModel, TotalsModel} from "./payrolls.model";
import {toast} from "react-toastify";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import axios from "axios";
import {api} from "../../../api/endpoints";

const payrollsInitialValues: PayrollsModel[] = [];
const loadingInitialState: boolean = false;
const totalsInitialState: TotalsModel = {
    allowance: 0,
    deduction: 0,
    salary: 0,
    total_amount: 0
}
const filtersInitialValues: PayrollsFilterModel = {
    search: '',
    user_id: 0,
    employee_id: 0,
    date_from: '',
    date_to: '',
    order_by: '',
    order_descending: '',
    pages: 1,
    page: 1,
    page_limit: 25,
}

class PayrollsStore {
    public payrolls = payrollsInitialValues;
    public isLoading = loadingInitialState;
    public payrollsFilters = filtersInitialValues;
    public totals = totalsInitialState;
    message: string = '';
    error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public init = async (signal: AbortSignal) => {
        await this.payrollsReq(signal);
    }

    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    setPayrolls(data: any) {
        const {payrolls} = data;
        this.payrolls = payrolls;
    }

    setTotals(data: any) {
        const {totals} = data;
        this.totals = totals;
    }

    private setPayrollsFiltersPages = (pages: number) => {
        this.payrollsFilters.pages = pages;
    }

    public clearFilter = () => {
        this.payrollsFilters = filtersInitialValues;
        void this.payrollsReq();
    }

    public setPayrollsFilter = ({search, user_id, employee_id, date_from, date_to, page_limit}: PayrollsFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined'
        this.payrollsFilters = {
            ...this.payrollsFilters,
            search: isUndefined(search) ? this.payrollsFilters.search! : search!,
            user_id: isUndefined(search) ? this.payrollsFilters.user_id! : user_id!,
            employee_id: isUndefined(employee_id) ? this.payrollsFilters.employee_id! : employee_id!,
            date_from: isUndefined(date_from) ? this.payrollsFilters.date_from! : date_from!,
            date_to: isUndefined(date_to) ? this.payrollsFilters.date_to! : date_to!,
        }

        if (page_limit) {
            this.payrollsFilters.page = 1
        }

        void this.payrollsReq();
    }

    public payrollsReq = async (signal?: AbortSignal) => {
        this.setLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        const filterUrl = Object.keys(this.payrollsFilters).reduce((filters, filter) => {
            const symbol = filters ? '&' : '?';
            const filterKeyName = filter as keyof PayrollsFilterModel;
            if (this.payrollsFilters[filterKeyName] !== 0 &&
                this.payrollsFilters[filterKeyName] !== '') {
                filters += symbol + filter + '=' + this.payrollsFilters[filterKeyName];
            }

            return filters;
        }, '');

        try {
            const response = await axios.get(api.payrolls + filterUrl, {headers, signal});
            this.setPayrolls(response.data);
            this.setTotals(response.data);
            this.setPayrollsFiltersPages(response.data.pages);
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }
}

export default new PayrollsStore();