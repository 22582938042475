import { BrandModel, BrandsFilterModel } from './brands.model';
import { makeAutoObservable } from 'mobx';
import axios, { GenericAbortSignal } from 'axios';
import AppStore from '../../app.store';
import { api } from '../../api/endpoints';
import { toast } from 'react-toastify';

class BrandsStore {
  public brands?: BrandModel[] = [];
  public brandsFilter = {
    page: 1,
    pageLimit: 25,
    search: '',
    pages: 0,
    technical: false,
  };

  public loading = false;
  public error = '';
  public message = '';

  public brandsPageInit = async (
    technical: boolean,
    signal: GenericAbortSignal
  ) => {
    void this.brandsReq(signal);
  };

  constructor() {
    makeAutoObservable(this);
  }

  setBrands(data: any) {
    const { brands } = data;
    this.brands = brands;
  }

  setPages(pages: number) {
    this.brandsFilter.pages = pages;
  }

  public brandsReq = async (signal?: GenericAbortSignal) => {
    try {
      const { getToken } = AppStore;

      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };

      const filterUrl = Object.keys(this.brandsFilter).reduce(
        (filters, filter) => {
          const symbol = filters ? '&' : '?';
          const filterKeyName = filter as keyof BrandsFilterModel;

          if (
            this.brandsFilter[filterKeyName] !== 0 &&
            this.brandsFilter[filterKeyName] !== ''
          ) {
            filters += symbol + filter + '=' + this.brandsFilter[filterKeyName];
          }

          return filters;
        },
        ''
      );

      const response: any = await axios.get(api.product_brands + filterUrl, {
        headers,
        signal,
      });

      this.loading = false;
      this.setBrands(response.data);
      this.setPages(response.data.pages);
    } catch (err) {}
  };

  public setBrandsFilter = ({
    search,
    page,
    pageLimit,
    technical,
  }: BrandsFilterModel) => {
    const isUndefined = (value: any) => typeof value === 'undefined';
    this.brandsFilter = {
      ...this.brandsFilter,
      search: isUndefined(search) ? this.brandsFilter.search! : search!,
      page: isUndefined(page) ? this.brandsFilter.page! : page!,
      pageLimit: isUndefined(pageLimit)
        ? this.brandsFilter.pageLimit!
        : pageLimit!,
      technical: isUndefined(technical)
        ? this.brandsFilter.technical!
        : technical!,
    };

    if (pageLimit) {
      this.brandsFilter.page = 1;
    }

    void this.brandsReq();
  };

  public deleteBrand = async (id: number) => {
    this.loading = true;
    try {
      const { getToken } = AppStore;
      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };
      const response = await axios.delete(`${api.brands}/${id}`, { headers });

      this.message = response?.data.message;
      toast.success(this.message);

      if (response) {
        this.brandsReq();
      }
    } catch (err: any) {
      this.error = err?.response.data.reason;
      toast.error(this.error);
    } finally {
      this.loading = false;
    }
  };

  public clearFilter = () => {
    this.brandsFilter = {
      page: 1,
      pageLimit: 25,
      search: '',
      pages: 0,
      technical: false,
    };
  };
}
export default new BrandsStore();
