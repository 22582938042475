import {WorkingHoursModel} from "./working.hours.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../app.store";
import axios from "axios";
import {api} from "../../api/endpoints";
import {toast} from "react-toastify";

const workingHoursInitialState: WorkingHoursModel[] = [];
const loadingInitialState: boolean = false;


class WorkingHoursStore {
    public workingHours: WorkingHoursModel[] = workingHoursInitialState;
    public createEditLoading = loadingInitialState;
    message: string = '';
    error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    setWorkingHours(data: any) {
        this.workingHours = data;
    }

    setCreateEditLoading(loading: boolean) {
        this.createEditLoading = loading;
    }

    public init = (businessUnitID: number, signal?: AbortSignal) => {
        void this.workingHoursReq(businessUnitID, signal);
    }

    public workingHoursReq = async (businessUnitID: number, signal?: AbortSignal) => {
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken(),
        }

        try {
            const response = await axios.get(api.get_business_units + `/working_hours/${businessUnitID}`,
                {headers, signal}
            );

            this.setWorkingHours(response.data);
        } catch (e: any) {
            this.error = e?.response?.data.reason;
            toast.error(this.error);
        } finally {
        }
    }

    public createEditWorkingHourReq = async (businessUnitID: number) => {
        this.setCreateEditLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken(),
        }
        const payload = {
            business_unit_id: businessUnitID,
            scheme: this.workingHours,
        }

        try {
            const response = await axios.post(api.get_business_units + `/working_hours`, payload, {headers});
            this.message = response?.data.message;
            toast.success(this.message);
            return response;
        } catch (e: any) {
            this.error = e?.response?.data.reason;
            toast.error(this.error);
        } finally {
            this.setCreateEditLoading(false);
        }
    }
}

export default new WorkingHoursStore();