import {
  CategoriesFilterModel,
  ProductCategoryModel,
} from './product_categories.model';
import { makeAutoObservable } from 'mobx';
import axios, { GenericAbortSignal } from 'axios';
import AppStore from '../../app.store';
import { api } from '../../api/endpoints';
import { toast } from 'react-toastify';

class ProductCategoriesStore {
  public productCategories: ProductCategoryModel[] = [];
  public isLoading: boolean = false;
  public message: string = '';
  public error: string = '';
  public categoriesFilter = {
    search: '',
    page: 1,
    page_limit: 25,
    pages: 0,
    order_descending: false,
    technical: false,
  };

  public productCategoriesPageInit = async (signal?: GenericAbortSignal) => {
    void this.productCategoriesReq(signal);
  };

  constructor() {
    makeAutoObservable(this);
  }

  setProductCategories(data: any) {
    const { categories } = data;
    this.productCategories = categories;
  }

  setFilterPages(pages: number) {
    this.categoriesFilter.pages = pages;
  }

  public productCategoriesReq = async (signal?: GenericAbortSignal) => {
    this.isLoading = true;

    try {
      const { getToken } = AppStore;
      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };
      const filterUrl = Object.keys(this.categoriesFilter).reduce(
        (filters, filter) => {
          const symbol = filters ? '&' : '?';
          const filterKeyName = filter as keyof CategoriesFilterModel;

          if (
            this.categoriesFilter[filterKeyName] !== 0 &&
            this.categoriesFilter[filterKeyName] !== ''
          ) {
            filters +=
              symbol + filter + '=' + this.categoriesFilter[filterKeyName];
          }

          return filters;
        },
        ''
      );

      const response = await axios.get(api.product_categories + filterUrl, {
        headers,
        signal,
      });

      this.setProductCategories(response.data);
      this.setFilterPages(response.data.pages);
    } catch (err) {
    } finally {
      this.isLoading = false;
    }
  };

  public deleteCategory = async (id: number) => {
    this.isLoading = true;

    try {
      const { getToken } = AppStore;

      const headers = {
        Authorization: 'Bearer' + getToken()!,
      };

      const response = await axios.delete(`${api.product_category}/${id}`, {
        headers,
      });

      this.message = response.data.message;
      toast(this.message);

      if (response) {
        this.productCategoriesReq();
      }
    } catch (err: any) {
      this.error = err?.response.reason;
      toast(this.error);
    } finally {
      this.isLoading = false;
    }
  };

  public setCategoriesFilter = ({
    search,
    page,
    page_limit,
    technical,
  }: CategoriesFilterModel) => {
    const isUndefined = (value: any) => typeof value === 'undefined';
    this.categoriesFilter = {
      ...this.categoriesFilter,
      search: isUndefined(search) ? this.categoriesFilter.search! : search!,
      page: isUndefined(page) ? this.categoriesFilter.page! : page!,
      page_limit: isUndefined(page_limit)
        ? this.categoriesFilter.page_limit!
        : page_limit!,
      technical: isUndefined(technical)
        ? this.categoriesFilter.technical!
        : technical!,
    };

    if (page_limit) {
      this.categoriesFilter.page = 1;
    }

    void this.productCategoriesReq();
  };

  public clearFilter = () => {
    this.categoriesFilter = {
      search: '',
      page: 1,
      page_limit: 25,
      pages: 0,
      order_descending: false,
      technical: false,
    };
  };
}

export default new ProductCategoriesStore();
