import {observer} from "mobx-react";
import {Layout} from "../../../../styles/layout";
import {AllowancesModel} from "../allowances.model";
import AllowancesCreateEditStore from "./allowances.create.edit.store";
import {useEffect} from "react";
import {renderInputs} from "../../../../components/form/render.inputs";
import {Button} from "../../../../styles/button";
import {isValid} from "../../../../utils/isValid";
import AllowancesStore from "../allowances.store";
import DictionaryStore from "../../../../utils/dictionary/dictionary.store";

interface AllowancesCreateEditViewProps {
    setModal: (isOpen: boolean) => void;
    initialValue: AllowancesModel | null;
}

const AllowancesCreateEditView = observer(({setModal, initialValue}: AllowancesCreateEditViewProps) => {
    const {
        isLoading,
        form,
        allowanceCreateEditReq,
        init,
        destroy,
        setField
    } = AllowancesCreateEditStore;
    const {allowancesReq} = AllowancesStore;
    const {destroyModalDictionary, dictionaryListReq, getModalPlaceholders} = DictionaryStore;

    useEffect(() => {
        destroyModalDictionary();
        const controller = new AbortController();
        void dictionaryListReq('ALLOWANCES', 'MODAL', controller.signal);
        init(initialValue);

        return () => controller.abort();
    }, [destroyModalDictionary, dictionaryListReq, destroy, init, initialValue]);

    const handleCreateEditAllowance = async () => {
        const resp = await allowanceCreateEditReq(!!initialValue);

        if (resp) {
            destroy();
            setModal(false);
            await allowancesReq();
        }
    }

    const {name, description} = form;

    const inputs = [
        {field: name, fieldName: 'name', placeholder: getModalPlaceholders('name'), type: 'text', required: true},
        {field: description, fieldName: 'description', placeholder: getModalPlaceholders('description'), type: 'text', required: false},
    ];

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setField)}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={isLoading}
                    onClick={handleCreateEditAllowance}
                    disabled={isValid(inputs)}
                >
                    {isLoading ? '' : renderSubmitText}
                </Button>
            </Layout>
        </Layout>
    );
});

export default AllowancesCreateEditView;