// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import firebase from "firebase/compat";
import {makeAutoObservable} from "mobx";
import FirebaseStore from "./components/header/firebase.store";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDf2oaCKCWizAqcW6ByfMhCqxw9TIqppgc",
    authDomain: "business-visor.firebaseapp.com",
    projectId: "business-visor",
    storageBucket: "business-visor.appspot.com",
    messagingSenderId: "506573654734",
    appId: "1:506573654734:web:8e3a1c39a93a2479125e9f",
    measurementId: "G-NM133Z20TJ"
};

class Firebase {
    constructor() {
        makeAutoObservable(this);
    }

    public async firebaseInit() {
        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging(app);

        getToken(messaging, {vapidKey: 'BAC9Jf8MYCqgMiNK9Gzc9nUL6WEnZpUgMDv-0wUK6YUMoxci4hcSB0HceUy-ml3x4K-Try6zbIQH8qgyynEsjz0'}).then((currentToken: any) => {
            if (currentToken) {
                // Send the token to your server and update the UI if necessary
                // ...
                const {registerNotificationToken} = FirebaseStore;

                registerNotificationToken(currentToken);
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
                // ...
            }
        }).catch((err: any) => {
            console.log('An error occurred while retrieving token. ', err);
            // ...
        });
    }

    public onMessageListener = () =>
        new Promise((resolve) => {
            const app = initializeApp(firebaseConfig);
            const messaging = getMessaging(app);
            const { firebaseReq } = FirebaseStore;

            onMessage(messaging, (payload: any) => {
                resolve(payload);
                void firebaseReq();
            });
        });
}

export default new Firebase();