import axios, {GenericAbortSignal} from 'axios';
import {makeAutoObservable} from 'mobx';
import {api} from '../../api/endpoints';
import AppStore from '../../app.store';
import {
    TBusinessStocksGetResponse,
    TBusinessUnitsGetResponse,
    TMeasurementsGetResponse,
    TProductsGetResponse,
} from './stock.types';
import {toast} from 'react-toastify';
import {ExpiryDateModel, StocksFilterModel} from './stocks.model';

const stocksFilterInitialState = {
    search: '',
    brand_id: 0,
    business_unit_id: 0,
    category_id: 0,
    goods_id: 0,
    measure_unit_id: 0,
    page: 1,
    page_limit: 25,
    pages: 0,
    order_by: '',
    order_descending: false,
};
const expiryDatesInitialState: ExpiryDateModel[] = [];

class StockStore {
    stocks: TBusinessStocksGetResponse['stocks'] = [];
    loading = false;
    filter = stocksFilterInitialState;
    measurements: TMeasurementsGetResponse = [];
    business_units: TBusinessUnitsGetResponse['business_units'] = [];
    products: TProductsGetResponse['products'] = [];
    expiryDates = expiryDatesInitialState;
    page = 1;
    selectedProducts = [];
    pages = 1;
    page_limit = 25;
    public expiryLoading = false;
    public message = '';
    public error = '';

    constructor() {
        makeAutoObservable(this);
    }

    private setStocksFilterPages = (pages: number) => {
        this.filter.pages = pages;
    };

    setExpiryDates(data: any) {
        this.expiryDates = data;
    }

    public setStocksFilter = ({
                                  search,
                                  brand_id,
                                  business_unit_id,
                                  category_id,
                                  measure_unit_id,
                                  page,
                                  page_limit,
                              }: StocksFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.filter = {
            ...this.filter,
            search: isUndefined(search) ? this.filter.search! : search!,
            brand_id: isUndefined(brand_id) ? this.filter.brand_id! : brand_id!,
            business_unit_id: isUndefined(business_unit_id)
                ? this.filter.business_unit_id!
                : business_unit_id!,
            category_id: isUndefined(category_id)
                ? this.filter.category_id!
                : category_id!,
            measure_unit_id: isUndefined(measure_unit_id)
                ? this.filter.measure_unit_id!
                : measure_unit_id!,
            page: isUndefined(page) ? this.filter.page! : page!,
            page_limit: isUndefined(page_limit)
                ? this.filter.page_limit!
                : page_limit!,
        };

        if (page_limit) {
            this.filter.page = 1;
        }

        void this.getGoods();
    };

    public getExpiryDatesReq = async (productID: number, businessUnitID: number, signal?: AbortSignal) => {
        this.expiryLoading = true
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken(),
        };

        try {
            const response = await axios.get(api.business_stock + `/expiry?product_id=${productID}&bu_id=${businessUnitID}`,
                {headers, signal});
            this.setExpiryDates(response.data);
        } catch (e: any) {
            this.error = e?.response?.data.reason;
            toast.error(this.error);
        } finally {
            this.expiryLoading = false;
        }
    }

    public destroyExpiryDates = () => {
        this.expiryDates = expiryDatesInitialState;
    }

    getGoods = async (signal?: GenericAbortSignal) => {
        this.loading = true;
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken(),
        };

        const filterUrl = Object.keys(this.filter).reduce((filters, filter) => {
            const symbol = filters ? '&' : '?';
            const filterKeyName = filter as keyof StocksFilterModel;
            if (
                this.filter[filterKeyName] !== 0 &&
                this.filter[filterKeyName] !== ''
            ) {
                filters += symbol + filter + '=' + this.filter[filterKeyName];
            }

            return filters;
        }, '');

        try {
            const response = await axios.get(api.business_stocks + filterUrl, {
                headers,
                signal,
            });
            this.stocks = response.data.stocks;
            this.setStocksFilterPages(response.data.pages);
        } catch (error) {
        } finally {
            this.loading = false;
        }
    };

    public clearFilter = () => {
        this.filter = stocksFilterInitialState;
    };

    deleteProduct = async (id: string, business_unit_id: string) => {
        const {getToken} = AppStore;
        const url = new URL(api.business_stock);
        url.searchParams.set('goods_id', id);
        url.searchParams.set('bu_id', business_unit_id);

        try {
            const headers = {
                Authorization: 'Bearer ' + getToken(),
            };
            const response = await axios.delete(url.toString(), {
                headers,
            });
            this.message = response?.data.message;
            toast.success(this.message);

            void this.getGoods();
        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);
        }
    };

    private setMeasurements = (data: any) => {
        this.measurements = data;
    };

    getMeasurements = async (signal: AbortSignal) => {
        const url = new URL(api.get_measurements);
        const headers = {
            Authorization: 'Bearer ' + AppStore.getToken(),
        };
        try {
            const response = await axios.get<TMeasurementsGetResponse>(
                url.toString(),
                {headers, signal}
            );
            this.setMeasurements(response.data);
        } catch (err) {
        } finally {
        }
    };

    getBusinessUnits = async (signal: AbortSignal) => {
        const url = new URL(api.get_business_units);
        url.searchParams.set('page_limit', '999');
        const headers = {
            Authorization: 'Bearer ' + AppStore.getToken(),
        };
        try {
            const response = await axios.get<TBusinessUnitsGetResponse>(
                url.toString(),
                {headers, signal}
            );
            const {business_units} = response.data;
            this.business_units = business_units;
        } catch (error) {
        }
    };

    getProducts = async (signal: AbortSignal) => {
        const url = new URL(api.get_products);
        url.searchParams.set('page_limit', '999');
        url.searchParams.set('type_id', '1');

        const headers = {
            Authorization: 'Bearer ' + AppStore.getToken(),
        };

        const response = await axios.get<TProductsGetResponse>(url.toString(), {
            headers,
            signal,
        });
        this.products = response.data.products;
    };
}

export default new StockStore();
