import {observer} from "mobx-react";
import {Layout} from "../../../../styles/layout";
import EmployeeDeductionsStore from "../../employee.deductions/employee.deductions.store";
import {useEffect} from "react";
import {
    EmployeeDeductionsFilterModel,
    EmployeeDeductionsModel
} from "../../employee.deductions/employee.deductions.model";
import {Table, TableBody, TableContainer, TableHead, TCell, THeadCell, TRow} from "../../../../styles/table";
import TableSkeleton from "../../../../utils/skeleton/table.skeleton";
import NoResultTitle from "../../../../components/no_result_title/no_result_title.view";
import PayrollsCreateEditStore from "./payrolls.create.edit.store";

interface PayrollsCreateEditDeductionsViewProps {
    employeeID: number;
    setModal: (isOpen: boolean) => void;
}

const PayrollsCreateEditDeductionsView = observer(({employeeID, setModal}: PayrollsCreateEditDeductionsViewProps) => {

    const {employeeDeductions, setEmployeeDeductionFilter, isLoading} = EmployeeDeductionsStore;
    const {setSelectedItem, form} = PayrollsCreateEditStore;

    useEffect(() => {
        setEmployeeDeductionFilter({employee_id: employeeID, paid: 'false'} as EmployeeDeductionsFilterModel)
    }, []);

    const handleDeductionSelect = (deduction: EmployeeDeductionsModel) => {
        setSelectedItem(deduction.id, deduction.deduction, deduction.amount, 'DEDUCTION');
        setModal(false);
    }

    return (
        <Layout>
            <Table inModal>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>Название</THeadCell>
                        <THeadCell>Описание</THeadCell>
                        <THeadCell>Сумма</THeadCell>
                    </TRow>
                </TableHead>
                {isLoading ? (
                    <TableSkeleton columns={3}/>
                ) : (
                    <TableBody>
                        {employeeDeductions.length ? (
                            employeeDeductions.filter((deduction) =>
                                !form.allow_ded.find((all_ded) =>
                                    deduction.id === all_ded.id && all_ded.type === 'DEDUCTION'))
                                .map((deduction, index) => (
                                    <TRow
                                        key={index}
                                        onClick={() => handleDeductionSelect(deduction)}
                                    >
                                        <TCell>{deduction.deduction}</TCell>
                                        <TCell>{deduction.details}</TCell>
                                        <TCell>{deduction.amount}</TCell>
                                    </TRow>
                                ))
                        ) : (
                            <NoResultTitle colspan={3}/>
                        )}
                    </TableBody>
                )}
            </Table>
        </Layout>
    );
});

export default PayrollsCreateEditDeductionsView;