import axios from 'axios';
import { makeObservable, observable, action, computed } from "mobx";
import { api } from '../../api/endpoints';
import React from 'react';
import uuid from 'react-uuid';

interface Form {
    username: string;
    password: string;
}

class LoginStore {
    public form: Form = {
        username: '',
        password: ''
    }
    public isLoading = false;
    public backendError?: string;

    constructor() {
        makeObservable(this, {
            form: observable,
            isLoading: observable,
            backendError: observable,
            setForm: action.bound,
            loginReq: action.bound,
            isValid: computed,
            destroy: action.bound,
        })
    }

    public setForm(value: string, field: string) {
        this.form[field as keyof Form] = value;
    }

    public async loginReq() {
        this.isLoading = true;

        var deviceUUID = localStorage.getItem("device_uuid")

        if (deviceUUID === null || deviceUUID === "") {
            deviceUUID = uuid();
            localStorage.setItem("device_uuid", deviceUUID)
        }

        const { username, password } = this.form;
        const payload = {
            username: username.toLowerCase(),
            password,
            captcha: null,
            otp: null,
            "device_uuid": deviceUUID,
        }
        try {
            const res = await axios.post(api.login, payload);
            localStorage.setItem('visor-access-token', res.data.access_token);
            localStorage.setItem('visor-refresh-token', res.data.refresh_token);
            this.isLoading = false;

            return true;
        } catch (error: any) {
            if (error?.response?.data?.message) {
                this.backendError = error.response.data.message;
            }
            this.isLoading = false;
            return false;
        }
    }

    public get isValid() {
        const { username, password } = this.form;
        return !(username && password)
    }

    public destroy() {
        this.form.username = ''
        this.form.password = ''
    }
}

export default new LoginStore();