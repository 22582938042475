import {EmployeeAllowancesFilterModel, EmployeeAllowancesModel} from "./employee.allowances.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import axios from "axios";
import {api} from "../../../api/endpoints";
import {toast} from "react-toastify";

const employeeAllowanceFilterInitialState: EmployeeAllowancesFilterModel = {
    search: '',
    user_id: 0,
    employee_id: 0,
    paid: '',
    page: 1,
    pages: 1,
    page_limit: 25,
    order_descending: true,
}

const employeeAllowancesInitialState: EmployeeAllowancesModel[] = [];
const loadingInitialState: boolean = false;

class EmployeeAllowancesStore {
    public employeeAllowancesFilters = employeeAllowanceFilterInitialState;
    public employeeAllowances = employeeAllowancesInitialState;
    public isLoading = loadingInitialState;
    error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public init = async (signal: AbortSignal) => {
        await this.employeeAllowancesReq(signal);
    }

    setAllowances(data: any) {
        const {allowances} = data;
        this.employeeAllowances = allowances;
    }

    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    private setEmployeeAllowancesFilterPages = (pages: number) => {
        this.employeeAllowancesFilters.pages = pages;
    }

    public clearFilter = () => {
        this.employeeAllowancesFilters = employeeAllowanceFilterInitialState;
        void this.employeeAllowancesReq();
    }

    public employeeAllowancesReq = async (signal?: AbortSignal) => {
        this.setLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        const filterUrl = Object.keys(this.employeeAllowancesFilters).reduce((filters, filter) => {
            const symbol = filters ? '&' : '?';
            const filterKeyName = filter as keyof EmployeeAllowancesFilterModel;
            if (this.employeeAllowancesFilters[filterKeyName] !== 0 &&
                this.employeeAllowancesFilters[filterKeyName] !== '') {
                filters += symbol + filter + '=' + this.employeeAllowancesFilters[filterKeyName];
            }

            return filters;
        }, '');

        try {

            const response = await axios.get(api.payrolls + '/employee/allowances' + filterUrl, {headers, signal});
            this.setAllowances(response.data);
            this.setEmployeeAllowancesFilterPages(response.data.pages);

        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }

    public setEmployeeAllowancesFilter = ({search, user_id, employee_id, paid, page, page_limit}: EmployeeAllowancesFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.employeeAllowancesFilters = {
            ...this.employeeAllowancesFilters,
            search: isUndefined(search) ? this.employeeAllowancesFilters.search! : search!,
            user_id: isUndefined(user_id) ? this.employeeAllowancesFilters.user_id! : user_id!,
            employee_id: isUndefined(employee_id) ? this.employeeAllowancesFilters.employee_id! : employee_id!,
            paid: isUndefined(paid) ? this.employeeAllowancesFilters.paid! : paid!,
            page: isUndefined(page) ? this.employeeAllowancesFilters.page! : page!,
            page_limit: isUndefined(page_limit) ? this.employeeAllowancesFilters.page_limit! : page_limit!
        }

        if (page_limit) {
            this.employeeAllowancesFilters.page = 1;
        }

        void this.employeeAllowancesReq();
    }
}

export default new EmployeeAllowancesStore();