import styled from "styled-components";

export const NoResultTRow = styled.tr`
  background: #fff;
`;

export const NoResultTCell = styled.td`
  padding: 20px;
  text-align: center;
`;

export const Image = styled.img`
  max-width: 100%;
  height: 90px;
  margin-bottom: 20px;
`;

export const NoResultText = styled.p`
  color: #6c757d;
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
`;
