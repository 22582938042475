import {DeductionsModel} from "./deductions.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import {toast} from "react-toastify";
import axios from "axios";
import {api} from "../../../api/endpoints";

const deductionsInitialValue: DeductionsModel[] = [];
const loadingInitialState: boolean = false;

class DeductionsStore {
    public deductions = deductionsInitialValue;
    public isLoading = loadingInitialState;
    public message: string = '';
    public error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    setDeductions(data: any) {
        this.deductions = data;
    }

    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    public init = async (signal?: AbortSignal) => {
        await this.deductionsReq(signal);
    }

    public deductionsReq = async (signal?: AbortSignal) => {
        this.setLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        try {
            const response = await axios.get(api.payrolls + '/deductions', {headers, signal});
            this.setDeductions(response.data);
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }
}

export default new DeductionsStore();