import {observer} from "mobx-react";
import {useState} from "react";
import {Layout} from "../../styles/layout";
import {Text} from "../../styles/text";
import {Button} from "../../styles/button";
import {EyeImgIcon, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../styles/table";
import EyeIcon from "../../assets/icons/eye.svg";
import {primaryColor} from "../../utils/variables";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import ModalView from "../../components/modal/modal.view";
import {ClientDocumentsModel} from "./client.documents.model";

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface ClientDocumentsMobileViewProps {
    loading: boolean;
    clientDocs: ClientDocumentsModel[] | undefined;
    handleEditClick: (doc: ClientDocumentsModel) => void;
    setConfirmDeleteModal: ({
                                isModalOpen,
                                id,
                                name,
                            }: setConfirmDeleteModalProps) => void;
    tableHeaders: (key: string) => string;
    handleDocumentType: (docType: number) => string;
}

const ClientDocumentsMobileView = observer(({
                                                loading,
                                                clientDocs,
                                                handleEditClick,
                                                setConfirmDeleteModal,
                                                tableHeaders,
                                                handleDocumentType
                                            }: ClientDocumentsMobileViewProps) => {
    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<ClientDocumentsModel | null>(null);

    const handleRowClick = (doc: ClientDocumentsModel) => {
        setModal(true);
        setInitialValue(doc);
    };

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    };

    const renderModalItems = (doc: ClientDocumentsModel) => (
        <Layout vertical between={10}>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('type_id')}</Text>
                <Text>{handleDocumentType(doc.type_id)}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('number')}</Text>
                <Text>{`${doc.series}${doc.number}`}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('inn')}</Text>
                <Text>{doc.inn}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('address')}</Text>
                <Text>{doc.address}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('issued_by')}</Text>
                <Text>{doc.issued_by}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('issue_date_text')}</Text>
                <Text>{doc.issue_date_text}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('expiration_date_text')}</Text>
                <Text>{doc.expiration_date_text}</Text>
            </Layout>
            <Layout hAlign={'center'} between={5}>
                <Button onClick={() => handleEditClick(doc)}>Изменить</Button>
                <Button
                    background={'rgb(146,4,4)'}
                    onClick={() =>
                        setConfirmDeleteModal({
                            isModalOpen: true,
                            id: doc.id,
                            name: `${handleDocumentType(initialValue?.type_id!)} ${initialValue?.series}${initialValue?.number}`,
                        })
                    }
                >
                    Удалить
                </Button>
            </Layout>
        </Layout>
    );

    const renderedItems = () => (
        <>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>{tableHeaders('type_id')}</THeadCell>
                        <THeadCell>{tableHeaders('number')}</THeadCell>
                        <THeadCell>{tableHeaders('address')}</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                {!loading ? (
                    <TableBody>
                        {clientDocs?.length ? (
                            clientDocs?.map((doc, index) => (
                                <TRow key={index} menu>
                                    <TCell>{handleDocumentType(doc.type_id)}</TCell>
                                    <TCell>{`${doc.series}${doc.number}`}</TCell>
                                    <TCell>{doc.address}</TCell>
                                    <TCell relative>
                                        <EyeImgIcon
                                            src={EyeIcon}
                                            onClick={() => handleRowClick(doc)}
                                            height="18px"
                                            color={primaryColor}
                                        />
                                    </TCell>
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={4}/>
                        )}
                    </TableBody>
                ) : (
                    <TableSkeleton columns={4}/>
                )}
            </Table>
            {modal && (
                <ModalView
                    title={`Данные документа ${handleDocumentType(initialValue?.type_id!)} ${initialValue?.series}${initialValue?.number}`}
                    onClose={handleCloseModal}
                >
                    {renderModalItems(initialValue!)}
                </ModalView>
            )}
        </>
    );

    return <Layout>{renderedItems()}</Layout>;
});

export default ClientDocumentsMobileView;