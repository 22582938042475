import {makeAutoObservable} from "mobx";
import {ExpiryDates, ProductQuantitiesModel, ProductsCreateModel, ProductsImportModel} from "./products.create.model";
import AppStore from "../../../app.store";
import {toast} from "react-toastify";
import axios from "axios";
import {api} from "../../../api/endpoints";
import {BusinessUnitModel} from "../../business_units/business_units.model";

const loadingInitialState: boolean = false;
const productInitialState: ProductsImportModel = {
    name: '',
    description: '',
    cost: 0,
    price: 0,
    fixed_price: false,
    part_number: '',
    category: '',
    brand: '',
    bonus_percentage: 0,
    has_expiry: false,
    quantities: [],
}

class ProductsCreateStore {
    public excelData: ProductsCreateModel[] = [];
    public form: ProductsImportModel[] = []
    public loading = loadingInitialState;
    public product = productInitialState;
    brands: string[] = []
    categories: string[] = [];
    error: string = '';
    message: string = '';

    constructor() {
        makeAutoObservable(this)
    }

    public destroyProduct = () => {
        this.product = productInitialState;
    }

    public initProduct = (businessUnits: BusinessUnitModel[]) => {
        console.log('businessUnits:', businessUnits)
        businessUnits?.forEach((bu) => {
            const quantityModel: ProductQuantitiesModel = {
                business_unit_id: bu.id,
                quantity: 0,
                expiry: [
                    {
                        production_date: new Date().toLocaleDateString('en-CA'),
                        expiry_date: new Date().toLocaleDateString('en-CA'),
                        quantity: 0,
                    }
                ],
            }
            if (this.product.quantities.length !== businessUnits.length) {
                this.product.quantities.push(quantityModel);
            }
        })
    }

    public setProductField = (product: ProductsImportModel) => {
        this.product = product;
    }

    public setExcelData = (data: ProductsCreateModel[]) => {
        this.excelData = data;
        this.excelData.forEach((item) => {
            const product: ProductsImportModel = {
                name: item.Название ? String(item.Название) : "",
                description: item.Описание ? String(item.Описание) : "",
                cost: item.Стоимость ? Number(item.Стоимость) : 0,
                price: item.Цена ? Number(item.Цена) : 0,
                // todo add fixed price to excel
                fixed_price: false,
                part_number: item.Номер ? String(item.Номер) : "",
                category: item.Категория ? String(item.Категория) : "",
                brand: item.Бренд ? String(item.Бренд) : "",
                bonus_percentage: item.Бонус ? Number(item.Бонус) : 0,
                has_expiry: item.Срок === 'Да',
                quantities: [],
            }
            this.addProductToForm(product);
        })
    }

    public changeQuantity = (buID: number, quantity: number, index: number) => {
        for (let i = 0; i < this.form[index].quantities.length; i++) {
            if (this.form[index].quantities[i].business_unit_id === buID) {
                this.form[index].quantities[i].quantity = quantity
            }
        }
    }

    public getProductExpiryDates = (prodIndex: number, buID: number) => {
        let value: ExpiryDates[] = [];
        for (let i = 0; i < this.form[prodIndex].quantities.length; i++) {
            if (this.form[prodIndex].quantities[i].business_unit_id === buID) {
                value = this.form[prodIndex].quantities[i].expiry
            }
        }
        return value;
    }

    public addNewExpiryDates = (prodIndex: number, buID: number) => {
        for (let i = 0; i < this.form[prodIndex].quantities.length; i++) {
            if (this.form[prodIndex].quantities[i].business_unit_id === buID) {
                const expiry: ExpiryDates = {
                    production_date: new Date().toLocaleDateString('en-CA'),
                    expiry_date: new Date().toLocaleDateString('en-CA'),
                    quantity: 0
                }
                this.form[prodIndex].quantities[i].expiry.push(expiry)
            }
        }
    }

    public changeProductInfo = (fieldName: string, value: any, index: number) => {
        // @ts-ignore
        this.form[index][fieldName as keyof ProductsImportModel] = value;
    }

    public changeQuantityWithExpiry = (buID: number, prodIndex: number, buIndex: number, expIndex: number, fieldName: string, value: number | string) => {
        if (this.form[prodIndex].quantities[buIndex].business_unit_id === buID) {
            // @ts-ignore
            this.form[prodIndex].quantities[buIndex].expiry[expIndex][fieldName] = value
        }
    }

    public deleteExpiry = (buID: number, prodIndex: number, buIndex: number, expIndex: number) => {
        if (this.form[prodIndex].quantities[buIndex].business_unit_id === buID) {
            this.form[prodIndex].quantities[buIndex].expiry.splice(expIndex, 1)
        }
    }

    public deleteProduct = (prodIndex: number) => {
        this.form.splice(prodIndex, 1)
    }

    public setQuantityForBu = (prodIndex: number, buID: number, quantity: number) => {
        this.form[prodIndex].quantities.forEach((item, index) => {
            if (item.business_unit_id === buID) {
                this.form[prodIndex].quantities[index].quantity = quantity
            }
        })
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    public addProductToForm = (product: ProductsImportModel) => {
        this.form.push(product);
    }

    addBrandToList(brand: string) {
        this.brands.push(brand);
    }

    addCategoryToList(category: string) {
        this.categories.push(category);
    }

    private handleUniqueBrandsAndCategories = () => {
        this.form.forEach((item) => {
            this.addBrandToList(item.brand);
            this.addCategoryToList(item.category)
        })

        this.brands = this.brands.filter(function (value, index, array) {
            return array.indexOf(value) === index;
        })

        this.categories = this.categories.filter(function (value, index, array) {
            return array.indexOf(value) === index;
        })
    }

    public destroy = () => {
        this.form = [];
        this.excelData = [];
        this.loading = loadingInitialState;
        this.brands = [];
        this.categories = [];
    }

    public importProductsReq = async () => {
        this.setLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }
        this.handleUniqueBrandsAndCategories();

        const payload = {
            brands: this.brands,
            categories: this.categories,
            products: this.form,
        }

        try {
            const response = await axios.post(api.product + `/excel-import`, payload, {headers});
            this.message = response.data.message;
            toast.success(this.message);
            return response;
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }
}

export default new ProductsCreateStore();