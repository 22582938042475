import { makeAutoObservable } from 'mobx';
import axios, { GenericAbortSignal } from 'axios';
import AppStore from '../../app.store';
import { api } from '../../api/endpoints';
import { ClientContactsModel } from './client.contacts.modal/clients.contacts.model';
import { toast } from 'react-toastify';

const ClientContactsInitialState: ClientContactsModel[] = [];
const loadingInitialState: boolean = false;

const formInitialValue = {
  name: '',
  surname: '',
  patronymic: '',
  phone: '',
  extra_phone: '',
  address: '',
  relationship: '',
};

class ClientContactsStore {
  public entity_Type: string = '';
  public entity_Id: string = '';
  public clientsContacts?: ClientContactsModel[] = ClientContactsInitialState;
  public loading: boolean = loadingInitialState;
  public message = '';
  public error = '';

  form = formInitialValue;

  public setData(entityType: string, entityId: string) {
    this.entity_Type = entityType;
    this.entity_Id = entityId;
  }

  constructor() {
    makeAutoObservable(this);
  }

  setClinetContacts(data: any) {
    this.clientsContacts = data;
  }

  public setField = (form: any) => {
    this.form = form;
  };

  public destroy = () => {
    this.form = formInitialValue;
  };

  public clientsContactsPageInit = async (
    entityId: string,
    signal?: GenericAbortSignal
  ) => {
    void this.clientContactReq(entityId, signal);
  };
  public clientContactReq = async (
    entId?: string,
    signal?: GenericAbortSignal
  ) => {
    this.setLoading(true);

    try {
      const { getToken } = AppStore;

      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };

      const response = await axios.get(api.client_contacts + `/${entId}`, {
        headers,
        signal,
      });
      this.setClinetContacts(response.data);
    } catch (err) {
    } finally {
      this.setLoading(false);
    }
  };
  public updetaContactReq = async (
    entId?: string,
    signal?: GenericAbortSignal
  ) => {
    this.setLoading(true);

    try {
      const { getToken } = AppStore;

      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };

      const response = await axios.put(api.client_contacts + `/${entId}`, {
        headers,
        signal,
      });
      this.message = response?.data.message;
      toast.success(this.message);
      this.setClinetContacts(response.data);
    } catch (err) {
    } finally {
      this.setLoading(false);
    }
  };
  public deleteClientContacts = async (id: number, entityId: string) => {
    this.setLoading(true);

    try {
      const { getToken } = AppStore;
      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };
      const response = await axios.delete(`${api.client_contacts}/${id}`, {
        headers,
      });

      this.message = response?.data.message;
      toast.success(this.message);

      if (response) {
        this.clientContactReq(entityId);
      }
    } catch (err: any) {
      this.error = err?.response.data.reason;
      toast.error(this.error);
    } finally {
      this.setLoading(false);
    }
  };

  private setLoading = (loading: boolean) => {
    this.loading = loading;
  };
}

export default new ClientContactsStore();
