import {observer} from "mobx-react";
import {useState} from "react";
import {Layout} from "../../styles/layout";
import {Text} from "../../styles/text";
import {Badge} from "../../styles/badge";
import {Button} from "../../styles/button";
import {EyeImgIcon, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../styles/table";
import {Image} from "../../styles/image";
import EyeIcon from "../../assets/icons/eye.svg";
import {primaryColor} from "../../utils/variables";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import ModalView from "../../components/modal/modal.view";
import {BusinessUnitModel} from "./business_units.model";

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface BusinessUnitsTableMobileViewProps {
    loading: boolean;
    businessUnits: BusinessUnitModel[] | undefined;
    handleAccountClick: (businessUnit: BusinessUnitModel) => void;
    handleEditClick: (businessUnit: BusinessUnitModel) => void;
    setConfirmDeleteModal: ({isModalOpen, id, name}: setConfirmDeleteModalProps) => void;
    getTableHeaders: (key: string) => string;
}

const BusinessUnitsTableMobileView = observer(({
                                                   loading,
                                                   businessUnits,
                                                   handleAccountClick,
                                                   handleEditClick,
                                                   setConfirmDeleteModal,
                                                   getTableHeaders
                                               }: BusinessUnitsTableMobileViewProps) => {

    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<BusinessUnitModel | null>(null)

    const handleRowClick = (businessUnit: BusinessUnitModel) => {
        setModal(true);
        setInitialValue(businessUnit);
    }

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    }

    const renderModalItems = (businessUnit: BusinessUnitModel) => (
        <Layout vertical between={10}>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{getTableHeaders('name')}</Text>
                <Text>{businessUnit.name}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{getTableHeaders('description')}</Text>
                <Text>{businessUnit.description}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{getTableHeaders('address')}</Text>
                <Text>{businessUnit.address}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{getTableHeaders('phone')}</Text>
                <Text>{businessUnit.phone}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{getTableHeaders('cash_balance')}</Text>
                <Text>{businessUnit.cash_balance}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{getTableHeaders('employee_count')}</Text>
                <Text>{businessUnit.employee_count}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{getTableHeaders('active')}</Text>
                <Text>
                    <Badge online={businessUnit.active}>
                        {businessUnit.active ? "Активный" : "Заблокирован"}
                    </Badge>
                </Text>
            </Layout>
            <Layout hAlign={"center"} between={5}>
                <Button onClick={() => handleAccountClick(businessUnit)}>Счета</Button>
                <Button onClick={() => handleEditClick(businessUnit)}>Изменить</Button>
                <Button
                    background={'rgb(146,4,4)'}
                    onClick={() =>
                        setConfirmDeleteModal({
                            isModalOpen: true,
                            id: businessUnit.id,
                            name: businessUnit.name,
                        })
                    }
                >Удалить
                </Button>
            </Layout>
        </Layout>
    );

    const renderedItems = () => (
        <>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>{getTableHeaders('name')}</THeadCell>
                        <THeadCell>{getTableHeaders('address')}</THeadCell>
                        <THeadCell>{getTableHeaders('phone')}</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                {!loading ? (
                    <TableBody>
                        {businessUnits?.length ? (
                            businessUnits?.map((businessUnit, index) => (
                                <TRow key={index} menu>
                                    <TCell>{businessUnit.name}</TCell>
                                    <TCell>{businessUnit.address}</TCell>
                                    <TCell>{businessUnit.phone}</TCell>
                                    <TCell relative>
                                        <EyeImgIcon
                                            src={EyeIcon}
                                            onClick={() => handleRowClick(businessUnit)}
                                            height="18px"
                                            color={primaryColor}
                                        />
                                    </TCell>
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={4}/>
                        )}
                    </TableBody>
                ) : (
                    <TableSkeleton columns={4}/>
                )}
            </Table>
            {modal && (
                <ModalView
                    title={`Данные подразделения ${initialValue?.name}`}
                    onClose={handleCloseModal}
                >
                    {renderModalItems(initialValue!)}
                </ModalView>
            )}
        </>
    );

    return (
        <Layout>
            {renderedItems()}
        </Layout>
    )
});

export default BusinessUnitsTableMobileView;