import { makeAutoObservable } from 'mobx';
import axios, { GenericAbortSignal } from 'axios';
import AppStore from '../../app.store';
import { api } from '../../api/endpoints';
import { toast } from 'react-toastify';
import {
  AccountRecords,
  AccountRecordsFilterModel,
  AccountsModel,
} from './account.model';

const accountsInitialState: AccountsModel[] = [];
const loadingInitialState: boolean = false;

interface Form {
  total_amount: number;
  client_id: number;
  account_id: number;
  payment_method_id: number;
  details: string;
  type_id: number;
}

const formInitialValue: Form = {
  total_amount: 0,
  client_id: 1,
  payment_method_id: 1,
  account_id: 0,
  details: '',
  type_id: 4,
};

class AccountsStore {
  public entity_Type: string = '';
  public entity_Id: string = '';
  public accounts?: AccountsModel[] = accountsInitialState;
  public accountRecords?: AccountRecords[] = [];
  public loading: boolean = loadingInitialState;
  public message = '';
  public error = '';
  public bonusPaymentLoading = false;
  public recordsLoading = false;
  public accountRecordsFilter = {
    date_from: '',
    date_to: '',
    page: 1,
    page_limit: 25,
    pages: 0,
  };
  form = formInitialValue;

  public setData(entityType: string, entityId: string) {
    this.entity_Type = entityType;
    this.entity_Id = entityId;
  }

  public setAccountRecords = (data: any) => {
    const { records } = data;
    this.accountRecords = records;
  };

  public accountsPageInit = async (
    entityType: string,
    entityId: string,
    signal?: GenericAbortSignal
  ) => {
    void this.accountsReq(false, entityType, entityId, signal);
  };

  public accountRecordsInit = async (accountID: number) => {
    void this.accountRecordsReq(accountID);
  };

  constructor() {
    makeAutoObservable(this);
  }

  setAccounts(data: any) {
    this.accounts = data;
  }

  public setField = (form: Form) => {
    this.form = form;
  };

  public bonusPaymentReq = async (
    id: number,
    entityID: string,
    paymentType: string
  ) => {
    this.bonusPaymentLoading = true;
    switch (paymentType) {
      case 'bonus':
        this.form.type_id = 4;
        break;
      case 'supplier':
        this.form.type_id = 6;
        break;
    }
    const payload = {
      account_id: Number(id),
      total_amount: Number(this.form.total_amount),
      details: this.form.details,
      type_id: this.form.type_id,
    };

    try {
      const { getToken } = AppStore;

      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };

      const response = await axios.post(api.transactions, payload, { headers });
      this.message = response.data.message;
      toast(this.message);
      return response;
    } catch (err: any) {
      this.error = err?.response.data.reason;
      toast.error(this.error);
    } finally {
      await this.accountsReq(false, 'user', entityID);
      this.bonusPaymentLoading = false;
    }
  };

  public makePayment = async (id: number, entityID: string) => {
    this.bonusPaymentLoading = true;
    const payload = {
      account_id: Number(id),
      total_amount: Number(this.form.total_amount),
      details: this.form.details,
      type_id: this.form.type_id,
    };

    try {
      const { getToken } = AppStore;

      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };

      const response = await axios.post(api.transactions, payload, { headers });
      this.message = response.data.message;
      toast(this.message);
      return response;
    } catch (err: any) {
      this.error = err?.response.data.reason;
      toast.error(this.error);
    } finally {
      await this.accountsReq(false, 'user', entityID);
      this.bonusPaymentLoading = false;
    }
  };

  setAccountRecordsFilterPages(pages: number) {
    this.accountRecordsFilter.pages = pages;
  }

  public setAccountRecordsFilter = ({
    date_from,
    date_to,
    page,
    page_limit,
  }: AccountRecordsFilterModel) => {
    const isUndefined = (value: any) => typeof value === 'undefined';
    this.accountRecordsFilter = {
      ...this.accountRecordsFilter,
      date_from: isUndefined(date_from)
        ? this.accountRecordsFilter.date_from!
        : date_from!,
      date_to: isUndefined(date_to)
        ? this.accountRecordsFilter.date_to!
        : date_to!,
      page: isUndefined(page) ? this.accountRecordsFilter.page! : page!,
      page_limit: isUndefined(page_limit)
        ? this.accountRecordsFilter.page_limit!
        : page_limit!,
    };

    if (page_limit) {
      this.accountRecordsFilter.page = 1;
    }
  };

  public accountRecordsReq = async (accountID: number) => {
    this.recordsLoading = true;
    try {
      const { getToken } = AppStore;

      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };

      const filterUrl = Object.keys(this.accountRecordsFilter).reduce(
        (filters, filter) => {
          const symbol = filters ? '&' : '?';
          const filterKeyName = filter as keyof AccountRecordsFilterModel;
          if (
            this.accountRecordsFilter[filterKeyName] !== 0 &&
            this.accountRecordsFilter[filterKeyName] !== ''
          ) {
            filters +=
              symbol + filter + '=' + this.accountRecordsFilter[filterKeyName];
          }

          return filters;
        },
        ''
      );

      const response = await axios.get(
        api.account_records + `/${accountID}` + filterUrl,
        { headers }
      );
      this.setAccountRecords(response.data);
      this.setAccountRecordsFilterPages(response.data.pages);
    } catch (err: any) {
      this.error = err?.response.data.reason;
      toast.error(this.error);
    } finally {
      this.recordsLoading = false;
    }
  };

  public destroy = () => {
    this.form = formInitialValue;
  };

  public destroyAccounts = () => {
    this.accounts = [];
  };

  public accountsReq = async (
    technical: boolean,
    entType?: string,
    entId?: string,
    signal?: GenericAbortSignal
  ) => {
    this.setLoading(true);

    try {
      const { getToken } = AppStore;

      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };

      const response = await axios.get(
        api.account + `/${entType}?entity_id=${entId}&technical=${technical}`,
        { headers, signal }
      );
      this.setAccounts(response.data.resp);
    } catch (err) {
    } finally {
      this.setLoading(false);
    }
  };

  public deleteAccount = async (
    id: number,
    entityType?: string,
    entityQuery?: string,
    entityId?: string
  ) => {
    this.setLoading(true);

    try {
      const { getToken } = AppStore;
      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };
      const response = await axios.delete(`${api.account}/${id}`, { headers });

      this.message = response?.data.message;
      toast.success(this.message);

      if (response) {
        this.accountsReq(false, entityType, entityId);
      }
    } catch (err: any) {
      this.error = err?.response.data.reason;
      toast.error(this.error);
    } finally {
      this.setLoading(false);
    }
  };

  private setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  public destroyFilters = () => {
    this.accountRecordsFilter = {
      date_from: '',
      date_to: '',
      page: 1,
      page_limit: 25,
      pages: 0,
    };
  };
}

export default new AccountsStore();
