import {SuppliersModel} from "../suppliers.model";
import {observer} from "mobx-react";
import SuppliersCreateEditStore from "./suppliers.create.edit.store";
import SuppliersStore from "../suppliers.store";
import {useEffect} from "react";
import {Layout} from "../../../styles/layout";
import {renderInputs} from "../../../components/form/render.inputs";
import {Button} from "../../../styles/button";
import {isValid} from "../../../utils/isValid";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";

interface SuppliersCreateEditViewProps {
    initialValue: SuppliersModel | null;
    setModal: (isOpen: boolean) => void;
}

const SupplierCreateEditView = observer(({initialValue, setModal}: SuppliersCreateEditViewProps) => {

    const {
        form,
        setField,
        init,
        countryOptions,
        createOrEditSupplier,
        isLoading,
        destroy
    } = SuppliersCreateEditStore;

    const {
        name,
        phone,
        contact_name,
        country_id
    } = form;
    const {suppliersReq} = SuppliersStore;
    const {destroyModalDictionary, dictionaryListReq, getModalPlaceholders} = DictionaryStore;

    useEffect(() => {
        destroyModalDictionary();
        const controller = new AbortController();
        void dictionaryListReq('SUPPLIERS', 'MODAL', controller.signal);
        void init(initialValue);

        return () => {
            destroy();
            controller.abort();
        }
    }, [destroyModalDictionary, dictionaryListReq, destroy, init, initialValue]);

    const inputs = [
        {
            field: name,
            fieldName: 'name',
            placeholder: getModalPlaceholders('name'),
            type: 'text',
            required: true
        },
        {
            field: phone,
            fieldName: 'phone',
            placeholder: getModalPlaceholders('phone'),
            type: 'text',
            required: false
        },
        {
            field: contact_name,
            fieldName: 'contact_name',
            placeholder: getModalPlaceholders('contact_name'),
            type: 'text',
            required: false
        },
        {
            field: country_id,
            options: countryOptions,
            fieldName: 'country_id',
            placeholder: getModalPlaceholders('country_id'),
            type: 'select',
            required: true
        },
    ];

    const handleCreateEditSupplier = async () => {
        const res = await createOrEditSupplier(!!initialValue);
        if (res) {
            setModal(false);
            void suppliersReq();
        }
    }

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setField)}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={isLoading}
                    onClick={handleCreateEditSupplier}
                    disabled={isValid([inputs])}
                >{isLoading ? '' : renderSubmitText}</Button>
            </Layout>
        </Layout>
    )
});

export default SupplierCreateEditView;