import React from "react";
import { WrapperSkeleton } from "./main.style";

const MyLoader = () => (
  <WrapperSkeleton>
    <div className="wrapper">
      <div className="columns left">
        <div className="card"></div>
      </div>
      <div className="columns right-columns right">
        <div className="card"></div>
        <div className="card"></div>
        <div className="card"></div>
        <div className="card"></div>
        <div className="card"></div>
        <div className="card"></div>
      </div>
    </div>
  </WrapperSkeleton>
);

export default MyLoader;