import {observer} from "mobx-react";
import { Dispatch, SetStateAction } from "react";
import { Button } from "../../styles/button";
import { Image } from "../../styles/image";
import { Layout } from "../../styles/layout";
import { Text } from "../../styles/text";
import { deleteButton } from "../../utils/variables";

import WarningIcon from '../../assets/icons/warning.svg';

interface UsersCreateEditViewProps {
    setModal?: Dispatch<SetStateAction<DeleteItem>>;
    modalObj?: DeleteItem;
    warningText?: string;
    callback?: () => void;
    isLoading?: boolean;
    buttonColor?: string;
    buttonText?: string;
}

export interface DeleteItem {
    isModalOpen: boolean;
    id?: number;
    name?: string;
    //
}

const UsersCreateEditView = observer(({setModal, modalObj, warningText, callback, isLoading, buttonColor, buttonText}: UsersCreateEditViewProps) => {
    return (
        <Layout vertical heightSize={100} between={20} vAlign="space-between">
            <Layout between={10} vAlign="center">
                <Image extent="30px" src={WarningIcon} />
                <Text extent="14px">{warningText}</Text>
            </Layout>
            <Layout hAlign="center" between={14}>
                <Button
                    extent="130px"
                    heightSize="30px"
                    background="grey"
                    onClick={() => {
                        if (setModal) {
                            setModal({ isModalOpen: false })
                        }

                        if (modalObj) {
                            modalObj.isModalOpen = false
                        }
                    }}
                >Отменить</Button>
                <Button
                    extent="130px"
                    heightSize="30px"
                    background={buttonColor ? buttonColor: deleteButton}
                    onClick={callback}
                    isLoading={isLoading}
                >{isLoading ? '' : buttonText ? buttonText: 'Удалить'}</Button>
            </Layout>
        </Layout>
    );
});

export default UsersCreateEditView;
