import {observer} from 'mobx-react';
import {useState} from 'react';
import {Layout} from '../../styles/layout';
import {Text} from '../../styles/text';
import {Button} from '../../styles/button';
import {
    EyeImgIcon,
    Table,
    TableBody,
    TableHead,
    TCell,
    THeadCell,
    TRow,
} from '../../styles/table';
import EyeIcon from '../../assets/icons/eye.svg';
import {primaryColor} from '../../utils/variables';
import NoResultTitle from '../../components/no_result_title/no_result_title.view';
import TableSkeleton from '../../utils/skeleton/table.skeleton';
import ModalView from '../../components/modal/modal.view';
import {ClientContactsModel} from './client.contacts.modal/clients.contacts.model';

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface ClientsContactsTableMobileViewProps {
    loading: boolean;
    contacts: ClientContactsModel[] | undefined;
    handleAccountClick: (contacts: ClientContactsModel) => void;
    handleEditClick: (contacts: ClientContactsModel) => void;
    setConfirmDeleteModal: ({
                                isModalOpen,
                                id,
                                name,
                            }: setConfirmDeleteModalProps) => void;
    tableHeaders: (key: string) => string;
}

const ClientsContactsTableMobileView = observer(
    ({
         loading,
         contacts,
         handleEditClick,
         setConfirmDeleteModal,
         tableHeaders,
     }: ClientsContactsTableMobileViewProps) => {
        const [modal, setModal] = useState(false);
        const [initialValue, setInitialValue] =
            useState<ClientContactsModel | null>(null);

        const handleRowClick = (contacts: ClientContactsModel) => {
            setModal(true);
            setInitialValue(contacts);
        };

        const handleCloseModal = () => {
            setModal(false);
            setInitialValue(null);
        };

        const renderModalItems = (contacts: ClientContactsModel) => (
            <Layout vertical between={10}>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('name')}</Text>
                    <Text>{`${contacts.name}  ${contacts.surname}`}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('phone')}</Text>
                    <Text>{contacts.phone}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('extra_phone')}</Text>
                    <Text>{contacts.extra_phone}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('address')}</Text>
                    <Text>{contacts.address}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('relationship')}</Text>
                    <Text>{contacts.relationship}</Text>
                </Layout>
                <Layout hAlign={'center'} between={5}>
                    <Button onClick={() => handleEditClick(contacts)}>Изменить</Button>
                    <Button
                        background={'rgb(146,4,4)'}
                        onClick={() =>
                            setConfirmDeleteModal({
                                isModalOpen: true,
                                id: contacts.id,
                                name: contacts.name,
                            })
                        }
                    >
                        Удалить
                    </Button>
                </Layout>
            </Layout>
        );

        const renderedItems = () => (
            <>
                <Table>
                    <TableHead>
                        <TRow tableHead>
                            <THeadCell>{tableHeaders('name')}</THeadCell>
                            <THeadCell>{tableHeaders('phone')}</THeadCell>
                            <THeadCell>{tableHeaders('address')}</THeadCell>
                            <THeadCell></THeadCell>
                        </TRow>
                    </TableHead>
                    {!loading ? (
                        <TableBody>
                            {contacts?.length ? (
                                contacts?.map((contacts, index) => (
                                    <TRow key={index} menu>
                                        <TCell>{`${contacts.name}  ${contacts.surname}`}</TCell>
                                        <TCell>{contacts.phone}</TCell>
                                        <TCell>{contacts.address}</TCell>
                                        <TCell relative>
                                            <EyeImgIcon
                                                src={EyeIcon}
                                                onClick={() => handleRowClick(contacts)}
                                                height="18px"
                                                color={primaryColor}
                                            />
                                        </TCell>
                                    </TRow>
                                ))
                            ) : (
                                <NoResultTitle colspan={4}/>
                            )}
                        </TableBody>
                    ) : (
                        <TableSkeleton columns={4}/>
                    )}
                </Table>
                {modal && (
                    <ModalView
                        title={`Данные контакта ${initialValue?.name}`}
                        onClose={handleCloseModal}
                    >
                        {renderModalItems(initialValue!)}
                    </ModalView>
                )}
            </>
        );

        return <Layout>{renderedItems()}</Layout>;
    }
);

export default ClientsContactsTableMobileView;
