import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import {saveAs} from "file-saver";
import {toast} from "react-toastify";
// @ts-ignore
import request_dc from '../../utils/files/request-dc.docx'
// @ts-ignore
import agreement_dc from '../../utils/files/agreement-dc.docx'

const bankList = {
    fmfb: 1,//ЗАО «ПЕРВЫЙ МИКРОФИНАНСОВЫЙ БАНК»
    agroinvestbank: 2,//ОАО «АГРОИНВЕСТБАНК»
    amonatbonk: 3, //ОАО ГСБ «АМОНАТБАНК»
    tajik_sodirot_bank: 4, //ОАО «ТАДЖИКСОДИРОТБАНК»
    kafolat_bonk: 5, //ЗАО «КАФОЛАТБАНК»
    fononBank: 6, //ЗАО «ФОНОНБАНК»
    spitamen: 7, //ЗАО «СПИТАМЕН БОНК»
    matin: 8, //ООО «МАТИН»
    imon_international: 9, //ЗАО МДО «ИМОН Интернешнл»
    pak_nac_bank: 10, //ЗАО «ПАК НАЦ БАНК»
    kont_bank: 11, //ЗАО «КОНТ БАНК»
    kazkom: 12, //ЗАО «КАЗКОММЕРТСБОНК»
    bank_asia: 13, //ЗАО «Банк Азия»
    eskhata: 14, //ОАО «БАНК ЭСХАТА»
    oriyonbank: 15, //ОАО «ОРИЁНБАНК»
    commerce_bank: 16, //"ОАО ""Коммерцбанк Таджикистан"
    ibt: 17, //ЗАО «МЕЖД БАНК ТАДЖИКИСТАНА»
    alif: 18, //ООО МДО «АЛИФ-САРМОЯ»
    humo: 19, //ЗАО МДО “ХУМО”
    sohibkorbank: 20, //ОАО “Сохибкорбанк”
    tavhidbank: 21, //ОАО  “Тавхидбанк”
    dc: 22, //ООО МДО «Душанбе Сити»
    tamvil: 23, //ООО МДО «Тамвил»
    argun: 24, //ООО МДО «Аргун»
    zudamal: 25, //ЗАО МДО «ЗУДАМАЛ»
    vasl: 26, //ЗАО МДО «ВАСЛ»
    arvand: 27, //ЗАО Банк «Арванд»
    finca: 28, //ЗАО FINCA
    ardo_capital: 29, //ЗАО МДО “Ардо-капитал”
    ssb: 30, //КВД БССТ «Саноатсодиротбонк»
    payvand: 31, //"ООО МДО ""Пайванд Групп"""
    sanduk: 32, //ЗАО МДО “Сандук”
    sarvat: 33, //"ООО МДО ""Сарват-М"""
    fazo: 34, //"ООО МДО ""ФАЗО С"""
    shukr_moliya: 35, ///ЗАО МДО «ШУКР МОЛИЯ»
    rushd: 36, //ЗАО «Бонки Рушди Точикистон»
    emin: 37, //ООО МДО «Эмин Сармоя»
    fardo: 38 //"ООО «ФАРДО»"
}

//TODO: Пока что все сделано на фронте. Нужно подумать сделать в беке. Добавить ссылку на скачивание шаблона

class ClientsAccountsDocumentsStore {

    error: string = ''
    private getFirstLetter = (str: string): string => {
        return str.length > 0 ? str.charAt(0) : '';
    };

    // Async function to load and modify the docx file
    public loadAndModifyDocx = async (cardInfo: any, clientDocs: any, docType: string, phone: string) => {
        let clientInfo: any;
        if (clientDocs.length === 0) {
            this.error = 'Нет данных паспорта клиента'
            toast.error(this.error);
            return
        } else {
            clientInfo = clientDocs[0]
        }

        try {
            let file;
            let updatedFileName;

            switch (cardInfo.bank_id) {
                case bankList.dc:
                    if (docType === 'request') {
                        file = request_dc;
                        updatedFileName = 'zayavlenie.docx'
                    } else if (docType === 'agreement') {
                        file = agreement_dc;
                        updatedFileName = 'dogovor.docx'
                    }
                    break
                case bankList.fmfb:
                default:
                    this.error = `Нет шаблона документа банка ${cardInfo.bank}`
                    toast.error(this.error)
                    return
            }
            // Fetch the base .docx file from the public folder
            const response: Response = await fetch(file);

            // Ensure the response is OK
            if (!response.ok) {
                this.error = 'Ошибка считании шаблона'
                toast.error(this.error)
                return
            }

            // Read the file as an ArrayBuffer
            const arrayBuffer: ArrayBuffer = await response.arrayBuffer();

            // Initialize PizZip to load the .docx file
            const zip = new PizZip(arrayBuffer);

            // Create an instance to edit the file
            const doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true,
            });

            const today = new Date();
            const day = String(today.getDate()).padStart(2, '0');
            const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
            const year = today.getFullYear();
            /*
            DATA NEEDED for Zayavlenie DC
            {client_name_bold} capitalized
            {client_name} capitalized
            {passport_issue_date} dd.mm.yyyy
            {passport_number} with series
            {passport_issued_by} capitalized
            {client_inn}
            {client_sex} capped
            {client_birth_date} dd.mm.yyyy
            {living_address} according to passport, capped
            {client_email}
            {client_phone}
            {card_type} capped, VISA, KM
            {pan_0}, {pan_1}...{pan_15} card pan
            {client_surname_initials} Забиров И. Ш.
            {date} dd.mm.yyyy
            */
            const placeholders: { [key: string]: string } = {};

            // To split pan numbers to separate placeholders to replace each number in one box
            cardInfo.pan.split("").forEach((digit: any, index: number) => {
                placeholders[`pan_${index}`] = digit;
            });

            let gender: string = '';
            if (clientInfo.gender === 1) {
                gender = 'Мард'
            } else if (clientInfo.gender === 2) {
                gender = 'Зан'
            } else {
                gender = ''
            }

            placeholders['client_name_bold'] = `${clientInfo.surname} ${clientInfo.name} ${clientInfo.patronymic}`.toUpperCase()
            placeholders['client_name'] = `${clientInfo.surname} ${clientInfo.name} ${clientInfo.patronymic}`.toUpperCase()
            placeholders['passport_issue_date'] = clientInfo.issue_date_text ? clientInfo.issue_date_text : ''
            placeholders['passport_number'] = clientInfo.series + clientInfo.number
            placeholders['passport_issued_by'] = clientInfo.issued_by ? clientInfo.issued_by.toUpperCase() : ''
            placeholders['client_inn'] = clientInfo.inn ? clientInfo.inn : ''
            placeholders['client_sex'] = gender.toUpperCase()
            placeholders['client_birth_date'] = clientInfo.birthday ? clientInfo.birthday : ''
            placeholders['living_address'] = clientInfo.address ? clientInfo.address.toUpperCase() : ''
            placeholders['client_email'] = clientInfo.email ? clientInfo.email : ''
            placeholders['client_phone'] = phone ? phone : ''
            placeholders['card_type'] = cardInfo.card_type ? cardInfo.card_type : ''
            placeholders['client_surname_initials'] = `${clientInfo.surname} ${this.getFirstLetter(clientInfo.name).toUpperCase()}. ${this.getFirstLetter(clientInfo.patronymic).toUpperCase()}`
            placeholders['date'] = `${day}.${month}.${year}`
            placeholders['client_birth_date'] = clientInfo.birthday_text ? clientInfo.birthday_text : ''

            // Set the data for the placeholders in the document
            doc.setData(placeholders)

            // Render the document with the new data
            doc.render();

            // Generate the updated .docx file as a Blob
            const updatedDoc: Blob = doc.getZip().generate({
                type: 'blob',
                mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });

            // Use FileSaver to trigger a download of the new .docx file
            saveAs(updatedDoc, updatedFileName);
        } catch (error) {
            this.error = 'Ошибка в формировании документа';
            toast.error(this.error);
            console.error('Error while modifying the .docx file:', error);
        }
    };
}

export default new ClientsAccountsDocumentsStore();