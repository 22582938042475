import {Headline} from "../../styles/headline";
import {primaryColor} from "../../utils/variables";
import {observer} from "mobx-react";
import {
    ContextMenu, ContextMenuItem,
    Table,
    TableBody,
    TableContainer, TableContainerMobile,
    TableHead,
    TCell,
    THeadCell,
    TRow,
} from "../../styles/table";
import AppStore from "../../app.store";
import {Page} from "../../styles/page";
import {useEffect, useState} from "react";
import {
    AddButton,
    Search,
    SearchLayout, Select,
    TableHeader,
} from "../../styles/table-header";
import BusinessUnitsStore from "./business_units.store";
import {Badge} from "../../styles/badge";
import {Image} from "../../styles/image";
import SearchIcon from "../../assets/icons/search.svg";
import Modal from "../../components/modal/modal.view";
import AccountModal from "../../components/modal/account.modal.view";
import ConfirmDelete, {
    DeleteItem,
} from "../../components/confirm/confirm.delete";
import {BusinessUnitModel} from "./business_units.model";
import BusinessUnitsCreateEditView from "./business_units.modal/business_units.create.edit.view";
import {Filters} from "../../styles/filters";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import ContextMenuIcon from "../../assets/icons/context-menu.svg";
import AccountView from "../accounts/account.view";
import accountStore from "../accounts/account.store";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import TablePaginationView from "../../utils/table.pagination.view";
import {Button, FilterButton} from "../../styles/button";
import {Layout} from "../../styles/layout";
import BusinessUnitsTableMobileView from "./business_units.mobile.view";
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import WorkingHoursView from "../working.hours/working.hours.view";

const BusinessUnitsView = observer(() => {
    const {isNavOpen} = AppStore;
    const {
        businessUnits,
        businessUnitFilter,
        businessUnitsInit,
        setBusinessUnitFilter,
        loading,
        clearFilter,
        deleteBusinessUnit
    } = BusinessUnitsStore;

    const {destroyAccounts} = accountStore;
    const {businessSettings} = AppStore;

    const {getTableHeaders, dictionaryListReq, destroyDictionary, getPageTitle} = DictionaryStore;

    const [modal, setModal] = useState(false);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<DeleteItem>({
        isModalOpen: false,
    });
    const [initialValue, setInitialValue] = useState<BusinessUnitModel | null>(
        null
    );
    const [accountModal, setAccountModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterModal, setFilterModal] = useState(false);
    const [workingHoursModal, setWorkingHoursModal] = useState(false);
    const [deleted, setDeleted] = useState(true);

    useEffect(() => {
        const controller = new AbortController();
        destroyDictionary();
        businessUnitsInit(controller.signal);
        void dictionaryListReq('BUSINESS UNITS', 'TABLE HEADERS', controller.signal)

        return () => controller.abort();
    }, [destroyDictionary, businessUnitsInit, dictionaryListReq]);

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === "page" && index) {
            setBusinessUnitFilter({page: index + 1});
            return;
        }
        if (objectKey === 'show_deleted') {
            setDeleted(!deleted)
        }
        setBusinessUnitFilter({[objectKey]: options[selectedIndex].value});
    };

    const cancelSearch = (e: any) => {
        if (e.target.value === "" && businessUnitFilter.search !== "") {
            setBusinessUnitFilter({search: ""});
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter") {
            setBusinessUnitFilter({search: e.target.value});
        }
    };

    const handleAccountClick = (businessUnit: BusinessUnitModel) => {
        setAccountModal(true);
        setInitialValue(businessUnit);
    };

    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector(".context-menu-focused");
        if (e.target.classList.contains("context-menu-focused")) {
            e.target.classList.remove("context-menu-focused");
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            e.target.classList.add("context-menu-focused");
        }
    };


    const prevPage = () => {
        if (businessUnitFilter.page > 1) {
            setBusinessUnitFilter({page: businessUnitFilter.page - 1});
        }
    };

    const nextPage = () => {
        if (businessUnitFilter.page < businessUnitFilter.pages) {
            setBusinessUnitFilter({page: businessUnitFilter.page + 1});
        }
    };

    const handleCloseAccountModal = () => {
        destroyAccounts();
        setAccountModal(false);
        dictionaryListReq('BUSINESS UNITS', 'TABLE HEADERS')
    }

    const handleClearFilter = () => {
        clearFilter();
        setFilterModal(false);
    }

    const handleConfirmFilter = (e: any) => {
        setFilterModal(false);
    }

    const handleEditClick = (businessUnit: BusinessUnitModel) => {
        setInitialValue(businessUnit);
        setModal(true);
    };

    const handleDelete = async () => {
        await deleteBusinessUnit(confirmDeleteModal.id!);
        setConfirmDeleteModal({isModalOpen: false});
    };

    const handleWorkingHoursClick = (businessUnit: BusinessUnitModel) => {
        setInitialValue(businessUnit);
        setWorkingHoursModal(true);
    }

    const handleCloseWorkingHours = () => {
        setInitialValue(null);
        setWorkingHoursModal(false);
    }

    const handleDeleted = () => {
        return businessUnitFilter.show_deleted
    }

    const pageLimits = [1, 5, 10, 25, 100, 500];

    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
            <>
                <Headline color={primaryColor}>{getPageTitle('BUSINESS UNITS')}</Headline>
                <TableHeader>
                    <Filters wrapped between={10}>
                        <SearchLayout>
                            <Search
                                type="search"
                                id="search"
                                placeholder="Поиск по названию"
                                onKeyDown={(e) => handleKeyDown(e)}
                                onChange={(e) => cancelSearch(e)}
                            ></Search>
                            <Image src={SearchIcon} height="16px"/>
                        </SearchLayout>
                        <Select
                            id="status-filter"
                            onChange={(e) => handleFilterChange(e, 'show_deleted')}
                        >
                            {/*TODO change for build*/}
                            <option value="false">Активный</option>
                            <option value="true">Удаленный</option>
                        </Select>
                    </Filters>
                    <FilterButton
                        id={'business-unit-filter-button'}
                        onClick={() => setFilterModal(true)}
                    >
                        Фильтры
                    </FilterButton>
                    <AddButton type="button" id="add-role" onClick={() => setModal(true)}>
                        Создать
                    </AddButton>
                </TableHeader>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TRow tableHead>
                                <THeadCell>{getTableHeaders('name')}</THeadCell>
                                <THeadCell>{getTableHeaders('description')}</THeadCell>
                                <THeadCell>{getTableHeaders('address')}</THeadCell>
                                <THeadCell>{getTableHeaders('phone')}</THeadCell>
                                <THeadCell>{getTableHeaders('cash_balance')}</THeadCell>
                                <THeadCell>{getTableHeaders('employee_count')}</THeadCell>
                                <THeadCell>{getTableHeaders('active')}</THeadCell>
                                <THeadCell></THeadCell>
                            </TRow>
                        </TableHead>
                        {!loading ? (
                            <TableBody>
                                {businessUnits!.length ? (
                                    businessUnits?.map((businessUnit, index) => (
                                        <TRow key={index}>
                                            <TCell>{businessUnit.name}</TCell>
                                            <TCell>{businessUnit.description}</TCell>
                                            <TCell>{businessUnit.address}</TCell>
                                            <TCell>{businessUnit.phone}</TCell>
                                            <TCell>{businessUnit.cash_balance}</TCell>
                                            <TCell>{businessUnit.employee_count}</TCell>
                                            <TCell>
                                                <Badge online={deleted}>
                                                    {deleted ? "Активный" : "Неактивный"}
                                                </Badge>
                                            </TCell>
                                            <TCell relative>
                                                <Image
                                                    src={ContextMenuIcon}
                                                    height="18px"
                                                    onClick={handleContext}
                                                />
                                                <ContextMenu anchorEl={anchorEl}>
                                                    {businessSettings?.working_hours && (
                                                        <ContextMenuItem
                                                            onClick={() => handleWorkingHoursClick(businessUnit)}>
                                                            Режим работы
                                                        </ContextMenuItem>
                                                    )}
                                                    <ContextMenuItem onClick={() => handleEditClick(businessUnit)}>
                                                        Изменить
                                                    </ContextMenuItem>
                                                    <ContextMenuItem
                                                        onClick={() => setConfirmDeleteModal({
                                                            isModalOpen: true,
                                                            id: businessUnit.id,
                                                            name: businessUnit.name,
                                                        })
                                                        }
                                                    >
                                                        Удалить
                                                    </ContextMenuItem>
                                                    <ContextMenuItem onClick={() => handleAccountClick(businessUnit)}>
                                                        Счета
                                                    </ContextMenuItem>
                                                </ContextMenu>
                                            </TCell>
                                        </TRow>
                                    ))
                                ) : (
                                    <NoResultTitle colspan={8}/>
                                )}
                            </TableBody>
                        ) : (
                            <TableSkeleton columns={8}/>
                        )}
                    </Table>
                </TableContainer>
                <TableContainerMobile>
                    <BusinessUnitsTableMobileView
                        loading={loading}
                        businessUnits={businessUnits}
                        handleAccountClick={handleAccountClick}
                        handleEditClick={handleEditClick}
                        setConfirmDeleteModal={setConfirmDeleteModal}
                        getTableHeaders={getTableHeaders}
                    />
                </TableContainerMobile>
                <TablePaginationView
                    prevPage={prevPage}
                    nextPage={nextPage}
                    pages={businessUnitFilter.pages}
                    currentPage={businessUnitFilter.page}
                    pageLimits={pageLimits}
                    handleFilterChange={handleFilterChange}
                />
            </>
            {filterModal && (
                <Modal
                    title={"Фильтры"}
                    onClose={() => setFilterModal(false)}
                >
                    <Layout vertical between={10}>
                        <SearchLayout>
                            <Search
                                type="search"
                                id="search"
                                placeholder="Поиск по названию"
                                onKeyDown={(e) => handleKeyDown(e)}
                                onChange={(e) => cancelSearch(e)}
                            ></Search>
                            <Image src={SearchIcon} height="16px"/>
                        </SearchLayout>
                        <Layout hAlign={"space-between"} between={10}>
                            <Button
                                extent={"L"}
                                id={"clear-filter-button"}
                                onClick={handleClearFilter}
                                background={"#333"}
                                color={"red"}
                            > Очистить </Button>
                            <Button
                                extent={"L"}
                                id={"confirm-filter-button"}
                                onClick={(e) => handleConfirmFilter(e)}
                            > Потвердить </Button>
                        </Layout>
                    </Layout>
                </Modal>
            )}
            {accountModal && (
                <AccountModal
                    title={
                        "Счета"
                    }
                    onClose={handleCloseAccountModal}
                    hasContext={true}
                >
                    <AccountView
                        entityType={"bu"}
                        entityId={`${initialValue?.id}`}
                    />
                </AccountModal>
            )}
            {modal && (
                <Modal
                    title={
                        initialValue ? "Изменить подразделение" : "Создать подразделение"
                    }
                    onClose={() => {
                        setModal(false);
                        setInitialValue(null);
                    }}
                >
                    <BusinessUnitsCreateEditView
                        initialValue={initialValue}
                        setModal={setModal}
                    />
                </Modal>
            )}
            {confirmDeleteModal.isModalOpen && (
                <Modal
                    title="Удалить подразделение"
                    onClose={() => {
                        setConfirmDeleteModal({isModalOpen: false});
                    }}
                >
                    <ConfirmDelete
                        warningText={`Вы уверены, что хотите удалить подразделения ${confirmDeleteModal.name}?`}
                        setModal={setConfirmDeleteModal}
                        callback={handleDelete}
                        isLoading={loading}
                    />
                </Modal>
            )}
            {workingHoursModal && (
                <Modal
                    title={`Режим работы ${initialValue?.name}`}
                    onClose={handleCloseWorkingHours}
                >
                    <WorkingHoursView
                        businessUnitID={initialValue?.id!}
                        setInitialValue={setInitialValue}
                        setModal={setWorkingHoursModal}
                    />
                </Modal>
            )}
        </Page>
    );
});

export default BusinessUnitsView;
