import { Layout } from '../../styles/layout';
import { Text } from '../../styles/text';
import { modalTextColor } from '../../utils/variables';

export const renderText = (form: any, inputs: any[], onChange: any) =>
  inputs.map((input, index) => {
    const {
      placeholder,
      type,
      name,
      surname,
      patronymic,
      phone,
      email,
      login,
      role,
      business_unit,
    } = input;
    switch (type) {
      case 'text':
        return (
          <Layout key={index} between={2} vAlign="center">
            <Layout widthSize="35%" style={{ fontWeight: '600' }}>
              <Text extent="S" color={modalTextColor}>
                {placeholder}
              </Text>
            </Layout>
            <Layout widthSize="65%">
              <Text extent="S" color={modalTextColor}>
                {name}
              </Text>
              <Text extent="S" color={modalTextColor}>
                {surname}
              </Text>
              <Text extent="S" color={modalTextColor}>
                {login}
              </Text>
              <Text extent="S" color={modalTextColor}>
                {patronymic}
              </Text>
              <Text extent="S" color={modalTextColor}>
                {phone}
              </Text>
              <Text extent="S" color={modalTextColor}>
                {email}
              </Text>
              <Text extent="S" color={modalTextColor}>
                {role}
              </Text>
              <Text extent="S" color={modalTextColor}>
                {business_unit}
              </Text>
            </Layout>
          </Layout>
        );

      default:
        return null;
    }
  });
