import {SerialNumbersModel, SerialNumbersFilterModel} from "./product_serial_numbers.model";
import axios, {GenericAbortSignal} from "axios";
import {makeAutoObservable} from "mobx";
import AppStore from "../../app.store";
import {api} from "../../api/endpoints";
import {toast} from "react-toastify";

const serialNumbersFilterInitialState = {
    num: '',
    business_unit_id: 0,
    sold: false,
    page: 1,
    page_limit: 25,
    pages: 0,
    order_by: 'created_at',
    order_descending: true,
    product_id: 0,
}

const serialNumbersInitialState: SerialNumbersModel[] = [];
const loadingInitialState: boolean = false;

class SerialNumbersStore {
    public serialNumbers ?: SerialNumbersModel[] = serialNumbersInitialState;
    public serialNumbersFilter = serialNumbersFilterInitialState;
    public loading: boolean = loadingInitialState;
    public message = "";
    public error = "";

    public serialNumbersPageInit = async (signal: GenericAbortSignal, product_id: number, business_unit_id: number) => {
        this.serialNumbersFilter.product_id = product_id
        this.serialNumbersFilter.business_unit_id = business_unit_id

        void this.serialNumbersReq(signal);
    }

    constructor() {
        makeAutoObservable(this);
    }

    setSerialNumbers(data: any) {
        const {product_serial} = data;

        this.serialNumbers = product_serial;
    }

    private setSerialNumbersFilterPages = (pages: number) => {
        this.serialNumbersFilter.pages = pages;
    }

    private setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    public serialNumbersReq = async (signal?: GenericAbortSignal) => {
        this.setLoading(true);
        try {
            const {getToken} = AppStore

            const headers = {
                Authorization: 'Bearer ' + getToken()!,
            }


            const filterUrl = Object.keys(this.serialNumbersFilter).reduce((filters, filter) => {
                const symbol = filters ? '&' : '?';
                const filterKeyName = filter as keyof SerialNumbersFilterModel;
                if (this.serialNumbersFilter[filterKeyName] !== 0 &&
                    this.serialNumbersFilter[filterKeyName] !== '') {
                    filters += symbol + filter + '=' + this.serialNumbersFilter[filterKeyName];
                }

                return filters;
            }, '');

            const response = await axios.get(api.product_serial_numbest + filterUrl, {headers, signal});
            this.setSerialNumbers(response.data)
            this.setSerialNumbersFilterPages(response.data.pages)

            return response.data.product_serial;
        } catch (err) {
            return [];
        } finally {
            this.setLoading(false);
        }
    }

    public setSerialNumbersFilter = ({num, business_unit_id, product_id, sold, page, page_limit}: SerialNumbersFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.serialNumbersFilter = {
            ...this.serialNumbersFilter,
            num: isUndefined(num) ? this.serialNumbersFilter.num! : num!,
            business_unit_id: isUndefined(business_unit_id) ? this.serialNumbersFilter.business_unit_id! : business_unit_id!,
            product_id: isUndefined(business_unit_id) ? this.serialNumbersFilter.product_id! : product_id!,
            sold: isUndefined(sold) ? this.serialNumbersFilter.sold! : sold!,
            page: isUndefined(page) ? this.serialNumbersFilter.page! : page!,
            page_limit: isUndefined(page_limit) ? this.serialNumbersFilter.page_limit! : page_limit!
        };

        if (page_limit) {
            this.serialNumbersFilter.page = 1;
        }

        void this.serialNumbersReq();
    }

    public clearFilter = () => {
        this.serialNumbersFilter = serialNumbersFilterInitialState;
        void this.serialNumbersReq();
    }

    public serialNumbersCreateReq = async (inputs: any) => {
        const payload = {
            product_id: this.serialNumbersFilter.product_id,
            business_unit_id: this.serialNumbersFilter.business_unit_id,
            numbers: inputs
        }

        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }
            let res;
            res = await axios.post(api.product_serial_numbest, payload, {headers});

            // if (res?.code >= 400) {
            //     throw new Error(res)
            // }
            this.message = res?.data.message;
            toast.success(this.message);

            return res;
        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.loading = false;
        }
    }
}

export default new SerialNumbersStore();