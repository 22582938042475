import {observer} from "mobx-react";
import AppStore from "../../../app.store";
import {Page} from "../../../styles/page";
import {Headline} from "../../../styles/headline";
import {primaryColor} from "../../../utils/variables";
import PayrollsStore from "./payrolls.store";
import {useEffect, useState} from "react";
import {PayrollsFilterModel, PayrollsModel} from "./payrolls.model";
import {AddButton, TableHeader} from "../../../styles/table-header";
import {Filters} from "../../../styles/filters";
import PayrollsFilterView from "./payrolls.filter.view";
import {Button, FilterButton} from "../../../styles/button";
import {TableContainer, TableContainerMobile} from "../../../styles/table";
import TablePaginationView from "../../../utils/table.pagination.view";
import PayrollsDesktopView from "./payrolls.desktop.view";
import PayrollsItemsView from "./payrolls.items/payrolls.items.view";
import PayrollsItemsStore from "./payrolls.items/payrolls.items.store";
import ModalView from "../../../components/modal/modal.view";
import PayrollsCreateEditView from "./payrolls.modal/payrolls.create.edit.view";
import {Layout} from "../../../styles/layout";
import PayrollsCreateEditStore from "./payrolls.modal/payrolls.create.edit.store";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";
import PayrollsMobileView from "./payrolls.mobile.view";

const PayrollsView = observer(() => {
    const {isNavOpen} = AppStore;
    const {
        payrolls,
        payrollsFilters,
        isLoading,
        setPayrollsFilter,
        init,
        clearFilter
    } = PayrollsStore;
    const {itemsDestroy} = PayrollsItemsStore;
    const {destroy} = PayrollsCreateEditStore

    const [filterModal, setFilterModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<PayrollsModel | null>(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [itemsModal, setItemsModal] = useState(false);
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        dictionaryListReq('PAYROLL PAYMENTS', 'TABLE HEADERS', controller.signal);
        init(controller.signal);

        return () => {
            controller.abort();
        }
    }, [destroyDictionary, dictionaryListReq, init]);

    const handleClearFilter = () => {
        clearFilter();
        setFilterModal(false);
    };

    const handleConfirmFilter = (e: any) => {
        setFilterModal(false);
    };

    const handleCloseCreateEditModal = () => {
        setModal(false);
        destroy();
    }

    const setDates = (e: any) => {
        if (e !== null) {
            setPayrollsFilter({date_to: e[1].format('YYYY-MM-DD')} as PayrollsFilterModel);
            setPayrollsFilter({date_from: e[0].format('YYYY-MM-DD')} as PayrollsFilterModel);
        } else {
            setPayrollsFilter({date_from: ''} as PayrollsFilterModel);
            setPayrollsFilter({date_to: ''} as PayrollsFilterModel);
        }
    };

    const prevPage = () => {
        if (payrollsFilters.page > 1) {
            setPayrollsFilter({page: payrollsFilters.page - 1} as PayrollsFilterModel);
        }
    };

    const nextPage = () => {
        if (payrollsFilters.page < payrollsFilters.pages) {
            setPayrollsFilter({page: payrollsFilters.page + 1} as PayrollsFilterModel);
        }
    };

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === 'page' && index !== undefined) {
            setPayrollsFilter({page: index + 1} as PayrollsFilterModel);
            return;
        }
        setPayrollsFilter({[objectKey]: options[selectedIndex].value} as PayrollsFilterModel);
    };

    const cancelSearch = (e: any) => {
        if (e.target.value === '' && payrollsFilters.search !== '') {
            setPayrollsFilter({search: ''} as PayrollsFilterModel);
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            setPayrollsFilter({search: e.target.value} as PayrollsFilterModel);
        }
    };

    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector('.context-menu-focused');
        if (e.target.classList.contains('context-menu-focused')) {
            e.target.classList.remove('context-menu-focused');
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            e.target.classList.add('context-menu-focused');
        }
    };

    const handleItemsClick = (payroll: PayrollsModel) => {
        setInitialValue(payroll);
        setItemsModal(true);
    }

    const handleCloseItemsModal = () => {
        setItemsModal(false);
        setInitialValue(null);
        itemsDestroy();
    }

    const pageLimits = [25, 50, 100, 250, 500, 1000]

    return (
        <Page isNavOpen={isNavOpen} vertical between={20}>
            <Headline color={primaryColor}>{getPageTitle('PAYROLLS')}</Headline>
            <TableHeader>
                <Filters hAlign={"space-between"} wrapped between={10}>
                    <PayrollsFilterView
                        setDates={setDates}
                        handleKeyDown={handleKeyDown}
                        cancelSearch={cancelSearch}
                        handleFilterChange={handleFilterChange}
                        inModal={false}
                    />
                </Filters>
                <FilterButton
                    id={'payroll-filters'}
                    onClick={() => setFilterModal(true)}
                >
                    Фильтры
                </FilterButton>
                <AddButton type="button" id="add-payroll" onClick={() => setModal(true)}>
                    Создать
                </AddButton>
            </TableHeader>
            <TableContainer>
                <PayrollsDesktopView
                    isLoading={isLoading}
                    payrolls={payrolls}
                    handleContext={handleContext}
                    anchorEl={anchorEl}
                    handleItemsClick={handleItemsClick}
                    tableHeaders={getTableHeaders}
                />
            </TableContainer>
            <TableContainerMobile>
                <PayrollsMobileView
                    isLoading={isLoading}
                    payrolls={payrolls}
                    handleItemsClick={handleItemsClick}
                    tableHeaders={getTableHeaders}
                />
            </TableContainerMobile>
            <TablePaginationView
                prevPage={prevPage}
                nextPage={nextPage}
                pages={payrollsFilters.pages}
                currentPage={payrollsFilters.page}
                pageLimits={pageLimits}
                handleFilterChange={handleFilterChange}
            />
            {itemsModal && (
                <ModalView
                    title={'Подробнее'}
                    onClose={handleCloseItemsModal}
                >
                    <PayrollsItemsView payrollID={initialValue?.id!}/>
                </ModalView>
            )}
            {modal && (
                <ModalView
                    title={initialValue ? 'Изменить' : 'Создать'}
                    onClose={handleCloseCreateEditModal}
                >
                    <PayrollsCreateEditView setModal={setModal} initialValue={initialValue}/>
                </ModalView>
            )}
            {filterModal && (
                <ModalView
                    title={'Фильтры'}
                    onClose={() => setFilterModal(false)}
                >
                    <Layout vertical between={10}>
                        <PayrollsFilterView
                            setDates={setDates}
                            handleKeyDown={handleKeyDown}
                            cancelSearch={cancelSearch}
                            handleFilterChange={handleFilterChange}
                            inModal={false}
                        />
                        <Layout hAlign={'space-between'} between={10}>
                            <Button
                                extent={'L'}
                                id={'clear-filter-button'}
                                onClick={handleClearFilter}
                                background={'#333'}
                                color={'red'}
                            >
                                Очистить
                            </Button>
                            <Button
                                extent={'L'}
                                id={'confirm-filter-button'}
                                onClick={(e) => handleConfirmFilter(e)}
                            >
                                Потвердить
                            </Button>
                        </Layout>
                    </Layout>
                </ModalView>
            )}
        </Page>
    );
});

export default PayrollsView;