import {observer} from "mobx-react";
import {
    EyeImgIcon,
    Table, TableBody,
    TableContainerMobile,
    TableHead,
    TCell,
    THeadCell,
    TRow
} from "../../styles/table";
import {useState} from "react";
import {Layout} from "../../styles/layout";
import {Text} from "../../styles/text";
import {Button} from "../../styles/button";
import EyeIcon from "../../assets/icons/eye.svg";
import {primaryColor} from "../../utils/variables";
import NoResultTitle from "../no_result_title/no_result_title.view";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import ModalView from "../modal/modal.view";
import {DictionaryModel} from "../../utils/dictionary/dictionary.model";

interface TableMobileViewProps {
    data: any[];
    headers: DictionaryModel[];
    hasContext?: boolean;
    loading: boolean;
    contextOptions?: any[];
}


const TableMobileView = observer(({data, headers, hasContext, loading, contextOptions}: TableMobileViewProps) => {

    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<any | null>(null);

    const handleRowClick = (datum: any) => {
        setModal(true);
        setInitialValue(datum);
    };

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    };

    const renderModalItems = (datum: any) => (
        <Layout vertical between={10}>
            {headers.map((header, index) => (
                <Layout hAlign={'space-between'} key={`${header.key}_${index}`}>

                    <Text weight={'bold'}>{header.value}</Text>
                    <Text>{datum[header.key]}</Text>
                </Layout>
            ))}
            {hasContext && (
                <Layout hAlign={'center'} between={5}>
                    {contextOptions?.map((context, i) => (
                        <Button onClick={() => context.onClick(datum)} key={i}>
                            {context.name}
                        </Button>
                    ))}
                </Layout>
            )}
        </Layout>
    );

    const filteredHeaders = headers.filter(header => header.show_to_mobile)

    return (
        <TableContainerMobile>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        {filteredHeaders.map((header, index) => (
                            <THeadCell key={index}>{header.value}</THeadCell>
                        ))}
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                {!loading ? (
                    <TableBody>
                        {data?.length ? (
                            data?.map((item, index) => (
                                <TRow key={index} menu>
                                    {filteredHeaders.map((header, i) => (
                                        <TCell key={i}>{item[header.key]}</TCell>
                                    ))}
                                    <TCell relative>
                                        <EyeImgIcon
                                            src={EyeIcon}
                                            onClick={() => handleRowClick(item)}
                                            height="18px"
                                            color={primaryColor}
                                        />
                                    </TCell>
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={filteredHeaders.length + 1}/>
                        )}
                    </TableBody>
                ) : (
                    <TableSkeleton columns={filteredHeaders.length + 1}/>
                )}
            </Table>
            {modal && (
                <ModalView
                    title={`Подробняя информация`}
                    onClose={handleCloseModal}
                >
                    {renderModalItems(initialValue!)}
                </ModalView>
            )}
        </TableContainerMobile>
    );
});

export default TableMobileView;