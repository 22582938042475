import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {Search, SearchLayout, Select} from "../../../styles/table-header";
import {Image} from "../../../styles/image";
import SearchIcon from "../../../assets/icons/search.svg";
import {BusinessUnitModel} from "../../business_units/business_units.model";
import {ExpenseCategoriesModel} from "../expense.categories/expense.categories.model";
import {UsersModel} from "../../users/users.model";

interface ExpensesFilterViewProps {
    handleKeyDown: (e: any) => void;
    cancelSearch: (e: any) => void;
    handleFilterChange: (e: any, key: string) => void;
    categories: ExpenseCategoriesModel[] | undefined;
    businessUnits: BusinessUnitModel[] | undefined;
    users: UsersModel[] | undefined;
    inModal: boolean;
}

const ExpensesFilterView = observer(({
                                         handleKeyDown,
                                         cancelSearch,
                                         handleFilterChange,
                                         categories,
                                         businessUnits,
                                         users,
                                         inModal
                                     }:ExpensesFilterViewProps) => {
    return (
        <>
            <Layout between={10} wrapped vAlign="center" vertical={inModal}>
                <SearchLayout>
                    <Search
                        type="search"
                        id="search"
                        placeholder="Поиск"
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={(e) => cancelSearch(e)}
                    ></Search>
                    <Image src={SearchIcon} height="16px"/>
                </SearchLayout>
                <Select
                    id="category-filter"
                    onChange={(e) => handleFilterChange(e, 'category_id')}
                >
                    <option value="">Все категории</option>
                    {categories?.map((category, _) => (
                        <option value={category.id} key={category.id}>
                            {category.name}
                        </option>
                    ))}
                </Select>
                <Select
                    id="business-unit-filter"
                    onChange={(e) => handleFilterChange(e, 'business_unit_id')}
                >
                    <option value="">Все подразделения</option>
                    {businessUnits?.map((businessUnit, _) => (
                        <option value={businessUnit.id} key={businessUnit.id}>
                            {businessUnit.name}
                        </option>
                    ))}
                </Select>
            </Layout>
        </>
    );
});

export default ExpensesFilterView;