import {observer} from "mobx-react";
import {ExpenseOperationsModel} from "./expense.operations.model";
import {ContextMenu, ContextMenuItem, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import TableSkeleton from "../../../utils/skeleton/table.skeleton";
import NoResultTitle from "../../../components/no_result_title/no_result_title.view";
import {Badge} from "../../../styles/badge";
import {Image} from "../../../styles/image";
import ContextMenuIcon from "../../../assets/icons/context-menu.svg";

interface ExpenseOperationsTableDesktopViewProps {
    loading: boolean;
    operations: ExpenseOperationsModel[] | undefined;
    handleConfirmOperation: (operationID: number) => void;
    handleContext: (e: any) => void;
    anchorEl: any;
    tableHeaders: (key: string) => string;
}

const ExpenseOperationsDesktopView = observer(({
                                                   loading,
                                                   operations,
                                                   handleConfirmOperation,
                                                   handleContext,
                                                   anchorEl,
                                                   tableHeaders
                                               }: ExpenseOperationsTableDesktopViewProps) => {
    return (
        <Table>
            <TableHead>
                <TRow tableHead>
                    <THeadCell>{tableHeaders('owner_operation_id')}</THeadCell>
                    <THeadCell>{tableHeaders('expense')}</THeadCell>
                    <THeadCell>{tableHeaders('business_unit')}</THeadCell>
                    <THeadCell>{tableHeaders('category')}</THeadCell>
                    <THeadCell>{tableHeaders('amount')}</THeadCell>
                    <THeadCell>{tableHeaders('operation_date')}</THeadCell>
                    <THeadCell>{tableHeaders('description')}</THeadCell>
                    <THeadCell>{tableHeaders('confirmed')}</THeadCell>
                    <THeadCell>{tableHeaders('created_at')}</THeadCell>
                    <THeadCell></THeadCell>
                </TRow>
            </TableHead>
            {loading ? (
                <TableSkeleton columns={10}/>
            ) : (
                <TableBody>
                    {operations?.length ? (
                        operations.map((operation, index) => (
                            <TRow key={index}>
                                <TCell>{operation.owner_operation_id}</TCell>
                                <TCell>{operation.expense}</TCell>
                                <TCell>{operation.business_unit}</TCell>
                                <TCell>{operation.category}</TCell>
                                <TCell>{operation.amount}</TCell>
                                <TCell>{operation.operation_date}</TCell>
                                <TCell>{operation.description}</TCell>
                                <TCell>
                                    <Badge online={operation.confirmed}>
                                        {operation.confirmed ? 'Да' : 'Нет'}
                                    </Badge>
                                </TCell>
                                <TCell>{operation.created_at}</TCell>
                                <TCell relative>
                                    <Image
                                        src={ContextMenuIcon}
                                        height="18px"
                                        onClick={handleContext}
                                    />
                                    <ContextMenu anchorEl={anchorEl}>
                                        {!operation.confirmed && (
                                            <ContextMenuItem onClick={() => handleConfirmOperation(operation.id)}>
                                                Потвердить
                                            </ContextMenuItem>
                                        )}
                                    </ContextMenu>
                                </TCell>
                            </TRow>
                        ))
                    ) : (
                        <NoResultTitle colspan={10}/>
                    )}
                </TableBody>
            )}
        </Table>
    );
});

export default ExpenseOperationsDesktopView;