import {ProductCategoryModel} from "../product_categories.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import axios from "axios";
import {api} from "../../../api/endpoints";
import {toast} from "react-toastify";

const initialValues = {
    id: 0,
    name: '',
    description: '',
    active: true,
    img: '',
    created_at: '',
    updated_at: '',
    formatted_date: ''
}

class ProductCategoriesCreateEditStore {
    public form: ProductCategoryModel = initialValues;
    public message: string = '';
    public error: string = '';
    public isLoading: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    public init = async (initialValue: ProductCategoryModel | null) => {
        if (initialValue) {
            this.form = initialValue
        } else {
            this.form = initialValues
        }
    }

    public createOrEditProductCategory = async (edit?: boolean) => {
        this.isLoading = true;

        const payload = {
            id: this.form.id,
            name: this.form.name,
            description: this.form.description,
            active: this.form.active,
            img: this.form.img,
        }

        try {

            const {getToken} = AppStore;

            const headers = {
                Authorization: 'Bearer ' + getToken()!,
            }

            let response;

            if (edit) {
                response = await axios.put(api.product_category, payload, {headers})
            } else {
                response = await axios.post(api.product_category, payload, {headers})
            }

            this.message = response.data.message;
            toast(this.message);

            return response;
        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast(this.error);
        } finally {
            this.isLoading = false;
        }
    }

    setField = (form: ProductCategoryModel) => {
        this.form = form
    }

    public destroy = () => {
        this.form = initialValues;
    }
}

export default new ProductCategoriesCreateEditStore();