import {ExpensesFilterModel, ExpensesModel} from "./expenses.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import axios from "axios";
import {api} from "../../../api/endpoints";
import {toast} from "react-toastify";

const expensesInitialState: ExpensesModel[] = [];
const loadingInitialState: boolean = false;
const filtersInitialState: ExpensesFilterModel = {
    search: '',
    category_id: 0,
    created_by: 0,
    business_unit_id: 0,
    type: '',
    order_by: '',
    order_descending: '',
    pages: 0,
    page: 1,
    page_limit: 25,
}

class ExpensesStore {
    public expenses? = expensesInitialState;
    public isLoading = loadingInitialState;
    public expensesFilters = filtersInitialState;
    public deleteLoading = loadingInitialState;
    message: string = '';
    error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public expensesPageInit = async (signal: AbortSignal) => {
        await this.expensesReq(signal);
    }

    setExpenses(data: any) {
        const {expenses} = data;
        this.expenses = expenses;
    }

    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    setExpensesPages(pages: number) {
        this.expensesFilters.pages = pages;
    }

    public expensesReq = async (signal?: AbortSignal) => {
        this.setLoading(true);

        const {getToken} = AppStore;

        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        const filterUrl = Object.keys(this.expensesFilters).reduce((filters, filter) => {
            const symbol = filters ? '&' : '?';
            const filterKeyName = filter as keyof ExpensesFilterModel;
            if (this.expensesFilters[filterKeyName] !== 0 &&
                this.expensesFilters[filterKeyName] !== '') {
                filters += symbol + filter + '=' + this.expensesFilters[filterKeyName];
            }

            return filters;
        }, '');

        try {
            const response = await axios.get(api.expenses + filterUrl, {headers, signal});
            this.setExpenses(response.data);
            this.setExpensesPages(response.data.pages);
        } catch (e: any) {

        } finally {
            this.setLoading(false);
        }
    }

    public deleteExpenseReq = async (id: number) => {
        this.deleteLoading = true;
        const {getToken} = AppStore
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        try {
            const response = await axios.delete(api.expenses + `/${id}`, {headers})
            this.message = response.data.message;
            toast.success(this.message);

            if (response) {
                void this.expensesReq();
            }
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.deleteLoading = false;
        }
    }

    public setExpensesFilter = ({search, category_id, created_by, business_unit_id, type, page, page_limit}: ExpensesFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.expensesFilters = {
            ...this.expensesFilters,
            search: isUndefined(search) ? this.expensesFilters.search! : search!,
            category_id: isUndefined(category_id) ? this.expensesFilters.category_id! : category_id!,
            created_by: isUndefined(created_by) ? this.expensesFilters.created_by! : created_by!,
            business_unit_id: isUndefined(business_unit_id) ? this.expensesFilters.business_unit_id! : business_unit_id!,
            type: isUndefined(type) ? this.expensesFilters.type! : type!,
            page: isUndefined(page) ? this.expensesFilters.page! : page!,
            page_limit: isUndefined(page_limit) ? this.expensesFilters.page_limit! : page_limit!
        };

        if (page_limit) {
            this.expensesFilters.page = 1;
        }

        void this.expensesReq();
    }

    public clearFilter = () => {
        this.expensesFilters = filtersInitialState;
    }
}

export default new ExpensesStore();