import AccountsCreateEditStore from "../pages/accounts/account.modal/accounts.create.edit.store";
import Business_serviceOperationStore
    from "../pages/business_services/business_service.modal/business_service.operation.store";
import BusinessUnitCreateEditStore
    from "../pages/business_units/business_units.modal/business_units.create.edit.store";
import ClientsCreateEditStore from "../pages/clients/clients.modal/clients.create.edit.store";
import LoginStore from "../pages/login/login.store";
import ProductsCreateEditStore from "../pages/products/products.modal/products.create.edit.store";
import ProductsAllSellStore
    from "../pages/products_transactions/products_all_sell.modal(deprecated)/products_all_sell.modal.store";
import ProductsTransactionsModalStore
    from "../pages/products_transactions/products_transactions.modal(deprecated)/products_transactions.modal.store";
import RoleCreateEditStore from "../pages/roles/roles.modal/roles.create.edit.store";
import StockModalStore from "../pages/stock/stock.modal/stock.modal.store";
import UsersCreateEditStore from "../pages/users/users.modal/users.create.edit.store";

class DestroyAllStore {
    account = AccountsCreateEditStore;
    businessService = Business_serviceOperationStore;
    businessUnit = BusinessUnitCreateEditStore;
    client = ClientsCreateEditStore;
    login = LoginStore;
    product = ProductsCreateEditStore;
    massProductSell = ProductsAllSellStore;
    transProd = ProductsTransactionsModalStore;
    role = RoleCreateEditStore;
    stock = StockModalStore;
    user = UsersCreateEditStore;

    public destroyAll = () => {
        this.account.destroy();
        this.businessService.destroy();
        this.businessUnit.destroy();
        this.client.destroy();
        this.login.destroy();
        this.product.destroy();
        this.massProductSell.destroy();
        this.transProd.destroy();
        this.role.destroy();
        this.stock.resetFormFields();
        this.user.destroy();
    }
}

export default new  DestroyAllStore();