import { Required, TextInput } from '../../styles/form';
import { Layout } from '../../styles/layout';
import { Text } from '../../styles/text';
import { modalTextColor } from '../../utils/variables';
import { PasswordLayout } from '../../pages/login/login.style';
import { useState } from 'react';
import { Image } from '../../styles/image';
import showPasswordIcon from '../../assets/icons/show-password.svg';
import hidePasswordIcon from '../../assets/icons/hide-password.svg';

export const renderPassword = (form: any, inputs: any[], onChange: any) =>
  inputs.map((input, index) => {
    const { field, fieldName, placeholder, required, type } = input;
    const [showPassword, setShowPassword] = useState(false);

    switch (type) {
      case 'password':
        return (
          <Layout key={index} between={2} vAlign="center">
            <Layout widthSize="35%">
              <Text extent="S" color={modalTextColor}>
                {placeholder}
              </Text>
              {required && <Required>*</Required>}
            </Layout>

            <PasswordLayout>
              <TextInput
                name="oldpassword"
                type={showPassword ? 'text' : 'password'}
                value={type.old_password}
                onChange={(e) =>
                  onChange({
                    ...form,
                    [fieldName]: e.target.value,
                  })
                }
              />
              <Image
                src={showPassword ? showPasswordIcon : hidePasswordIcon}
                height="16px"
                onClick={() => setShowPassword(!showPassword)}
                style={{ backgroundColor: '#000', borderRadius: '10px' }}
              />
            </PasswordLayout>
          </Layout>
        );

      default:
        return null;
    }
  });
