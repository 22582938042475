import {BusinessServiceFilterModel, BusinessServiceModel} from "./business_service.model";
import AppStore from "../../app.store";
import {makeAutoObservable} from "mobx";
import axios, { GenericAbortSignal } from "axios";
import {api} from "../../api/endpoints";

class BusinessServicesStore {
    public businessServices?: BusinessServiceModel[] = [];
    public businessServicesFilter = {
        search: '',
        business_unit_id: 0,
        category_id: 0,
        brand_id: 0,
        outflow: '',
        page: 1,
        page_limit: 25,
        pages: 0,
        order_by: 'name',
        order_descending: false,
    };

    public loading = false;
    public error = "";
    public message = "";

    public businessServicesPageInit = async (signal: GenericAbortSignal) => {
        void this.businessServicesReq(signal);
    }

    constructor() {
        makeAutoObservable(this);
    }

    setBusinessServices(data: any) {
        const {services} = data;

        this.businessServices = services;
    }

    public businessServicesReq = async (signal?: GenericAbortSignal) => {
        this.setLoading(true);

        const {userData} = AppStore;
        this.businessServicesFilter.business_unit_id = userData?.business_unit_id!

        try {
            const {getToken} = AppStore

            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }

            const filterUrl = Object.keys(this.businessServicesFilter).reduce((filters, filter) => {
                const symbol = filters ? '&' : '?';
                const filterKeyName = filter as keyof BusinessServiceFilterModel;
                if (this.businessServicesFilter[filterKeyName] !== 0 &&
                    this.businessServicesFilter[filterKeyName] !== '') {
                    filters += symbol + filter + '=' + this.businessServicesFilter[filterKeyName];
                }

                return filters;
            }, '');

            const response: any = await axios.get(api.business_service + filterUrl, { headers, signal });
            
            this.setLoading(false);
            this.setBusinessServices(response.data)
            this.setBusinessServicesFilterPages(response.data.pages);
        } catch (err) {
        } finally {
            this.setLoading(false);

        }
    }

    public setBusinessServicesFilter = ({search, category_id, brand_id, outflow, page, page_limit}: BusinessServiceFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.businessServicesFilter = {
            ...this.businessServicesFilter,
            search: isUndefined(search) ? this.businessServicesFilter.search : search!,
            category_id: isUndefined(category_id) ? this.businessServicesFilter.category_id : category_id!,
            brand_id: isUndefined(brand_id) ? this.businessServicesFilter.brand_id : brand_id!,
            outflow: isUndefined(outflow) ? this.businessServicesFilter.outflow : outflow!,
            page: isUndefined(page) ? this.businessServicesFilter.page : page!,
            page_limit: isUndefined(page_limit) ? this.businessServicesFilter.page_limit : page_limit!,
        }

        if (page_limit) {
            this.businessServicesFilter.page = 1;
        }
        void this.businessServicesReq();
    }

    private setBusinessServicesFilterPage = (page: number) => {
        this.businessServicesFilter.page = page;
        void this.businessServicesReq();
    }

    private setBusinessServicesFilterPages(pages: number) {
        this.businessServicesFilter.pages = pages;
    }

    private setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    public clearFilter = () => {
        this.businessServicesFilter = {
            search: '',
            business_unit_id: 0,
            category_id: 0,
            brand_id: 0,
            outflow: '',
            page: 1,
            page_limit: 25,
            pages: 0,
            order_by: 'name',
            order_descending: false,
        };
    }
}

export default new BusinessServicesStore();