import {observer} from "mobx-react";
import {ContextMenu, ContextMenuItem, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import TableSkeleton from "../../../utils/skeleton/table.skeleton";
import {Image} from "../../../styles/image";
import ContextMenuIcon from "../../../assets/icons/context-menu.svg";
import NoResultTitle from "../../../components/no_result_title/no_result_title.view";
import {ExpenseCategoriesModel} from "./expense.categories.model";

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface ExpenseCategoriesDesktopViewProps {
    isLoading: boolean;
    categories: ExpenseCategoriesModel[] | undefined;
    handleEditClick: (expenseCategory: ExpenseCategoriesModel) => void;
    handleContext: (e: any) => void;
    setConfirmDeleteModal: ({isModalOpen, id, name}: setConfirmDeleteModalProps) => void;
    anchorEl: any
    tableHeaders: (key: string) => string;
}

const ExpenseCategoriesDesktopView = observer(({
                                                   isLoading,
                                                   categories,
                                                   handleEditClick,
                                                   handleContext,
                                                   anchorEl,
                                                   setConfirmDeleteModal,
                                                   tableHeaders
                                               }: ExpenseCategoriesDesktopViewProps) => {
    return (
        <Table>
            <TableHead>
                <TRow tableHead>
                    <THeadCell>{tableHeaders('name')}</THeadCell>
                    <THeadCell>{tableHeaders('description')}</THeadCell>
                    <THeadCell></THeadCell>
                </TRow>
            </TableHead>
            {isLoading ? (
                <TableSkeleton columns={3}/>
            ) : (
                <TableBody>
                    {categories?.length ? (
                        categories.map((category, index) => (
                            <TRow key={index}>
                                <TCell>{category.name}</TCell>
                                <TCell>{category.description}</TCell>
                                <TCell relative>
                                    <Image
                                        src={ContextMenuIcon}
                                        height="18px"
                                        onClick={handleContext}
                                    />
                                    <ContextMenu anchorEl={anchorEl}>
                                        <ContextMenuItem onClick={() => handleEditClick(category)}>
                                            Изменить
                                        </ContextMenuItem>
                                        <ContextMenuItem
                                            onClick={() =>
                                                setConfirmDeleteModal({
                                                    isModalOpen: true,
                                                    id: category.id,
                                                    name: category.name,
                                                })
                                            }
                                        >
                                            Удалить
                                        </ContextMenuItem>
                                    </ContextMenu>
                                </TCell>
                            </TRow>
                        ))
                    ) : (
                        <NoResultTitle colspan={3}/>
                    )}
                </TableBody>
            )}
        </Table>
    );
});

export default ExpenseCategoriesDesktopView;