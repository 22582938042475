import {makeAutoObservable} from "mobx";
import {TransactionsModel} from "./transactions.model";
import {StatusModel} from "../statuses/statuses.model";
import StatusesStore from "../statuses/statuses.store";
import AppStore from "../../app.store";
import axios from "axios";
import {api} from "../../api/endpoints";
import {toast} from "react-toastify";
import appStore from "../../app.store";

interface Form {
    tran_id: number;
    status_id: number;
    message: string;
}

const formInitialState: Form = {
    tran_id: 0,
    status_id: 0,
    message: '',
}

const statusesInitialState: StatusModel[] = [];

const loadingInitialState: boolean = false;

class UpdateStatusStore {
    public form: Form = formInitialState;
    public loading = loadingInitialState;
    public statuses = statusesInitialState;
    public message: string = '';
    public error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public destroyStatusUpdate = () => {
        this.form = formInitialState;
        this.loading = loadingInitialState;
        this.statuses = statusesInitialState;
    }

    public setField = (form: Form) => {
        this.form = form;
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    setStatuses(data: any) {
        const {statuses} = data;
        this.statuses = statuses;
    }

    public init = (initialValue: TransactionsModel) => {
     //   const appStore = AppStore;
        void this.statusesReq(initialValue.status_id)
        this.form.tran_id = initialValue.id;
      //  this.form.status_id = appStore.userData?.default_status_id!
    }

    public changeStatusReq = async (signal?: AbortSignal) => {
        this.setLoading(true);
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        const payload = {
            tran_id: Number(this.form.tran_id),
            status_id: Number(this.form.status_id),
            message: this.form.message
        }

        try {
            const response = await axios.put(api.transactions + '/status', payload, {headers, signal});
            this.message = response.data.message;
            toast.success(this.message);
            return response;
        } catch (e: any) {
            this.error = e?.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }

    private statusesReq = async (statusID: number, signal?: AbortSignal) => {

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        try {
            const response = await axios.get(api.statuses + `/${statusID}`, {headers, signal});
            this.setStatuses(response.data);
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }

    public get statusesOptions() {
        if (!this.statuses) return;
        return this.statuses.map((status: StatusModel) => ({
            label: status.description,
            value: status.id
        }));
    }
}

export default new UpdateStatusStore();