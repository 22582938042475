import {observer} from "mobx-react";
import {useState} from "react";
import {Layout} from "../../../styles/layout";
import {Text} from "../../../styles/text";
import {Button} from "../../../styles/button";
import {EyeImgIcon, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import EyeIcon from "../../../assets/icons/eye.svg";
import {primaryColor} from "../../../utils/variables";
import NoResultTitle from "../../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../../utils/skeleton/table.skeleton";
import ModalView from "../../../components/modal/modal.view";
import {ExpenseCategoriesModel} from "./expense.categories.model";

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface ExpenseCategoriesMobileViewProps {
    isLoading: boolean;
    categories: ExpenseCategoriesModel[] | undefined;
    handleEditClick: (expenseCategory: ExpenseCategoriesModel) => void;
    setConfirmDeleteModal: ({isModalOpen, id, name}: setConfirmDeleteModalProps) => void;
    tableHeaders: (key: string) => string;
}

const ExpenseCategoriesMobileView = observer(({
                                                  isLoading,
                                                  categories,
                                                  handleEditClick,
                                                  setConfirmDeleteModal,
                                                  tableHeaders
                                              }: ExpenseCategoriesMobileViewProps) => {
    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<ExpenseCategoriesModel | null>(null);

    const handleRowClick = (category: ExpenseCategoriesModel) => {
        setModal(true);
        setInitialValue(category);
    };

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    };

    const renderModalItems = (category: ExpenseCategoriesModel) => (
        <Layout vertical between={10}>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('name')}</Text>
                <Text>{category.name}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('description')}</Text>
                <Text>{category.description}</Text>
            </Layout>

            <Layout hAlign={'center'} between={5}>
                <Button onClick={() => handleEditClick(category)}>Изменить</Button>
                <Button
                    background={'rgb(146,4,4)'}
                    onClick={() =>
                        setConfirmDeleteModal({
                            isModalOpen: true,
                            id: category.id,
                            name: category.name,
                        })
                    }
                >
                    Удалить
                </Button>
            </Layout>
        </Layout>
    );

    const renderedItems = () => (
        <>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>{tableHeaders('name')}</THeadCell>
                        <THeadCell>{tableHeaders('description')}</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                {!isLoading ? (
                    <TableBody>
                        {categories?.length ? (
                            categories?.map((category, index) => (
                                <TRow key={index} menu>
                                    <TCell>{category.name}</TCell>
                                    <TCell>{category.description}</TCell>
                                    <TCell relative>
                                        <EyeImgIcon
                                            src={EyeIcon}
                                            onClick={() => handleRowClick(category)}
                                            height="18px"
                                            color={primaryColor}
                                        />
                                    </TCell>
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={3}/>
                        )}
                    </TableBody>
                ) : (
                    <TableSkeleton columns={3}/>
                )}
            </Table>
            {modal && (
                <ModalView
                    title={`Данные категории ${initialValue?.name}`}
                    onClose={handleCloseModal}
                >
                    {renderModalItems(initialValue!)}
                </ModalView>
            )}
        </>
    );

    return <Layout>{renderedItems()}</Layout>;
})

export default ExpenseCategoriesMobileView;