export const MainActions = {
    TRANSACTIONS: 'Продажа',
    CLIENTS: 'Клиент',
    PRODUCT_SINGLE: 'Продукт',
    PRODUCT_MASS: 'Продукты несколько',
    USERS: 'Сотрудник',
    TRANSFERS: 'Перевод товаров',
    EXPENSES: 'Плановый расход',
    EXPENSE_OPERATION: 'Операция по расходам',
    EXPENSE_CATEGORY: 'Категория расхода',
    PAYROLLS: 'Выплата зарплаты',
    EMPLOYEE_ALLOWANCE: 'Пособие сотрудника',
    EMPLOYEE_DEDUCTIONS: 'Удержание сотрудника',
    ALLOWANCE: 'Пособие',
    DEDUCTION: 'Удержание',
    BUSINESS_UNITS: 'Подразделение',
    BUSINESS_STOCKS: 'Запас товаров',
    ROLES: 'Роль',
    PRODUCT_CATEGORIES: 'Категория продукта',
    PRODUCT_BRANDS: 'Бренд продукта',
    SUPPLIERS: 'Поставщик',
    STATUSES: 'Статус',
}

