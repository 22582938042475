import {SupplierBrandRequest} from "../suppliers.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import axios from "axios";
import {api} from "../../../api/endpoints";
import {toast} from "react-toastify";

const initialValues: SupplierBrandRequest = {
    supplier_id: 0,
    brand_ids: [],
}

class SupplierBrandsCreateEditStore {
    public form = initialValues;
    public error = '';
    public message = '';
    public isLoading = false;

    constructor() {
        makeAutoObservable(this);
    }

    public init = async (initialValue: SupplierBrandRequest | null) => {
        this.form = initialValue!
    }

    public setBrandIDs = (ids: number[]) => {
        this.form.brand_ids = ids;
    }

    public createOrEditSupplierBrand = async (supplierID: number, edit?: boolean) => {
        this.isLoading = true;
        const payload = {
            supplier_id: supplierID,
            brand_ids: this.form.brand_ids,
        }
        const {getToken} = AppStore
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        try {
            let res;
            if (edit) {
                res = await axios.put(api.suppliers + `/brands`, payload, {headers});
            } else {
                res = await axios.post(api.suppliers + `/brands`, payload, {headers});
            }

            this.message = res?.data.message;
            toast.success(this.message);
            return res;

        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);

        } finally {
            this.isLoading = false;
        }
    }

    public setField = (form: SupplierBrandRequest) => {
        this.form = form;
    }

    public destroy = () => {
        this.form = initialValues;
    }
}

export default new SupplierBrandsCreateEditStore();