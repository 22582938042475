import {observer} from "mobx-react";
import {useState} from "react";
import {Layout} from "../../../styles/layout";
import {Text} from "../../../styles/text";
import {Badge} from "../../../styles/badge";
import {Button} from "../../../styles/button";
import {EyeImgIcon, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import EyeIcon from "../../../assets/icons/eye.svg";
import {primaryColor} from "../../../utils/variables";
import NoResultTitle from "../../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../../utils/skeleton/table.skeleton";
import ModalView from "../../../components/modal/modal.view";
import {ExpensesModel} from "./expenses.model";

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface ExpensesMobileViewProps {
    isLoading: boolean;
    expenses: ExpensesModel[] | undefined;
    handleEditClick: (expense: ExpensesModel) => void;
    setConfirmDeleteModal: ({isModalOpen, id, name}: setConfirmDeleteModalProps) => void;
    getPeriodLabel: (period: string) => string;
    tableHeaders: (key: string) => string;
}

const ExpensesMobileView = observer(({
                                         isLoading,
                                         expenses,
                                         handleEditClick,
                                         setConfirmDeleteModal,
                                         getPeriodLabel,
                                         tableHeaders
                                     }: ExpensesMobileViewProps) => {
    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<ExpensesModel | null>(null);

    const handleRowClick = (expense: ExpensesModel) => {
        setModal(true);
        setInitialValue(expense);
    };

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    };

    const renderModalItems = (expense: ExpensesModel) => (
        <Layout vertical between={10}>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('name')}</Text>
                <Text>{expense.name}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('description')}</Text>
                <Text>{expense.description}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('category')}</Text>
                <Text>{expense.category}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('business_unit')}</Text>
                <Text>{expense.business_unit}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('amount')}</Text>
                <Text>{expense.amount}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('post_check')}</Text>
                <Text>
                    <Badge online={expense.post_check}>
                        {expense.post_check ? 'Да' : 'Нет'}
                    </Badge>
                </Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('period')}</Text>
                <Text>{getPeriodLabel(expense.period)}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('period_count')}</Text>
                <Text>{expense.period_count}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('start_date_formatted')}</Text>
                <Text>{expense.start_date_formatted}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('end_date_formatted')}</Text>
                <Text>{expense.end_date_formatted}</Text>
            </Layout>
            <Layout hAlign={'center'} between={5}>
                <Button onClick={() => handleEditClick(expense)}>Изменить</Button>
                <Button
                    background={'rgb(146,4,4)'}
                    onClick={() =>
                        setConfirmDeleteModal({
                            isModalOpen: true,
                            id: expense.id,
                            name: expense.name,
                        })
                    }
                >
                    Удалить
                </Button>
            </Layout>
        </Layout>
    );

    const renderedItems = () => (
        <>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>{tableHeaders('name')}</THeadCell>
                        <THeadCell>{tableHeaders('category')}</THeadCell>
                        <THeadCell>{tableHeaders('amount')}</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                {!isLoading ? (
                    <TableBody>
                        {expenses?.length ? (
                            expenses?.map((expense, index) => (
                                <TRow key={index} menu>
                                    <TCell>{expense.name}</TCell>
                                    <TCell>{expense.category}</TCell>
                                    <TCell>{expense.amount}</TCell>
                                    <TCell relative>
                                        <EyeImgIcon
                                            src={EyeIcon}
                                            onClick={() => handleRowClick(expense)}
                                            height="18px"
                                            color={primaryColor}
                                        />
                                    </TCell>
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={4}/>
                        )}
                    </TableBody>
                ) : (
                    <TableSkeleton columns={4}/>
                )}
            </Table>
            {modal && (
                <ModalView
                    title={`Данные расхода ${initialValue?.name}`}
                    onClose={handleCloseModal}
                >
                    {renderModalItems(initialValue!)}
                </ModalView>
            )}
        </>
    );

    return <Layout>{renderedItems()}</Layout>;
});

export default ExpensesMobileView;