import {RoleModel} from "../roles/roles.model";
import {BusinessUnitModel} from "../business_units/business_units.model";
import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {Search, SearchLayout, Select} from "../../styles/table-header";
import {Image} from "../../styles/image";
import SearchIcon from "../../assets/icons/search.svg";

interface SuppliersFilterViewProps {
    handleKeyDown: (e: any) => void;
    cancelSearch: (e: any) => void;
    handleFilterChange: (e: any, key: string) => void;
    countries: any[] | undefined;
    inModal: boolean;
}

const SuppliersFiltersView = observer((
    {
        handleKeyDown,
        cancelSearch,
        handleFilterChange,
        countries,
        inModal
    }: SuppliersFilterViewProps
) => {

    return (
        <>
            <Layout between={10} wrapped vAlign="center" vertical={inModal}>
                <SearchLayout>
                    <Search
                        type="search"
                        id="search"
                        placeholder="Поиск"
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={(e) => cancelSearch(e)}
                    ></Search>
                    <Image src={SearchIcon} height="16px" />
                </SearchLayout>
                <Select
                    id={"country-filter"}
                    onChange={(e) => handleFilterChange(e, "country_id")}
                >
                    <option value="">Все страны</option>
                    {countries?.map((country, index) => (
                        <option value={country.id} key={country.id}>
                            {country.name}
                        </option>
                    ))}
                </Select>
            </Layout>
        </>
    );
});

export default SuppliersFiltersView;