import {observer} from "mobx-react";
import RoleCreateEditStore from "./roles.create.edit.store";
import {RoleAccessGroupModel, RoleCreateModel} from "../roles.model";
import {Layout} from "../../../styles/layout";
import {useEffect} from "react";
import {renderInputs} from "../../../components/form/render.inputs";
import {Switch} from 'antd';
import {Button} from "../../../styles/button";
import {Collapse} from 'antd';
import {Text} from "../../../styles/text";
import RolesStore from "../roles.store";
import AppStore from "../../../app.store";
import StatusesStore from "../../statuses/statuses.store";

const {Panel} = Collapse;

interface RolesCreateEditViewProps {
    initialValue: RoleCreateModel | null;
    setModal: (isOpen: boolean) => void;
}

const RolesCreateEditView = observer(({initialValue, setModal}: RolesCreateEditViewProps) => {
    const {
        init,
        accessGroups,
        setField,
        form,
        isLoading,
        addRemoveAccess,
        createRole,
        destroy,
    } = RoleCreateEditStore;

    const {rolesReq} = RolesStore;
    const {businessSettings} = AppStore;
    const {statusesPageInit, statuses} = StatusesStore

    const {name, description, default_status_id} = form;

    useEffect(() => {
        const controller = new AbortController;
        void init(initialValue);
        void statusesPageInit(true, controller.signal)

        return () => {
            destroy();
            controller.abort();
        }
    }, [destroy, init, initialValue, statusesPageInit]);

    const statusOptions = statuses?.map(({id, description}) => ({
        label: description,
        value: id,
    }));

    const inputs = [
        {field: name, fieldName: 'name', placeholder: 'Название', type: 'text', required: true},
        {field: description, fieldName: 'description', placeholder: 'Описание', type: 'text', required: true},
        businessSettings?.transaction_approval && {
            field: default_status_id,
            options: statusOptions,
            fieldName: 'default_status_id',
            placeholder: 'Статус по умолчанию',
            type: 'select',
            required: false,
        }
    ];

    const renderAccesses = (group: RoleAccessGroupModel, index: number) => (
        group.accesses?.map((access, index) => (
            <Layout key={index} vAlign="center" hAlign="space-between" widthSize="60%">
                <Text extent="13px">{access.name}</Text>
                <Switch key={index}
                        checked={access.active}
                        onChange={() => addRemoveAccess(group.id, access.id)}
                />
            </Layout>
        ))
    );

    const handleCreateRole = async () => {
        const res = await createRole(!!initialValue);
        if (res) {
            setModal(false);
            rolesReq();
        }
    }

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
        <Layout vertical between={15}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setField)}
                <Layout centered><h3>Доступы</h3></Layout>
                {
                    accessGroups?.map((group, index) => (
                        <Collapse>
                            <Panel key={index} header={group.name}>
                                <Layout vertical between={16}>
                                    <Layout vertical>
                                        <Text extent="13px">{group.description}</Text>
                                    </Layout>
                                    <Layout centered vertical between={6}>
                                        {renderAccesses(group, index)}
                                    </Layout>
                                </Layout>
                            </Panel>
                        </Collapse>
                    ))
                }
                <Layout hAlign="center">
                    <Button
                        extent="S"
                        heightSize="35px"
                        isLoading={isLoading}
                        onClick={handleCreateRole}
                        // disabled={isValid([inputs])}
                    >{isLoading ? '' : renderSubmitText}</Button>
                </Layout>
            </Layout>
        </Layout>
    );
});

export default RolesCreateEditView;