import {observer} from "mobx-react";
import usersStore from "../../users/users.store";
import {useEffect} from "react";
import {Search, SearchLayout, Select} from "../../../styles/table-header";
import {Image} from "../../../styles/image";
import SearchIcon from "../../../assets/icons/search.svg";
import {Layout} from "../../../styles/layout";
import {DatePicker} from "antd";

interface PayrollsFilterViewProps {
    setDates: (e: any) => void;
    handleKeyDown: (e: any) => void;
    cancelSearch: (e: any) => void;
    handleFilterChange: (e: any, key: string) => void;
    inModal: boolean;
}

const PayrollsFilterView = observer(({setDates, handleKeyDown, cancelSearch, handleFilterChange, inModal}: PayrollsFilterViewProps) => {
    const {users, setUsersFilter} = usersStore;

    useEffect(() => {
        const controller = new AbortController();
        setUsersFilter({page: 1, page_limit: 999999});

        return () => {
            controller.abort();
        }

    }, [setUsersFilter]);

    const {RangePicker} = DatePicker;

    return (
        <>
            <Layout between={10} wrapped vAlign={"center"} vertical={inModal}>
                <RangePicker
                    onChange={(e) => {
                        setDates(e);
                    }}
                    placeholder={['Начало даты', 'Конец даты']}
                    style={{
                        height: 40,
                        width: 250,
                        borderRadius: 12,
                        border: 0,
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        color: '#374151',
                        backgroundColor: '#FFFFFF',
                    }}
                />
                <SearchLayout>
                    <Search
                        type="search"
                        id="search"
                        placeholder="Поиск"
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={(e) => cancelSearch(e)}
                    />
                    <Image src={SearchIcon} height="16px"/>
                </SearchLayout>
                <Select
                    id="employees-filter"
                    onChange={(e) => handleFilterChange(e, 'employee_id')}
                >
                    <option value=''>Сотрудник</option>
                    {users?.map((user) => (
                        <option value={user.id} key={user.id}>
                            {`${user.surname} ${user.name}`}
                        </option>
                    ))}
                </Select>
            </Layout>
        </>
    );
});

export default PayrollsFilterView;