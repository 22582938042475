import {UsersModel} from "../users/users.model";
import {observer} from "mobx-react";
import {useState} from "react";
import {Layout} from "../../styles/layout";
import {Text} from "../../styles/text";
import {Badge} from "../../styles/badge";
import {Button} from "../../styles/button";
import {EyeImgIcon, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../styles/table";
import {Image} from "../../styles/image";
import EyeIcon from "../../assets/icons/eye.svg";
import {primaryColor} from "../../utils/variables";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import ModalView from "../../components/modal/modal.view";
import {RoleModel} from "./roles.model";

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface RolesTableMobileViewProps {
    loading: boolean;
    roles: RoleModel[] | undefined;
    handleEditClick: (role: RoleModel) => void;
    setConfirmDeleteModal: ({isModalOpen, id, name}: setConfirmDeleteModalProps) => void;
    tableHeaders: (key: string) => string;
}

const RolesTableMobileView = observer(({
                                           loading,
                                           roles,
                                           handleEditClick,
                                           setConfirmDeleteModal,
                                           tableHeaders
                                       }: RolesTableMobileViewProps) => {

    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<RoleModel | null>(null)

    const handleRowClick = (user: RoleModel) => {
        setModal(true);
        setInitialValue(user);
    }

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    }

    const renderModalItems = (role: RoleModel) => (
        <Layout vertical between={10}>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('name')}</Text>
                <Text>{role.name}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('description')}</Text>
                <Text>{role.description}</Text>
            </Layout>
            <Layout hAlign={"center"} between={5}>
                <Button onClick={() => handleEditClick(role)}>Изменить</Button>
                <Button
                    background={'rgb(146,4,4)'}
                    onClick={() =>
                        setConfirmDeleteModal({
                            isModalOpen: true,
                            id: role.id,
                            name: role.name,
                        })
                    }
                >Удалить
                </Button>
            </Layout>
        </Layout>
    );

    const renderedItems = () => (
        <>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>{tableHeaders('name')}</THeadCell>
                        <THeadCell>{tableHeaders('description')}</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                {!loading ? (
                    <TableBody>
                        {roles?.length ? (
                            roles?.map((role, index) => (
                                <TRow key={index} menu>
                                    <TCell>{role.name}</TCell>
                                    <TCell>{role.description}</TCell>
                                    <TCell relative>
                                        <EyeImgIcon
                                            src={EyeIcon}
                                            onClick={() => handleRowClick(role)}
                                            height='18px'
                                            color={primaryColor}
                                        />
                                    </TCell>
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={4} />
                        )}
                    </TableBody>
                ): (
                    <TableSkeleton columns={4}/>
                )}
            </Table>
            {modal && (
                <ModalView
                    title={`Данные роли ${initialValue?.name}`}
                    onClose={handleCloseModal}
                >
                    {renderModalItems(initialValue!)}
                </ModalView>
            )}
        </>
    );

    return (
        <Layout>
            {renderedItems()}
        </Layout>
    )
});

export default RolesTableMobileView;