import {observer} from "mobx-react";
import {Layout} from "../../../../styles/layout";
import {useEffect} from "react";
import EmployeeAllowancesStore from "../../employee.allowances/employee.allowances.store";
import {
    EmployeeAllowancesFilterModel,
    EmployeeAllowancesModel
} from "../../employee.allowances/employee.allowances.model";
import {Table, TableBody, TableContainer, TableHead, TCell, THeadCell, TRow} from "../../../../styles/table";
import TableSkeleton from "../../../../utils/skeleton/table.skeleton";
import NoResultTitle from "../../../../components/no_result_title/no_result_title.view";
import PayrollsCreateEditStore from "./payrolls.create.edit.store";

interface PayrollsCreateEditAllowancesViewProps {
    employeeID: number;
    setModal: (isOpen: boolean) => void;
}

const PayrollsCreateEditAllowancesView = observer(({employeeID, setModal}: PayrollsCreateEditAllowancesViewProps) => {
    const {
        employeeAllowances,
        setEmployeeAllowancesFilter,
        isLoading,
    } = EmployeeAllowancesStore;

    const {setSelectedItem, form} = PayrollsCreateEditStore;

    useEffect(() => {
        setEmployeeAllowancesFilter({employee_id: employeeID, paid: 'false'} as EmployeeAllowancesFilterModel)
    }, []);

    const handleAllowanceSelect = (allowance: EmployeeAllowancesModel) => {
        setSelectedItem(allowance.id, allowance.allowance, allowance.amount, 'ALLOWANCE');
        setModal(false);
    }

    return (
        <Layout>
            <Table inModal>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>Название</THeadCell>
                        <THeadCell>Описание</THeadCell>
                        <THeadCell>Сумма</THeadCell>
                    </TRow>
                </TableHead>
                {isLoading ? (
                    <TableSkeleton columns={3}/>
                ) : (
                    <TableBody>
                        {employeeAllowances.length ? (
                            employeeAllowances.filter((allowance) =>
                                !form.allow_ded.find((all_ded) =>
                                    allowance.id === all_ded.id && all_ded.type === 'ALLOWANCE'))
                                .map((allowance, index) => (
                                    <TRow
                                        key={index}
                                        onClick={() => handleAllowanceSelect(allowance)}
                                    >
                                        <TCell>{allowance.allowance}</TCell>
                                        <TCell>{allowance.details}</TCell>
                                        <TCell>{allowance.amount}</TCell>
                                    </TRow>
                                ))
                        ) : (
                            <NoResultTitle colspan={3}/>
                        )}
                    </TableBody>
                )}
            </Table>
        </Layout>
    );
});

export default PayrollsCreateEditAllowancesView;