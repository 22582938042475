import {observer} from "mobx-react";
import {EyeImgIcon, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import {useState} from "react";
import {Layout} from "../../../styles/layout";
import {Text} from "../../../styles/text";
import {Badge} from "../../../styles/badge";
import EyeIcon from "../../../assets/icons/eye.svg";
import {primaryColor} from "../../../utils/variables";
import NoResultTitle from "../../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../../utils/skeleton/table.skeleton";
import ModalView from "../../../components/modal/modal.view";
import {EmployeeAllowancesModel} from "./employee.allowances.model";

interface EmployeeAllowancesMobileViewProps {
    loading: boolean;
    allowances: EmployeeAllowancesModel[] | undefined;
    tableHeaders: (key: string) => string;
}

const EmployeeAllowancesMobileView = observer(({loading, allowances, tableHeaders}:EmployeeAllowancesMobileViewProps) => {
    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<EmployeeAllowancesModel | null>(null);

    const handleRowClick = (allowance: EmployeeAllowancesModel) => {
        setModal(true);
        setInitialValue(allowance);
    };

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    };

    const renderModalItems = (allowance: EmployeeAllowancesModel) => (
        <Layout vertical between={10}>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('allowance')}</Text>
                <Text>{allowance.allowance}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('user_name')}</Text>
                <Text>{allowance.user_name}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('employee')}</Text>
                <Text>{allowance.employee}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('amount')}</Text>
                <Text>{allowance.amount}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('details')}</Text>
                <Text>{allowance.details}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('paid')}</Text>
                <Text>
                    <Badge online={allowance.paid}>
                        {allowance.paid ? 'Да' : 'Нет'}
                    </Badge>
                </Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('date')}</Text>
                <Text>{allowance.date}</Text>
            </Layout>
        </Layout>
    );

    const renderedItems = () => (
        <>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>{tableHeaders('allowance')}</THeadCell>
                        <THeadCell>{tableHeaders('employee')}</THeadCell>
                        <THeadCell>{tableHeaders('amount')}</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                {!loading ? (
                    <TableBody>
                        {allowances?.length ? (
                            allowances?.map((allowance, index) => (
                                <TRow key={index} menu>
                                    <TCell>{allowance.allowance}</TCell>
                                    <TCell>{allowance.employee}</TCell>
                                    <TCell>{allowance.amount}</TCell>
                                    <TCell relative>
                                        <EyeImgIcon
                                            src={EyeIcon}
                                            onClick={() => handleRowClick(allowance)}
                                            height="18px"
                                            color={primaryColor}
                                        />
                                    </TCell>
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={4} />
                        )}
                    </TableBody>
                ) : (
                    <TableSkeleton columns={4} />
                )}
            </Table>
            {modal && (
                <ModalView
                    title={`Пособие ${initialValue?.employee}`}
                    onClose={handleCloseModal}
                >
                    {renderModalItems(initialValue!)}
                </ModalView>
            )}
        </>
    );

    return <Layout>{renderedItems()}</Layout>;
});

export default EmployeeAllowancesMobileView;