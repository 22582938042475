import {observer} from "mobx-react";
import {BusinessUnitModel} from "../../business_units/business_units.model";
import {UsersModel} from "../../users/users.model";
import {ExpenseCategoriesModel} from "../expense.categories/expense.categories.model";
import {Search, SearchLayout, Select} from "../../../styles/table-header";
import {Image} from "../../../styles/image";
import SearchIcon from "../../../assets/icons/search.svg";
import {Layout} from "../../../styles/layout";

interface ExpenseOperationsFiltersViewProps {
    handleKeyDown: (e: any) => void;
    cancelSearch: (e: any) => void;
    handleFilterChange: (e: any, key: string) => void;
    users: UsersModel[] | undefined;
    businessUnits: BusinessUnitModel[] | undefined;
    categories: ExpenseCategoriesModel[] | undefined;
    inModal: boolean;
}

const ExpenseOperationsFiltersView = observer(({
                                                   handleKeyDown,
                                                   cancelSearch,
                                                   handleFilterChange,
                                                   users,
                                                   businessUnits,
                                                   categories,
                                                   inModal
                                               }: ExpenseOperationsFiltersViewProps) => {
    return (
        <>
            <Layout between={10} wrapped vAlign="center" vertical={inModal}>
                <Select
                    id="business-unit-filter"
                    onChange={(e) => handleFilterChange(e, 'business_unit_id')}
                >
                    <option value="">Все подразделения</option>
                    {businessUnits?.map((businessUnit, _) => (
                        <option value={businessUnit.id} key={businessUnit.id}>
                            {businessUnit.name}
                        </option>
                    ))}
                </Select>
                <Select
                    id="category-filter"
                    onChange={(e) => handleFilterChange(e, 'category_id')}
                >
                    <option value="">Все категории</option>
                    {categories?.map((category, _) => (
                        <option value={category.id} key={category.id}>
                            {category.name}
                        </option>
                    ))}
                </Select>
                <Select
                    id="confirmed-filter"
                    onChange={(e) => handleFilterChange(e, 'confirmed')}
                >
                    <option value="">Все операции</option>
                    <option value="true">Потвержден</option>
                    <option value="false">Не потвержден</option>
                </Select>
            </Layout>
        </>
    );
});

export default ExpenseOperationsFiltersView;