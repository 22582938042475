import {observer} from "mobx-react";
import {SalesReportModel} from "./sales.report.model";
import {primaryColor} from "../../../utils/variables";
import {Line} from '@ant-design/plots';

interface SalesReportGraphViewProps {
    reports: SalesReportModel[] | undefined;
}

interface Data {
    x: string;
    Оборот: number;
}

const SalesReportGraphView = observer(({reports}: SalesReportGraphViewProps) => {
    const handleData = () => {
        let data: Data[] = [];
        reports?.map((report) => {
            const datum: Data = {
                x: report.entity_name,
                Оборот: report.turnover,
            };
            data.push(datum);
        })
        return data;
    }

    const config = {
        data: handleData(),
        height: 400,
        heightSize: '100%',
        xField: 'x',
        yField: 'Оборот',
        smooth: true,
        seriesField: 'Оборот',
        slider: false,
        animation: {
            appear: {
                animation: 'path-in',
                duration: 4000,
            }
        },
        color: [primaryColor],
    };

    return (
        <Line {...config}/>
    );
});

export default SalesReportGraphView;