import {observer} from "mobx-react";
import {Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import {EmployeeAllowancesModel} from "./employee.allowances.model";
import TableSkeleton from "../../../utils/skeleton/table.skeleton";
import NoResultTitle from "../../../components/no_result_title/no_result_title.view";
import {Badge} from "../../../styles/badge";

interface EmployeeAllowancesDesktopViewProps {
    loading: boolean;
    allowances: EmployeeAllowancesModel[] | undefined;
    tableHeaders: (key: string) => string;
}

const EmployeeAllowancesDesktopView = observer(({
                                                    loading,
                                                    allowances,
                                                    tableHeaders
                                                }: EmployeeAllowancesDesktopViewProps) => {
    return (
        <Table>
            <TableHead>
                <TRow tableHead>
                    <THeadCell>{tableHeaders('allowance')}</THeadCell>
                    <THeadCell>{tableHeaders('user_name')}</THeadCell>
                    <THeadCell>{tableHeaders('employee')}</THeadCell>
                    <THeadCell>{tableHeaders('amount')}</THeadCell>
                    <THeadCell>{tableHeaders('details')}</THeadCell>
                    <THeadCell>{tableHeaders('paid')}</THeadCell>
                    <THeadCell>{tableHeaders('date')}</THeadCell>
                </TRow>
            </TableHead>
            {loading ? (<TableSkeleton columns={7}/>) : (
                <TableBody>
                    {allowances?.length ? (
                        allowances.map((allowance, index) => (
                            <TRow key={index}>
                                <TCell>{allowance.allowance}</TCell>
                                <TCell>{allowance.user_name}</TCell>
                                <TCell>{allowance.employee}</TCell>
                                <TCell>{allowance.amount}</TCell>
                                <TCell>{allowance.details}</TCell>
                                <TCell>
                                    <Badge online={allowance.paid}>
                                        {allowance.paid ? 'Да' : 'Нет'}
                                    </Badge>
                                </TCell>
                                <TCell>{allowance.date}</TCell>
                            </TRow>
                        ))
                    ) : (
                        <NoResultTitle colspan={7}/>
                    )}
                </TableBody>
            )}
        </Table>
    );
});

export default EmployeeAllowancesDesktopView;