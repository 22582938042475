import axios from 'axios';
import {makeAutoObservable} from 'mobx';
import {api} from '../../../api/endpoints';
import AppStore from '../../../app.store';
import {toast} from 'react-toastify';
import {AccountsModel, AccountType} from "../account.model";
import accountTypeStore from "../account.type.store";

const initialValues = {
    id: 0,
    number: '',
    type: '',
    balance: 0,
    description: '',
    category_id: 1,
    created_at: '',
    category: '',
    entity_id: 0,
}

class AccountsCreateEditStore {
    private accountCategories?: any[];
    public accountTypes: AccountType[] = [
        {
            id: "active",
            name: "Активный счет"
        },
        {
            id: "passive",
            name: "Пассивный счет"
        },
    ];

    public form: AccountsModel = initialValues;
    public error: string = '';
    public message: string = '';
    public isLoading: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    public init = async (initialValue: AccountsModel | null) => {
        await this.accountCategoryReq();

        if (initialValue) {
            this.form = initialValue;
            this.form.category_id = this.accountCategoryOptions?.find((item) => this.form.category === item.label)?.value;
            this.form.type = this.accountTypeOptions?.find((item) => this.form.type === item.label)?.value;
        } else {
            this.form = initialValues;
        }
    }

    private accountCategoryReq = async () => {
        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }
            const response = await axios.get(api.account_categories, {headers});
            this.accountCategories = response.data.resp;
        } catch (err) {
            console.error(err);
        }
    }

    public createOrEditAccount = async (edit: boolean, entityType?: string, entityId?: string) => {
        this.isLoading = true;
        const payload = {
            id: Number(this.form.id),
            entity_id: Number(entityId),
            number: this.form.number,
            type: this.form.type,
            description: this.form.description,
            category_id: Number(this.form.category_id)
        }
        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }
            let res;
            if (edit) {
                res = await axios.put(api.account, payload, {headers});
            } else {
                res = await axios.post(api.account + `/${entityType}`, payload, {headers});
            }
            // if (res?.code >= 400) {
            //     throw new Error(res)
            // }
            this.message = res?.data.resp;
            toast.success(this.message);

            this.isLoading = false;
            return res;
        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.isLoading = false;
        }
    }

    public setField = (form: AccountsModel) => {
        this.form = form;
    }

    public destroy = () => {
        this.form = initialValues;
        this.isLoading = false;
    }

    public get accountCategoryOptions() {
        if (!this.accountCategories) return ([]);
        return this.accountCategories?.map((category: any) => ({
            label: category.name,
            value: category.id
        }));
    }

    public get accountTypeOptions() {
        return this.accountTypes?.map((accountType: any) => ({
                label: accountType.name,
                value: accountType.id
        }));
    }
}

export default new AccountsCreateEditStore();