import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {Search, SearchLayout, Select} from "../../styles/table-header";
import {Image} from "../../styles/image";
import SearchIcon from "../../assets/icons/search.svg";

interface BusinessServiceFilterViewProps {
    handleKeyDown: (e: any) => void
    cancelSearch: (e: any) => void
    handleFilterChange: (e: any, key: string) => void
    inModal: boolean
}

const BusinessServiceFiltersView = observer((
    {
        handleKeyDown,
        cancelSearch,
        handleFilterChange,
        inModal
    }: BusinessServiceFilterViewProps
) => {

    return (
        <>
            <Layout between={10} wrapped vAlign="center" vertical={inModal}>
                <SearchLayout>
                    <Search
                        type="search"
                        id="search"
                        placeholder="Поиск"
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={(e) => cancelSearch(e)}
                    ></Search>
                    <Image src={SearchIcon} height="16px" />
                </SearchLayout>
                <Select
                    id="outflow"
                    onChange={(e) => handleFilterChange(e, "outflow")}
                >
                    <option value="">Все типы операций</option>
                    <option value="false">Приход</option>
                    <option value="true">Расход</option>
                </Select>
            </Layout>
        </>
    );
});

export default BusinessServiceFiltersView;