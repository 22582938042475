import {PayrollsItemsModel} from "./payrolls.items.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../../app.store";
import {toast} from "react-toastify";
import axios from "axios";
import {api} from "../../../../api/endpoints";

const payrollItemsInitialValues: PayrollsItemsModel = {
    salary: 0,
    total_allowances: 0,
    total_amount: 0,
    total_deductions: 0,
    allowances: [],
    deductions: [],
    tax_amount: 0,
}
const loadingInitialState: boolean = false;

class PayrollsItemsStore {
    public payrollItems = payrollItemsInitialValues;
    public isLoading = loadingInitialState;
    error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public itemsDestroy = () => {
        this.payrollItems = payrollItemsInitialValues;
        this.isLoading = loadingInitialState;
        this.error = '';
    }

    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    setPayrollItems(data: any) {
        this.payrollItems = data;
    }

    public payrollItemsReq = async (payrollID: number, signal?: AbortSignal) => {
        this.setLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        try {
            const response = await axios.get(api.payrolls + `/items/${payrollID}`, {headers, signal});
            this.setPayrollItems(response.data);
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }
}

export default new PayrollsItemsStore();