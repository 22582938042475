import {observer} from "mobx-react";
import {Burger, BurgerBar, BurgerContainer, BurgerXMark} from "./burger.style";
import {useEffect, useRef, useState} from "react";
import NavbarListView from "./navbar.list";
import Xmark from '../../assets/icons/xmark.svg';

const BurgerView = observer(() => {
    const [open, setOpen] = useState(false);
    const menuRef = useRef(null);


    useEffect(() => {
        const handleClickScreen = (e: any) => {
            // @ts-ignore
            if (!menuRef?.current!.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickScreen);

        return () => {
            document.removeEventListener('mousedown', handleClickScreen)
        };
    });

    return (
        <BurgerContainer ref={menuRef}>
            <Burger
                open={open}
                onClick={() => setOpen(!open)}
            >
                <BurgerBar open={open}/>
                <BurgerBar open={open}/>
                <BurgerBar open={open}/>
                <BurgerXMark open={open}
                             src={Xmark}
                             extent='16px'
                />
            </Burger>
            <NavbarListView
                burgerOpen={open}
                setOpen={setOpen}
            />
        </BurgerContainer>
    );
});

export default BurgerView;