import {makeAutoObservable} from "mobx";
import AppStore from "../../app.store";
import axios, { GenericAbortSignal } from "axios";
import {api} from "../../api/endpoints";
import {ProductModel, ProductFilterModel} from "./products.model";
import { toast } from "react-toastify";


class ProductsStore {
    public products ?: ProductModel[] = [];
    public productsFilter = {
        search: '',
        brand_id: 0,
        category_id: 0,
        type_id: 0,
        price_from: 0,
        price_to: 0,
        page: 1,
        page_limit: 25,
        pages: 0,
        order_by: 'name',
        order_descending: false,
    }
    public loading = false;
    public error = "";
    public message = "";

    public productsInit = (signal: GenericAbortSignal) => {
        void this.productsReq(signal);
    }

    constructor() {
        makeAutoObservable(this);
    }

    public destroy = () => {
        this.products = [];
    };

    setProducts(data: any) {
        const {products} = data

        this.products = products;
    }

    public productsReq = async (signal?: GenericAbortSignal) => {
        this.setLoading(true);
        try {
            const { getToken } = AppStore

            const headers = {
                Authorization: "Bearer " + getToken()!
            }

            const filterUrl = Object.keys(this.productsFilter).reduce((filters, filter) => {
                const symbol = filters ? '&' : '?';
                const filterKeyName = filter as keyof ProductFilterModel;
                if (this.productsFilter[filterKeyName] !== 0 &&
                    this.productsFilter[filterKeyName] !== '') {
                    filters += symbol + filter + '=' + this.productsFilter[filterKeyName];
                }

                return filters;
            }, '');

            let response: any;
            response = await axios.get(api.get_products + filterUrl, { headers, signal });
            this.setProducts(response?.data)
            this.setProductsFilterPages(response?.data.pages)
        } catch (err) {
        } finally {
            this.setLoading(false);
        }
    };

    public setProductsFilter = ({search, type_id, category_id, brand_id, page, page_limit}: ProductFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.productsFilter = {
            ...this.productsFilter,
            search: isUndefined(search) ? this.productsFilter.search! : search!,
            type_id: isUndefined(type_id) ? this.productsFilter.type_id! : type_id!,
            category_id: isUndefined(category_id) ? this.productsFilter.category_id! : category_id!,
            brand_id: isUndefined(brand_id) ? this.productsFilter.brand_id! : brand_id!,
            page: isUndefined(page) ? this.productsFilter.page! : page!,
            page_limit: isUndefined(page_limit) ? this.productsFilter.page_limit! : page_limit!,
        }

        if (page_limit) {
            this.productsFilter.page = 1;
        }

        void this.productsReq();
    }
    
    public deleteProduct = async (id: number) => {
        this.setLoading(true);
        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }
            const response = await axios.delete(`${api.product}/${id}`, {headers});
            // if (res?.code >= 400) {
            //     throw new Error(res)
            // }
            this.message = response?.data.message;
            toast.success(this.message);

            if (response) {
                this.productsReq();
            }
        } catch (err:any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }


    public setProductsFilterPages = (pages: number) => {
        this.productsFilter.pages = pages;
    }
    private setLoading = (loadingState: boolean) => {
        this.loading = loadingState;
    }

    public clearFilter = () => {
        this.productsFilter = {
            search: '',
            brand_id: 0,
            category_id: 0,
            type_id: 0,
            price_from: 0,
            price_to: 0,
            page: 1,
            page_limit: 25,
            pages: 0,
            order_by: 'name',
            order_descending: false,
        }
    }
}

export default new ProductsStore();