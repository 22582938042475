import {RoleAccessGroupModel, RoleAccessModel, RoleCreateModel, RoleGroupAccessModel} from "../roles.model";
import axios from "axios";
import {api} from "../../../api/endpoints";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import { toast } from "react-toastify";

const initialValues: RoleCreateModel = {
    id: 0,
    name: '',
    description: '',
    default_status_id: 1,
    accesses: []
}

class RoleCreateEditStore {
    public accessGroups: RoleAccessGroupModel[] = [];
    public accesses: RoleAccessModel[] = [];
    private roleGroupAccesses: RoleGroupAccessModel[] = [];
    public form: RoleCreateModel = initialValues;

    public error = '';
    public message = '';
    public isLoading = false;

    constructor() {
        makeAutoObservable(this);
    }

    public init = async (initialValue: RoleCreateModel | null) => {
        await this.getAccessGroups();
        await this.getAccesses();

        if (initialValue) {
            await this.getRole(initialValue.id);

            this.roleGroupAccesses?.forEach((groupAccess) => {
                for (let i = 0; i < this.accessGroups!.length; i++) {
                    if (this.accessGroups![i].id === groupAccess.group_id) {
                        this.accessGroups![i].accesses = groupAccess.accesses!;
                    }

                }
            })

            this.form = initialValue;
        } else {
            await this.getRole(8);

            this.roleGroupAccesses?.forEach((groupAccess) => {
                for (let i = 0; i < this.accessGroups!.length; i++) {
                    if (this.accessGroups![i].id === groupAccess.group_id) {
                        for (let j = 0; j < groupAccess.accesses!.length; j++) {
                            groupAccess.accesses[j].active = false;
                        }
                        this.accessGroups![i].accesses = groupAccess.accesses!;
                    }

                }
            })

            this.form = initialValues;
        }
    }

    public getAccessGroups = async () => {
        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }

            const response = await axios.get(api.access_groups, {headers});
            const {access_groups} = response.data;
            this.accessGroups = access_groups;
        } catch (err) {
            console.error(err);
        }
    }

    public getAccesses = async () => {
        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }

            const response = await axios.get(api.accesses, {headers});
            const {accesses} = response.data;
            this.accesses = accesses;
        } catch (err) {
            console.error(err);
        }
    }

    public createRole = async (edit?: boolean) => {
        this.isLoading = true;
        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }

            let payload;

            if (edit) {
                payload = {
                    role: {
                        id: this.form.id,
                        name: this.form.name,
                        description: this.form.description,
                        default_status_id: this.form.default_status_id,
                    },
                    access_list: this.accessGroups
                }
            } else {
                this.accessGroups?.forEach((group) => {
                    // loop through all accesses
                    const accessIds: number[] = [];
                    group.accesses?.forEach((access) => {
                        // if access is checked, add it to the array
                        if (access.active) {
                            accessIds.push(access.id);
                        }
                    })

                    if (accessIds.length > 0) {
                        this.form.accesses.push({access_group_id: group.id, access_ids: accessIds});
                    }
                })

                payload = {
                    role: {
                        name: this.form.name,
                        description: this.form.description,
                        default_status_id: this.form.default_status_id,
                    },
                    accesses: this.form.accesses
                }
            }

            let res;

            if (edit) {
                res = await axios.put(api.role_with_accesses, payload, {headers});
            } else {
                res = await axios.post(api.role_with_accesses, payload, {headers});
            }
            // if (res?.code >= 400) {
            //     throw new Error(res)
            // }
            this.message = res?.data.message;
            toast.success(this.message);
            
            this.isLoading = false;
            return res;
        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.isLoading = false;
        }
    }

    public getRole = async (id: number) => {
        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }

            const response = await axios.get(api.role_with_accesses + '/' + id, {headers});
            const {access_list} = response.data;
            this.roleGroupAccesses = access_list;
        } catch (err) {
            console.error(err);
        }
    }

    public addRemoveAccess = (groupId: number, accessId: number) => {
        this.accessGroups!.map((group) => {
            if (group.id === groupId) {
                group.accesses.map((access) => {
                    if (access.id === accessId) {
                        access.active = !access.active;
                        return;
                    }
                })
            }
        })
    }

    public setField = (form: RoleCreateModel) => {
        this.form = form;
    }

    public destroy = () => {
        this.form = initialValues;
    }
}

export default new RoleCreateEditStore();