import {observer} from "mobx-react";
import AppStore from "../../../app.store";
import {Page} from "../../../styles/page";
import {Headline} from "../../../styles/headline";
import {AddButton, Search, SearchLayout, TableHeader} from "../../../styles/table-header";
import {Filters} from "../../../styles/filters";
import {Button, FilterButton} from "../../../styles/button";
import {useEffect, useState} from "react";
import {
    TableContainer, TableContainerMobile,
} from "../../../styles/table";
import ExpenseCategoriesStore from "./expense.categories.store";
import {ExpenseCategoriesFiltersModel, ExpenseCategoriesModel} from "./expense.categories.model";
import ConfirmDelete, {DeleteItem} from "../../../components/confirm/confirm.delete";
import {Image} from "../../../styles/image";
import TablePaginationView from "../../../utils/table.pagination.view";
import {primaryColor} from "../../../utils/variables";
import SearchIcon from "../../../assets/icons/search.svg";
import ModalView from "../../../components/modal/modal.view";
import ExpenseCategoriesCreateEditView from "./expense.categories.modal/expense.categories.create.edit.view";
import ExpenseCategoriesCreateEditStore from "./expense.categories.modal/expense.categories.create.edit.store";
import {Layout} from "../../../styles/layout";
import ExpenseCategoriesDesktopView from "./expense.categories.desktop.view";
import ExpenseCategoriesMobileView from "./expense.categories.mobile.view";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";

const ExpenseCategoriesView = observer(() => {
    const {isNavOpen} = AppStore;
    const [filterModal, setFilterModal] = useState(false);
    const [createEditModal, setCreateEditModal] = useState(false);
    const [initialValue, setInitialValue] = useState<ExpenseCategoriesModel | null>(null);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<DeleteItem>({
        isModalOpen: false,
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const {destroy} = ExpenseCategoriesCreateEditStore
    const {
        categories,
        isLoading,
        deleteLoading,
        deleteExpenseCategoryReq,
        expenseCategoriesPageInit,
        setCategoriesFilter,
        categoriesFilters,
        clearFilter
    } = ExpenseCategoriesStore;
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        dictionaryListReq('EXPENSE CATEGORIES', 'TABLE HEADERS', controller.signal);
        void expenseCategoriesPageInit(controller.signal);

        return () => {
            controller.abort();
        }
    }, [destroyDictionary, dictionaryListReq, expenseCategoriesPageInit]);

    const handleEditClick = (category: ExpenseCategoriesModel) => {
        setInitialValue(category);
        setCreateEditModal(true);
    }

    const handleDelete = async () => {
        await deleteExpenseCategoryReq(confirmDeleteModal.id!);
        setConfirmDeleteModal({isModalOpen: false});
    };

    const prevPage = () => {
        if (categoriesFilters.page > 1) {
            setCategoriesFilter({page: categoriesFilters.page - 1} as ExpenseCategoriesFiltersModel);
        }
    };

    const nextPage = () => {
        if (categoriesFilters.page < categoriesFilters.pages) {
            setCategoriesFilter({page: categoriesFilters.page + 1} as ExpenseCategoriesFiltersModel);
        }
    };

    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector('.context-menu-focused');
        if (e.target.classList.contains('context-menu-focused')) {
            e.target.classList.remove('context-menu-focused');
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            e.target.classList.add('context-menu-focused');
        }
    };

    const cancelSearch = (e: any) => {
        if (e.target.value === '' && categoriesFilters.search !== '') {
            setCategoriesFilter({search: ''} as ExpenseCategoriesFiltersModel);
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            setCategoriesFilter({search: e.target.value} as ExpenseCategoriesFiltersModel);
        }
    };

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === 'page' && index !== undefined) {
            setCategoriesFilter({page: index + 1} as ExpenseCategoriesFiltersModel);
            return;
        }
        setCategoriesFilter({[objectKey]: options[selectedIndex].value} as ExpenseCategoriesFiltersModel);
    };

    const handleCloseCreateEditModal = () => {
        setInitialValue(null);
        destroy()
        setCreateEditModal(false);
    }

    const handleConfirmFilter = () => {
        setFilterModal(false);
    };

    const handleClearFilter = () => {
        clearFilter();
        setFilterModal(false);
    };

    const pageLimits = [25, 50, 100, 250, 500, 1000];

    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
            <Headline color={primaryColor}>{getPageTitle('EXPENSE CATEGORIES')}</Headline>
            <TableHeader>
                <Filters hAlign="space-between" wrapped between={10}>
                    <SearchLayout>
                        <Search
                            type="search"
                            id="search"
                            placeholder="Поиск"
                            onKeyDown={(e) => handleKeyDown(e)}
                            onChange={(e) => cancelSearch(e)}
                        ></Search>
                        <Image src={SearchIcon} height="16px"/>
                    </SearchLayout>
                </Filters>
                <FilterButton
                    id={'expense-categories-filters'}
                    onClick={() => setFilterModal(true)}
                >
                    Фильтры
                </FilterButton>
                <AddButton type="button" id="add-expense-category" onClick={() => setCreateEditModal(true)}>
                    Создать
                </AddButton>
            </TableHeader>
            <TableContainer>
                <ExpenseCategoriesDesktopView
                    isLoading={isLoading}
                    categories={categories}
                    handleEditClick={handleEditClick}
                    handleContext={handleContext}
                    setConfirmDeleteModal={setConfirmDeleteModal}
                    anchorEl={anchorEl}
                    tableHeaders={getTableHeaders}
                />
            </TableContainer>
            <TableContainerMobile>
                <ExpenseCategoriesMobileView
                    isLoading={isLoading}
                    categories={categories}
                    handleEditClick={handleEditClick}
                    setConfirmDeleteModal={setConfirmDeleteModal}
                    tableHeaders={getTableHeaders}
                />
            </TableContainerMobile>
            <TablePaginationView
                prevPage={prevPage}
                nextPage={nextPage}
                pages={categoriesFilters.pages}
                currentPage={categoriesFilters.page}
                pageLimits={pageLimits}
                handleFilterChange={handleFilterChange}
            />
            {createEditModal && (
                <ModalView
                    title={initialValue ? 'Изменить' : 'Создать'}
                    onClose={handleCloseCreateEditModal}
                >
                    <ExpenseCategoriesCreateEditView
                        setModal={setCreateEditModal}
                        initialValue={initialValue}
                    />
                </ModalView>
            )}
            {confirmDeleteModal.isModalOpen && (
                <ModalView
                    title="Удалить категорию расходов"
                    onClose={() => {
                        setConfirmDeleteModal({isModalOpen: false});
                    }}
                >
                    <ConfirmDelete
                        warningText={`Вы уверены, что хотите удалить категорию ${confirmDeleteModal.name}?`}
                        setModal={setConfirmDeleteModal}
                        callback={handleDelete}
                        isLoading={deleteLoading}
                    />
                </ModalView>
            )}
            {filterModal && (
                <ModalView
                    title={'Фильтры'}
                    onClose={() => setFilterModal(false)}
                >
                    <Layout vertical between={10}>
                        <SearchLayout>
                            <Search
                                type="search"
                                id="search"
                                placeholder="Поиск"
                                onKeyDown={(e) => handleKeyDown(e)}
                                onChange={(e) => cancelSearch(e)}
                            ></Search>
                            <Image src={SearchIcon} height="16px"/>
                        </SearchLayout>
                        <Layout hAlign={'space-between'} between={10}>
                            <Button
                                extent={'L'}
                                id={'clear-filter-button'}
                                onClick={handleClearFilter}
                                background={'#333'}
                                color={'red'}
                            >
                                Очистить
                            </Button>
                            <Button
                                extent={'L'}
                                id={'confirm-filter-button'}
                                onClick={handleConfirmFilter}
                            >
                                Потвердить
                            </Button>
                        </Layout>
                    </Layout>
                </ModalView>
            )}
        </Page>
    );
});

export default ExpenseCategoriesView;