import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {renderInputs} from '../../../components/form/render.inputs';
import {Button} from '../../../styles/button';
import {Layout} from '../../../styles/layout';
import {isValid} from '../../../utils/isValid';
import StockModalStore from './stock.modal.store';
import suppliersStore from '../../suppliers/suppliers.store';
import {primaryColor} from '../../../utils/variables';
import {Text} from '../../../styles/text';
import {modalTextColor} from '../../../utils/variables';
import {TextInput} from '../../../styles/form';
import ProductsStore from '../../products/products.store';
import {ProductModel} from '../../products/products.model';
import BusinessUnitsStore from "../../business_units/business_units.store";
import {runInAction} from "mobx";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";
import AppStore from "../../../app.store";
import {Collapse, DatePicker} from "antd";
import ModalView from "../../../components/modal/modal.view";
import GoodsModalView from "./goods.modal.view";

type GoodsModalPropsType = { purpose: 'create'; closeModal: () => void };
const {Panel} = Collapse;

export const GoodsModal = observer(
    ({closeModal}: GoodsModalPropsType) => {
        const {suppliers, suppliersReq} = suppliersStore;
        const {
            form,
            selectedProducts,
            deleteProduct
        } = StockModalStore;
        const {businessUnits, businessUnitsReq} = BusinessUnitsStore;
        const {businessSettings} = AppStore
        const {
            destroyModalDictionary,
            dictionaryListReq,
            getModalPlaceholders,
            getTableHeaders
        } = DictionaryStore;

        const [goodsModal, setGoodsModal] = useState(false)

        useEffect(() => {
            destroyModalDictionary();
            const controller = new AbortController();
            void dictionaryListReq('STOCKS', 'MODAL', controller.signal);
            void dictionaryListReq('PRODUCTS', 'TABLE HEADERS', controller.signal);
            ProductsStore.destroy();
            ProductsStore.setProductsFilter({type_id: 1});
            void suppliersReq(controller.signal);
            void businessUnitsReq(controller.signal)
            return () => StockModalStore.resetFormFields();
        }, [destroyModalDictionary, dictionaryListReq, suppliersReq, businessUnitsReq]);

        const businessUnitOptions = businessUnits?.map(
            ({id, name}) => ({label: name, value: id})
        );
        const suppliersOptions = suppliers.map(({id, name}) => ({
            label: name,
            value: id,
        }));

        const calculateTotal = (goods: ProductModel, id: number) => {
            return goods.goods_id === id
                ? (Number(goods.quantity) * goods.cost_price) | 0
                : 0;
        };

        const grandTotal = selectedProducts!.reduce(
            (total, item) => total + calculateTotal(item, item.goods_id),
            0
        );

        const handleAddSubmit = async () => {
            const res = await StockModalStore.addProduct(ProductsStore.products);
            if (res) {
                StockModalStore.destroy();
                closeModal();
            }
        };

        const inputs = [
            {
                field: StockModalStore.form.business_unit_id,
                options: businessUnitOptions,
                fieldName: 'business_unit_id',
                placeholder: getModalPlaceholders('business_unit_id'),
                type: 'select',
                required: true,
            },

            {
                field: StockModalStore.form.supplier_id,
                options: suppliersOptions,
                fieldName: 'supplier_id',
                placeholder: getModalPlaceholders('supplier_id'),
                type: 'select',
                required: true,
            },
        ];

        const handleChangeTotalAmount = (product: any, e: any) => {
            runInAction(() => {
                product.total_amount = e.target.value;
                if (businessSettings?.changeable_total_amount) {
                    product.cost_price = (Number(e.target.value) / product.quantity).toFixed(2);
                }
            })
        }

        const handleCostChange = (product: any, e: any) => {
            runInAction(() => {
                product.cost_price = e.target.value;
                product.total_amount = product.quantity * product.cost_price;
            })
        }

        const handleProductQuantityChange = (product: any, e: any) => {
            runInAction(() => {
                product.quantity = e.target.value;
                product.total_amount = product.quantity * product.cost_price;
            })
        }

        const handleChangeProductionDate = (product: any, e: any) => {
            runInAction(() => {
                product.production_date = e?.format('YYYY-MM-DD');
            })
        }

        const handleChangeExpiryDate = (product: any, e: any) => {
            runInAction(() => {
                product.expiry_date = e.format('YYYY-MM-DD');
            })
        }

        return (
            <Layout vertical between={20}>
                <Layout
                    vertical
                    hAlign="space-between"
                    between={10}
                >
                    {renderInputs(StockModalStore.form, inputs, StockModalStore.setForm)}
                </Layout>
                <Text
                    style={{textAlign: 'center'}}
                    extent="20px"
                    weight="700"
                    color={primaryColor}
                >
                    {getModalPlaceholders('table_title')}
                </Text>
                {selectedProducts?.map((product: any, index) => (
                    <Collapse
                        defaultActiveKey={['0']}
                        style={{width: '100%'}}
                        accordion
                    >
                        <div
                            style={{
                                position: 'relative',
                                zIndex: '1',
                            }}
                        >
                            <button
                                onClick={() => deleteProduct(index)}
                                style={{
                                    position: 'absolute',
                                    marginTop: '12px',
                                    marginLeft: '380px',
                                }}
                            >
                                X
                            </button>
                        </div>

                        <Panel
                            key={index}
                            header={product.name}
                        >
                            <Layout vertical between={10}>
                                <Layout hAlign={"space-between"} vAlign={"center"}>
                                    <Layout>
                                        <Text color={modalTextColor} extent='S'>
                                            {getTableHeaders('name')}
                                        </Text>
                                    </Layout>
                                    <Layout>
                                        <Text extent='S'>{product.name}</Text>
                                    </Layout>
                                </Layout>
                                <Layout hAlign={"space-between"} vAlign={"center"}>
                                    <Layout>
                                        <Text color={modalTextColor} extent='S'>
                                            {getTableHeaders('part_number')}
                                        </Text>
                                    </Layout>
                                    <Layout>
                                        <Text extent='S'>{product.part_number}</Text>
                                    </Layout>
                                </Layout>
                                <Layout hAlign={"space-between"} vAlign={"center"}>
                                    <Layout>
                                        <Text color={modalTextColor} extent='S'>
                                            {getTableHeaders('brand')}
                                        </Text>
                                    </Layout>
                                    <Layout>
                                        <Text extent='S'>{product.brand}</Text>
                                    </Layout>
                                </Layout>
                                <Layout hAlign={"space-between"} vAlign={"center"}>
                                    <Layout>
                                        <Text color={modalTextColor} extent='S'>
                                            {getTableHeaders('quantity')}
                                        </Text>
                                    </Layout>
                                    <Layout>
                                        <TextInput
                                            value={product.quantity}
                                            onChange={(e) => handleProductQuantityChange(product, e)}
                                        />
                                    </Layout>
                                </Layout>
                                <Layout hAlign={"space-between"} vAlign={"center"}>
                                    <Layout>
                                        <Text color={modalTextColor} extent='S'>
                                            {getTableHeaders('cost')}
                                        </Text>
                                    </Layout>
                                    <Layout>
                                        <TextInput
                                            value={product.cost_price}
                                            onChange={(e) => handleCostChange(product, e)}
                                        />
                                    </Layout>
                                </Layout>
                                <Layout hAlign={"space-between"} vAlign={"center"}>
                                    <Layout>
                                        <Text color={modalTextColor} extent='S'>
                                            {getTableHeaders('amount')}
                                        </Text>
                                    </Layout>
                                    <Layout>
                                        <TextInput
                                            value={product.total_amount}
                                            onChange={(e) => handleChangeTotalAmount(product, e)}
                                        />
                                    </Layout>
                                </Layout>
                                {product.has_expiry && (
                                    <>
                                        <Layout hAlign={"space-between"} vAlign={"center"}>
                                            <Layout>
                                                <Text color={modalTextColor} extent='S'>
                                                    Дата производство
                                                </Text>
                                            </Layout>
                                            <Layout>
                                                <DatePicker
                                                    onChange={(e) => handleChangeProductionDate(product, e)}
                                                />
                                            </Layout>
                                        </Layout>
                                        <Layout hAlign={"space-between"} vAlign={"center"}>
                                            <Layout>
                                                <Text color={modalTextColor} extent='S'>
                                                    Дата окончания
                                                </Text>
                                            </Layout>
                                            <Layout>
                                                <DatePicker
                                                    onChange={(e) => handleChangeExpiryDate(product, e)}
                                                />
                                            </Layout>
                                        </Layout>
                                    </>
                                )}
                            </Layout>
                        </Panel>
                    </Collapse>
                ))}
                <Layout between={20} vAlign="center" hAlign="center">
                    <Layout vAlign="center" between={10}>
                        <Text extent="S" color={modalTextColor}>
                            {getModalPlaceholders('total_amount')}
                        </Text>
                        <TextInput
                            style={{width: '50%'}}
                            type="text"
                            value={grandTotal}
                            onChange={() =>
                                StockModalStore.setForm({
                                    ...form,
                                    total_amount: grandTotal,
                                })
                            }
                        />
                    </Layout>
                </Layout>
                <Layout centered vertical between={10}>
                    <Button
                        extent="S"
                        heightSize="35px"
                        onClick={() => setGoodsModal(true)}
                    >
                        Добавить товар
                    </Button>
                    <Button
                        extent="S"
                        heightSize="35px"
                        isLoading={StockModalStore.isLoading}
                        onClick={handleAddSubmit}
                        disabled={isValid([inputs])}
                    >
                        {StockModalStore.isLoading ? '' : 'Потвердить'}
                    </Button>
                </Layout>
                {goodsModal && (
                    <ModalView
                        title='Выбирете товар'
                        onClose={() => setGoodsModal(false)}
                    >
                        <GoodsModalView
                            setModal={setGoodsModal}
                            getTableHeaders={getTableHeaders}
                        />
                    </ModalView>
                )}
            </Layout>
        );
    }
);

export default GoodsModal;
