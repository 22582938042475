import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import ProductsCreateStore from "./products.create.store";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";
import AppStore from "../../../app.store";
import {renderInputs} from "../../../components/form/render.inputs";
import {Button} from "../../../styles/button";
import {isValid} from "../../../utils/isValid";
import {useEffect} from "react";
import {BusinessUnitModel} from "../../business_units/business_units.model";

interface ProductsCreateAddProductViewProps {
    setModal: (isOpen: boolean) => void;
    businessUnits: BusinessUnitModel[];
}

const ProductsCreateAddProductView = observer(({setModal, businessUnits}:ProductsCreateAddProductViewProps) => {

    const {product, setProductField, addProductToForm, initProduct, destroyProduct} = ProductsCreateStore;
    const {destroyModalDictionary, dictionaryListReq, getModalPlaceholders} = DictionaryStore;
    const {businessSettings} = AppStore;

    useEffect(() => {
        destroyModalDictionary()
        const controller = new AbortController();
        void dictionaryListReq('PRODUCTS', 'MODAL', controller.signal);
        initProduct(businessUnits);

        return () => controller.abort()
    }, [destroyModalDictionary, dictionaryListReq, initProduct]);

    const {
        name,
        description,
        cost,
        price,
        fixed_price,
        part_number,
        category,
        brand,
        bonus_percentage,
        has_expiry,
    } = product;

    const inputs = [
        {
            field: name,
            fieldName: 'name',
            placeholder: getModalPlaceholders('name'),
            type: 'text',
            required: true,
        },
        {
            field: description,
            fieldName: 'description',
            placeholder: getModalPlaceholders('description'),
            type: 'text',
        },
        {
            field: part_number,
            fieldName: 'part_number',
            placeholder: getModalPlaceholders('part_number'),
            type: 'text',
            required: true,
        },
        {
            field: cost,
            fieldName: 'cost',
            placeholder: getModalPlaceholders('cost_goods'),
            type: 'number',
            required: true,
        },
        {
            field: price,
            fieldName: 'price',
            placeholder: getModalPlaceholders('set_price'),
            type: 'number',
            required: true,
        },
        {
            field: fixed_price,
            fieldName: 'fixed_price',
            placeholder: getModalPlaceholders('fixed_price'),
            type: 'switch',
        },
        businessSettings?.employee_bonus && {
            field: bonus_percentage,
            fieldName: 'bonus_percentage',
            placeholder: getModalPlaceholders('bonus_percentage'),
            type: 'number',
            required: false,
        },
        {
            field: category,
            //options: productCategoriesOptions,
            fieldName: 'category',
            placeholder: getModalPlaceholders('category_id'),
            type: 'text',
            required: true,
        },
        {
            field: brand,
           // options: productBrandsOptions,
            fieldName: 'brand',
            placeholder: getModalPlaceholders('brand_id'),
            type: 'text',
            required: true,
        },
        {
            field: has_expiry,
            fieldName: 'has_expiry',
            placeholder: getModalPlaceholders('has_expiry'),
            type: 'switch',
        },
    ];

    const handleConfirm = () => {
        addProductToForm(product);
        setModal(false);
        destroyProduct();
    }

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(product, inputs, setProductField)}
            </Layout>
            <Layout centered>
                <Button
                    extent="S"
                    heightSize="35px"
                    onClick={handleConfirm}
                    disabled={isValid(inputs)}
                >
                    Добавить
                </Button>
            </Layout>
        </Layout>
    );
});

export default ProductsCreateAddProductView;