import styled from 'styled-components';
import { Layout } from '../../styles/layout';
import { Image } from '../../styles/image';

export const PasswordLayout = styled(Layout)`
    position: relative;

    ${Image} {
        position: absolute;
        right: 12px;
        top: calc(50% - 8px);
        cursor: pointer;
        opacity: .7;
        transition: 250ms;

        :hover {
            opacity: 1;
        }
    }
`;