import {StatusModel} from "../statuses.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import {toast} from "react-toastify";
import axios from "axios";
import {api} from "../../../api/endpoints";
import loading = toast.loading;
import {UsersModel} from "../../users/users.model";

const formInitialState: StatusModel = {
    id: 0,
    code: "",
    name: "",
    description: "",
    color: "",
    selected: false,
    can_execute: false,
    can_update: false,
    starting_status: false,
}

const loadingInitialState: boolean = false;

class StatusesCreateEditStore {
    public form = formInitialState;
    public loading = loadingInitialState;
    public message: string = '';
    public error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public setField = (form: StatusModel) => {
        this.form = form;
    }

    public destroyForm = () => {
        this.form = formInitialState;
        this.loading = loadingInitialState;
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    public init = async (initialValue: StatusModel | null) => {
        if (initialValue) {
            this.form = initialValue!;
        } else {
            this.form = formInitialState;
        }
    }

    public createEditStatusReq = async (edit: boolean, statuses: StatusModel[]) => {
        this.setLoading(true);

        const next_statuses = statuses?.length && statuses.filter((status) => status.selected).map((item, _) => ({
            next_status_id: Number(item.id)
        }));

        const payload = {
            id: this.form.id,
            code: this.form.code,
            name: this.form.name,
            description: this.form.description,
            color: this.form.color,
            can_execute: this.form.can_execute,
            can_update: this.form.can_update,
            starting_status: this.form.starting_status,
            next_statuses: next_statuses,
        }

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        try {
            let res;
            if (edit) {
                res = await axios.put(api.statuses, payload, {headers})
            } else {
                res = await axios.post(api.statuses, payload, {headers})
            }

            this.message = res.data.message;
            toast.success(this.message);
            return res;
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }
}

export default new StatusesCreateEditStore();