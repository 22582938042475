import {observer} from "mobx-react";
import {EyeImgIcon, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import {useState} from "react";
import {Layout} from "../../../styles/layout";
import {Text} from "../../../styles/text";
import {Badge} from "../../../styles/badge";
import EyeIcon from "../../../assets/icons/eye.svg";
import {primaryColor} from "../../../utils/variables";
import NoResultTitle from "../../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../../utils/skeleton/table.skeleton";
import ModalView from "../../../components/modal/modal.view";
import {EmployeeDeductionsModel} from "./employee.deductions.model";

interface EmployeeDeductionsMobileViewProps {
    loading: boolean;
    deductions: EmployeeDeductionsModel[] | undefined;
    tableHeaders: (key: string) => string;
}

const EmployeeDeductionsMobileView = observer(({loading, deductions, tableHeaders}:EmployeeDeductionsMobileViewProps) => {
    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<EmployeeDeductionsModel | null>(null);

    const handleRowClick = (allowance: EmployeeDeductionsModel) => {
        setModal(true);
        setInitialValue(allowance);
    };

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    };

    const renderModalItems = (deduction: EmployeeDeductionsModel) => (
        <Layout vertical between={10}>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('deduction')}</Text>
                <Text>{deduction.deduction}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('user_name')}</Text>
                <Text>{deduction.user_name}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('employee')}</Text>
                <Text>{deduction.employee}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('amount')}</Text>
                <Text>{deduction.amount}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('details')}</Text>
                <Text>{deduction.details}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('paid')}</Text>
                <Text>
                    <Badge online={deduction.paid}>
                        {deduction.paid ? 'Да' : 'Нет'}
                    </Badge>
                </Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('date')}</Text>
                <Text>{deduction.date}</Text>
            </Layout>
        </Layout>
    );

    const renderedItems = () => (
        <>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>{tableHeaders('deduction')}</THeadCell>
                        <THeadCell>{tableHeaders('employee')}</THeadCell>
                        <THeadCell>{tableHeaders('amount')}</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                {!loading ? (
                    <TableBody>
                        {deductions?.length ? (
                            deductions?.map((deduction, index) => (
                                <TRow key={index} menu>
                                    <TCell>{deduction.deduction}</TCell>
                                    <TCell>{deduction.employee}</TCell>
                                    <TCell>{deduction.amount}</TCell>
                                    <TCell relative>
                                        <EyeImgIcon
                                            src={EyeIcon}
                                            onClick={() => handleRowClick(deduction)}
                                            height="18px"
                                            color={primaryColor}
                                        />
                                    </TCell>
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={4} />
                        )}
                    </TableBody>
                ) : (
                    <TableSkeleton columns={4} />
                )}
            </Table>
            {modal && (
                <ModalView
                    title={`Удержание ${initialValue?.employee}`}
                    onClose={handleCloseModal}
                >
                    {renderModalItems(initialValue!)}
                </ModalView>
            )}
        </>
    );

    return <Layout>{renderedItems()}</Layout>;
});

export default EmployeeDeductionsMobileView;