import {observer} from "mobx-react";
import {Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import ProductsStore from "../../products/products.store";
import {useEffect} from "react";
import TableSkeleton from "../../../utils/skeleton/table.skeleton";
import StockModalStore from "./stock.modal.store";
import {Layout} from "../../../styles/layout";
import {Search, SearchLayout, TableHeader} from "../../../styles/table-header";
import {Image} from "../../../styles/image";
import SearchIcon from "../../../assets/icons/search.svg";

interface GoodsModalViewProps {
    setModal: (isOpen: boolean) => void;
    getTableHeaders: (key: string) => string;
}

const GoodsModalView = observer(({setModal, getTableHeaders}: GoodsModalViewProps) => {

    const {
        destroy,
        products,
        loading,
        setProductsFilter,
        productsFilter,
        clearFilter
    } = ProductsStore;
    const {selectProduct} = StockModalStore;

    useEffect(() => {
        const controller = new AbortController();
        setProductsFilter({page: 1, page_limit: 9999999, type_id: 1});
        return () => controller.abort();
    }, [setProductsFilter]);

    const handleSelectProduct = (object: any) => {
        clearFilter();
        object.quantity = 1;
        object.cost_price = object.cost
        object.total_amount = object.quantity * object.cost_price;
        selectProduct(object);
        setModal(false);
        destroy()
    };

    const cancelSearch = (e: any) => {
        if (e.target.value === '' && productsFilter.search !== '') {
            setProductsFilter({search: ''});
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            setProductsFilter({search: e.target.value});
        }
    };

    return (
        <>
            <TableHeader>
                <Layout>
                    <SearchLayout>
                        <Search
                            type="search"
                            id="search"
                            placeholder="Поиск"
                            onKeyDown={(e) => handleKeyDown(e)}
                            onChange={(e) => cancelSearch(e)}
                        ></Search>
                        <Image src={SearchIcon} height="16px"/>
                    </SearchLayout>
                </Layout>
            </TableHeader>
            <Table inModal>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>{getTableHeaders('name')}</THeadCell>
                        <THeadCell>{getTableHeaders('part_number')}</THeadCell>
                        <THeadCell>{getTableHeaders('brand')}</THeadCell>
                    </TRow>
                </TableHead>
                {!loading ? (
                    <TableBody>
                        {products!.length
                            ? products?.map((product, index) => (
                                <TRow
                                    key={index}
                                    menu
                                    onClick={() => handleSelectProduct(product)}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <TCell>{product.name}</TCell>
                                    <TCell>{product.part_number}</TCell>
                                    <TCell>{product.brand}</TCell>
                                </TRow>
                            ))
                            : ''}
                    </TableBody>
                ) : (
                    <TableSkeleton columns={3}/>
                )}
            </Table>
        </>
    );
});

export default GoodsModalView;