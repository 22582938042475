import { observer } from 'mobx-react';
import { Layout } from '../../../styles/layout';
import { AccountsModel } from '../account.model';
import AccountsCreateEditStore from './accounts.create.edit.store';
import AccountStore from '../account.store';
import { useEffect } from 'react';
import { renderInputs } from '../../../components/form/render.inputs';
import { Button } from '../../../styles/button';
import { isValid } from '../../../utils/isValid';

interface CreateEditAccountProps {
  initialValue: AccountsModel | null;
  setModal: (isOpen: boolean) => void;
  entityType: string;
  entityId: string;
}

const AccountCreateEditView = observer(
  ({
    initialValue,
    setModal,
    entityType,
    entityId,
  }: CreateEditAccountProps) => {
    const {
      form,
      setField,
      init,
      accountTypeOptions,
      accountCategoryOptions,
      createOrEditAccount,
      isLoading,
      destroy,
    } = AccountsCreateEditStore;

    const { number, type, description, category_id } = form;

    const { accountsReq } = AccountStore;

    useEffect(() => {
      init(initialValue);

      return () => {
        destroy();
      };
    }, [destroy, init, initialValue]);

    const inputs = [
      {
        field: number,
        fieldName: 'number',
        placeholder: 'Номер счета',
        type: 'text',
        required: true,
      },
      {
        field: type,
        options: accountTypeOptions,
        fieldName: 'type',
        placeholder: 'Тип счета',
        type: 'select',
        required: true,
        selectValueType: 'string',
      },
      {
        field: description,
        fieldName: 'description',
        placeholder: 'Описание счета',
        type: 'text',
        required: false,
      },
      {
        field: category_id,
        options: accountCategoryOptions,
        fieldName: 'category_id',
        placeholder: 'Категория счета',
        type: 'select',
        required: true,
      },
    ];

    const handleCreateEditAccount = async () => {
      const res = await createOrEditAccount(
        !!initialValue,
        entityType,
        entityId
      );
      if (res) {
        void accountsReq(false, entityType, entityId);
        setModal(false);
      }
    };

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
      <Layout vertical between={20}>
        <Layout vertical between={10}>
          {renderInputs(form, inputs, setField)}
        </Layout>
        <Layout hAlign="center">
          <Button
            extent="S"
            heightSize="35px"
            isLoading={isLoading}
            onClick={handleCreateEditAccount}
            disabled={isValid([inputs])}
          >
            {isLoading ? '' : renderSubmitText}
          </Button>
        </Layout>
      </Layout>
    );
  }
);

export default AccountCreateEditView;
