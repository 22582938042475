import {observer} from 'mobx-react';
import {Layout} from '../../../styles/layout';
import {useEffect} from 'react';
import ProductsCreateEditStore from './products.create.edit.store';
import {renderInputs} from '../../../components/form/render.inputs';
import {Button} from '../../../styles/button';
import {isValid} from '../../../utils/isValid';
import ProductsStore from '../products.store';
import {ProductModel} from '../products.model';
import AppStore from '../../../app.store';
import DictionaryStore from "../../../utils/dictionary/dictionary.store";

interface ProductCreateEditViewProps {
    initialValue: ProductModel | null;
    setModal: (isOpen: boolean) => void;
}

const ProductsCreateEditView = observer(
    ({initialValue, setModal}: ProductCreateEditViewProps) => {
        const {
            form,
            setField,
            destroy,
            init,
            createUpdateProduct,
            productTypeOptions,
            productCategoriesOptions,
            productBrandsOptions,
            productOutflowOptions,
            productCommissionTypeOptions,
            isLoading,
        } = ProductsCreateEditStore;
        const {
            type_id,
            brand_id,
            category_id,
            name,
            description,
            part_number,
            cost,
            set_price,
            fixed_price,
            outflow_id,
            bonus_percentage,
            // todo remove
            fixed_commission_limit,
            fixed_commission_amount,
            //

            commission_type_id,
            commission,
            fixed_commission,
            commission_limit,
            commission_before_limit,
            commission_after_limit,
            has_expiry,
            min,
            max,
            has_serial
        } = form;
        const {productsReq} = ProductsStore;
        const {businessSettings} = AppStore;
        const {destroyModalDictionary, dictionaryListReq, getModalPlaceholders} = DictionaryStore;

        useEffect(() => {
            destroyModalDictionary();
            const controller = new AbortController();
            void dictionaryListReq('PRODUCTS', 'MODAL', controller.signal);
            init(initialValue);
            return () => {
                destroy();
                controller.abort();
            };
        }, [destroyModalDictionary, dictionaryListReq, init, destroy, initialValue]);

        const initialForm = [
            {
                field: type_id,
                options: productTypeOptions,
                fieldName: 'type_id',
                placeholder: getModalPlaceholders('type_id'),
                type: 'select',
                required: true,
            },
        ];

        // commission types
        const onlyPercentage = 1
        const onlyFixed = 2
        const fixedAmountAndPercentageWithLimit = 3 // gets fixed amount of commission if amount is less than limit, else gets percentage commission
        const doubleFixedWithLimit = 4 // two given commissions are fixed
        //doublePercentageWithLimit = 4 // two given commissions are percentage


        const goods = [
            {
                field: type_id,
                options: productTypeOptions,
                fieldName: 'type_id',
                placeholder: getModalPlaceholders('type_id'),
                type: 'select',
                required: true,
            },
            {
                field: name,
                fieldName: 'name',
                placeholder: getModalPlaceholders('name'),
                type: 'text',
                required: true,
            },
            {
                field: description,
                fieldName: 'description',
                placeholder: getModalPlaceholders('description'),
                type: 'text',
            },
            {
                field: part_number,
                fieldName: 'part_number',
                placeholder: getModalPlaceholders('part_number'),
                type: 'text',
                required: true,
            },
            {
                field: cost,
                fieldName: 'cost',
                placeholder: getModalPlaceholders('cost_goods'),
                type: 'text',
                required: true,
            },
            {
                field: set_price,
                fieldName: 'set_price',
                placeholder: getModalPlaceholders('set_price'),
                type: 'text',
                required: true,
            },
            {
                field: fixed_price,
                fieldName: 'fixed_price',
                placeholder: getModalPlaceholders('fixed_price'),
                type: 'switch',
            },
            businessSettings?.employee_bonus && {
                field: bonus_percentage,
                fieldName: 'bonus_percentage',
                placeholder: getModalPlaceholders('bonus_percentage'),
                type: 'text',
                required: false,
            },
            // businessSettings?.product_commissions && {
            //     field: commission,
            //     fieldName: 'commission',
            //     placeholder: getModalPlaceholders('commission'),
            //     type: 'text',
            //     required: false,
            // },
            {
                field: category_id,
                options: productCategoriesOptions,
                fieldName: 'category_id',
                placeholder: getModalPlaceholders('category_id'),
                type: 'select',
                required: true,
            },
            {
                field: brand_id,
                options: productBrandsOptions,
                fieldName: 'brand_id',
                placeholder: getModalPlaceholders('brand_id'),
                type: 'select',
                required: true,
            },
            {
                field: has_expiry,
                fieldName: 'has_expiry',
                placeholder: getModalPlaceholders('has_expiry'),
                type: 'switch',
            },
            {
                field: has_serial,
                fieldName: 'has_serial',
                placeholder: 'Серийный номер', //todo add to dictionary getModalPlaceholders('has_serial'),
                type: 'switch',
            },
        ];

        const service = [
            {
                field: type_id,
                options: productTypeOptions,
                fieldName: 'type_id',
                placeholder: getModalPlaceholders('type_id'),
                type: 'select',
                required: true,
            },
            {
                field: name,
                fieldName: 'name',
                placeholder: getModalPlaceholders('name'),
                type: 'text',
                required: true,
            },
            {
                field: description,
                fieldName: 'description',
                placeholder: getModalPlaceholders('description'),
                type: 'text',
            },
            {
                field: cost,
                fieldName: 'cost',
                placeholder: getModalPlaceholders('cost_service'),
                type: 'text',
                required: false,
            },
            businessSettings?.employee_bonus && {
                field: bonus_percentage,
                fieldName: 'bonus_percentage',
                placeholder: getModalPlaceholders('bonus_percentage'),
                type: 'text',
                required: false,
            },
            businessSettings?.product_commissions && {
                field: commission_type_id,
                options: productCommissionTypeOptions,
                fieldName: 'commission_type_id',
                placeholder: 'Тип комиссии',
                type: 'select',
                required: false,
            },
            businessSettings?.product_commissions && commission_type_id === onlyPercentage && {
                field: commission,
                fieldName: 'commission',
                placeholder: 'Комиссия (Процент от суммы)',
                type: 'text',
                required: false,
            },
            businessSettings?.product_commissions && commission_type_id === onlyFixed && {
                field: fixed_commission,
                fieldName: 'fixed_commission',
                placeholder: 'Комиссия (Фиксированаая сумма)',
                type: 'text',
                required: false,
            },
            businessSettings?.product_commissions && (commission_type_id === fixedAmountAndPercentageWithLimit || commission_type_id === doubleFixedWithLimit) && {
                field: commission_limit,
                fieldName: 'commission_limit',
                placeholder: 'Лимит для подсчета комиссии до и после',
                type: 'text',
                required: false,
            },
            businessSettings?.product_commissions && (commission_type_id === fixedAmountAndPercentageWithLimit || commission_type_id === doubleFixedWithLimit) && {
                field: commission_before_limit,
                fieldName: 'commission_before_limit',
                placeholder: 'Комиссия до лимита (Фиксированная сумма)',
                type: 'text',
                required: false,
            },
            businessSettings?.product_commissions && (commission_type_id === fixedAmountAndPercentageWithLimit || commission_type_id === doubleFixedWithLimit) && {
                field: commission_after_limit,
                fieldName: 'commission_after_limit',
                placeholder: 'Комиссия после лимита ' + (commission_type_id === fixedAmountAndPercentageWithLimit ? '(Процент от суммы)' : '(Фиксированна сумма)'),
                type: 'text',
                required: false,
            },
            {
                field: outflow_id,
                options: productOutflowOptions,
                fieldName: 'outflow_id',
                placeholder: getModalPlaceholders('outflow_id'),
                type: 'select',
                required: true,
            },
            businessSettings?.product_min_max && {
                field: min,
                fieldName: 'min',
                placeholder: getModalPlaceholders('min'),
                type: 'text',
                required: false,
            },
            businessSettings?.product_min_max && {
                field: max,
                fieldName: 'max',
                placeholder: getModalPlaceholders('max'),
                type: 'text',
                required: false,
            },
        ];

        const handleCreateUpdateProduct = async () => {
            const res = await createUpdateProduct(!!initialValue);
            if (res) {
                setModal(false);
                productsReq();
            }
        };

        const validation = () => {
            switch (type_id) {
                case 1:
                    return isValid([initialForm, goods]);
                case 2:
                    return isValid([initialForm, service]);
                default:
                    return true;
            }
        };

        const renderForm = (product_type_id: number) => {
            switch (product_type_id) {
                case 1:
                    return renderInputs(form, goods, setField);
                case 2:
                    return renderInputs(form, service, setField);
                default:
                    return renderInputs(form, initialForm, setField);
            }
        };

        const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

        return (
            <Layout vertical between={20}>
                <Layout vertical between={10}>
                    {renderForm(form.type_id)}
                </Layout>
                <Layout hAlign="center">
                    <Button
                        extent="S"
                        heightSize="35px"
                        isLoading={isLoading}
                        onClick={handleCreateUpdateProduct}
                        disabled={validation()}
                    >
                        {isLoading ? '' : renderSubmitText}
                    </Button>
                </Layout>
            </Layout>
        );
    }
);

export default ProductsCreateEditView;
