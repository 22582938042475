import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {Search, SearchLayout, Select} from "../../../styles/table-header";
import {Image} from "../../../styles/image";
import SearchIcon from "../../../assets/icons/search.svg";
import usersStore from "../../users/users.store";
import {useEffect} from "react";

interface EmployeeAllowancesFilterViewProps {
    handleKeyDown: (e: any) => void;
    cancelSearch: (e: any) => void;
    handleFilterChange: (e: any, key: string) => void;
    inModal: boolean;
}

const EmployeeAllowancesFilterView = observer(({
                                                   handleKeyDown,
                                                   cancelSearch,
                                                   handleFilterChange,
                                                   inModal,
                                               }: EmployeeAllowancesFilterViewProps) => {

    const {users, setUsersFilter} = usersStore;

    useEffect(() => {
        const controller = new AbortController();
        setUsersFilter({page: 1, page_limit: 999999});

        return () => {
            controller.abort();
        }

    }, [setUsersFilter]);

    const paidOptions = [
        {
            label: 'Оплачено',
            value: 'true'
        },
        {
            label: 'Неоплачено',
            value: 'false'
        }
    ];

    return (
        <>
            <Layout between={10} wrapped vAlign={"center"} vertical={inModal}>
                <SearchLayout>
                    <Search
                        type="search"
                        id="search"
                        placeholder="Поиск"
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={(e) => cancelSearch(e)}
                    />
                    <Image src={SearchIcon} height="16px"/>
                </SearchLayout>
                <Select
                    id="users-filter"
                    onChange={(e) => handleFilterChange(e, 'user_id')}
                >
                    <option value=''>Создан</option>
                    {users?.map((user) => (
                        <option value={user.id} key={user.id}>
                            {`${user.surname} ${user.name}`}
                        </option>
                    ))}
                </Select>
                <Select
                    id="employees-filter"
                    onChange={(e) => handleFilterChange(e, 'employee_id')}
                >
                    <option value=''>Сотрудник</option>
                    {users?.map((user) => (
                        <option value={user.id} key={user.id}>
                            {`${user.surname} ${user.name}`}
                        </option>
                    ))}
                </Select>
                <Select
                    id="paid-filter"
                    onChange={(e) => handleFilterChange(e, 'paid')}
                >
                    <option value=''>Все</option>
                    {paidOptions?.map((paid) => (
                        <option value={paid.value} key={paid.value}>
                            {`${paid.label}`}
                        </option>
                    ))}
                </Select>
            </Layout>
        </>
    );
});

export default EmployeeAllowancesFilterView;