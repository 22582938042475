import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {StatusModel} from "./statuses.model";
import StatusesStore from "./statuses.store";
import {useEffect} from "react";
import {Text} from "../../styles/text";
import {TextInput} from "../../styles/form";
import {runInAction} from "mobx";
import {Button} from "../../styles/button";

interface RolesModalViewProps {
    initialValue: StatusModel | null;
    setModal: (isOpen: boolean) => void
}

const RolesModalView = observer(({initialValue, setModal}: RolesModalViewProps) => {
    const {statusRolesReq, statusRoles, editStatusRoles, editRolesLoading} = StatusesStore;

    useEffect(() => {
        const controller = new AbortController();
        void statusRolesReq(initialValue?.id!, controller.signal);
        setTimeout(() => {
            handleExistingRoles()
        }, 1000)

        return () => {
            controller.abort();
        }
    }, [statusRolesReq]);

    const handleExistingRoles = () => {
        for (let i = 0; i < statusRoles.length; i++) {
            runInAction(() => {
                statusRoles[i].selected = statusRoles[i].status_id === initialValue?.id!;
            })
        }
    }

    const handleCheckboxClick = (index: number) => {
        runInAction(() => {
            statusRoles[index].selected = !statusRoles[index].selected
        })
    }

    const handleSubmitClick = async () => {
        const response = await editStatusRoles(initialValue?.id!)

        if (response) {
            setModal(false)
        }
    }

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {statusRoles.map((role, index) => (
                    <Layout
                        key={index}
                        hAlign={'flex-start'}
                        between={15}
                        style={{
                            width: '100%',
                            boxShadow: '0 0 5px grey',
                            padding: '15px',
                            borderRadius: '5px',
                            marginBottom: '5px',
                        }}
                        onClick={() => handleCheckboxClick(index)}
                    >
                        <Layout widthSize={'5%'}>
                            <TextInput
                                type={'checkbox'}
                                checked={role.selected}
                            />
                        </Layout>
                        <Layout widthSize={'95%'}>
                            <Text extent={'S'}>{role.roles_name}</Text>
                        </Layout>

                    </Layout>
                ))}
            </Layout>
            <Layout centered>
                <Button
                    onClick={handleSubmitClick}
                    disabled={editRolesLoading}
                    isLoading={editRolesLoading}
                >
                    {editRolesLoading ? '' : 'Потвердить'}
                </Button>
            </Layout>
        </Layout>
    );
});

export default RolesModalView;