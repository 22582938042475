import {observer} from "mobx-react";
import {Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../styles/table";
import {Layout} from "../../styles/layout";
import {Text} from "../../styles/text";
import {modalTextColor, primaryColor} from "../../utils/variables";
import StockStore from "./stock.store";
import {useEffect} from "react";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";

interface StocksExpiryDateViewProps {
    initialValue: {
        name: string;
        business_unit: string;
        goods_id: number;
        business_unit_id: number;
    } | null
}

const StocksExpiryDateView = observer(({initialValue}: StocksExpiryDateViewProps) => {

    const {expiryDates, getExpiryDatesReq, expiryLoading} = StockStore;

    useEffect(() => {
        const controller = new AbortController();
        void getExpiryDatesReq(initialValue!.goods_id, initialValue!.business_unit_id, controller.signal);
        return () => controller.abort();
    }, [getExpiryDatesReq]);

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Layout widthSize='35%'>
                        <Text color={modalTextColor} extent={'S'}>Продукт</Text>
                    </Layout>
                    <Layout widthSize='65%'>
                        <Text extent={'S'}>{initialValue!.name}</Text>
                    </Layout>
                </Layout>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Layout widthSize='35%'>
                        <Text color={modalTextColor} extent={'S'}>Бизнес точка</Text>
                    </Layout>
                    <Layout widthSize='65%'>
                        <Text extent={'S'}>{initialValue!.business_unit}</Text>
                    </Layout>
                </Layout>
            </Layout>
            <Layout vertical between={5}>
                <Layout centered>
                    <Text color={primaryColor} extent={'M'} weight={'bold'}>
                        Подробнее
                    </Text>
                </Layout>
                <Table inModal>
                    <TableHead>
                        <TRow tableHead>
                            <THeadCell>Дата производства</THeadCell>
                            <THeadCell>Дата окончания</THeadCell>
                            <THeadCell>Количество</THeadCell>
                        </TRow>
                    </TableHead>
                    {expiryLoading ? (<TableSkeleton columns={3}/>) : (
                        <TableBody>
                            {expiryDates!.length ? (
                                expiryDates.map((expiry, index) => (
                                    <TRow key={index} menu>
                                        <TCell>{expiry.production_date}</TCell>
                                        <TCell>{expiry.expiry_date}</TCell>
                                        <TCell>{expiry.quantity}</TCell>
                                    </TRow>
                                ))
                            ) : (
                                <NoResultTitle colspan={3}/>
                            )}
                        </TableBody>
                    )}
                </Table>
            </Layout>
        </Layout>
    );
});

export default StocksExpiryDateView;