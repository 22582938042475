import {StatusModel, StatusRoles} from './statuses.model'
import {makeAutoObservable} from "mobx";
import AppStore from "../../app.store";
import axios from "axios";
import {api} from "../../api/endpoints";
import {toast} from "react-toastify";

const statusesInitialState: StatusModel[] = [];
const loadingInitialState: boolean = false;
const statusesAfterInitialState: StatusModel[] = [];
const statusRolesInitialState: StatusRoles[] = [];

class StatusesStore {
    public pageLoading = loadingInitialState;
    public statusesAfterLoading = loadingInitialState;
    public statuses? = statusesInitialState;
    public statusesAfter = statusesAfterInitialState;
    public statusRoles = statusRolesInitialState;
    public editRolesLoading = loadingInitialState
    public message: string = '';
    public error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public statusesPageInit = async (technical: boolean, signal?: AbortSignal) => {
        await this.statusesReq(technical, signal);
    }

    setStatusRoles(data: any) {
        const {transaction_statuses} = data;
        this.statusRoles = transaction_statuses;
    }

    setStatuses(data: any) {
        const {statuses} = data;
        this.statuses = statuses;
    }

    setStatusesAfter(data: any) {
        const {statuses} = data;
        this.statusesAfter = statuses;
    }

    setLoading(loading: boolean) {
        this.pageLoading = loading;
    }

    setStatusesAfterLoading(loading: boolean) {
        this.statusesAfterLoading = loading;
    }

    public destroy = () => {
        this.statuses = statusesInitialState;
        this.statusesAfter = statusesAfterInitialState;
        this.pageLoading = loadingInitialState;
        this.statusesAfterLoading = loadingInitialState;
        this.statusRoles = statusRolesInitialState;
    }

    public statusRolesReq = async (statusID: number, signal?: AbortSignal) => {
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        try {
            const response = await axios.get(api.status_roles + `?status_id=${statusID}`, {headers, signal});
            this.setStatusRoles(response.data);
        } catch (e: any) {
            this.error = e?.response?.data?.reason;
            toast.error(this.error);
        } finally {
        }
    }

    public statusesReq = async (technical: boolean, signal?: AbortSignal) => {
        this.setLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        try {
            const response = await axios.get(api.statuses + `?technical=${technical}`, {headers, signal});
            this.setStatuses(response.data);

        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }

    public deleteStatusReq = async (id: number) => {
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        try {
            const response = await axios.delete(api.statuses + `/${id}`, {headers});
            this.message = response.data.message;
            toast.success(this.message);
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        }

        await this.statusesReq(false);
    }

    public statusesAfterReq = async (id: number) => {
        this.setStatusesAfterLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        try {
            const response = await axios.get(api.statuses + `/${id}`, {headers});
            this.setStatusesAfter(response.data);
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setStatusesAfterLoading(false);
        }
    }

    public editStatusRoles = async (statusID: number) => {
        this.editRolesLoading = true;

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        console.log('this.statusRoles:', this.statusRoles)

        const selectedIds = this.statusRoles
            .filter(role => role.selected)
            .map(role => role.role_id);

        const payload = {
            role_ids: selectedIds
        }

        try {
            const response = await axios.post(api.status_roles + `/${statusID}`, payload, {headers});
            this.message = response.data.transaction_statuses;
            toast.success(this.message);
            return response;
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.editRolesLoading = false;
        }
    }
}

export default new StatusesStore();