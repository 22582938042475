import {FinReportFilterModel, FinReportsModel} from "./fin.reports.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import axios from "axios";
import {api} from "../../../api/endpoints";
import {toast} from "react-toastify";

const reportsInitialState: FinReportsModel[] = [];
const filtersInitialState: FinReportFilterModel = {
    report_type: 1,
    entity_id: 0,
    period: 0,
    date_from: '',
    date_to: '',
    order_by: '',
    order_asc: '',
}
const loadingInitialState: boolean = false;
const totalsInitialState: FinReportsModel = {
    entity_name: '',
    sales: 0,
    cost: 0,
    sales_profit: 0,
    other_expenses: 0,
    salary: 0,
    bonuses: 0,
    saldo: 0,
}

class FinReportsStore {
    public finReports = reportsInitialState;
    public totals = totalsInitialState;
    public finReportsFilters = filtersInitialState;
    public loading = loadingInitialState;
    error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public clearFilter = () => {
        this.finReportsFilters = filtersInitialState;
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    setReports(data: any) {
        const {resp, total} = data;
        this.finReports = resp;
        this.totals = total;
    }

    public getFinReportsReq = async (signal?: AbortSignal) => {
        this.setLoading(true);

        const {getToken} = AppStore

        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        const filterUrl = Object.keys(this.finReportsFilters).reduce((filters, filter) => {
            const symbol = filters ? '&' : '?';
            const filterKeyName = filter as keyof FinReportFilterModel;
            if (this.finReportsFilters[filterKeyName] !== 0 &&
                this.finReportsFilters[filterKeyName] !== '') {
                filters += symbol + filter + '=' + this.finReportsFilters[filterKeyName];
            }

            return filters;
        }, '');

        try {
            const response = await axios.get(api.fin_reports + filterUrl, {headers, signal});
            this.setReports(response.data);
        } catch (e: any) {
            this.error = e?.response?.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }

    public setFinReportFilters = ({
                                      entity_id,
                                      report_type,
                                      date_to,
                                      date_from,
                                      period,
                                      order_asc,
                                      order_by
                                  }: FinReportFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.finReportsFilters = {
            ...this.finReportsFilters,
            entity_id: isUndefined(entity_id) ? this.finReportsFilters.entity_id : entity_id!,
            report_type: isUndefined(report_type) ? this.finReportsFilters.report_type : report_type!,
            date_to: isUndefined(date_to) ? this.finReportsFilters.date_to : date_to!,
            date_from: isUndefined(date_from) ? this.finReportsFilters.date_from : date_from!,
            period: isUndefined(period) ? this.finReportsFilters.period : period!,
            order_asc: isUndefined(order_asc) ? this.finReportsFilters.order_asc : order_asc!,
            order_by: isUndefined(order_by) ? this.finReportsFilters.order_by : order_by!,
        }

        void this.getFinReportsReq()
    }
}

export default new FinReportsStore();