import { observer } from 'mobx-react';
import settingsCratePasswordStore from './settings.crate.password.store';
import { Layout } from '../../../styles/layout';
import { Button } from '../../../styles/button';
import { renderPassword } from './../../form/password.viet';
import { Text } from '../../../styles/text';
import { errorColor } from '../../../utils/variables';
import { useState, useEffect } from 'react';
interface SettingsChangePassword {
  setModal: (isOpen: boolean) => void;
}

const SettingsCreatePassword = observer(
  ({ setModal }: SettingsChangePassword) => {
    const { isLoading, changePasswordReq, error, success, destroy } =
      settingsCratePasswordStore;

    const { form } = settingsCratePasswordStore;

    const inputs = [
      {
        field: form.old_password,
        value: form.old_password,
        fieldName: 'old_password',
        placeholder: 'Текущий пароль',
        type: 'password',
        required: true,
      },
      {
        field: form.new_password,
        value: form.new_password,
        fieldName: 'new_password',
        placeholder: 'Новый пароль',
        type: 'password',
        required: true,
      },
      {
        field: form.repeat_new_password,
        value: form.repeat_new_password,
        fieldName: 'repeat_new_password',
        placeholder: 'Повторить пароль',
        type: 'password',
        required: true,
      },
    ];
    const [formValid, setFormValid] = useState(false);

    useEffect(() => {
      inputs.map((input) => {
        if (input.value === '') {
          setFormValid(false);
        } else if (input.value !== '') {
          setFormValid(true);
        }
      });
    });

    useEffect(() => {
      return () => {
        setFormValid(false);
        destroy();
      };
    }, [destroy]);

    const renderSubmitText = 'Изменить пароль';

    const handleChange = (object: any) => {
      settingsCratePasswordStore.changeForm(object);
    };

    const handleChangePassword = async () => {
      await changePasswordReq();
    };
    if (success) {
      setModal(false);
    } else {
      setModal(true);
    }

    return (
      <Layout vertical between={15}>
        <Layout vertical between={10}>
          {renderPassword(form, inputs, handleChange)}
          <Text style={{ textAlign: 'center' }} extent="S" color={errorColor}>
            {error}
          </Text>

          <Layout hAlign="center">
            <Button
              extent="S"
              heightSize="35px"
              isLoading={isLoading}
              disabled={!formValid}
              onClick={handleChangePassword}
            >
              {isLoading ? '' : renderSubmitText}
            </Button>
          </Layout>
        </Layout>
      </Layout>
    );
  }
);

export default SettingsCreatePassword;
