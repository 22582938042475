import styled from "styled-components";
import { Layout } from "./layout";
import {media} from "../utils/variables";

export const Filters = styled(Layout)`
  width: 100%;
  
  ${media.mobile} {
    display: none;
  }

  ${media.tablet} {
    display: none;
  }
`;

export const FiltersTransactions = styled(Layout)`
  width: fit-content;
`;
