import {observer} from 'mobx-react';
import {useState} from 'react';
import {Layout} from '../../styles/layout';
import {Text} from '../../styles/text';
import {Button} from '../../styles/button';
import {
    EyeImgIcon,
    Table,
    TableBody,
    TableHead,
    TCell,
    THeadCell,
    TRow,
} from '../../styles/table';
import EyeIcon from '../../assets/icons/eye.svg';
import {primaryColor} from '../../utils/variables';
import NoResultTitle from '../../components/no_result_title/no_result_title.view';
import TableSkeleton from '../../utils/skeleton/table.skeleton';
import ModalView from '../../components/modal/modal.view';
import {ProductModel} from './products.model';
import AppStore from '../../app.store';

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface ProductsTableMobileViewProps {
    loading: boolean;
    products: ProductModel[] | undefined;
    handleEditClick: (user: ProductModel) => void;
    setConfirmDeleteModal: ({
                                isModalOpen,
                                id,
                                name,
                            }: setConfirmDeleteModalProps) => void;
    tableHeaders: (key: string) => string;
}

const ProductsMobileView = observer(
    ({
         loading,
         products,
         handleEditClick,
         setConfirmDeleteModal,
         tableHeaders
     }: ProductsTableMobileViewProps) => {
        const [modal, setModal] = useState(false);
        const [initialValue, setInitialValue] = useState<ProductModel | null>(null);
        const {businessSettings} = AppStore;

        const handleRowClick = (product: ProductModel) => {
            setModal(true);
            setInitialValue(product);
        };

        const handleCloseModal = () => {
            setModal(false);
            setInitialValue(null);
        };

        const renderModalItems = (product: ProductModel) => (
            <Layout vertical between={10}>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('name')}</Text>
                    <Text>{product.name}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('part_number')}</Text>
                    <Text>{product.part_number}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('type')}</Text>
                    <Text>{product.type}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('category')}</Text>
                    <Text>{product.category}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('brand')}</Text>
                    <Text>{product.brand}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('cost')}</Text>
                    <Text>{product.cost}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('profitability')}</Text>
                    <Text>{product.profitability}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('set_price')}</Text>
                    <Text>{product.set_price}</Text>
                </Layout>
                {businessSettings?.employee_bonus && (
                    <Layout hAlign={'space-between'}>
                        <Text weight={'bold'}>{tableHeaders('bonus_percentage')}</Text>
                        <Text>{product.bonus_percentage}</Text>
                    </Layout>
                )}
                {businessSettings?.product_commissions && (
                    <Layout hAlign={'space-between'}>
                        <Text weight={'bold'}>{tableHeaders('commission')}</Text>
                        <Text>{product.commission}</Text>
                    </Layout>
                )}
                <Layout hAlign={'center'} between={5}>
                    <Button onClick={() => handleEditClick(product)}>Изменить</Button>
                    <Button
                        background={'rgb(146,4,4)'}
                        onClick={() =>
                            setConfirmDeleteModal({
                                isModalOpen: true,
                                id: product.id,
                                name: product.name,
                            })
                        }
                    >
                        Удалить
                    </Button>
                </Layout>
            </Layout>
        );

        const renderedItems = () => (
            <>
                <Table>
                    <TableHead>
                        <TRow tableHead>
                            <THeadCell>{tableHeaders('name')}</THeadCell>
                            <THeadCell>{tableHeaders('brand')}</THeadCell>
                            <THeadCell>{tableHeaders('set_price')}</THeadCell>
                            <THeadCell></THeadCell>
                        </TRow>
                    </TableHead>
                    {!loading ? (
                        <TableBody>
                            {products?.length ? (
                                products?.map((product, index) => (
                                    <TRow key={index} menu>
                                        <TCell>{product.name}</TCell>
                                        <TCell>{product.brand}</TCell>
                                        <TCell>{product.set_price}</TCell>
                                        <TCell relative>
                                            <EyeImgIcon
                                                src={EyeIcon}
                                                onClick={() => handleRowClick(product)}
                                                height="18px"
                                                color={primaryColor}
                                            />
                                        </TCell>
                                    </TRow>
                                ))
                            ) : (
                                <NoResultTitle colspan={4}/>
                            )}
                        </TableBody>
                    ) : (
                        <TableSkeleton columns={4}/>
                    )}
                </Table>
                {modal && (
                    <ModalView
                        title={`Данные продукта ${initialValue?.name}`}
                        onClose={handleCloseModal}
                    >
                        {renderModalItems(initialValue!)}
                    </ModalView>
                )}
            </>
        );

        return <Layout>{renderedItems()}</Layout>;
    }
);

export default ProductsMobileView;
