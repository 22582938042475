import { observer } from 'mobx-react';
import { Layout } from '../../../styles/layout';
import { AddButton } from '../../../styles/table-header';
import { useState } from 'react';
import ModalView from '../../../components/modal/modal.view';
import CreateOperationView from './create.operation.view';
import CreateOperationStore from "./create.operation.store";

const OperationView = observer(() => {
  const [modal, setModal] = useState(false);
  const {destroy} = CreateOperationStore;

    const handleCloseModal = () => {
        setModal(false);
        destroy();
    };


    return (
    <Layout>
      <AddButton type="button" onClick={() => setModal(true)}>
        Создать операцию
      </AddButton>
      {modal && (
        <ModalView title={'Создать операцию'} onClose={handleCloseModal}>
          <CreateOperationView initialValue={null} setModal={setModal} />
        </ModalView>
      )}
    </Layout>
  );
});

export default OperationView;
