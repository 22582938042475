import {observer} from 'mobx-react';
import {Layout} from '../../styles/layout';
import NoResultTitle from '../../components/no_result_title/no_result_title.view';
import {Text} from '../../styles/text';
import {Badge} from '../../styles/badge';
import ModalView from '../../components/modal/modal.view';
import {
    EyeImgIcon,
    Table,
    TableBody,
    TableHead,
    TCell,
    THeadCell,
    TRow,
} from '../../styles/table';
import {Button} from '../../styles/button';
import EyeIcon from '../../assets/icons/eye.svg';
import TableSkeleton from '../../utils/skeleton/table.skeleton';
import {TransferModel} from './transfer.model';
import {useState} from 'react';

interface TransactionsExtendedTableMobileViewProps {
    loading: boolean;
    transfers: TransferModel[] | undefined;
    handleTransferProductClick: (transfers: TransferModel) => void;
    tableHeaders: (key: string) => string;
}

const TransfersTableMobileView = observer(
    ({
         loading,
         transfers,
         handleTransferProductClick,
         tableHeaders
     }: TransactionsExtendedTableMobileViewProps) => {
        const [initialValue, setInitialValue] = useState<TransferModel | null>(
            null
        );
        const [modal, setModal] = useState(false);

        const handleAddClick = (transfer: TransferModel) => {
            setModal(true);
            setInitialValue(transfer);
        };

        const handleCloseModal = () => {
            setModal(false);
            setInitialValue(null);
        };

        const renderModalItems = (transfers: TransferModel) => (
            <Layout vertical between={10}>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('owner_transfer_id')}</Text>
                    <Text>{transfers.owner_transfer_id}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('from_business_unit')}</Text>
                    <Text>{transfers.from_business_unit}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('to_business_unit')}</Text>
                    <Text>{transfers.to_business_unit}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('details')}</Text>
                    <Text>{transfers.details}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('delivered')}</Text>
                    <Text>
                        <Badge online={transfers.delivered}>
                            {transfers.delivered ? 'Да' : 'Нет'}
                        </Badge>
                    </Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('date')}</Text>
                    <Text>{transfers.date}</Text>
                </Layout>
                <Layout hAlign="center" between={5}>
                    <Button onClick={() => handleTransferProductClick(transfers)}>
                        Контакты
                    </Button>
                </Layout>
            </Layout>
        );

        const renderedItem = () => (
            <>
                <Table>
                    <TableHead>
                        <TRow tableHead>
                            <THeadCell>{tableHeaders('user_name')}</THeadCell>
                            <THeadCell>{tableHeaders('from_business_unit')}</THeadCell>
                            <THeadCell>{tableHeaders('to_business_unit')}</THeadCell>
                            <THeadCell></THeadCell>
                        </TRow>
                    </TableHead>
                    {loading ? (
                        <TableSkeleton columns={4}/>
                    ) : (
                        <TableBody>
                            {transfers?.length ? (
                                transfers?.map((transfer, index) => (
                                    <TRow key={index} menu>
                                        <TCell>{transfer.user_name}</TCell>
                                        <TCell>{transfer.from_business_unit}</TCell>
                                        <TCell>{transfer.to_business_unit}</TCell>
                                        <TCell relative>
                                            <EyeImgIcon
                                                src={EyeIcon}
                                                height="18px"
                                                onClick={() => handleAddClick(transfer)}
                                            />
                                        </TCell>
                                    </TRow>
                                ))
                            ) : (
                                <NoResultTitle colspan={4}/>
                            )}
                        </TableBody>
                    )}
                </Table>
                {modal && (
                    <ModalView
                        title={`Данные перевода №${initialValue?.owner_transfer_id}`}
                        onClose={handleCloseModal}
                    >
                        {renderModalItems(initialValue!)}
                    </ModalView>
                )}
            </>
        );

        return <Layout>{renderedItem()}</Layout>;
    }
);

export default TransfersTableMobileView;
