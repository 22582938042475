import { observer } from 'mobx-react';
import {
  ContextMenu,
  ContextMenuItem,
  Table,
  TableBody,
  TableContainer,
  TableContainerMobile,
  TableHead,
  TCell,
  THeadCell,
  TRow,
} from '../../styles/table';
import { PageView } from '../../styles/page';
import { useEffect, useState } from 'react';
import { AddButton } from '../../styles/table-header';

import Modal from '../../components/modal/modal.view';
import AccountModal from '../../components/modal/account.modal.view';
import { Layout } from '../../styles/layout';
import { Image } from '../../styles/image';
import ContextMenuIcon from '../../assets/icons/context-menu.svg';
import ConfirmDelete, {
  DeleteItem,
} from '../../components/confirm/confirm.delete';
import NoResultTitle from '../../components/no_result_title/no_result_title.view';
import TableSkeleton from '../../utils/skeleton/table.skeleton';
import clientsContactsStore from './clients.contacts.store';
import { ClientContactsModel } from './client.contacts.modal/clients.contacts.model';
import ClientContactsCreateEditView from './client.contacts.modal/clients.contacts.create.view';
import ModalView from '../../components/modal/modal.view';
import ClientsContactsTableMobileView from './clients.contacts.mobile.view';
import DictionaryStore from "../../utils/dictionary/dictionary.store";
interface ClientContactsViewProps {
  entityType: string;
  entityId: string;
}
const ClientsContactsView = observer(
  ({ entityId }: ClientContactsViewProps) => {
    const [modal, setModal] = useState(false);

    const [initialValue, setInitialValue] =
      useState<ClientContactsModel | null>(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<DeleteItem>({
      isModalOpen: false,
    });
    const {
      clientsContacts,
      loading,
      clientsContactsPageInit,
      deleteClientContacts,
    } = clientsContactsStore;
    const {destroyDictionary, getTableHeaders, dictionaryListReq} = DictionaryStore;

    useEffect(() => {
      destroyDictionary()
      const controller = new AbortController();
      dictionaryListReq('CLIENT CONTACTS', 'TABLE HEADERS', controller.signal)
      clientsContactsPageInit(entityId, controller.signal);

      return () => {
        controller.abort();
      };
    }, [destroyDictionary, dictionaryListReq, clientsContactsPageInit]);

    const handleContext = (e: any) => {
      e.stopPropagation();
      document.querySelector('.context-menu-focused');
      if (e.target.classList.contains('context-menu-focused')) {
        e.target.classList.remove('context-menu-focused');
        setAnchorEl(null);
      } else {
        setAnchorEl(e.currentTarget);
        e.target.classList.add('context-menu-focused');
      }
    };
    const handleDelete = async () => {
      await deleteClientContacts(confirmDeleteModal.id!, entityId);
      setConfirmDeleteModal({ isModalOpen: false });
    };

    const handleEditClick = (contact: ClientContactsModel) => {
      setInitialValue(contact);
      setModal(true);
    };

    const handleAddClick = () => {
      setInitialValue(null);
      setModal(true);
    };

    return (
      <PageView isNavOpen={false} vertical between={10}>
        <>
          <Layout between={10} wrapped hAlign="flex-end">
            <AddButton type="button" id="add-client" onClick={handleAddClick}>
              Создать
            </AddButton>
          </Layout>
          <TableContainer>
            <Table>
              <TableHead>
                <TRow tableHead>
                  <THeadCell>{getTableHeaders('name')}</THeadCell>
                  <THeadCell>{getTableHeaders('phone')}</THeadCell>
                  <THeadCell>{getTableHeaders('extra_phone')}</THeadCell>
                  <THeadCell>{getTableHeaders('address')}</THeadCell>
                  <THeadCell>{getTableHeaders('relationship')}</THeadCell>
                  <THeadCell></THeadCell>
                </TRow>
              </TableHead>
              {!loading ? (
                <TableBody>
                  {clientsContacts?.length ? (
                    clientsContacts?.map((contact, index) => (
                      <TRow key={index} menu>
                        <TCell>{`${contact.name}  ${contact.surname}`}</TCell>
                        <TCell>{contact.phone}</TCell>
                        <TCell>{contact.extra_phone}</TCell>
                        <TCell>{contact.address}</TCell>
                        <TCell>{contact.relationship}</TCell>
                        <TCell relative>
                          <Image
                            src={ContextMenuIcon}
                            height="18px"
                            onClick={handleContext}
                          />
                          <ContextMenu anchorEl={anchorEl}>
                            <ContextMenuItem
                              onClick={() => {
                                handleEditClick(contact);
                              }}
                            >
                              Изменить
                            </ContextMenuItem>
                            <ContextMenuItem
                              onClick={() =>
                                setConfirmDeleteModal({
                                  isModalOpen: true,
                                  id: contact.id,
                                })
                              }
                            >
                              Удалить
                            </ContextMenuItem>
                          </ContextMenu>
                        </TCell>
                      </TRow>
                    ))
                  ) : (
                    <NoResultTitle colspan={6} />
                  )}
                </TableBody>
              ) : (
                <TableSkeleton columns={6} />
              )}
            </Table>
          </TableContainer>
          <TableContainerMobile>
            <ClientsContactsTableMobileView
              contacts={clientsContacts}
              loading={loading}
              handleAccountClick={handleAddClick}
              handleEditClick={handleEditClick}
              setConfirmDeleteModal={setConfirmDeleteModal}
              tableHeaders={getTableHeaders}
            />
          </TableContainerMobile>
        </>
        {modal && (
          <ModalView onClose={() => setModal(false)}>
            <Layout>
              <AccountModal
                hasContext={true}
                title={initialValue ? 'Изменить контакт' : 'Создать контакт'}
                onClose={() => {
                  setModal(false);
                }}
              >
                <ClientContactsCreateEditView
                  initialValue={initialValue}
                  setModal={setModal}
                  entityId={entityId}
                />
              </AccountModal>
            </Layout>
          </ModalView>
        )}
        {confirmDeleteModal.isModalOpen && (
          <Modal
            title="Удалить котакть"
            onClose={() => {
              setConfirmDeleteModal({ isModalOpen: false });
            }}
          >
            <ConfirmDelete
              warningText={`Вы уверены, что хотите удалить котакты?`}
              setModal={setConfirmDeleteModal}
              callback={handleDelete}
              isLoading={loading}
            />
          </Modal>
        )}
      </PageView>
    );
  }
);

export default ClientsContactsView;
