import {
  SupplierBrandsModel,
  SuppliersFilterModel,
  SuppliersModel,
} from './suppliers.model';
import { makeAutoObservable } from 'mobx';
import axios, { GenericAbortSignal } from 'axios';
import AppStore from '../../app.store';
import { api } from '../../api/endpoints';
import { toast } from 'react-toastify';

const suppliersFilterInitialState: SuppliersFilterModel = {
  search: '',
  country_id: 0,
  page: 1,
  page_limit: 25,
  pages: 0,
  order_by: '',
  order_descending: true,
  technical: false,
};

const suppliersInitialState: SuppliersModel[] = [];
const loadingInitialState: boolean = false;
const supplierBrandsInitialState: SupplierBrandsModel[] = [];
const supplierBrandsLoadingInitialState: boolean = false;

class SuppliersStore {
  public suppliers = suppliersInitialState;
  public supplierBrands = supplierBrandsInitialState;
  public suppliersFilter = suppliersFilterInitialState;
  public loading: boolean = loadingInitialState;
  public supplierBrandsLoading: boolean = supplierBrandsLoadingInitialState;
  public message = '';
  public error = '';
  public countries?: any[];

  constructor() {
    makeAutoObservable(this);
  }

  setSuppliers(data: any) {
    const { suppliers } = data;
    this.suppliers = suppliers;
  }

  setSupplierBrands(data: any) {
    const { brands } = data;
    this.supplierBrands = brands;
  }

  public suppliersInit = (signal?: GenericAbortSignal) => {
    void this.suppliersReq(signal);
    void this.countriesReq();
  };

  public supplierBrandsInit = (supplierID: number) => {
    void this.supplierBrandsReq(supplierID);
  };

  public suppliersReq = async (signal?: GenericAbortSignal) => {
    this.loading = true;

    const { getToken } = AppStore;
    const headers = {
      Authorization: 'Bearer ' + getToken()!,
    };
    const filterUrl = Object.keys(this.suppliersFilter).reduce(
      (filters, filter) => {
        const symbol = filters ? '&' : '?';
        const filterKeyName = filter as keyof SuppliersFilterModel;
        if (
          this.suppliersFilter[filterKeyName] !== 0 &&
          this.suppliersFilter[filterKeyName] !== ''
        ) {
          filters +=
            symbol + filter + '=' + this.suppliersFilter[filterKeyName];
        }

        return filters;
      },
      ''
    );

    try {
      const response = await axios.get(api.suppliers + filterUrl, {
        headers,
        signal,
      });
      this.setSuppliers(response?.data);
      this.setSuppliersFilterPages(response?.data.pages);
    } catch (err) {
    } finally {
      this.loading = false;
    }
  };

  public deleteSupplier = async (id: number) => {
    try {
      const { getToken } = AppStore;
      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };
      const response = await axios.delete(`${api.suppliers}/${id}`, {
        headers,
      });

      this.message = response?.data.message;
      toast.success(this.message);
    } catch (err: any) {
      this.error = err?.response.data.reason;
      toast.error(this.error);
    } finally {
      void this.suppliersReq();
    }
  };

  public supplierBrandsReq = async (supplierID: number) => {
    this.supplierBrandsLoading = true;
    const { getToken } = AppStore;
    const headers = {
      Authorization: 'Bearer ' + getToken()!,
    };

    try {
      const response = await axios.get(
        api.suppliers + `/brands/${supplierID}`,
        { headers }
      );
      this.setSupplierBrands(response.data);
    } catch (e) {
    } finally {
      this.supplierBrandsLoading = false;
    }
  };

  private countriesReq = async () => {
    try {
      const { getToken } = AppStore;
      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };

      const response = await axios.get(api.get_countries, { headers });
      this.countries = response.data.countries;
    } catch (err) {}
  };

  public setSuppliersFilter = ({
    search,
    country_id,
    page,
    page_limit,
    technical,
  }: SuppliersFilterModel) => {
    const isUndefined = (value: any) => typeof value === 'undefined';
    this.suppliersFilter = {
      ...this.suppliersFilter,
      search: isUndefined(search) ? this.suppliersFilter.search! : search!,
      country_id: isUndefined(country_id)
        ? this.suppliersFilter.country_id!
        : country_id!,
      page: isUndefined(page) ? this.suppliersFilter.page! : page!,
      page_limit: isUndefined(page_limit)
        ? this.suppliersFilter.page_limit!
        : page_limit!,
      technical: isUndefined(technical)
        ? this.suppliersFilter.technical!
        : technical!,
    };

    if (page_limit) {
      this.suppliersFilter.page = 1;
    }

    void this.suppliersReq();
  };

  private setSuppliersFilterPages = (pages: number) => {
    this.suppliersFilter.pages = pages;
  };

  public clearFilter = () => {
    this.suppliersFilter = suppliersFilterInitialState;
  };
}

export default new SuppliersStore();
