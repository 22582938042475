import {observer} from 'mobx-react';
import {
    EyeImgIcon,
    Table,
    TableBody,
    TableHead,
    TCell,
    THeadCell,
    TRow,
} from '../../styles/table';
import {Badge} from '../../styles/badge';
import NoResultTitle from '../../components/no_result_title/no_result_title.view';
import TableSkeleton from '../../utils/skeleton/table.skeleton';
import {UsersModel} from './users.model';
import {Button} from '../../styles/button';
import {Layout} from '../../styles/layout';
import {Text} from '../../styles/text';
import {useState} from 'react';
import ModalView from '../../components/modal/modal.view';
import EyeIcon from '../../assets/icons/eye.svg';
import {primaryColor} from '../../utils/variables';

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface UsersTableMobileViewProps {
    loading: boolean;
    users: UsersModel[] | null;
    handleAccountClick: (user: UsersModel) => void;
    handleEditClick: (user: UsersModel) => void;
    setConfirmDeleteModal: ({
                                isModalOpen,
                                id,
                                name,
                            }: setConfirmDeleteModalProps) => void;
    tableHeaders: (key: string) => string;
}

const UsersTableMobileView = observer(
    ({
         loading,
         users,
         handleAccountClick,
         handleEditClick,
         setConfirmDeleteModal,
         tableHeaders
     }: UsersTableMobileViewProps) => {
        const [modal, setModal] = useState(false);
        const [initialValue, setInitialValue] = useState<UsersModel | null>(null);

        const handleRowClick = (user: UsersModel) => {
            setModal(true);
            setInitialValue(user);
        };

        const handleCloseModal = () => {
            setModal(false);
            setInitialValue(null);
        };

        const renderModalItems = (user: UsersModel) => (
            <Layout vertical between={10}>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('full_name')}</Text>
                    <Text>{user.full_name}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('login')}</Text>
                    <Text>{user.login}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('phone')}</Text>
                    <Text>{user.phone}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('role')}</Text>
                    <Text>{user.role}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('business_unit')}</Text>
                    <Text>{user.business_unit}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('salary')}</Text>
                    <Text>{user.salary}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('active')}</Text>
                    <Text>
                        <Badge online={user.active}>
                            {user.active ? 'Активный' : 'Заблокирован'}
                        </Badge>
                    </Text>
                </Layout>
                <Layout hAlign={'center'} between={5}>
                    <Button onClick={() => handleAccountClick(user)}>Счета</Button>
                    <Button onClick={() => handleEditClick(user)}>Изменить</Button>
                    <Button
                        background={'rgb(146,4,4)'}
                        onClick={() =>
                            setConfirmDeleteModal({
                                isModalOpen: true,
                                id: user.id,
                                name: user.full_name,
                            })
                        }
                    >
                        Удалить
                    </Button>
                </Layout>
            </Layout>
        );

        const renderedItems = () => (
            <>
                <Table>
                    <TableHead>
                        <TRow tableHead>
                            <THeadCell>{tableHeaders('full_name')}</THeadCell>
                            <THeadCell>{tableHeaders('phone')}</THeadCell>
                            <THeadCell>{tableHeaders('role')}</THeadCell>
                            <THeadCell></THeadCell>
                        </TRow>
                    </TableHead>
                    {!loading ? (
                        <TableBody>
                            {users?.length ? (
                                users?.map((user, index) => (
                                    <TRow key={index} menu>
                                        <TCell>{user.full_name}</TCell>
                                        <TCell>{user.phone}</TCell>
                                        <TCell>{user.role}</TCell>
                                        <TCell relative>
                                            <EyeImgIcon
                                                src={EyeIcon}
                                                onClick={() => handleRowClick(user)}
                                                height="18px"
                                                color={primaryColor}
                                            />
                                        </TCell>
                                    </TRow>
                                ))
                            ) : (
                                <NoResultTitle colspan={4}/>
                            )}
                        </TableBody>
                    ) : (
                        <TableSkeleton columns={4}/>
                    )}
                </Table>
                {modal && (
                    <ModalView
                        title={`Данные сотрудника ${initialValue?.name}`}
                        onClose={handleCloseModal}
                    >
                        {renderModalItems(initialValue!)}
                    </ModalView>
                )}
            </>
        );

        return <Layout>{renderedItems()}</Layout>;
    }
);

export default UsersTableMobileView;
