import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import { api } from '../../../api/endpoints';
import AppStore from '../../../app.store';
import { toast } from 'react-toastify';
import { TransferModel } from '../transfer.model';
const initialValues = {
  from_business_unit_id: 0,
  to_business_unit_id: 0,
  delivered: false,
  details: '',
  products: [],
};

class TransferCreateEditStore {
  private transferCategories?: any[];
  public transferType: TransferModel[] = [];
  public form = initialValues;
  public error: string = '';
  public message: string = '';
  public isLoading: boolean = false;
  public selectedProducts = [];
  constructor() {
    makeAutoObservable(this);
  }
  public setProductTotalSumm = (product: any, value: any) => {
    product.totalSumm = value;
  };
  public selectSubmit = (product: any) => {
    // @ts-ignore
    this.selectedProducts.push(product);
  };
  public createOrEditTransfers = async (signal?: AbortSignal) => {
    this.isLoading = true;
    const payload = {
      from_business_unit_id: Number(this.form.from_business_unit_id),
      to_business_unit_id: Number(this.form.to_business_unit_id),
      delivered: this.form.delivered,
      details: this.form.details,
      products: [],
    };

    const products = this.selectedProducts.map((item: any) => ({
      product_id: Number(item.id),
      quantity: Number(item.totalSumm),
    }));

    // @ts-ignore
    payload.products = products;
    try {
      const { getToken } = AppStore;
      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };
      const response = await axios.post(api.transfers, payload, {
        headers,
        signal,
      });

      this.message = response?.data.message;
      toast.success(this.message);

      this.isLoading = false;
      return response;
    } catch (err: any) {
      this.error = err?.response.data.reason;
      toast.error(this.error);
    } finally {
      this.isLoading = false;
    }
  };
  public deleteProduct = (id: any) => {
    this.selectedProducts = this.selectedProducts.filter(
      (item: any) => item.id !== id
    );
  };
  public setField = (form: any) => {
    this.form = form;
  };

  public destroy = () => {
    this.form = initialValues;
    this.isLoading = false;
    this.selectedProducts = [];
  };
  public changeForm = (name: string, value: string | number | boolean) => {
    // @ts-ignore
    this.form[name] = value;
  };
  public get TransferCategoryOptions() {
    if (!this.transferCategories) return [];
    return this.transferCategories?.map((category: any) => ({
      label: category.name,
      value: category.id,
    }));
  }
}

export default new TransferCreateEditStore();
