import {observer} from "mobx-react";
import {
    ModalBody,
    ModalContainer,
    ModalGlobalStyles,
    ModalHeader,
    TransProdsModalInner
} from "./modal.style";
import {Text} from "../../styles/text";
import {primaryColor} from "../../utils/variables";
import {Image} from "../../styles/image";
import Xmark from "../../assets/icons/xmark.svg";

interface TransProdsModalProps {
    children: JSX.Element;
    title?: string;
    onClose: () => void;
}

const TransProdsModalView = observer(({ children, title, onClose }: TransProdsModalProps) => {
    return (
        <ModalContainer onClick={onClose}>
            <TransProdsModalInner onClick={(e) => e.stopPropagation()}>
                <ModalHeader>
                    <Text extent="20px" weight="700" color={primaryColor}>
                        {title}
                    </Text>
                    <Image src={Xmark} extent="18px" onClick={onClose} />
                </ModalHeader>
                <ModalBody>{children}</ModalBody>
            </TransProdsModalInner>
            <ModalGlobalStyles />
        </ModalContainer>
    );
});

export default TransProdsModalView;