import {observer} from "mobx-react";
import {DatePicker} from "antd";
import AccountStore from "./account.store";
import {PageView} from "../../styles/page";
import {TableHeader} from "../../styles/table-header";
import {Filters} from "../../styles/filters";
import {Layout} from "../../styles/layout";
import {
    Table,
    TableBody,
    TableContainer,
    TableContainerMobile,
    TableHead,
    TCell,
    THeadCell,
    TRow
} from "../../styles/table";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import {AccountRecordsFilterModel} from "./account.model";
import {useEffect} from "react";
import TablePaginationView from "../../utils/table.pagination.view";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import AccountRecordsMobileView from "./account.records.mobile.view";
import DictionaryStore from "../../utils/dictionary/dictionary.store";

interface AccountRecordsViewProps {
    accountID: number;
}

const AccountRecordsView = observer(({accountID}: AccountRecordsViewProps) => {
    const {
        destroyFilters,
        recordsLoading,
        accountRecords,
        accountRecordsReq,
        accountRecordsFilter,
        setAccountRecordsFilter,
        accountRecordsInit
    } = AccountStore;

    const {destroyDictionary, getTableHeaders, dictionaryListReq} = DictionaryStore;

    useEffect(() => {
        const controller = new AbortController();
        destroyDictionary();
        destroyFilters()
        dictionaryListReq('ACCOUNT OPERATIONS', 'TABLE HEADERS', controller.signal);
        void accountRecordsInit(accountID);
        return () => controller.abort();
    }, [destroyDictionary, dictionaryListReq, accountRecordsInit]);

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === "page" && index !== undefined) {
            setAccountRecordsFilter({page: index + 1} as AccountRecordsFilterModel);
            void accountRecordsReq(accountID);
            return;
        }
        setAccountRecordsFilter({[objectKey]: options[selectedIndex].value} as AccountRecordsFilterModel);
        void accountRecordsReq(accountID);
    };

    const pageLimits = [25, 50, 100, 250, 500, 1000];

    const prevPage = () => {
        if (accountRecordsFilter.page > 1) {
            setAccountRecordsFilter({page: accountRecordsFilter.page - 1} as AccountRecordsFilterModel);
        }
        void accountRecordsReq(accountID);
    };

    const nextPage = () => {
        if (accountRecordsFilter.page < accountRecordsFilter.pages) {
            setAccountRecordsFilter({page: accountRecordsFilter.page + 1} as AccountRecordsFilterModel);
        }
        void accountRecordsReq(accountID);
    };

    const {RangePicker} = DatePicker;

    const setDates = (e: any) => {
        if (e !== null) {
            setAccountRecordsFilter({date_to: e[1].format("YYYY-MM-DD")} as AccountRecordsFilterModel);
            setAccountRecordsFilter({date_from: e[0].format("YYYY-MM-DD")} as AccountRecordsFilterModel);
        } else {
            setAccountRecordsFilter({date_from: ""} as AccountRecordsFilterModel);
            setAccountRecordsFilter({date_to: ""} as AccountRecordsFilterModel);
        }
        void accountRecordsReq(accountID);
    };

    return (
        <PageView isNavOpen={true} hasTable={true} vertical between={10}>
            <>
                <TableHeader>
                    <Filters hAlign="space-between" wrapped between={10}>
                        <Layout between={10} wrapped vAlign="center">
                            <RangePicker
                                onChange={(e) => {
                                    setDates(e);
                                }}
                                placeholder={["Начало даты", "Конец даты"]}
                                style={{
                                    height: 40,
                                    width: 250,
                                    borderRadius: 12,
                                    border: 0,
                                    borderWidth: 1,
                                    borderColor: "#E5E5E5",
                                    color: "#374151",
                                    backgroundColor: "#FFFFFF",
                                }}
                            />
                        </Layout>
                    </Filters>
                </TableHeader>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TRow tableHead>
                                <THeadCell>{getTableHeaders('tran_id')}</THeadCell>
                                <THeadCell>{getTableHeaders('tran_type')}</THeadCell>
                                <THeadCell>{getTableHeaders('balance_before')}</THeadCell>
                                <THeadCell>{getTableHeaders('balance_after')}</THeadCell>
                                <THeadCell>{getTableHeaders('amount')}</THeadCell>
                                <THeadCell>{getTableHeaders('details')}</THeadCell>
                                <THeadCell>{getTableHeaders('description')}</THeadCell>
                                <THeadCell>{getTableHeaders('reason')}</THeadCell>
                                <THeadCell>{getTableHeaders('created_at')}</THeadCell>
                            </TRow>
                        </TableHead>
                        <TableBody>
                            {recordsLoading ? (<TableSkeleton columns={9}/>) : (
                                accountRecords?.length ? (
                                    accountRecords?.map((record, index) => (
                                        <TRow key={index} menu>
                                            <TCell>{record.tran_id}</TCell>
                                            <TCell>{record.tran_type}</TCell>
                                            <TCell>{record.balance_before}</TCell>
                                            <TCell>{record.balance_after}</TCell>
                                            <TCell>{record.amount}</TCell>
                                            <TCell>{record.details}</TCell>
                                            <TCell>{record.description}</TCell>
                                            <TCell>{record.reason}</TCell>
                                            <TCell>{record.created_at}</TCell>
                                        </TRow>
                                ))) : (
                                    <NoResultTitle colspan={9}/>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainerMobile
                    isInModal={true}
                >
                    <AccountRecordsMobileView
                        loading={recordsLoading}
                        accountsRecords={accountRecords}
                        tableHeaders={getTableHeaders}
                    />
                </TableContainerMobile>
                <TablePaginationView
                    prevPage={prevPage}
                    nextPage={nextPage}
                    pages={accountRecordsFilter.pages}
                    currentPage={accountRecordsFilter.page}
                    pageLimits={pageLimits}
                    handleFilterChange={handleFilterChange}
                />
            </>
        </PageView>
    );
});

export default AccountRecordsView;