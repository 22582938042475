import axios from 'axios';
import {makeAutoObservable} from 'mobx';
import {api} from '../../../api/endpoints';
import AppStore from '../../../app.store';
import StockStore from '../stock.store';
import {TBusinessUnitsGetResponse, TProductsGetResponse,} from '../stock.types';
import {toast} from 'react-toastify';
import {ProductModel} from "../../products/products.model";

const formInitialValue = {
    business_unit_id: 0,
    supplier_id: 0,
    paid_amount: 0,
    goods_id: 0,
    quantity: 0,
    total_amount: 0,
    doc_number: '',
    goods: [],
};

class StockModalStore {
    business_units: TBusinessUnitsGetResponse['business_units'] = [];
    products: TProductsGetResponse['products'] = [];
    form = formInitialValue;
    isLoading = false;
    selectedProducts: any[] = [];
    public message = '';
    public error = '';

    constructor() {
        makeAutoObservable(this);
    }

    public destroy = () => {
        this.form = formInitialValue;
        this.selectedProducts = [];
    };

    public deleteProduct = (index: number) => {
        this.selectedProducts.splice(index, 1);
    }

    public selectProduct = (product: any) => {
        this.selectedProducts.push(product);
    }

    setForm = (form: typeof this.form) => {
        this.form = form;
    };

    public addProduct = async (products: ProductModel[] | undefined) => {
        this.isLoading = true;
        const url = new URL(api.business_stock);
        const headers = {
            Authorization: 'Bearer ' + AppStore.getToken(),
        };

        const payload = {
            business_unit_id: this.form.business_unit_id,
            supplier_id: this.form.supplier_id,
            goods: [],
        };

        // @ts-ignore
        payload.goods = this.selectedProducts?.map((item: any) => ({
            goods_id: Number(item.id),
            quantity: Number(item.quantity),
            production_date: item.production_date,
            expiry_date: item.expiry_date,
        }));

        try {
            const response = await axios.post(url.toString(), payload, {headers});

            this.message = response?.data.message;
            toast.success(this.message);

            return response;
        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);
        } finally {
            void StockStore.getGoods();
            this.isLoading = false;
        }
    };

    resetFormFields = () => {
        this.form = formInitialValue;
    };
}

export default new StockModalStore();
