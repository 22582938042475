import {BusinessUnitModel} from "../business_units.model";
import {observer} from "mobx-react";
import {useEffect} from "react";
import {Layout} from "../../../styles/layout";
import {renderInputs} from "../../../components/form/render.inputs";
import {Button} from "../../../styles/button";
import {isValid} from "../../../utils/isValid";
import BusinessUnitsCreateEditStore from "./business_units.create.edit.store";
import BusinessUnitsStore from "../business_units.store";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";

interface BusinessUnitsCreateEditViewProps {
    initialValue: BusinessUnitModel | null;
    setModal: (isOpen: boolean) => void;
}

const BusinessUnitsCreateEditView = observer(({initialValue, setModal}: BusinessUnitsCreateEditViewProps) => {
    const {
        form,
        setField,
        init,
        createOrEditBusinessUnit,
        isLoading,
        destroy
    } = BusinessUnitsCreateEditStore;

    const {dictionaryListReq, getModalPlaceholders, destroyModalDictionary} = DictionaryStore;

    const {
        name,
        description,
        address,
        phone
    } = form;

    const {businessUnitsReq} = BusinessUnitsStore;

    useEffect(() => {
        destroyModalDictionary();
        const controller = new AbortController();
        dictionaryListReq('BUSINESS UNITS', 'MODAL', controller.signal);
        init(initialValue);

        return () => {
            destroy();
            controller.abort();
        }
    }, [destroyModalDictionary, dictionaryListReq, init]);

    const inputs = [
        {
            field: name,
            fieldName: 'name',
            placeholder: getModalPlaceholders('name'),
            type: 'text',
            required: true
        },
        {
            field: description,
            fieldName: 'description',
            placeholder: getModalPlaceholders('description'),
            type: 'text'
        },
        {
            field: address,
            fieldName: 'address',
            placeholder: getModalPlaceholders('address'),
            type: 'text'
        },
        {
            field: phone,
            fieldName: 'phone',
            placeholder: getModalPlaceholders('phone'),
            type: 'text'
        },
        ];

    const handleCreateEditBusinessUnit = async () => {
        const res = await createOrEditBusinessUnit(!!initialValue);
        if (res) {
            setModal(false);
            await businessUnitsReq();
        }
    }

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setField)}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={isLoading}
                    onClick={handleCreateEditBusinessUnit}
                    disabled={isValid([inputs])}
                >{isLoading ? '' : renderSubmitText}</Button>
            </Layout>
        </Layout>
    );

});

export default BusinessUnitsCreateEditView;