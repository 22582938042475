import {observer} from "mobx-react";
import {ProductCategoryModel} from "../product_categories.model";
import {Layout} from "../../../styles/layout";
import {renderInputs} from "../../../components/form/render.inputs";
import {Button} from "../../../styles/button";
import {isValid} from "../../../utils/isValid";
import ProductCategoriesCreateEditStore from "./product_categories.create.edit.store";
import ProductCategoriesStore from "../../product_categories/product_categories.store";
import {useEffect} from "react";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";

interface ProductCategoriesCreateEditViewProps {
    initialValue: ProductCategoryModel | null;
    setModal: (isOpen: boolean) => void;
}

const ProductCategoryCreateEditView = observer(({initialValue, setModal}: ProductCategoriesCreateEditViewProps) => {

    const {
        form,
        setField,
        init,
        createOrEditProductCategory,
        isLoading,
        destroy
    } = ProductCategoriesCreateEditStore;

    const {
        name,
        description
    } = form;

    const {destroyModalDictionary, dictionaryListReq, getModalPlaceholders} = DictionaryStore;


    const {productCategoriesReq} = ProductCategoriesStore;

    useEffect(() => {
        destroyModalDictionary();
        const controller = new AbortController();
        void dictionaryListReq('PRODUCT CATEGORIES', 'MODAL', controller.signal);
        void init(initialValue);

        return () => {
            destroy()
            controller.abort();
        }
    }, [destroyModalDictionary, dictionaryListReq, destroy, init, initialValue]);


    const inputs = [
        {field: name, fieldName: 'name', placeholder: getModalPlaceholders('name'), type: 'text', required: true},
        {
            field: description,
            fieldName: 'description',
            placeholder: getModalPlaceholders('description'),
            type: 'text'
        }
    ];

    const handleCreateEditProductCategory = async () => {
        const response = await createOrEditProductCategory(!!initialValue);

        if (response) {
            await productCategoriesReq();
            setModal(false);
        }
    }

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setField)}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={isLoading}
                    onClick={handleCreateEditProductCategory}
                    disabled={isValid([inputs])}
                >{isLoading ? '' : renderSubmitText}</Button>
            </Layout>
        </Layout>
    );
});

export default ProductCategoryCreateEditView;