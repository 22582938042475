import axios, { GenericAbortSignal } from 'axios';
import { makeAutoObservable } from 'mobx';
import { api } from '../../api/endpoints';
import AppStore from '../../app.store';
import {
  TransferProductsModel,
  TransferModel,
  TransferModalFilter,
} from './transfer.model';

const transferFilterInitialValue = {
  search: '',
  user_id: 0,
  from_bu_id: 0,
  to_bu_id: 0,
  delivered: '',
  date_from: '',
  date_to: '',
  order_by: '',
  order_descending: false,
  pages: 0,
  page: 0,
  page_limit: 0,
};

const formInitialValue = {
  brand: '',
  name: '',
  part_number: '',
  quantity: 0,
};
class TransferStore {
  transfers: TransferModel[] = [];
  loading = false;
  filter = transferFilterInitialValue;
  form = formInitialValue;
  page = 1;
  selectedProducts = [];
  pages = 1;
  page_limit = 25;
  transfersProducts: TransferProductsModel[] = [];
  public message = '';
  public error = '';

  constructor() {
    makeAutoObservable(this);
  }

  setTransfers = (data: any) => {
    const { transfers } = data;
    this.transfers = transfers;
  };

  setProductTotalSumm = (product: any, value: any) => {
    product.cost_price = value;
  };

  private setTrasnferFilterPages = (pages: number) => {
    this.filter.pages = pages;
  };

  public setTransfersFilter = ({
    search,
    user_id,
    from_bu_id,
    to_bu_id,
    delivered,
    date_from,
    date_to,
    order_by,
    order_descending,
    page,
    page_limit,
  }: TransferModalFilter) => {
    const isUndefined = (value: any) => typeof value === 'undefined';
    this.filter = {
      ...this.filter,
      search: isUndefined(search) ? this.filter.search! : search!,
      user_id: isUndefined(user_id) ? this.filter.user_id! : user_id!,
      from_bu_id: isUndefined(from_bu_id)
        ? this.filter.from_bu_id!
        : from_bu_id!,
      to_bu_id: isUndefined(to_bu_id) ? this.filter.to_bu_id! : to_bu_id!,
      delivered: isUndefined(delivered) ? this.filter.delivered! : delivered!,
      date_from: isUndefined(date_from) ? this.filter.date_from! : date_from!,
      date_to: isUndefined(date_to) ? this.filter.date_to! : date_to!,
      order_by: isUndefined(order_by) ? this.filter.order_by! : order_by!,
      order_descending: isUndefined(order_descending)
        ? this.filter.order_descending!
        : order_descending!,
      page: isUndefined(page) ? this.filter.page! : page!,
      page_limit: isUndefined(page_limit)
        ? this.filter.page_limit!
        : page_limit!,
    };

    if (page_limit) {
      this.filter.page = 1;
    }
    void this.transfersReq();
  };

  transfersReq = async (signal?: GenericAbortSignal) => {
    this.loading = true;
    const { getToken } = AppStore;
    const headers = {
      Authorization: 'Bearer ' + getToken(),
    };

    const filterUrl = Object.keys(this.filter).reduce((filters, filter) => {
      const symbol = filters ? '&' : '?';
      const filterKeyName = filter as keyof TransferModalFilter;
      if (
        this.filter[filterKeyName] !== 0 &&
        this.filter[filterKeyName] !== ''
      ) {
        filters += symbol + filter + '=' + this.filter[filterKeyName];
      }

      return filters;
    }, '');

    try {
      const response = await axios.get(api.transfers + filterUrl, {
        headers,
        signal,
      });
      this.setTransfers(response.data);
      this.setTrasnferFilterPages(response.data.pages);
    } catch (error) {
    } finally {
      this.loading = false;
    }
  };

  setTransferProducts(data: any) {
    const { products } = data;
    this.transfersProducts = products;
  }
  public tranfersProductsReq = async (
    entId?: string,
    signal?: GenericAbortSignal
  ) => {
    this.loading = true;

    try {
      const { getToken } = AppStore;

      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };

      const response = await axios.get(api.transfers + `/products/${entId}`, {
        headers,
        signal,
      });
      console.log(response);
      this.setTransferProducts(response.data);
    } catch (err) {
    } finally {
      this.loading = false;
    }
  };
  public clearFilter = () => {
    this.filter = transferFilterInitialValue;
  };

  getMeasurements = async (signal: AbortSignal) => {
    const headers = {
      Authorization: 'Bearer ' + AppStore.getToken(),
    };

    try {
      const response = await axios.post(api.transfers, {
        headers,
        signal,
      });
      this.transfers = response.data.transfers;
      this.setTrasnferFilterPages(response.data.pages);
    } catch (err) {
    } finally {
    }
  };
}
export default new TransferStore();
