import { observer } from 'mobx-react';
import ProfileEditStore from './settings.create-profile.store';
import { Layout } from '../../../styles/layout';
import { renderText } from '../../form/render.text';
import { useEffect, useState } from 'react';
import { Button } from '../../../styles/button';
interface SettingsProfile {
  setModal: (isOpen: boolean) => void;
  setChangePasswordModal: (isOpen: boolean) => void;
}
const SettingsCreateProfile = observer(
  ({ setModal, setChangePasswordModal }: SettingsProfile) => {
    const { isLoading } = ProfileEditStore;

    useEffect(() => {
      ProfileEditStore.businessReq();
    }, []);

    const { form } = ProfileEditStore;

    const userData = [
      {
        name: form.name,
        fieldName: 'name',
        placeholder: 'Имя',
        type: 'text',
      },
      {
        surname: form.surname,
        fieldName: 'surname',
        placeholder: 'Фамиля',
        type: 'text',
      },
      {
        patronymic: form.patronymic,
        fieldName: 'patronymic',
        placeholder: 'Отчество',
        type: 'text',
      },
      {
        phone: form.phone,
        fieldName: 'phone',
        placeholder: 'Номер телефона',
        type: 'text',
      },
      {
        email: form.email,
        fieldName: 'email',
        placeholder: 'Электронная почта',
        type: 'text',
      },
      {
        login: form.login,
        fieldName: 'login',
        placeholder: 'Логин',
        type: 'text',
      },
      {
        role: form.role,
        fieldName: 'role',
        placeholder: 'Роль',
        type: 'text',
      },
      {
        business_unit: form.business_unit,
        fieldName: 'business_unit',
        placeholder: 'Подразделение',
        type: 'text',
      },
    ];

    const renderSubmitText = 'Изменить пароль';
    const [openModal, setOpenModal] = useState(false);
    const handleChange = (object: any) => {
      ProfileEditStore.changeForm(object);
    };

    const handleUpdatePassword = () => {
      setModal(isLoading);
      setOpenModal(false);
    };
    const hanldeOpenModalPassword = () => {
      setModal(false);
      setChangePasswordModal(true);
    };

    return (
      <Layout vertical between={15}>
        <Layout vertical between={10}>
          {renderText(form, userData, handleChange)}
          {
            <Layout vertical>
              <Layout vertical>
                <Layout centered vertical between={6}></Layout>
              </Layout>
            </Layout>
          }
          <Layout hAlign="center">
            {
              <Button
                extent="S"
                heightSize="35px"
                onClick={hanldeOpenModalPassword}
                isLoading={isLoading}
              >
                {renderSubmitText}
              </Button>
            }
          </Layout>
        </Layout>
      </Layout>
    );
  }
);
export default SettingsCreateProfile;
