import {BusinessUnitModel} from "../business_units.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import {api} from "../../../api/endpoints";
import axios from "axios";
import { toast } from "react-toastify";

const initialValues = {
    id: 0,
    name: '',
    description: '',
    address: '',
    phone: '',
    cash_balance: 0,
    employee_count: 0,
    active: true
};

class BusinessUnitCreateEditStore {
    public form: BusinessUnitModel = initialValues;
    public error = '';
    public message = '';
    public isLoading = false;

    constructor() {
        makeAutoObservable(this);
    }

    public init = (initialValue: BusinessUnitModel | null) => {
        this.form = initialValue ? initialValue : initialValues;
    }

    public createOrEditBusinessUnit = async (edit?: boolean) => {
        this.isLoading = true;
        const payload = {
            id: this.form.id,
            name: this.form.name,
            description: this.form.description,
            address: this.form.address,
            phone: this.form.phone,
            active: this.form.active
        }

        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }
            let res: any;

            if (edit) {
                res = await axios.put(api.business_unit, payload, {headers});
            } else {
                res = await axios.post(api.business_unit, payload, {headers});
            }
            // if (res?.code >= 400) {
            //     throw new Error(res)
            // }
            this.message = res?.data.message;
            toast.success(this.message);
            
            return res;
        } catch (err: any) {
            this.error = err.response.data.reason;
            toast.error(this.error);
        } finally {
            this.isLoading = false;
        }
    }

    public setField = (form: BusinessUnitModel) => {
        this.form = form;
    }

    public destroy = () => {
        this.form = initialValues;
    }
}

export default new BusinessUnitCreateEditStore();