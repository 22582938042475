import {observer} from "mobx-react";
import AppStore from "../../app.store";
import {Page} from "../../styles/page";
import {Headline} from "../../styles/headline";
import {primaryColor} from "../../utils/variables";
import GoodsMovementStore from "./goods_movement.store";
import {Filters} from "../../styles/filters";
import {FilterButton} from "../../styles/button";
import {TableHeader} from "../../styles/table-header";
import {useEffect, useState, MouseEventHandler} from "react";
import TableDesktopView, {ContextOptionsProps} from "../../components/table/render.table.desktop";
import TableMobileView from "../../components/table/render.table.mobile";
import TablePaginationView from "../../utils/table.pagination.view";
import FilterView from "../../components/filters/filters.view";
import BusinessUnitsStore from "../business_units/business_units.store";
import UsersStore from "../users/users.store";
import ModalView from "../../components/modal/modal.view";
import ProductsStore from "../products/products.store";
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import {GoodsMovementModel} from "./goods_movement.model";
import {Layout} from "../../styles/layout";
import {TableContainer} from "../../styles/table";
import GoodsMovementHistoryTrackerView from "./goods_movement.history.tracker.view";

const GoodsMovementView = observer(() => {
    const {isNavOpen} = AppStore;
    const {
        loading,
        goodsMovementPageInit,
        movementsFilters,
        movements,
        setMovementsFilter,
        destroy
    } = GoodsMovementStore;
    const {setBusinessUnitFilter, businessUnits} = BusinessUnitsStore;
    const {setUsersFilter, users} = UsersStore;
    const {setProductsFilter, products} = ProductsStore;
    const {destroyDictionary, dictionaryListReq, getPageTitle, dictionaryList} = DictionaryStore;
    const [filterModal, setFilterModal] = useState(false);
    const [historyModal, setHistoryModal] = useState(false);
    const [initialValue, setInitialValue] = useState<GoodsMovementModel | null>(null)

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === 'page' && index !== undefined) {
            setMovementsFilter({page: index + 1});
            return;
        }
        setMovementsFilter({[objectKey]: options[selectedIndex].value});
    };

    useEffect(() => {
        destroyDictionary();
        destroy();
        const controller = new AbortController();
        void goodsMovementPageInit(controller.signal);
        void dictionaryListReq('GOODS_MOVEMENTS', 'TABLE HEADERS', controller.signal);
        setBusinessUnitFilter({page: 1, page_limit: 100000});
        setUsersFilter({page: 1, page_limit: 100000});
        setProductsFilter({page: 1, page_limit: 100000, type_id: 1});
        return () => {
            controller.abort();
        }
    }, [goodsMovementPageInit, setBusinessUnitFilter, setUsersFilter, setProductsFilter]);

    const businessUnitsOptions = businessUnits?.map(
        ({id, name}) => ({label: name, value: id})
    );

    const usersOptions = users?.map(
        ({id, full_name}) => ({label: full_name, value: id})
    )

    const productsOptions = products?.map(
        ({id, name}) => ({label: name, value: id})
    )

    const cancelSearch = (e: any) => {
        if (e.target.value === '' && movementsFilters.search !== '') {
            setMovementsFilter({search: ''});
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            setMovementsFilter({search: e.target.value});
        }
    };

    const prevPage = () => {
        if (movementsFilters.page! > 1) {
            setMovementsFilter({page: movementsFilters.page! - 1});
        }
    };

    const nextPage = () => {
        if (movementsFilters.page! < movementsFilters.pages!) {
            setMovementsFilter({page: movementsFilters.page! + 1});
        }
    };

    const pageLimits = [25, 50, 100, 250, 500];

    const movementTypeOptions = [
        {
            label: 'Продажа',
            value: 1
        },
        {
            label: 'Получения',
            value: 2
        },
        {
            label: 'Перевод',
            value: 3
        },
        {
            label: 'Списание',
            value: 4
        },
        {
            label: 'Аренда',
            value: 5
        },
        {
            label: 'Возврат',
            value: 6
        },
        {
            label: 'Поставка',
            value: 7
        }
    ]

    const filterInputs: any[] = [
        {
            fieldName: '',
            placeholder: '',
            type: 'range_picker',
            options: '',
        },
        {
            fieldName: 'user_id',
            placeholder: 'Все сотрудники',
            type: 'select',
            options: usersOptions,
        },
        {
            fieldName: 'bu_id',
            placeholder: 'Все подразделения',
            type: 'select',
            options: businessUnitsOptions,
        },
        {
            fieldName: 'product_id',
            placeholder: 'Все продукты',
            type: 'select',
            options: productsOptions,
            //   hasSearch: true
        },

        {
            fieldName: 'movement_type_id',
            placeholder: 'Все типы движения',
            type: 'select',
            options: movementTypeOptions,
        },
    ]

    const handleCloseFilterModal = () => {
        setFilterModal(false);
    }

    const setDates = (e: any) => {
        if (e !== null) {
            setMovementsFilter({date_to: e[1].format('YYYY-MM-DD')});
            setMovementsFilter({date_from: e[0].format('YYYY-MM-DD')});
        } else {
            setMovementsFilter({date_from: ''});
            setMovementsFilter({date_to: ''});
        }
    };

    const handleClickHistory = (movement: GoodsMovementModel) => {
            setHistoryModal(true);
            setInitialValue(movement);
    }

    const handleCloseHistory = () => {
            setHistoryModal(false);
            setInitialValue(null);
    }

    const contextMenuOptions: ContextOptionsProps[] = [
        {
            name: 'История движения',
            onClick: (movement: GoodsMovementModel) => handleClickHistory(movement),
        }
    ]

    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
            <Headline color={primaryColor}>{getPageTitle('GOODS_MOVEMENTS')}</Headline>
            <TableHeader>
                <Filters hAlign="space-between" wrapped between={10}>
                    <FilterView
                        filterInputs={filterInputs}
                        isInModal={false}
                        filters={movementsFilters}
                        handleFilterChange={handleFilterChange}
                        handleKeyDown={handleKeyDown}
                        cancelSearch={cancelSearch}
                        setDates={setDates}
                    />
                </Filters>
                <FilterButton
                    id={'movements-filters'}
                    onClick={() => setFilterModal(true)}
                >
                    Фильтры
                </FilterButton>
            </TableHeader>
            <TableDesktopView data={movements} headers={dictionaryList} hasContext={true} loading={loading}
                              contextOptions={contextMenuOptions}/>
            <TableMobileView data={movements} headers={dictionaryList} hasContext={true} loading={loading}
                             contextOptions={contextMenuOptions}/>
            <TablePaginationView
                prevPage={prevPage}
                nextPage={nextPage}
                pages={movementsFilters.pages}
                currentPage={movementsFilters.page!}
                pageLimits={pageLimits}
                handleFilterChange={handleFilterChange}
            />
            {filterModal && (
                <ModalView
                    title={'Фильтры'}
                    onClose={handleCloseFilterModal}
                >
                    <FilterView
                        filterInputs={filterInputs}
                        isInModal={true}
                        filters={movementsFilters}
                        handleFilterChange={handleFilterChange}
                        handleKeyDown={handleKeyDown}
                        cancelSearch={cancelSearch}
                        setDates={setDates}
                    />
                </ModalView>
            )}
            {historyModal && (
                <ModalView
                    onClose={handleCloseHistory}
                    title={`Истори движения ${initialValue?.product_name}`}
                >
                    <GoodsMovementHistoryTrackerView initialValue={initialValue}/>
                </ModalView>
            )}
        </Page>
    );
});

export default GoodsMovementView;