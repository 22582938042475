import styled from 'styled-components';
import {media, primaryColorGradient} from '../utils/variables';
import { Layout } from './layout';

export const ProfileIcon = styled(Layout)`
    background: ${primaryColorGradient};
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: white;
    cursor: pointer;
  
  ${media.mobile} {
    height: 30px;
    width: 30px;
    font-size: 12px;
  }

  ${media.tablet} {
    height: 30px;
    width: 30px;
    font-size: 15px;
  }
`;
