import {PayrollsModel} from "./payrolls.model";
import {observer} from "mobx-react";
import {ContextMenu, ContextMenuItem, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import TableSkeleton from "../../../utils/skeleton/table.skeleton";
import NoResultTitle from "../../../components/no_result_title/no_result_title.view";
import {Image} from "../../../styles/image";
import ContextMenuIcon from "../../../assets/icons/context-menu.svg";
import {handleDownloadFile} from "../../../utils/handleDownloadFile";
import {api} from "../../../api/endpoints";
import AppStore from "../../../app.store";

interface PayrollsDesktopViewProps {
    isLoading: boolean;
    payrolls: PayrollsModel[] | undefined;
    handleContext: (e: any) => void;
    anchorEl: any;
    handleItemsClick: (payroll: PayrollsModel) => void;
    tableHeaders: (key: string) => string;
}

const PayrollsDesktopView = observer(({isLoading, payrolls, handleContext, anchorEl, handleItemsClick, tableHeaders}: PayrollsDesktopViewProps) => {
    const handleDownloadPDF = async (payroll: PayrollsModel) => {
        const {getToken} = AppStore;
        await handleDownloadFile(api.payrolls + `/pdf?id=${payroll.id}`, getToken(), `Расчетный лист ${payroll.owner_payroll_id}`, 'PDF')
    }

    return (
        <Table>
            <TableHead>
                <TRow tableHead>
                    <THeadCell>{tableHeaders('owner_payroll_id')}</THeadCell>
                    <THeadCell>{tableHeaders('employee_name')}</THeadCell>
                    <THeadCell>{tableHeaders('period')}</THeadCell>
                    <THeadCell>{tableHeaders('salary')}</THeadCell>
                    <THeadCell>{tableHeaders('allowance')}</THeadCell>
                    <THeadCell>{tableHeaders('deduction')}</THeadCell>
                    <THeadCell>{tableHeaders('total_amount')}</THeadCell>
                    <THeadCell>{tableHeaders('payment_method')}</THeadCell>
                    <THeadCell>{tableHeaders('account')}</THeadCell>
                    <THeadCell>{tableHeaders('financial_institution')}</THeadCell>
                    <THeadCell>{tableHeaders('details')}</THeadCell>
                    <THeadCell>{tableHeaders('date')}</THeadCell>
                    <THeadCell></THeadCell>
                </TRow>
            </TableHead>
            {isLoading ? (<TableSkeleton columns={13}/>) : (
                <TableBody>
                    {payrolls?.length ? (
                        payrolls.map((payroll, index) => (
                            <TRow key={index}>
                                <TCell>{payroll.owner_payroll_id}</TCell>
                                <TCell>{payroll.employee_name}</TCell>
                                <TCell>{payroll.period}</TCell>
                                <TCell>{payroll.salary}</TCell>
                                <TCell>{payroll.allowance}</TCell>
                                <TCell>{payroll.deduction}</TCell>
                                <TCell>{payroll.total_amount}</TCell>
                                <TCell>{payroll.payment_method}</TCell>
                                <TCell>{payroll.account}</TCell>
                                <TCell>{payroll.financial_institution}</TCell>
                                <TCell>{payroll.details}</TCell>
                                <TCell>{payroll.date}</TCell>
                                <TCell relative>
                                    <Image
                                        src={ContextMenuIcon}
                                        height="18px"
                                        onClick={handleContext}
                                    />
                                    <ContextMenu anchorEl={anchorEl}>
                                        <ContextMenuItem onClick={() => handleItemsClick(payroll)}>
                                            Подробнее
                                        </ContextMenuItem>
                                        <ContextMenuItem onClick={() => handleDownloadPDF(payroll)}>
                                            Скачать PDF
                                        </ContextMenuItem>
                                    </ContextMenu>
                                </TCell>
                            </TRow>
                        ))
                    ) : (
                        <NoResultTitle colspan={13}/>
                    )}
                </TableBody>
            )}
        </Table>
    );
});

export default PayrollsDesktopView;