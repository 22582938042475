import {observer} from "mobx-react";
import {Page} from "../../../styles/page";
import {BrandModel} from "../brands.model";
import BrandsCreateEditStore from "./brands.create.edit.store";
import BrandsStore from "../brands.store";
import {useEffect} from "react";
import {Layout} from "../../../styles/layout";
import {renderInputs} from "../../../components/form/render.inputs";
import {Button} from "../../../styles/button";
import {isValid} from "../../../utils/isValid";

interface BrandCreateEditProps {
    initialValue: BrandModel | null;
    setModal: (isOpen: boolean) => void;
}

const BrandsCreateEditView = observer(({initialValue, setModal}: BrandCreateEditProps) => {

    const {
        form,
        setField,
        init,
        createOrEditBrand,
        isLoading,
        destroy,
        countriesOptions
    } = BrandsCreateEditStore

    const {
        name,
        country_of_origin_id,
    } = form

    const {brandsReq} = BrandsStore;

    useEffect(() => {
        init(initialValue);

        return () => {
            destroy()
        }
    }, [destroy, init, initialValue]);

    const inputs = [
        {
            field: name,
            fieldName: "name",
            placeholder: "Название",
            type: "text",
            required: true
        },
        {
            field: country_of_origin_id,
            options: countriesOptions,
            fieldName: 'country_of_origin_id',
            placeholder: 'Страна производства',
            type: 'select',
            required: true
        },
    ];

    const handleCreateEditBrand = async () => {
        const res = await createOrEditBrand(!!initialValue);
        if (res) {
            brandsReq();
            setModal(false);
        }
    }

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setField)}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={isLoading}
                    onClick={handleCreateEditBrand}
                    disabled={isValid([inputs])}
                >{isLoading ? '' : renderSubmitText}</Button>
            </Layout>
        </Layout>
    );
});

export default BrandsCreateEditView;