import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {AccountsModel} from "./account.model";
import {Text} from "../../styles/text";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import ModalView from "../../components/modal/modal.view";
import {useState} from "react";
import {Button} from "../../styles/button";
import {EyeImgIcon, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../styles/table";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import EyeIcon from "../../assets/icons/eye.svg"

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
}

interface AccountsMobileViewProps {
    accounts: AccountsModel[] | undefined;
    handleEditClick: (account: AccountsModel) => void;
    handleBonusPayment: (id: number) => void;
    handleAccountRecords: (accountID: number) => void;
    setConfirmDeleteModal: ({isModalOpen, id}: setConfirmDeleteModalProps) => void;
    entityType: string;
    loading: boolean;
    tableHeaders: (key: string) => string;
}

const AccountsMobileView = observer(({
                                         accounts,
                                         handleEditClick,
                                         handleBonusPayment,
                                         tableHeaders,
                                         handleAccountRecords,
                                         setConfirmDeleteModal,
                                         entityType,
                                         loading
                                     }: AccountsMobileViewProps) => {

    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<AccountsModel | null>(null)

    const handleRowClick = (account: AccountsModel) => {
        setModal(true);
        setInitialValue(account);
    }

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    }
    
    const renderModalItems = (account: AccountsModel) => (
        <Layout vertical between={10}>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('id')}</Text>
                <Text>{account.id}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('number')}</Text>
                <Text>{account.number}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('category')}</Text>
                <Text>{account.category}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('description')}</Text>
                <Text>{account.description}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('balance')}</Text>
                <Text>{account.balance}</Text>
            </Layout>
            <Layout hAlign={"center"} between={5} vertical>
                <Layout hAlign={"center"} between={5}>
                    <Button
                        onClick={() => handleAccountRecords(account.id)}
                    >
                        История операции
                    </Button>
                    <Button
                        onClick={() => {
                            handleEditClick(account)
                        }
                        }
                    > Изменить </Button>
                    <Button onClick={() =>
                        setConfirmDeleteModal({
                            isModalOpen: true,
                            id: account.id,
                        })
                    }
                    >
                        Удалить
                    </Button>
                </Layout>
                <Layout hAlign={"center"} between={5}>
                    {entityType === 'user' && account.category_id === 2 ? (
                        <Button
                            onClick={() => handleBonusPayment(account.id)}
                        >Оплата бонуса</Button>
                    ) : (<></>)}
                </Layout>
            </Layout>
        </Layout>
    );

    const renderItems = () => (
      <>
          <Table>
              <TableHead>
                  <TRow tableHead>
                      <THeadCell>{tableHeaders('category')}</THeadCell>
                      <THeadCell>{tableHeaders('balance')}</THeadCell>
                      <THeadCell></THeadCell>
                  </TRow>
              </TableHead>
              {!loading ? (
                  <TableBody>
                      {accounts?.length ? (
                          accounts?.map((account, index) => (
                              <TRow key={index} menu>
                                  <TCell width="50">{account.category}</TCell>
                                  <TCell width="50">{account.balance}</TCell>
                                  <TCell width="10" relative>
                                      <EyeImgIcon
                                          src={EyeIcon}
                                          height="18px"
                                          onClick={() => handleRowClick(account)}
                                      />
                                  </TCell>
                              </TRow>
                          ))
                      ) : (
                          <NoResultTitle colspan={3}/>
                      )}
                  </TableBody>
              ) : (<TableSkeleton columns={3}/>)}
          </Table>
          {modal && (
              <ModalView
                  title={`Данные счета ${initialValue?.number}`}
                  onClose={handleCloseModal}
              >
                  {renderModalItems(initialValue!)}
              </ModalView>
          )}
      </>
    );

    return (
        <Layout>
            {renderItems()}
        </Layout>
    )
});

export default AccountsMobileView;