import styled from "styled-components";

export const SubMenuWrap = styled.div`
    .sub-menu-link:active{
        .logout{
            filter: invert(18%) sepia(86%) saturate(5836%) hue-rotate(335deg) brightness(93%) contrast(95%);
        }
    }
    .sub-menu-wrap{
        position: absolute;
        top: 105%;
        right: 15px;
        overflow: auto;
        transition: max-height 0.5s;
        border-radius: 12px;
        box-shadow: 2px 3px 3px 3px #ccc;
    }
    .sub-menu{
        background-color: #fff;
        padding: 10px 20px;
        border-radius: 12px;
    }
    .user-info{
        display: flex;
        align-items: center;
    }
    .user-info .user-name{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
    }
    .user-info h3{
        font-weight: 500;
        font-size: 16px;
    }
    .sub-menu hr{
        border: 0;
        height: 1px;
        width: 100%;
        background: #ccc;
        margin: 8px 0;
    }
    .sub-menu-link{
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #525252;
        cursor: pointer;
        border-radius: 4px;
        padding: 0 6px;
        margin: 3px 0;
        height: 30px;
    }
    .sub-menu-link:hover{
        background: #DDD;
    }
    .sub-menu-link p{
        width: 100%;
        font-size: 14px;
    }
    .sub-menu-link img{
        width: 16px;
        margin-right: 10px;
        margin-left: -5px;
    }
`