import {observer} from "mobx-react";
import FilterView from "../../../components/filters/filters.view";
import FinReportsStore from "./fin.reports.store";
interface FinReportsFiltersViewProps {
    inModal?: boolean;
    setDates: (e: any) => void;
    handleFilterChange: (e: any, key: string) => void;
}

const FinReportsFiltersView = observer(({inModal, setDates, handleFilterChange}: FinReportsFiltersViewProps) => {
    const {finReportsFilters} = FinReportsStore;
    const entityOptions = [
        {
            label: 'По офисам',
            value: 1
        },
        {
            label: 'По сотрудникам',
            value: 2
        },
        {
            label: 'По продуктам',
            value: 3
        },
    ]

    const filterInputs = [
        {
            fieldName: '',
            placeholder: '',
            type: 'range_picker',
            options: '',
        },
        {
            fieldName: 'report_type',
            placeholder: 'Тип отчетности',
            type: 'select',
            options: entityOptions,
        },
    ]

    return (
        <FilterView
            filterInputs={filterInputs}
            isInModal={inModal!}
            filters={finReportsFilters}
            setDates={setDates}
            handleFilterChange={handleFilterChange}
        />
    );
});

export default FinReportsFiltersView;