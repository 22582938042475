import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {Text} from "../../../styles/text";
import {modalTextColor} from "../../../utils/variables";
import {DatePicker} from "antd";
import {TextInput} from "../../../styles/form";
import React from "react";
import {Button} from "../../../styles/button";
import ProductsImportExcelStore from "./products.create.store";
import dayjs from "dayjs";

interface ProductsImportExcelExpiryModalProps {
    prodIndex: number;
    buIndex: number;
    buID: number;
    setModal: (isOpen: boolean) => void;
}

const ProductsCreateExpiryModal = observer(({
                                                     prodIndex,
                                                     buID,
                                                     buIndex,
                                                     setModal
                                                 }: ProductsImportExcelExpiryModalProps) => {

    const {
        getProductExpiryDates,
        addNewExpiryDates,
        changeQuantityWithExpiry,
        deleteExpiry
    } = ProductsImportExcelStore;

    const expiryDates = getProductExpiryDates(prodIndex, buID)

    const handleAddExpiryButtonClick = () => {
        addNewExpiryDates(prodIndex, buID);
    }

    const handleConfirmExpiry = () => {
        setModal(false);
    }

    const handleChangeQuantityWithExpiry = (fieldName: string, value: number | string, expiryIndex: number) => {
        changeQuantityWithExpiry(buID, prodIndex, buIndex, expiryIndex, fieldName, value)
    }

    const handleDeleteExpiry = (expiryIndex: number) => {
        deleteExpiry(buID, prodIndex, buIndex, expiryIndex)
    }

    return (
        <Layout vertical between={20}>
            {expiryDates.map((item, index) => (
                <Layout
                    vertical
                    between={10}
                    style={{
                        boxShadow: '0 0 5px grey',
                        padding: '10px',
                        borderRadius: '5px',
                    }}
                >
                    <Layout hAlign={"flex-end"}>
                        <button
                          onClick={() => handleDeleteExpiry(index)}
                        > X
                        </button>
                    </Layout>
                    <Layout hAlign={"space-between"} vAlign={"center"}>
                        <Layout>
                            <Text color={modalTextColor} extent={'S'}>Дата производства</Text>
                        </Layout>
                        <Layout>
                            <DatePicker
                                value={dayjs(item.production_date, 'YYYY-MM-DD')}
                                onChange={(e) =>
                                    handleChangeQuantityWithExpiry('production_date', e!.format('YYYY-MM-DD'), index)
                                }
                            />
                        </Layout>
                    </Layout>
                    <Layout hAlign={"space-between"} vAlign={"center"}>
                        <Layout>
                            <Text color={modalTextColor} extent={'S'}>Дата окончания</Text>
                        </Layout>
                        <Layout>
                            <DatePicker
                                value={dayjs(item.expiry_date, 'YYYY-MM-DD')}
                                onChange={(e) =>
                                    handleChangeQuantityWithExpiry('expiry_date', e!.format('YYYY-MM-DD'), index)
                                }
                            />
                        </Layout>
                    </Layout>
                    <Layout hAlign={"space-between"} vAlign={"center"}>
                        <Layout>
                            <Text color={modalTextColor} extent={'S'}>Количество</Text>
                        </Layout>
                        <Layout>
                            <TextInput
                                value={item.quantity}
                                onChange={(e) =>
                                    handleChangeQuantityWithExpiry('quantity', +e.target.value, index)
                                }
                            />
                        </Layout>
                    </Layout>
                </Layout>
            ))}
            <Layout centered>
                <Button
                    extent={'S'}
                    onClick={handleAddExpiryButtonClick}
                >
                    Добавить
                </Button>
            </Layout>
            <Layout centered>
                <Button
                    extent={'S'}
                    onClick={handleConfirmExpiry}
                >
                    Потвердить
                </Button>
            </Layout>
        </Layout>
    );
});

export default ProductsCreateExpiryModal;