import {observer} from "mobx-react";
import {ExpenseOperationsModel} from "./expense.operations.model";
import {useState} from "react";
import {Layout} from "../../../styles/layout";
import {Text} from "../../../styles/text";
import {Badge} from "../../../styles/badge";
import {Button} from "../../../styles/button";
import {EyeImgIcon, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import EyeIcon from "../../../assets/icons/eye.svg";
import {primaryColor} from "../../../utils/variables";
import NoResultTitle from "../../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../../utils/skeleton/table.skeleton";
import ModalView from "../../../components/modal/modal.view";

interface ExpenseOperationsTableMobileViewProps {
    loading: boolean;
    operations: ExpenseOperationsModel[] | undefined;
    handleConfirmOperation: (operationID: number) => void;
    handleContext: (e: any) => void;
    anchorEl: any;
    tableHeaders: (key: string) => string;
}

const ExpenseOperationsMobileView = observer(({
                                                  loading,
                                                  operations,
                                                  handleConfirmOperation,
                                                  tableHeaders
                                              }:ExpenseOperationsTableMobileViewProps) => {

    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<ExpenseOperationsModel | null>(null);

    const handleRowClick = (operation: ExpenseOperationsModel) => {
        setModal(true);
        setInitialValue(operation);
    };

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    };

    const renderModalItems = (operation: ExpenseOperationsModel) => (
        <Layout vertical between={10}>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('owner_operation_id')}</Text>
                <Text>{operation.owner_operation_id}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('expense')}</Text>
                <Text>{operation.expense}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('business_unit')}</Text>
                <Text>{operation.business_unit}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('category')}</Text>
                <Text>{operation.category}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('amount')}</Text>
                <Text>{operation.amount}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('operation_date')}</Text>
                <Text>{operation.operation_date}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('confirmed')}</Text>
                <Text>
                    <Badge online={operation.confirmed}>
                        {operation.confirmed ? 'Да' : 'Нет'}
                    </Badge>
                </Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('created_at')}</Text>
                <Text>{operation.created_at}</Text>
            </Layout>
            <Layout hAlign={'center'} between={5}>
                {!operation.confirmed && <Button onClick={() => handleConfirmOperation(operation.id)}>Потвердить</Button>}
            </Layout>
        </Layout>
    );


    const renderedItems = () => (
        <>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>{tableHeaders('expense')}</THeadCell>
                        <THeadCell>{tableHeaders('amount')}</THeadCell>
                        <THeadCell>{tableHeaders('operation_date')}</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                {!loading ? (
                    <TableBody>
                        {operations?.length ? (
                            operations?.map((operation, index) => (
                                <TRow key={index} menu>
                                    <TCell>{operation.expense}</TCell>
                                    <TCell>{operation.amount}</TCell>
                                    <TCell>{operation.operation_date}</TCell>
                                    <TCell relative>
                                        <EyeImgIcon
                                            src={EyeIcon}
                                            onClick={() => handleRowClick(operation)}
                                            height="18px"
                                            color={primaryColor}
                                        />
                                    </TCell>
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={4} />
                        )}
                    </TableBody>
                ) : (
                    <TableSkeleton columns={4} />
                )}
            </Table>
            {modal && (
                <ModalView
                    title={`Данные операции ${initialValue?.expense}`}
                    onClose={handleCloseModal}
                >
                    {renderModalItems(initialValue!)}
                </ModalView>
            )}
        </>
    );

    return <Layout>{renderedItems()}</Layout>;
});

export default ExpenseOperationsMobileView;