import { observer } from "mobx-react";
import { useEffect } from "react";
import { renderInputs } from "../../../components/form/render.inputs";
import { Button } from "../../../styles/button";
import { Layout } from "../../../styles/layout";
import { isValid } from "../../../utils/isValid";
import ProductsTransactionsStore from "../products_transactions.store";
import ProductsTransactionsModalStore from './products_transactions.modal.store';

type ProductsTransactionsModalPropsType = { closeModal: () => void };

export const ProductsTransactionsModalView = observer(
    ({ closeModal }: ProductsTransactionsModalPropsType) => {
        useEffect(() => {
            // return () => ProductsTransactionsModalStore.resetFormFields();
        }, []);

        const businessUnitOptions = ProductsTransactionsStore.business_units.map(
            ({ id, name }) => ({ label: name, value: id })
        );
        console.log(businessUnitOptions);        

        const handleMakeTrans = () => {
            ProductsTransactionsModalStore.makeTransaction(closeModal);
        };

        const inputs = [
            {
                field: ProductsTransactionsModalStore.form.amount,
                fieldName: "amount",
                placeholder: "Сумма",
                type: "text",
                required: true,
            },
            {
                field: ProductsTransactionsModalStore.form.quantity,
                fieldName: "quantity",
                placeholder: "Количество",
                type: "number",
                required: true,
            },
            {
                field: ProductsTransactionsModalStore.form.details,
                fieldName: "details",
                placeholder: "Детали",
                type: "text",
                required: false,
            },
            {
                field: ProductsTransactionsModalStore.form.client,
                fieldName: "client",
                placeholder: "Клиент",
                type: "text",
                required: false,
            },
        ];

        return (
            <Layout vertical between={20}>
                <Layout vertical between={10}>
                    {renderInputs(ProductsTransactionsModalStore.form, inputs, ProductsTransactionsModalStore.setForm)}
                </Layout>
                {/* {ProductsTransactionsModalStore.errorMessage && (
                    <Error>{ProductsTransactionsModalStore.errorMessage}</Error>
                )} */}
                <Layout hAlign="center">
                    <Button
                        extent="S"
                        heightSize="35px"
                        isLoading={ProductsTransactionsModalStore.isLoading}
                        onClick={handleMakeTrans}
                        disabled={isValid([inputs])}
                    >
                        {ProductsTransactionsModalStore.isLoading ? "" : "Создать операцию"}
                    </Button>
                </Layout>
            </Layout>
        );
    }
);