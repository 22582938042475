import {MainActions} from "./main.actions";
import CreateOperationView from "../products_transactions/create.operation.modal/create.operation.view";
import ProductsCreateEditView from "../products/products.modal/products.create.edit.view";
import ProductsCreateAddProductView from "../products/products.create.modal/products.create.add.product.view";
import BusinessUnitsCreateEditView from "../business_units/business_units.modal/business_units.create.edit.view";
import BrandsCreateEditView from "../brands/brands.modal/brands.create.edit.view";
import ProductCategoriesCreateEditView
    from "../product_categories/product_categories.modal/product_categories.create.edit.view";
import SupplierCreateEditView from "../suppliers/suppliers.modal/suppliers.create.edit.view";
import StockModalView from "../stock/stock.modal/stock.modal.view";
import TransferCreateEditView from "../transfer_of_goods/transfer_of_goods.modal/transfer.create.view";
import ClientsCreateEditView from "../clients/clients.modal/clients.create.edit.view";
import RolesCreateEditView from "../roles/roles.modal/roles.create.edit.view";
import UsersCreateEditView from "../users/users.modal/users.create.edit.view";
import ExpensesCreateEditView from "../expenses/expenses/expenses.modal/expenses.create.edit.view";
import ExpenseCategoriesCreateEditView
    from "../expenses/expense.categories/expense.categories.modal/expense.categories.create.edit.view";
import PayrollsCreateEditView from "../payrolls/payrolls/payrolls.modal/payrolls.create.edit.view";
import EmployeeAllowancesCreateEditView
    from "../payrolls/employee.allowances/employee.allowances.modal/employee.allowances.create.edit.view";
import EmployeeDeductionsCreateEditView
    from "../payrolls/employee.deductions/employee.deductions.modal/employee.deductions.create.edit.view";
import AllowancesCreateEditView from "../payrolls/allowances/allowance.modal/allowances.create.edit.view";
import DeductionsCreateEditView from "../payrolls/deductions/deductions.modal/deductions.create.edit.view";
import StatusesCreateEditView from "../statuses/statuses.modal/statuses.create.edit.view";
import ProductsCreateView from "../products/products.create.modal/products.create.view";
import {entries} from "mobx";

export function getCreateModal(entity: string, setModal: (isOpen: boolean) => void) {
    const handleEmptyFunc = () => {
    }
    switch (entity) {
        case 'TRANSACTIONS':
            return <CreateOperationView initialValue={null} setModal={setModal}/>

        case 'PRODUCT_SINGLE':
            return <ProductsCreateEditView initialValue={null} setModal={setModal}/>

        case 'PRODUCT_MASS':
            return <ProductsCreateView setModal={setModal}/> //think about params

        case 'BUSINESS_UNITS':
            return <BusinessUnitsCreateEditView initialValue={null} setModal={setModal}/>

        case 'PRODUCT_BRANDS':
            return <BrandsCreateEditView initialValue={null} setModal={setModal}/>

        case 'PRODUCT_CATEGORIES':
            return <ProductCategoriesCreateEditView initialValue={null} setModal={setModal}/>

        case 'SUPPLIERS':
            return <SupplierCreateEditView initialValue={null} setModal={setModal}/>

        case 'BUSINESS_STOCKS':
            return <StockModalView purpose='create' closeModal={handleEmptyFunc}/> // think about it

        case 'TRANSFERS':
            return <TransferCreateEditView setModal={setModal}/>

        case 'CLIENTS':
            return <ClientsCreateEditView initialValue={null} setModal={setModal}/>

        case 'ROLES':
            return <RolesCreateEditView initialValue={null} setModal={setModal}/>

        case 'USERS':
            return <UsersCreateEditView initialValue={null} setModal={setModal}/>

        case 'EXPENSE_OPERATION':
            return <ExpensesCreateEditView initialValue={null} setModal={setModal} createOperation/>

        case 'EXPENSES':
            return <ExpensesCreateEditView initialValue={null} setModal={setModal} createOperation={false}/>

        case 'EXPENSE_CATEGORY':
            return <ExpenseCategoriesCreateEditView initialValue={null} setModal={setModal}/>

        case 'PAYROLLS':
            return <PayrollsCreateEditView initialValue={null} setModal={setModal}/>

        case 'EMPLOYEE_ALLOWANCE':
            return <EmployeeAllowancesCreateEditView initialValue={null} setModal={setModal}/>

        case 'EMPLOYEE_DEDUCTIONS':
            return <EmployeeDeductionsCreateEditView initialValue={null} setModal={setModal}/>

        case 'ALLOWANCE':
            return <AllowancesCreateEditView initialValue={null} setModal={setModal}/>

        case 'DEDUCTION':
            return <DeductionsCreateEditView initialValue={null} setModal={setModal}/>

        case 'STATUSES':
            return <StatusesCreateEditView initialValue={null} setModal={setModal} statuses={[]}/> // think about it

        default:
            return null
    }
}