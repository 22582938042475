import { observer } from 'mobx-react';
import BrandsStore from '../../brands/brands.store';
import { Layout } from '../../../styles/layout';
import {
  Table,
  TableBody,
  TableContainer,
  TableContainerMobile,
  TableHead,
  TCell,
  THeadCell,
  TRow,
} from '../../../styles/table';
import TableSkeleton from '../../../utils/skeleton/table.skeleton';
import NoResultTitle from '../../../components/no_result_title/no_result_title.view';
import { useEffect, useState } from 'react';
import { SupplierBrandsModel } from '../suppliers.model';
import { Button } from '../../../styles/button';
import SupplierBrandsCreateEditStore from './supplier.brands.create.edit.store';
import SuppliersStore from '../suppliers.store';

interface SupplierBrandsCreateEditViewProps {
  existingBrands: SupplierBrandsModel[] | null;
  supplierID: number;
  setModal: (isOpen: boolean) => void;
}

const SupplierBrandsCreateEditView = observer(
  ({
    existingBrands,
    supplierID,
    setModal,
  }: SupplierBrandsCreateEditViewProps) => {
    const { brands, brandsPageInit, loading } = BrandsStore;
    const { isLoading, createOrEditSupplierBrand, destroy, setBrandIDs } =
      SupplierBrandsCreateEditStore;

    const { supplierBrandsReq } = SuppliersStore;
    const [selected, setSelected] = useState(
      new Array(brands!.length).fill(false)
    );

    const [selectedIDs, setSelectedIDs] = useState<number[]>([]);

    const handleOnChange = (
      position: number,
      brandID: number,
      checked: boolean
    ) => {
      const updatedCheckedState = selected.map((item, index) =>
        index === position ? !item : item
      );
      setSelected(updatedCheckedState);
      handleSelectedBrands(brandID, checked);
    };

    const handleSelectedBrands = (brandID: number, checked: boolean) => {
      if (checked) {
        if (!selectedIDs.includes(brandID)) {
          selectedIDs.push(brandID);
        }
      } else {
        const array = [...selectedIDs];
        const index = array.indexOf(brandID);
        if (index !== -1) {
          array.splice(index, 1);
          setSelectedIDs(array);
        }
      }
    };

    useEffect(() => {
      const controller = new AbortController();
      void brandsPageInit(true, controller.signal);
    }, [brandsPageInit]);

    const handleConfirmClick = async () => {
      setBrandIDs(selectedIDs);
      const res = await createOrEditSupplierBrand(supplierID);
      if (res) {
        handleCancelClick();
        void supplierBrandsReq(supplierID);
      }
    };

    const handleCancelClick = () => {
      setSelected(Array(brands!.length).fill(false));
      setModal(false);
      destroy();
    };

    return (
      <Layout vertical between={20}>
        <TableContainer>
          <Table>
            <TableHead>
              <TRow tableHead>
                <THeadCell>ID</THeadCell>
                <THeadCell>Название</THeadCell>
                <THeadCell>Страна производства</THeadCell>
                <THeadCell>Фото</THeadCell>
                <THeadCell></THeadCell>
              </TRow>
            </TableHead>
            {loading ? (
              <TableSkeleton columns={4} />
            ) : (
              <TableBody>
                {brands!.length ? (
                  brands
                    ?.filter(
                      (brand) =>
                        !existingBrands?.find(
                          (existingBrand: any) =>
                            brand.id === existingBrand.brand_id
                        )
                    )
                    .map((brand, index) => (
                      <TRow key={index} menu>
                        <TCell>{brand.id}</TCell>
                        <TCell>{brand.name}</TCell>
                        <TCell>{brand.country_of_origin}</TCell>
                        <TCell>{brand.img}</TCell>
                        <TCell relative>
                          <input
                            type="checkbox"
                            id={`brand-checkbox-${index}`}
                            checked={selected[index]}
                            onChange={() =>
                              handleOnChange(index, brand.id, !selected[index])
                            }
                          />
                        </TCell>
                      </TRow>
                    ))
                ) : (
                  <NoResultTitle colspan={4} />
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TableContainerMobile>
          <Table>
            <TableHead>
              <TRow tableHead>
                <THeadCell>ID</THeadCell>
                <THeadCell>Название</THeadCell>
                <THeadCell>Страна производства</THeadCell>
                <THeadCell>Фото</THeadCell>
                <THeadCell></THeadCell>
              </TRow>
            </TableHead>
            {loading ? (
              <TableSkeleton columns={4} />
            ) : (
              <TableBody>
                {brands!.length ? (
                  brands
                    ?.filter(
                      (brand) =>
                        !existingBrands?.find(
                          (existingBrand: any) =>
                            brand.id === existingBrand.brand_id
                        )
                    )
                    .map((brand, index) => (
                      <TRow key={index} menu>
                        <TCell>{brand.id}</TCell>
                        <TCell>{brand.name}</TCell>
                        <TCell>{brand.country_of_origin}</TCell>
                        <TCell>{brand.img}</TCell>
                        <TCell relative>
                          <input
                            type="checkbox"
                            id={`brand-checkbox-${index}`}
                            checked={selected[index]}
                            onChange={() =>
                              handleOnChange(index, brand.id, !selected[index])
                            }
                          />
                        </TCell>
                      </TRow>
                    ))
                ) : (
                  <NoResultTitle colspan={4} />
                )}
              </TableBody>
            )}
          </Table>
        </TableContainerMobile>
        <Layout hAlign={'center'} between={15}>
          <Button extent={'S'} background={'#888'} onClick={handleCancelClick}>
            Отмена
          </Button>
          <Button
            extent={'S'}
            isLoading={isLoading}
            onClick={handleConfirmClick}
          >
            {isLoading ? '' : 'Потвердить'}
          </Button>
        </Layout>
      </Layout>
    );
  }
);

export default SupplierBrandsCreateEditView;
