import styled from 'styled-components';
import {
  borderRadius,
  media,
  primaryColor,
  primaryColorTransparent,
  textColorDark,
  textColorLight,
} from '../utils/variables';
import { Image } from './image';

interface TRowProps {
  tableHead?: boolean;
  menu?: boolean;
}

export const TableContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  border-radius: ${borderRadius};
   ::-webkit-scrollbar{
     width: 5px;
     height: 5px;
     display: none;
   }
   ::-webkit-scrollbar-track{
     background-color: #dbdbdb;
     border-radius: ${borderRadius};
   }
   ::-webkit-scrollbar-thumb{
     background-color: #456186;
     border-radius: ${borderRadius};
   }
   ::-webkit-scrollbar-thumb:active{
     background-color: #006eff;
   }
  
  :hover {
    ::-webkit-scrollbar{
      display: inline-table;
    }
  }

  ${media.mobile} {
    display: none;
  }

  ${media.tablet} {
    display: none;
  }
`;

export const TableContainerMobile = styled.div<{
  isInModal?: boolean;
}>`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  border-radius: ${borderRadius};
  // ::-webkit-scrollbar{
  //   width: 5px;
  // }
  // ::-webkit-scrollbar-track{
  //   background-color: #dbdbdb;
  //   border-radius: ${borderRadius};
  // }
  // ::-webkit-scrollbar-thumb{
  //   background-color: #456186;
  //   border-radius: ${borderRadius};
  // }
  // ::-webkit-scrollbar-thumb:active{
  //   background-color: #006eff;
  // }

  ${({ isInModal }) =>
    isInModal
      ? `
          position: relative;
          `
      : ''}

  ${media.desktop} {
    display: none;
  }
`;

export const TableContainerTransaction = styled.div`
  align-self: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  border-radius: ${borderRadius};
`;

export const Table = styled.table<{
  inModal?: boolean;
}>`
  border-collapse: collapse;
  background-color: white;
  width: 100%;
  min-width: ${({inModal}) => inModal ? '' : '830px'};
  border-radius: ${borderRadius};
  font-size: 13px;
  max-width: 100%;
  max-height: calc(100vh - 255px);
  overflow-x: auto;

  ${media.mobile} {
    max-height: calc(100vh - 100px);
    min-width: calc(100vw - 50px);
    font-size: 11px;
  }

  ${media.tablet} {
    max-height: calc(100vh - 50px);
    min-width: calc(100vw - 50px);
    font-size: 11px;
  }
`;

export const TableView = styled.table`
  border-collapse: collapse;
  background-color: white;
  width: 100%;
  min-width: fit-content;
  border-radius: ${borderRadius};
  font-size: 13px;
  max-width: 100%;
  max-height: calc(100vh - 500px);
`;

export const TableHead = styled.thead``;
export const TableBody = styled.tbody`
  ${media.mobile} {
    width: 100%;
  }
`;

export const ContextMenu = styled.ul<{
  anchorEl: any;
}>`
  position: fixed;
  display: none;
  background: #fefeff;
  min-width: 100px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  padding: 0;
  right: ${({ anchorEl }) => {
    return anchorEl
      ? window.innerWidth - anchorEl.getBoundingClientRect().left + 'px'
      : 'unset';
  }};
  top: ${({ anchorEl }) => {
    return anchorEl
      ? anchorEl.getBoundingClientRect().top + 40 + 'px'
      : 'unset';
  }};
  transform: translateY(-50%);
  border-radius: 4px;
  z-index: 1;
  margin: 0;
`;

export const ContextMenuItem = styled.li`
  list-style: none;
  padding: 6px 14px;
  cursor: pointer;
  :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  :hover {
    background-color: #c8daea;
  }
`;

export const TRow = styled.tr<TRowProps>`
  background: ${({ tableHead }) =>
    tableHead ? `${primaryColor} !important` : 'unset'};
  position: ${({ tableHead }) => (tableHead ? 'sticky' : 'unset')};
  z-index: 1;
  top: 0;
  color: ${({ tableHead }) => (tableHead ? textColorLight : textColorDark)};

  &:first-child {
    border-top-left-radius: ${borderRadius};
    border-top-right-radius: ${borderRadius};
  }

  &:last-child {
    border-bottom-left-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
    & td:first-child {
      border-bottom-left-radius: ${borderRadius};
    }
    & td:last-child {
      border-bottom-right-radius: ${borderRadius};
    }
  }
  & th:first-child {
    border-top-left-radius: ${borderRadius};
  }

  & th:last-child {
    border-top-right-radius: ${borderRadius};
  }

  ${({ menu }) =>
    menu
      ? `& td:last-child {
    text-align: left;
    ul {
      text-align: left;
    }
  }`
      : ''}

  &:nth-child(odd) {
    background-color: #f5f5f5;
  }

  ${Image} {
    opacity: 50;
    transition: 250ms;
    cursor: pointer;
    padding: 0 4px;
  }

  ${Image} {
    &.context-menu-focused {
      + ${ContextMenu} {
        display: block;
      }
    }
  }

  :hover {
    background-color: ${primaryColorTransparent};
    ${Image} {
      opacity: 0.8;
      :hover {
        opacity: 60;
      }
    }
  }
`;

export const THeadCell = styled.th`
  padding: 10px 12px;
  text-align: start;
`;

export const TCell = styled.td<{
  width?: string;
  flex?: string;
  relative?: boolean;
}>`
  padding: 10px 12px;
  position: ${({ relative }) => (relative ? 'relative' : 'unset')};
`;

export const ButtonImgArrowIcon = styled(Image)`
  width: 20px;
  background-color: red;
  ${({ rotate }) =>
    rotate
      ? `
            border-left: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        `
      : `
            border-right: none;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        `}
  :hover {
    border: 3px solid green;
  }
`;

export const EyeImgIcon = styled(Image)<{
  active?: boolean
}>`
  filter: brightness(0) saturate(100%) invert(17%) sepia(0%) saturate(1%)
    hue-rotate(224deg) brightness(96%) contrast(90%);
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
