import { observer } from 'mobx-react';
import CreateOperationStore from '../products_transactions/create.operation.modal/create.operation.store';
import { useEffect } from 'react';
import { SearchLayout, TableHeader } from '../../styles/table-header';
import { Layout } from '../../styles/layout';
import { Image } from '../../styles/image';
import SearchIcon from '../../assets/icons/search.svg';
import { Search } from '../../styles/table-header';
import {
  TableBody,
  TableContainerTransaction,
  TableHead,
  TableView,
  TCell,
  THeadCell,
  TRow,
} from '../../styles/table';
import TableSkeleton from '../../utils/skeleton/table.skeleton';
import NoResultTitle from '../../components/no_result_title/no_result_title.view';
import transferCreateEditeStore from './transfer_of_goods.modal/transfer.create.edite.store';

interface StocksModalViewProps {
  businessUnitID: number;
  setModal: (isOpen: boolean) => void;
}

const StocksModalView = observer(
  ({ businessUnitID, setModal }: StocksModalViewProps) => {
    const { setSearch, stocks, stocksReq, stockLoading } = CreateOperationStore;

    const { selectedProducts, selectSubmit } = transferCreateEditeStore;
    useEffect(() => {
      const controller = new AbortController();
      void stocksReq(controller.signal, businessUnitID);
    }, [stocksReq]);

    const handleKeyDown = (e: any) => {
      setSearch(e.target.value, 'stock', businessUnitID);
    };

    const cancelSearch = (e: any) => {
      setSearch('', 'stock', businessUnitID);
    };

    const handleSelectProduct = (object: any, type: string) => {
      setSearch('', 'stock');
      object.summ = '';
      object.totalSumm = '';
      object.type = type;
      object.id = object.goods_id;
      selectSubmit(object);
      setModal(false);
    };

    return (
      <>
        <TableHeader>
          <Layout>
            <SearchLayout>
              <Search
                type="search"
                id="search"
                placeholder="Поиск"
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(e) => cancelSearch(e)}
              ></Search>
              <Image src={SearchIcon} height="16px" />
            </SearchLayout>
          </Layout>
        </TableHeader>
        <TableContainerTransaction style={{ maxWidth: '100%' }}>
          <TableView style={{ maxWidth: '100%' }}>
            <TableHead>
              <TRow tableHead>
                <THeadCell>Наименование</THeadCell>
                <THeadCell>Бренд</THeadCell>
                <THeadCell>Цена</THeadCell>
                <THeadCell>В наличии</THeadCell>
              </TRow>
            </TableHead>
            {stockLoading ? (
              <TableSkeleton columns={4} />
            ) : (
              <TableBody>
                {stocks!.length ? (
                  stocks!
                    ?.filter(
                      (item) =>
                        !selectedProducts.find(
                          (item1: any) => item.goods_id === item1.id
                        )
                    )
                    .map((product, index) => (
                      <TRow
                        key={index}
                        menu
                        onClick={() => handleSelectProduct(product, 'goods')}
                      >
                        <TCell>{product.name}</TCell>
                        <TCell>{product.brand}</TCell>
                        <TCell>{product.set_price}</TCell>
                        <TCell>{product.quantity}</TCell>
                      </TRow>
                    ))
                ) : (
                  <NoResultTitle colspan={4} />
                )}
              </TableBody>
            )}
          </TableView>
        </TableContainerTransaction>
      </>
    );
  }
);

export default StocksModalView;
