import {EmployeeDeductionsFilterModel, EmployeeDeductionsModel} from "./employee.deductions.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import axios from "axios";
import {api} from "../../../api/endpoints";
import {toast} from "react-toastify";

const employeeAllowanceFilterInitialState: EmployeeDeductionsFilterModel = {
    search: '',
    user_id: 0,
    employee_id: 0,
    paid: '',
    page: 1,
    pages: 1,
    page_limit: 25,
    order_descending: true,
}

const employeeDeductionInitialState: EmployeeDeductionsModel[] = [];
const loadingInitialState: boolean = false;

class EmployeeDeductionStore {
    public employeeDeductionsFilters = employeeAllowanceFilterInitialState;
    public employeeDeductions = employeeDeductionInitialState;
    public isLoading = loadingInitialState;
    error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public init = async (signal: AbortSignal) => {
        await this.employeeDeductionsReq(signal);
    }

    setDeduction(data: any) {
        const {deductions} = data;
        this.employeeDeductions = deductions;
    }

    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    private setEmployeeDeductionFilterPages = (pages: number) => {
        this.employeeDeductionsFilters.pages = pages;
    }

    public clearFilter = () => {
        this.employeeDeductionsFilters = employeeAllowanceFilterInitialState;
        void this.employeeDeductionsReq();
    }

    public employeeDeductionsReq = async (signal?: AbortSignal) => {
        this.setLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        const filterUrl = Object.keys(this.employeeDeductionsFilters).reduce((filters, filter) => {
            const symbol = filters ? '&' : '?';
            const filterKeyName = filter as keyof EmployeeDeductionsFilterModel;
            if (this.employeeDeductionsFilters[filterKeyName] !== 0 &&
                this.employeeDeductionsFilters[filterKeyName] !== '') {
                filters += symbol + filter + '=' + this.employeeDeductionsFilters[filterKeyName];
            }

            return filters;
        }, '');

        try {

            const response = await axios.get(api.payrolls + '/employee/deductions' + filterUrl, {headers, signal});
            this.setDeduction(response.data);
            this.setEmployeeDeductionFilterPages(response.data.pages);

        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }

    public setEmployeeDeductionFilter = ({
                                             search,
                                             user_id,
                                             employee_id,
                                             paid,
                                             page,
                                             page_limit
                                         }: EmployeeDeductionsFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.employeeDeductionsFilters = {
            ...this.employeeDeductionsFilters,
            search: isUndefined(search) ? this.employeeDeductionsFilters.search! : search!,
            user_id: isUndefined(user_id) ? this.employeeDeductionsFilters.user_id! : user_id!,
            employee_id: isUndefined(employee_id) ? this.employeeDeductionsFilters.employee_id! : employee_id!,
            paid: isUndefined(paid) ? this.employeeDeductionsFilters.paid! : paid!,
            page: isUndefined(page) ? this.employeeDeductionsFilters.page! : page!,
            page_limit: isUndefined(page_limit) ? this.employeeDeductionsFilters.page_limit! : page_limit!
        }

        if (page_limit) {
            this.employeeDeductionsFilters.page = 1;
        }

        void this.employeeDeductionsReq();
    }
}

export default new EmployeeDeductionStore();