import {observer} from "mobx-react";
import {SuppliersModel} from "../suppliers.model";
import {Layout} from "../../../styles/layout";
import {
    Table,
    TableBody,
    TableContainer,
    TableContainerMobile,
    TableHead,
    TCell,
    THeadCell,
    TRow
} from "../../../styles/table";
import {AddButton} from "../../../styles/table-header";
import SuppliersStore from "../suppliers.store";
import {useEffect, useState} from "react";
import TableSkeleton from "../../../utils/skeleton/table.skeleton";
import NoResultTitle from "../../../components/no_result_title/no_result_title.view";
import SupplierBrandsCreateEditView from "./supplier.brands.create.edit.view";
import AccountModalView from "../../../components/modal/account.modal.view";

interface SupplierBrandsViewProps {
    initialValue: SuppliersModel | null;
}

const SupplierBrandsView = observer(({initialValue}: SupplierBrandsViewProps) => {

    const {
        supplierBrandsLoading,
        supplierBrands,
        supplierBrandsInit
    } = SuppliersStore;
    const [modal, setModal] = useState(false);

    useEffect(() => {
        supplierBrandsInit(initialValue?.id!)
    }, [supplierBrandsInit]);

    return (
            <Layout vertical between={10}>
                <Layout hAlign={"flex-end"}>
                    <AddButton type="button" id="add-supplier-brand" onClick={() => setModal(true)}>
                        Добавить
                    </AddButton>
                </Layout>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TRow tableHead>
                                <THeadCell>ID</THeadCell>
                                <THeadCell>Название бренда</THeadCell>
                                <THeadCell>Иконка</THeadCell>
                            </TRow>
                        </TableHead>
                        {supplierBrandsLoading ? (
                            <TableSkeleton columns={3}/>
                        ) : (
                            <TableBody>
                                {supplierBrands?.length ? (
                                    supplierBrands?.map((brand, index) => (
                                        <TRow key={index} menu>
                                            <TCell>{brand.id}</TCell>
                                            <TCell>{brand.brand_name}</TCell>
                                            <TCell>{brand.image}</TCell>
                                        </TRow>
                                    ))
                                    ) : (
                                    <NoResultTitle colspan={3}/>
                                )}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TableContainerMobile>
                    <Table>
                        <TableHead>
                            <TRow tableHead>
                                <THeadCell>ID</THeadCell>
                                <THeadCell>Название бренда</THeadCell>
                                <THeadCell>Иконка</THeadCell>
                            </TRow>
                        </TableHead>
                        {supplierBrandsLoading ? (
                            <TableSkeleton columns={3}/>
                        ) : (
                            <TableBody>
                                {supplierBrands?.length ? (
                                    supplierBrands?.map((brand, index) => (
                                        <TRow key={index} menu>
                                            <TCell>{brand.id}</TCell>
                                            <TCell>{brand.brand_name}</TCell>
                                            <TCell>{brand.image}</TCell>
                                        </TRow>
                                    ))
                                ) : (
                                    <NoResultTitle colspan={3}/>
                                )}
                            </TableBody>
                        )}
                    </Table>
                </TableContainerMobile>
                {modal && (
                    <AccountModalView
                        title={`Добавить бренды поставщика ${initialValue?.name}`}
                        onClose={() => setModal(false)}
                    >
                        <SupplierBrandsCreateEditView
                            existingBrands={supplierBrands}
                            supplierID={initialValue?.id!}
                            setModal={setModal}
                        />
                    </AccountModalView>
                )}
            </Layout>
    );
});

export default SupplierBrandsView;