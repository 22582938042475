import {observer} from "mobx-react";
import {EyeImgIcon, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import {PayrollsModel} from "./payrolls.model";
import {useState} from "react";
import {Layout} from "../../../styles/layout";
import EyeIcon from "../../../assets/icons/eye.svg";
import {primaryColor} from "../../../utils/variables";
import NoResultTitle from "../../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../../utils/skeleton/table.skeleton";
import ModalView from "../../../components/modal/modal.view";
import {Text} from "../../../styles/text";
import {Badge} from "../../../styles/badge";
import {Button} from "../../../styles/button";
import AppStore from "../../../app.store";
import {handleDownloadFile} from "../../../utils/handleDownloadFile";
import {api} from "../../../api/endpoints";

interface PayrollsMobileViewProps {
    isLoading: boolean;
    payrolls: PayrollsModel[] | undefined;
    handleItemsClick: (payroll: PayrollsModel) => void;
    tableHeaders: (key: string) => string;
}

const PayrollsMobileView = observer(({
                                         isLoading,
                                         payrolls,
                                         handleItemsClick,
                                         tableHeaders
                                     }: PayrollsMobileViewProps) => {
    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<PayrollsModel | null>(null);

    const handleRowClick = (payroll: PayrollsModel) => {
        setModal(true);
        setInitialValue(payroll);
    };

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    };

    const handleDownloadPDF = async (payroll: PayrollsModel) => {
        const {getToken} = AppStore;
        await handleDownloadFile(api.payrolls + `/pdf?id=${payroll.id}`, getToken(), `Расчетный лист ${payroll.owner_payroll_id}`, 'PDF')
    }

    const renderModalItems = (payroll: PayrollsModel) => (
        <Layout vertical between={10}>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('owner_payroll_id')}</Text>
                <Text>{payroll.owner_payroll_id}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('user_name')}</Text>
                <Text>{payroll.user_name}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('employee_name')}</Text>
                <Text>{payroll.employee_name}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('period')}</Text>
                <Text>{payroll.period}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('salary')}</Text>
                <Text>{payroll.salary}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('allowance')}</Text>
                <Text>{payroll.allowance}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('deduction')}</Text>
                <Text>{payroll.deduction}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('total_amount')}</Text>
                <Text>{payroll.total_amount}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('payment_method')}</Text>
                <Text>{payroll.payment_method}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('account')}</Text>
                <Text>{payroll.account}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('financial_institution')}</Text>
                <Text>{payroll.financial_institution}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('details')}</Text>
                <Text>{payroll.details}</Text>
            </Layout>
            <Layout hAlign={'space-between'}>
                <Text weight={'bold'}>{tableHeaders('date')}</Text>
                <Text>{payroll.date}</Text>
            </Layout>
            <Layout hAlign={'center'} between={5}>
                <Button onClick={() => handleItemsClick(payroll)}>Подробнее</Button>
                <Button onClick={() => handleDownloadPDF(payroll)}>Скачать PDF</Button>
            </Layout>
        </Layout>
    );

    const renderedItems = () => (
        <>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>{tableHeaders('employee_name')}</THeadCell>
                        <THeadCell>{tableHeaders('period')}</THeadCell>
                        <THeadCell>{tableHeaders('total_amount')}</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                {!isLoading ? (
                    <TableBody>
                        {payrolls?.length ? (
                            payrolls?.map((payroll, index) => (
                                <TRow key={index} menu>
                                    <TCell>{payroll.employee_name}</TCell>
                                    <TCell>{payroll.period}</TCell>
                                    <TCell>{payroll.total_amount}</TCell>
                                    <TCell relative>
                                        <EyeImgIcon
                                            src={EyeIcon}
                                            onClick={() => handleRowClick(payroll)}
                                            height="18px"
                                            color={primaryColor}
                                        />
                                    </TCell>
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={4}/>
                        )}
                    </TableBody>
                ) : (
                    <TableSkeleton columns={4}/>
                )}
            </Table>
            {modal && (
                <ModalView
                    title={`Выплата ${initialValue?.employee_name}`}
                    onClose={handleCloseModal}
                >
                    {renderModalItems(initialValue!)}
                </ModalView>
            )}
        </>
    );

    return <Layout>{renderedItems()}</Layout>;
});

export default PayrollsMobileView;