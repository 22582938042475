import {EmployeeDeductionsModel} from "../employee.deductions.model";
import {observer} from "mobx-react";
import EmployeeDeductionsCreateEditStore
    from "../../employee.deductions/employee.deductions.modal/employee.deductions.create.edit.store";
import EmployeeDeductionsStore from "../../employee.deductions/employee.deductions.store";
import {useEffect} from "react";
import {Layout} from "../../../../styles/layout";
import {renderInputs} from "../../../../components/form/render.inputs";
import {Button} from "../../../../styles/button";
import {isValid} from "../../../../utils/isValid";
import DictionaryStore from "../../../../utils/dictionary/dictionary.store";

interface EmployeeDeductionsCreateEditViewProps {
    setModal: (isOpen: boolean) => void;
    initialValue: EmployeeDeductionsModel | null;
}

const EmployeeDeductionsCreateEditView = observer(({setModal, initialValue}: EmployeeDeductionsCreateEditViewProps) => {
    const {
        form,
        destroy,
        init,
        employeeDeductionCreateEditReq,
        usersOptions,
        deductionsOptions,
        setField,
        isLoading,
    } = EmployeeDeductionsCreateEditStore;
    const {employeeDeductionsReq} = EmployeeDeductionsStore;

    const {employee_id, deduction_id, amount, details} = form;

    const {destroyModalDictionary, dictionaryListReq, getModalPlaceholders} = DictionaryStore;

    useEffect(() => {
        destroyModalDictionary();
        const controller = new AbortController();
        void dictionaryListReq('EMPLOYEE DEDUCTIONS', 'MODAL', controller.signal);
        void init(initialValue);

        return () => {
            destroy();
            controller.abort();
        }
    }, [destroyModalDictionary, dictionaryListReq, destroy ,init, initialValue]);

    const handleCreateEditEmployeeDeduction = async () => {
        const resp = await employeeDeductionCreateEditReq(!!initialValue);

        if (resp) {
            setModal(false);
            destroy();
            await employeeDeductionsReq();
        }
    }

    const inputs = [
        {
            field: employee_id,
            options: usersOptions,
            fieldName: 'employee_id',
            placeholder: getModalPlaceholders('employee_id'),
            type: 'select',
            required: true
        },
        {
            field: deduction_id,
            options: deductionsOptions,
            fieldName: 'deduction_id',
            placeholder: getModalPlaceholders('deduction_id'),
            type: 'select',
            required: true
        },
        {
            field: amount,
            fieldName: 'amount',
            placeholder: getModalPlaceholders('amount'),
            type: 'text',
            required: true
        },
        {
            field: details,
            fieldName: 'details',
            placeholder: getModalPlaceholders('details'),
            type: 'text',
            required: false
        },
    ];

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setField)}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={isLoading}
                    onClick={handleCreateEditEmployeeDeduction}
                    disabled={isValid(inputs)}
                >
                    {isLoading ? '' : renderSubmitText}
                </Button>
            </Layout>
        </Layout>
    );
});

export default EmployeeDeductionsCreateEditView;