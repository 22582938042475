import { ProfileModel } from '../settings.model';
import axios from 'axios';
import { api } from '../../../api/endpoints';
import { makeAutoObservable } from 'mobx';
import AppStore from '../../../app.store';
import { toast } from 'react-toastify';

const initialValues: ProfileModel = {
  id: 0,
  name: '',
  surname: '',
  patronymic: '',
  full_name: '',
  login: '',
  phone: '',
  email: '',
  role: '',
  role_id: 0,
  business_unit: '',
  business_unit_id: 0,
  active: false,
  online: false,
};

class ProfileEditStore {
  public form = initialValues;
  changeForm = (object: any) => {
    this.form = object;
  };
  public error = '';
  public message = '';
  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }
  public businessReq = async (signal?: any) => {
    try {
      const { getToken } = AppStore;

      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };
      const response = await axios.get(api.profile, { headers, signal });
      this.form = response.data.user_data;
    } catch (err) {
      console.error(err);
    }
  };
  public updatePasswordReq = async () => {
    try {
      this.isLoading = true;
      const { getToken } = AppStore;
      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };
      const response = await axios.put(api.profile, this.form, { headers });
      this.message = response?.data.message;
      toast.success(this.message);
      const { getProfile } = AppStore;
      await getProfile();
      this.isLoading = false;
    } catch (err: any) {
      this.error = err?.response.data.reason;
      toast.error(this.error);
    }
  };
}

export default new ProfileEditStore();
