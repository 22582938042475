import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import SalesReportStore from "./sales.report.store";
import {useEffect, useState} from "react";
import {Text} from "../../../styles/text";
import ChartPieView from "../../../components/graphs/chart.pie.view";
import {sizes} from "../../../utils/variables";

const SalesReportPieView = observer(() => {
    const {
        buSalesReports,
        userSalesReports,
        getUserSalesReportsForPieReq,
        getBuSalesReportsForPieReq,
        salesReportFilters
    } = SalesReportStore;

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const vAlign = () => {
        return windowWidth < sizes.desktop
    }

    useEffect(() => {
        const controller = new AbortController();
        void getUserSalesReportsForPieReq(controller.signal);
        void getBuSalesReportsForPieReq(controller.signal);
        window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
        return () => controller.abort();
    }, [getUserSalesReportsForPieReq, getBuSalesReportsForPieReq, salesReportFilters]);

    return (
        <Layout vertical centered between={30} style={{margin: '10px'}} widthSize={'100%'}>
            <Layout vertical between={20} widthSize={'100%'}>
                <Layout centered>
                    <Text weight={'bold'} extent={'L'}>По сотрудникам</Text>
                </Layout>
                <Layout
                    vAlign={"center"}
                    vertical={vAlign()}
                    hAlign={"space-around"}
                    between={20}
                >
                    <ChartPieView
                        title='Оборот'
                        data={userSalesReports}
                        angleField='turnover'
                        colorField='entity_name'
                        width={450}
                    />
                    <ChartPieView
                        title='Количество операций'
                        data={userSalesReports}
                        angleField='quantity'
                        colorField='entity_name'
                        width={450}
                    />
                </Layout>
            </Layout>
            <Layout vertical between={20} widthSize={'100%'}>
                <Layout centered>
                    <Text weight={'bold'} extent={'L'}>По офисам</Text>
                </Layout>
                <Layout
                    vAlign={"center"}
                    vertical={vAlign()}
                    hAlign={"space-around"}
                    between={20}
                >
                    <ChartPieView
                        title='Оборот'
                        data={buSalesReports}
                        angleField='turnover'
                        colorField='entity_name'
                        width={450}
                    />
                    <ChartPieView
                        title='Количество операций'
                        data={buSalesReports}
                        angleField='quantity'
                        colorField='entity_name'
                        width={450}
                    />
                </Layout>
            </Layout>
        </Layout>
    );
});

export default SalesReportPieView;