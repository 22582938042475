import {observer} from "mobx-react";
import {NavChildItem, NavItem, NavList, StyledBadge} from "../../styles/nav";
import LinksStore from "./links";
import {hasPermission} from "../../utils/hasPermission";
import {NavLink} from "react-router-dom";
import {Collapse} from "antd";
import {Image} from "../../styles/image";
import {Layout} from "../../styles/layout";
import {Text} from "../../styles/text";
import AppStore from "../../app.store";
import React, {useEffect} from "react";

const {Panel} = Collapse;

interface NavLinkProps {
    isActive?: boolean;
}

interface NavListProps {
    burgerOpen?: boolean;
    setOpen: (isOpen: boolean) => void;
}

const NavbarListView = observer(({burgerOpen, setOpen}: NavListProps) => {
    return (
        <NavList burgerOpen={burgerOpen}>
            <RenderNavItems setOpen={setOpen}/>
        </NavList>
    );
});

export const RenderNavItems = observer(({setOpen}: NavListProps) => {
    const {
        isNavOpen,
        permissions
    } = AppStore;

    const {NavItemsReq, NavItems} = LinksStore;

    useEffect(() => {
        const controller = new AbortController();
        void NavItemsReq(controller.signal);

        return () => {
            controller.abort();
        }
    }, [NavItemsReq]);

    if (!permissions) {
        return null;
    }

    const handleLinkClick = () => {
        setOpen(false);
    }

    const renderNavItems = NavItems
        .filter(item => !item.hidden)
        .filter(item => !item.permission || hasPermission(permissions, item.permission, 'READ'))
        .map((item, index) => (
            <NavLink to={item.route!} key={index}>
                {({isActive}: NavLinkProps) => (
                    <>
                        {item.children ?
                            <StyledBadge isNavOpen={isNavOpen}>
                                <Collapse>
                                    <Image src={item.icon} height="15px" extent="15px" style={{
                                        marginLeft: "20px",
                                        position: "absolute",
                                        marginTop: "17px",
                                        opacity: "0.5"
                                    }}/>
                                    <Panel header={item.label} key={index}>
                                        {item.children.map((child, childIndex) =>
                                            <NavLink to={child.route} key={(index + 1) * childIndex}>
                                                {({isActive}) => (
                                                    <NavChildItem isActive={isActive} isNavOpen={isNavOpen}
                                                                  onClick={handleLinkClick}>
                                                        <Layout
                                                            vAlign="center"
                                                            between={12}
                                                        >
                                                            <Image src={child.icon} height="15px" extent="15px"/>
                                                            <Text extent="S" color="white"> {child.label}</Text>
                                                        </Layout>
                                                    </NavChildItem>
                                                )}
                                            </NavLink>
                                        )}
                                    </Panel>
                                </Collapse>
                            </StyledBadge>
                            : <NavItem isActive={isActive} onClick={handleLinkClick}>
                                <Layout
                                    vAlign="center"
                                    between={12}
                                >
                                    <Image src={item.icon} height="15px" extent="15px"/>
                                    <Text extent="S" color="white">{item.label}</Text>
                                </Layout>
                            </NavItem>}
                    </>
                )}
            </NavLink>
        ));

    return (
        <>
            {renderNavItems}
        </>
    )
});

export default NavbarListView;