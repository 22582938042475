import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import AppStore from "../../app.store";
import {Headline} from "../../styles/headline";
import {Layout} from "../../styles/layout";
import {MainPageLayout} from "../../styles/page";
import {primaryColor} from "../../utils/variables";
import MainStore from "./main.store";
import {
    InfoBox,
    InfoBoxLayout,
    InfoBoxTitle,
    InfoBoxValue,
    InfoBoxIncomePercent, ButtonContainer, DeleteElementButton, TableContainerMainPage,
} from "./main.style";
import ARROW_UP from "../../assets/icons/arrow-up-circle.svg";
import ARROW_DOWN from "../../assets/icons/arrow-down-circle.svg";
import MyLoader from "./main.skeleton";
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import {Text} from "../../styles/text";
import {MainModel} from "./main.model";
import {
    Table,
    TableBody,
    TableContainer,
    TableContainerMobile,
    TableHead,
    TCell,
    THeadCell,
    TRow
} from "../../styles/table";
import {Button} from "../../styles/button";
import ModalView from "../../components/modal/modal.view";
import MainAddComponentModalView from "./main.add.component.modal.view";
import Draggable from "react-draggable";
import {AddButton} from "../../styles/table-header";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import {getCreateModal} from "./get_create_modal";
import {getModalTitle} from "./get_modal_title";
import AccountModalView from "../../components/modal/account.modal.view";

const MainView = observer(() => {
    const {isNavOpen} = AppStore;
    const {
        loading,
        components,
        getItemsReq,
        deleteComponentFromItemList,
        setItemField,
        updateMainPageComponents,
        destroyComponent
    } = MainStore;
    const {getPageTitle} = DictionaryStore;
    const [edit, setEdit] = useState(false);
    const [addComponentModal, setAddComponentModal] = useState(false);
    const [initialValue, setInitialValue] = useState<MainModel | null>(null);

    useEffect(() => {
        const controller = new AbortController();

        void getItemsReq(controller.signal);
        return () => {
            controller.abort();
        };
    }, [getItemsReq]);

    const handleCancel = async () => {
        await updateMainPageComponents();
        setEdit(false);
    }

    const handleClickSaveButton = async () => {
        await updateMainPageComponents();
        setEdit(false);
    }

    const handleClickEditButton = () => {
        setEdit(true);
    }

    const handleDeleteComponent = (index: number) => {
        deleteComponentFromItemList(index);
    }

    const [modal, setModal] = useState(false);

    const handleActionButtonClick = (initialValue: MainModel) => {
        setInitialValue(initialValue!);
        setModal(true)
    }

    const handleOnStop = (index: number, data: any) => {
        setItemField(index, 'position_x', data.x);
        setItemField(index, 'position_y', data.y)
    }

    const handleCloseAddComponentModal = () => {
        setAddComponentModal(false);
        destroyComponent();
    }

    const renderItemComponents = (item: MainModel, index: number) => {
        switch (item.type) {
            case 'BUTTON':
                return (
                    <Draggable
                        disabled={!edit}
                        bounds={'parent'}
                        defaultPosition={{x: item.position_x, y: item.position_y}}
                        onStop={(_, data,) => handleOnStop(index, data)}
                    >
                        <ButtonContainer
                            vertical
                            wrapped
                            between={10}
                            key={index}
                            id={`${item.type}${index}`}
                            edit={edit}
                            centered
                        >
                            <Layout
                                hAlign={"space-between"}
                                vAlign={"center"}
                            >
                                <Layout widthSize={'60%'}>
                                    <Text>{item.title}</Text>
                                </Layout>
                                <DeleteElementButton
                                    display={edit}
                                    onClick={() => handleDeleteComponent(index)}
                                >
                                    X
                                </DeleteElementButton>
                            </Layout>
                            <AddButton
                                onClick={() => handleActionButtonClick(item)}
                                disabled={edit}
                            >
                                {item.subtype === 'CREATE' ? 'Создать' : 'Скачать'}
                            </AddButton>
                            <Layout>
                                <Text>{item.description}</Text>
                            </Layout>
                        </ButtonContainer>
                    </Draggable>
                );
            case 'WIDGET':
                const data = item.data?.data![0]
                return (
                    <Draggable
                        disabled={!edit}
                        bounds={'parent'}
                        defaultPosition={{x: item.position_x, y: item.position_y}}
                        onStop={(_, data) => handleOnStop(index, data)}
                    >
                        <InfoBoxLayout
                            vertical
                            edit={edit}
                            id={`${item.type}${index}`}
                        >
                            <InfoBoxComponent
                                key={index}
                                text={data?.field_1}
                                value={data?.field_2}
                                value2={data?.field_3}
                                is_percent={data?.field_4}
                                index={index}
                                edit={edit}
                                handleDeleteComponent={handleDeleteComponent}
                            />
                        </InfoBoxLayout>
                    </Draggable>
                );
            case 'TABLE':
                return (
                    <Draggable
                        disabled={!edit}
                        bounds={'parent'}
                        defaultPosition={{x: item.position_x, y: item.position_y}}
                        onStop={(_, data) => handleOnStop(index, data)}
                    >
                        <TableContainerMainPage
                            vertical
                            between={5}
                            edit={edit}
                            extent={item.size}
                            id={`${item.type}${index}`}
                        >
                            <Layout hAlign={"space-between"}>
                                <Layout centered>
                                    <Text weight={'600'} color={'#444'}>{item.title}</Text>
                                </Layout>
                                <Layout hAlign={"flex-end"}>
                                    <DeleteElementButton
                                        display={edit}
                                        onClick={() => handleDeleteComponent(index)}
                                    >
                                        X
                                    </DeleteElementButton>
                                </Layout>
                            </Layout>
                            <TableContainer>
                                <Table inModal>
                                    <TableHead>
                                        <TRow tableHead>
                                            <THeadCell>{item.data?.headers.field_1}</THeadCell>
                                            <THeadCell>{item.data?.headers.field_2}</THeadCell>
                                            <THeadCell>{item.data?.headers.field_3}</THeadCell>
                                            <THeadCell>{item.data?.headers.field_4}</THeadCell>
                                        </TRow>
                                    </TableHead>
                                    <TableBody>
                                        {item.data?.data?.length ? (
                                            item.data.data.map((datum, index) => (
                                                <TRow
                                                    key={index}
                                                    menu
                                                >
                                                    <TCell>{datum.field_1}</TCell>
                                                    <TCell>{datum.field_2}</TCell>
                                                    <TCell>{datum.field_3}</TCell>
                                                    <TCell>{datum.field_4}</TCell>
                                                </TRow>
                                            ))
                                        ) : (<NoResultTitle colspan={4}/>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainerMobile>
                                <Table inModal>
                                    <TableHead>
                                        <TRow tableHead>
                                            <THeadCell>{item.data?.headers.field_1}</THeadCell>
                                            <THeadCell>{item.data?.headers.field_2}</THeadCell>
                                            <THeadCell>{item.data?.headers.field_3}</THeadCell>
                                            <THeadCell>{item.data?.headers.field_4}</THeadCell>
                                        </TRow>
                                    </TableHead>
                                    <TableBody>
                                        {item.data?.data?.length ? (
                                            item.data.data.map((datum, index) => (
                                                <TRow
                                                    key={index}
                                                    menu
                                                >
                                                    <TCell>{datum.field_1}</TCell>
                                                    <TCell>{datum.field_2}</TCell>
                                                    <TCell>{datum.field_3}</TCell>
                                                    <TCell>{datum.field_4}</TCell>
                                                </TRow>
                                            ))
                                        ) : (<NoResultTitle colspan={4}/>)}
                                    </TableBody>
                                </Table>
                            </TableContainerMobile>
                        </TableContainerMainPage>
                    </Draggable>
                );
            default:
                return null
        }
    }

    return (
        <MainPageLayout isNavOpen={isNavOpen} vertical between={20} edit={edit}>
            <Layout
                hAlign={"space-between"}
                vAlign={"center"}
                heightSize={20}
            >
                <Layout>
                    <Headline color={primaryColor}>{getPageTitle('MAIN')}</Headline>
                </Layout>
                {edit && (
                    <Layout
                        centered
                    >
                        <Button
                            onClick={() => {
                                setAddComponentModal(true)
                            }}
                        >
                            Добавить компонент
                        </Button>
                    </Layout>
                )}
                <Layout>
                    {edit ? (
                        <Layout between={10}>
                            <Button
                                onClick={() => handleClickSaveButton()}
                            >
                                Сохранить
                            </Button>
                            <Button
                                onClick={handleCancel}
                            >
                                Отменить
                            </Button>
                        </Layout>
                    ) : (
                        <Button
                            onClick={handleClickEditButton}
                        >
                            Изменить страницу
                        </Button>
                    )}
                </Layout>
            </Layout>

            {!loading ? (
                <Layout
                    widthSize={'100%'}
                    style={{
                        position: 'relative',
                        zIndex: 0,
                        height: 'calc(100vh - 150px)',
                        overflowY: 'auto'
                    }}
                >
                    {components?.map((item, index) => (
                        renderItemComponents(item, index)
                    ))}
                </Layout>
            ) : (
                <MyLoader/>
            )}
            {addComponentModal && (
                <ModalView
                    title={'Добавить компонент'}
                    onClose={handleCloseAddComponentModal}
                >
                    <MainAddComponentModalView setModal={setAddComponentModal}/>
                </ModalView>
            )}
            {modal && (
                <>
                    {initialValue?.entity === 'PRODUCT_MASS' ? (
                        <AccountModalView
                            title={`Создать ${getModalTitle(initialValue?.entity!)}`}
                            onClose={() => setModal(false)}
                        >
                            {getCreateModal(initialValue?.entity!, setModal)!}
                        </AccountModalView>
                    ) : (
                        <ModalView
                            title={`Создать ${getModalTitle(initialValue?.entity!)}`}
                            onClose={() => setModal(false)}
                        >
                            {getCreateModal(initialValue?.entity!, setModal)!}
                        </ModalView>
                    )}

                </>

            )}
        </MainPageLayout>
    );
});

interface InfoBoxComponentProps {
    text: any;
    value: any;
    value2: any;
    is_percent: any;
    edit: boolean;
    handleDeleteComponent: (index: number) => void;
    index: number
}

const InfoBoxComponent = ({
                              text,
                              value,
                              value2,
                              is_percent,
                              edit,
                              handleDeleteComponent,
                              index,
                          }:
                              InfoBoxComponentProps
    ) => {
        return (
            <InfoBox>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <InfoBoxTitle>{text}</InfoBoxTitle>
                    <DeleteElementButton
                        display={edit}
                        onClick={() => handleDeleteComponent(index)}
                    >
                        X
                    </DeleteElementButton>
                </Layout>
                <InfoBoxValue>{value}</InfoBoxValue>
                {is_percent ? (
                    <InfoBoxIncomePercent profit={value2 > 0}>
                        <Layout vAlign="center">
                            {value2 > 0 ? (
                                <img
                                    className="arrow arrow-up"
                                    src={ARROW_UP}
                                    alt="arrow-icon-up"
                                />
                            ) : (
                                <img
                                    className="arrow arrow-down"
                                    src={ARROW_DOWN}
                                    alt="arrow-icon-down"
                                />
                            )}
                            <span>{value2}%</span>
                        </Layout>
                    </InfoBoxIncomePercent>
                ) : (
                    <Layout>
                        <span>{value2}</span>
                    </Layout>
                )}
            </InfoBox>
        );
    }
;

export default MainView;
