import axios from "axios";
import {makeAutoObservable} from "mobx";
import {api} from "../../api/endpoints";
import AppStore from "../../app.store";
import ChartsStore from "./charts.config"
import {Data, MainModel} from "./main.model";
import {toast} from "react-toastify";

const data: Data = {
    headers: {
        field_1: '',
        field_2: '',
        field_3: '',
        field_4: '',
    },
    data: []
}

const mainItemsInitialValues: MainModel[] = [];

const componentInitialValues: MainModel = {
    type: '',
    subtype: '',
    entity: '',
    title: '',
    description: '',
    size: '',
    position_x: 0,
    position_y: 0,
    data: data,
}

class MainStore {
    public isLoading: boolean = false;
    public components: MainModel[] = [];
    public component = componentInitialValues;
    error: string = '';

    public loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    private setLoading = (state: boolean) => {
        this.loading = state
    }

    setItems(data: any) {
        this.components = data;
    }

    public destroyComponent = () => {
        this.component = componentInitialValues;
    }

    public setComponentFields = (component: MainModel) => {
        this.component = component;
    }

    public setItemField = (index: number, field: string, value: any) => {
        // @ts-ignore
        this.components[index][field] = value;
    }

    public addComponentToItemList = (component: MainModel) => {
        if (component.type === 'WIDGET') {
            component.subtype = 'INFO'
        }
        component.position_x = 100
        component.position_y = 100
        this.components.push(component);
    }

    public deleteComponentFromItemList = (index: number) => {
        this.components.splice(index, 1)
    }

    public getItemsReq = async (signal?: AbortSignal) => {
        this.setLoading(true);
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        try {
            const response = await axios.get(api.main, {headers, signal})
            this.setItems(response.data);
        } catch (e: any) {
            this.error = e?.response?.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false)
        }
    }

    public updateMainPageComponents = async () => {
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        try {
            return await axios.post(api.main, this.components, {headers});
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            await this.getItemsReq()
        }
    }
}

// @ts-ignore
export default new MainStore();