import {ShipmentsFiltersModel, ShipmentsModel} from "./shipments.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../app.store";
import axios from "axios";
import {api} from "../../api/endpoints";
import {GoodsMovementFilterModel} from "../goods_movement/goods_movement.model";
import {toast} from "react-toastify";

const loadingInitialState: boolean = false;
const shipmentsInitialState: ShipmentsModel[] = [];
const shipmentsFiltersInitialState: ShipmentsFiltersModel = {
    supplier_id: 0,
    business_unit_id: 0,
    product_id: 0,
    user_id: 0,
    purchase_bill_id: 0,
    quantity: 0,
    show_deleted: '',

    order_by: '',
    order_descending: false,
    page: 1,
    pages: 0,
    page_limit: 25
}

class ShipmentsStore {
    public shipments = shipmentsInitialState;
    public loading = loadingInitialState;
    public shipmentFilters = shipmentsFiltersInitialState;
    public message: string = '';
    public reason: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public shipmentsPageInit = async (signal: AbortSignal) => {
        await this.getShipmentsReq(signal)
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    setShipments(data: any) {
        const {shipments} = data;
        this.shipments = shipments;
    }

    setShipmentsPages(pages: number) {
        this.shipmentFilters.pages = pages;
    }

    public getShipmentsReq = async (signal?: AbortSignal) => {
        this.setLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        const filterUrl = Object.keys(this.shipmentFilters).reduce((filters, filter) => {
            const symbol = filters ? '&' : '?';
            const filterKeyName = filter as keyof ShipmentsFiltersModel;
            if (this.shipmentFilters[filterKeyName] !== 0 &&
                this.shipmentFilters[filterKeyName] !== '') {
                filters += symbol + filter + '=' + this.shipmentFilters[filterKeyName];
            }

            return filters;
        }, '');

        try {
            const response = await axios.get(api.shipments + filterUrl, {headers, signal});
            this.setShipments(response?.data);
            this.setShipmentsPages(response?.data.pages);
        } catch (e: any) {

        } finally {
            this.setLoading(false);
        }
    }

    public setShipmentsFilters = ({
                                     supplier_id,
                                     business_unit_id,
                                     user_id,
                                     product_id,
                                     purchase_bill_id,
                                     quantity,
                                     show_deleted,
                                     page,
                                     page_limit
                                 }: ShipmentsFiltersModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.shipmentFilters = {
            ...this.shipmentFilters,
            supplier_id: isUndefined(supplier_id) ? this.shipmentFilters.supplier_id! : supplier_id!,
            business_unit_id: isUndefined(business_unit_id) ? this.shipmentFilters.business_unit_id! : business_unit_id!,
            user_id: isUndefined(user_id) ? this.shipmentFilters.user_id! : user_id!,
            product_id: isUndefined(product_id) ? this.shipmentFilters.product_id! : product_id!,
            purchase_bill_id: isUndefined(purchase_bill_id) ? this.shipmentFilters.purchase_bill_id! : purchase_bill_id!,
            show_deleted: isUndefined(show_deleted) ? this.shipmentFilters.show_deleted! : show_deleted!,
            quantity: isUndefined(quantity) ? this.shipmentFilters.quantity! : quantity!,
            page: isUndefined(page) ? this.shipmentFilters.page! : page!,
            page_limit: isUndefined(page_limit) ? this.shipmentFilters.page_limit! : page_limit!
        };

        if (page_limit) {
            this.shipmentFilters.page = 1;
        }

        void this.getShipmentsReq();
    }

    public deleteShipmentReq = async (id: number) => {
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        try {
            const response = await axios.delete(api.shipments + `/${id}`, {headers})
            this.message = response?.data.message;
            toast.success(this.message)
        } catch (e: any) {
            this.reason = e?.response.data.reason;
            toast.error(this.reason);
        } finally {
           await this.getShipmentsReq()
        }
    }
}

export default new ShipmentsStore();