// func checking if all text elements are numbers
export const isNumber = (text: string) => {
    const regex = /^[0-9]+$/;
    return regex.test(text);
}

// func checking if all text elements are float or digits
export const isFloat = (text: string) => {
    const regex = /^[0-9]+(\.[0-9]+)?$/;
    return regex.test(text);
}