import {Bank, Card, CardRegex, CardType} from "../cards.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import axios from "axios";
import {api} from "../../../api/endpoints";
import {toast} from "react-toastify";

const initialValue: Card = {
    id: 0,
    client_id: 0,
    pan: '',
    balance: 0,
    card_type_id: 0,
    bank_id: 0,
    card_type: '',
    bank: '',
}

const banksInitialValue: Bank[] = [];
const regexesInitialValue: CardRegex[] = [];
const cardTypeInitialValue: CardType[] = [];

const loadingInitialState: boolean = false;

class CardsCreateEditStore {
    public loading = loadingInitialState;
    public form = initialValue;
    public banks = banksInitialValue;
    public regexes = regexesInitialValue;
    public cardTypes = cardTypeInitialValue;
    message: string = '';
    error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public destroy = () => {
        this.form = initialValue;
        this.loading = loadingInitialState;
        this.banks = banksInitialValue;
        this.regexes = regexesInitialValue;
        this.cardTypes = cardTypeInitialValue;
        this.message = '';
        this.error = '';
    }

    public init = async (initialValues: Card | null) => {
        await this.cardTypesReq();
        await this.banksReq();
        await this.regexesReq();
        if (initialValues) {
            this.form = initialValues;
        } else {
            this.form = initialValue;
        }
    }

    public createEditCardReq = async (clientID: number, edit: boolean) => {
        this.loading = true;
        const headers = this.getHeaders();
        const payload = {
            id: this.form.id,
            client_id: Number(clientID),
            pan: this.form.pan,
            balance: Number(this.form.balance),
            card_type_id: Number(this.form.card_type_id),
            bank_id: Number(this.form.bank_id)
        }

        try {

            let res;
            if (edit) {
                res = await axios.put(api.cards, payload, {headers});
            } else {
                res = await axios.post(api.cards, payload, {headers});
            }

            this.message = res?.data.message;
            toast.success(this.message);
            return res;

        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.loading = false;
        }
    }

    public setField = (form: Card) => {
        this.form = form;
    }

    setCardTypes(data: any) {
        const {card_types} = data;
        this.cardTypes = card_types;
    }

    setBanks(data: any) {
        const {banks} = data;
        this.banks = banks;
    }

    setRegexes(data: any) {
        const {regexes} = data;
        this.regexes = regexes;
    }

    private cardTypesReq = async () => {
        const headers = this.getHeaders();
        try {
            const response = await axios.get(api.cards + '/card_types', {headers})
            this.setCardTypes(response.data);
        } catch (err: any) {
            console.log('err:', err)
        }
    }

    private banksReq = async () => {
        const headers = this.getHeaders();
        try {
            const response = await axios.get(api.cards + '/banks', {headers})
            this.setBanks(response.data);
        } catch (err: any) {
            console.log('err:', err)
        }
    }

    private regexesReq = async () => {
        const headers = this.getHeaders();
        try {
            const response = await axios.get(api.cards + '/regexes', {headers})
            this.setRegexes(response.data);
        } catch (err: any) {
            console.log('err:', err)
        }
    }

    private getHeaders = () => {
        const {getToken} = AppStore;
        return {
            Authorization: 'Bearer ' + getToken()!
        };
    }

    public get bankOptions() {
        if (!this.banks) return;
        return this.banks.map((bank: any) => ({
            label: bank.name,
            value: bank.id
        }));
    }

    public get cardTypeOptions() {
        if (!this.cardTypes) return;
        return this.cardTypes.map((cardType: any) => ({
            label: cardType.name,
            value: cardType.id
        }));
    }
}

export default new CardsCreateEditStore();