import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {useEffect} from "react";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";
import ClientDocumentCreateEditStore from "./client.document.create.edit.store";
import {renderInputs} from "../../../components/form/render.inputs";
import {Button} from "../../../styles/button";
import {isValid} from "../../../utils/isValid";
import ClientDocumentsStore from "../client.documents.store";

interface ClientDocumentCreateEditViewProps {
    clientID: number;
    initialValue: any;
    setModal: (isOpen: boolean) => void
}

const ClientDocumentCreateEditView = observer(({clientID, initialValue, setModal}: ClientDocumentCreateEditViewProps) => {
    const {isLoading, form, destroy, initCreateEditPage, documentCreateEditReq, setField} = ClientDocumentCreateEditStore;
    const {documentsReq} = ClientDocumentsStore;
    const {destroyModalDictionary, getModalPlaceholders, dictionaryListReq} = DictionaryStore;
    const {
        name,
        surname,
        patronymic,
        series,
        number,
        inn,
        address,
        issued_by,
        issue_date,
        expiration_date,
        type_id, //1 pass, 2-driver license, 3-military ticket, 4-pension doc, 5-other
        extra,
        blood_type,
        family_status, //1-Состоит в браке 2-Не состоит в браке,
        gender, //1 муж 2 жен
        birthday,
    } = form;

    useEffect(() => {
        destroyModalDictionary();
        const controller = new AbortController();
        void dictionaryListReq('CLIENT DOCUMENTS', 'MODAL', controller.signal);
        void initCreateEditPage(initialValue);

        return () => {
            destroy();
            controller.abort();
        }
    }, [destroyModalDictionary, dictionaryListReq, destroy, initCreateEditPage, initialValue]);

    const typeOptions = [
        {
            label: 'Паспорт',
            value: 1
        },
        {
            label: 'Сведетельство о рождении',
            value: 2
        },
        {
            label: 'Водительское удостоверение',
            value: 3
        },
        {
            label: 'Военный билет',
            value: 4
        },
        {
            label: 'Пенсионное удостоверение',
            value: 5
        },
        {
            label: 'Другое',
            value: 6
        }
    ];

    const familyStatusOptions = [
        {
            label: 'Состоит в браке',
            value: 1
        },
        {
            label: 'Не состоит в браке',
            value: 2
        }
    ];

    const genderOptions = [
        {
            label: 'Муж',
            value: 1
        },
        {
            label: 'Жен',
            value: 2
        }
    ]

    const inputs = [
        {field: name, fieldName: 'name', placeholder: getModalPlaceholders('name'), type: 'text', required: true},
        {field: surname, fieldName: 'surname', placeholder: getModalPlaceholders('surname'), type: 'text', required: true},
        {field: patronymic, fieldName: 'patronymic', placeholder: getModalPlaceholders('patronymic'), type: 'text', required: false},
        {field: series, fieldName: 'series', placeholder: getModalPlaceholders('series'), type: 'text', required: false},
        {field: number, fieldName: 'number', placeholder: getModalPlaceholders('number'), type: 'text', required: false},
        {field: inn, fieldName: 'inn', placeholder: getModalPlaceholders('inn'), type: 'text', required: false},
        {field: address, fieldName: 'address', placeholder: getModalPlaceholders('address'), type: 'text', required: false},
        {field: issued_by, fieldName: 'issued_by', placeholder: getModalPlaceholders('issued_by'), type: 'text', required: false},
        {field: issue_date, fieldName: 'issue_date', placeholder: getModalPlaceholders('issue_date_text'), type: 'date', required: false},
        {field: expiration_date, fieldName: 'expiration_date', placeholder: getModalPlaceholders('expiration_date_text'), type: 'date', required: false},
        {field: type_id, fieldName: 'type_id', placeholder: getModalPlaceholders('type_id'), type: 'select', required: true, options: typeOptions},
        {field: extra, fieldName: 'extra', placeholder: getModalPlaceholders('extra'), type: 'text', required: false},
        {field: blood_type, fieldName: 'blood_type', placeholder: getModalPlaceholders('blood_type'), type: 'text', required: false},
        {field: family_status, fieldName: 'family_status', placeholder: getModalPlaceholders('family_status'), type: 'select', required: false, options: familyStatusOptions},
        {field: gender, fieldName: 'gender', placeholder: getModalPlaceholders('gender'), type: 'select', required: false, options: genderOptions},
        {field: birthday, fieldName: 'birthday', placeholder: getModalPlaceholders('birthday_text'), type: 'date', required: false},
    ];

    const handleSubmit = async () => {
        const res = await documentCreateEditReq(clientID, !!initialValue);

        if (res) {
            setModal(false);
            void documentsReq(clientID);
        }
    }

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setField)}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={isLoading}
                    onClick={handleSubmit}
                    disabled={isValid(inputs)}
                >{isLoading ? '' : renderSubmitText}</Button>
            </Layout>
        </Layout>
    );
});

export default ClientDocumentCreateEditView;