import { BusinessUnitModel } from '../business_units/business_units.model';
import { observer } from 'mobx-react';
import { Layout } from '../../styles/layout';
import { Search, SearchLayout, Select } from '../../styles/table-header';
import { Image } from '../../styles/image';
import SearchIcon from '../../assets/icons/search.svg';
import { DatePicker } from 'antd';
import { UsersModel } from '../users/users.model';
interface TransferFilterViewProps {
  handleKeyDown: (e: any) => void;
  cancelSearch: (e: any) => void;
  handleFilterChange: (e: any, key: string, index?: number) => void;
  setDates: (e: any) => void;
  users: UsersModel[] | undefined;
  fromBu: BusinessUnitModel[] | undefined;
  toBu: BusinessUnitModel[] | undefined;
  inModal: boolean;
}

const TransferFiltersView = observer(
  ({
    setDates,
    handleKeyDown,
    cancelSearch,
    handleFilterChange,
    users,
    fromBu,
    toBu,
    inModal,
  }: TransferFilterViewProps) => {
    const arr = [
      {
        id: 'true',
        name: 'Доставлено',
      },
      {
        id: 'false',
        name: 'Не доставлено',
      },
    ];
    const { RangePicker } = DatePicker;

    return (
      <>
        <Layout between={10} wrapped vAlign="center" vertical={inModal}>
          <RangePicker
            onChange={(e) => {
              setDates(e);
            }}
            placeholder={['Начало даты', 'Конец даты']}
            style={{
              height: 40,
              width: 250,
              borderRadius: 12,
              border: 0,
              borderWidth: 1,
              borderColor: '#E5E5E5',
              color: '#374151',
              backgroundColor: '#FFFFFF',
            }}
          />
          <SearchLayout>
            <Search
              type="search"
              id="search"
              placeholder="Поиск"
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e) => cancelSearch(e)}
            ></Search>
            <Image src={SearchIcon} height="16px" />
          </SearchLayout>
          <Select
            id="measure-unit-filter"
            onChange={(e) => handleFilterChange(e, 'user_id')}
          >
            <option value="">Все сотрудники</option>
            {users?.map((user, _) => (
              <option value={user.id} key={user.id}>
                {user.name}
              </option>
            ))}
          </Select>
          <Select
            id="category-filter"
            onChange={(e) => handleFilterChange(e, 'from_bu_id')}
          >
            <option value="">Отправитель</option>
            {fromBu?.map((bu, _) => (
              <option value={bu.id} key={bu.id}>
                {bu.name}
              </option>
            ))}
          </Select>
          <Select
            id="brand-filter"
            onChange={(e) => handleFilterChange(e, 'to_bu_id')}
          >
            <option value="">Получатель</option>
            {toBu?.map((bu, _) => (
              <option value={bu.id} key={bu.id}>
                {bu.name}
              </option>
            ))}
          </Select>

          <Select
            id="brand-filter"
            onChange={(e) => handleFilterChange(e, 'delivered')}
          >
            <option value="">Статус доставки</option>
            {arr?.map((bu, _) => (
              <option value={bu.id} key={bu.id}>
                {bu.name}
              </option>
            ))}
          </Select>
        </Layout>
      </>
    );
  }
);

export default TransferFiltersView;
