import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {Text} from "../../styles/text";
import {G2, Pie} from "@ant-design/plots";
import {borderRadius} from "../../utils/variables";
import {useEffect, useState} from "react";

interface ChartPieViewProps {
    title?: string;
    data: any[];
    angleField: string;
    colorField: string;
    width?: number;
}

const ChartPieView = observer(({title, data, angleField, colorField, width}:ChartPieViewProps) => {
    const renderStatistic = (containerWidth: number, text: string) => {
        const textStyleStr = `width:${containerWidth}px;`;
        const wrap = `white-space: pre-wrap;`

        return `<div style="${textStyleStr};font-size:18px;${wrap}">${text}</div>`;
    }

    const statisticsSettings = {
        title: {
            customHtml: (container: HTMLElement, view: G2.View, datum: any) => {
                const {width, height} = container.getBoundingClientRect();
                const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
                const text = datum ? datum[colorField] : 'Итого';
                return renderStatistic(width, text);
            },
        },
        content: {
            offsetY: 4,
            style: {
                fontSize: '18px',
            },
            customHtml: (container: HTMLElement, view: G2.View, datum: any, data: any) => {
                const {width} = container.getBoundingClientRect();

                const text = datum ? `${datum[angleField]}` : `${data?.reduce((r: any, d: any) => r + d[angleField], 0)}`;
                return renderStatistic(width, text);
            },
        },
    }

    return (
        <Layout vertical between={20}>
            <Layout centered>
                <Text weight={'bold'}>{title}</Text>
            </Layout>
            <Layout
                background={'#eee'}
                style={{
                    borderRadius: borderRadius,
                }}
            >
                <Pie
                    data={data}
                    angleField={angleField}
                    colorField={colorField}
                    appendPadding={10}
                    width={width ? width : 500}
                    innerRadius={0.5}
                    statistic={statisticsSettings}
                  //  legend={false}
                    label={false}
                    interactions={[
                        {type: 'element-selected'},
                        {type: 'element-active'},
                        {type: 'pie-statistic-active'},
                        {type: 'pie-legend-active'},
                    ]}
                />
            </Layout>
        </Layout>
    );
});

export default ChartPieView;