import { ChangePassword } from '../settings.model';
import axios from 'axios';
import { api } from '../../../api/endpoints';
import { makeAutoObservable } from 'mobx';
import AppStore from '../../../app.store';
import { toast } from 'react-toastify';
const initialValues: ChangePassword = {
  new_password: '',
  old_password: '',
  repeat_new_password: '',
};

class SettingsChangePassword {
  public form = initialValues;
  changeForm = (object: ChangePassword) => {
    this.form = object;
  };
  public error = '';
  public message = '';
  public isLoading = false;
  public success = false;
  public destroy = () => {
    this.form = initialValues;
    this.error = '';
    this.message = '';
    this.isLoading = false;
    this.success = false;
  };

  constructor() {
    makeAutoObservable(this);
  }

  public changePasswordReq = async () => {
    try {
      this.isLoading = true;
      const { getToken } = AppStore;

      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };

      if (this.form.new_password !== this.form.repeat_new_password) {
        this.error = 'Пароли не совпадают';
        this.isLoading = false;
        return;
      }
      const response = await axios.put(api.change_password, this.form, {
        headers,
      });

      this.message = response?.data.message;
      this.success = true;

      this.isLoading = false;
      toast.success(this.message);
      return;
    } catch (err: any) {
      this.error = err?.response.data.reason;
      this.success = false;
      this.isLoading = false;
    }
  };
}

export default new SettingsChangePassword();
