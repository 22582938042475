import styled, {css} from 'styled-components'
import { getSize, SizeTypes } from '../utils/getSize';
import {
    borderRadius,
    inputBackground,
    inputColor,
    inputPadding,
    primaryColor,
    inputSize,
    inputBackgroundFocus,
    inputBackgroundDarkMode,
    inputColorDarkMode, media
} from '../utils/variables';

interface InputProps {
    extent?: SizeTypes;
    darkMode?: boolean;
    minMaxValueCheck?: boolean;
}

const inputStyles = ({ extent, darkMode }: InputProps) => `
    border: unset;
    flex: 1;
    border: 2px solid ${darkMode ? 'transparent' : '#ddd'};
    transition: 300ms;
    border-radius: ${borderRadius};
    background-color: ${darkMode ? inputBackgroundDarkMode : inputBackground};
    padding: ${inputPadding};
    color: ${darkMode ? inputColorDarkMode : inputColor};
    width: ${extent
        ? `${getSize(extent, inputSize)}`
        : 'unset'
    };

    ::placeholder {
        color: #999;
    }
    :hover, :focus {
        border: 2px solid ${primaryColor};
    }
    :focus {
        background-color: ${darkMode ? inputBackgroundFocus : inputBackground};
    }
    
    ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        -moz-appearance: textfield;
    
    ${media.mobile} {
        width: 180px;
    }
`;

export const Form = styled.form``;

export const TextInput = styled.input<InputProps>`
    ${({minMaxValueCheck}) => minMaxValueCheck && css`
        :invalid {
            border: 1px solid red;
        }
        :hover:invalid, :focus:invalid {
            border: 2px solid red;
        }
    `}
    
    ${({ extent, darkMode }) => inputStyles({ extent, darkMode })}
`;

export const NumberInput = styled.input<InputProps>`
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
    :invalid {
        border: 1px solid red;
    }
    :hover:invalid, :focus:invalid {
        border: 2px solid red;
    }
    ${({ extent, darkMode }) => inputStyles({ extent, darkMode })}
`;

export const TextArea = styled.textarea<InputProps>`
    ${({ extent, darkMode }) => inputStyles({ extent, darkMode })}
`;

export const Select = styled.select<InputProps>`
    ${({ extent, darkMode }) => inputStyles({ extent, darkMode })}
`;

export const SelectView = styled.select<InputProps>`
    ${({ extent, darkMode }) => inputStyles({ extent, darkMode })}
`;

export const Required = styled.span`
    font-size: 14px;
    font-weight: 800;
    position: relative;
    top: -2px;
    color: red;
`;

export const Wrapper = styled.div`
    display: flex; 
    justify-content: space-between; 
    align-items: center;
`;

