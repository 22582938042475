import styled from 'styled-components';
import { getSize, SizeTypes } from '../utils/getSize';
import {media, textSize} from '../utils/variables';

interface TextProps {
    extent?: SizeTypes | string;
    color?: string;
    weight?: string | number;
}

export const Text = styled.span<TextProps>`
    font-size: ${({ extent }) => extent
        ? `${getSize(extent, textSize)}`
        : 'unset'
    };
    font-weight: ${({ weight }) => weight
        ? weight
        : 'unset'
    };
    color: ${({ color }) => color
        ? color
        : 'unset'
    };
  ${media.mobile} {
    font-size: 12px;
  };
`;