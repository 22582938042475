import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {TransactionsModel} from "./transactions.model";
import UpdateStatusStore from "./transaction.change.status.store";
import {useEffect} from "react";
import TransactionsStore from "./transactions.store";
import {renderInputs} from "../../components/form/render.inputs";
import {Button} from "../../styles/button";
import {isValid} from "../../utils/isValid";
import {Text} from "../../styles/text";
import {modalTextColor} from "../../utils/variables";
import AppStore from "../../app.store";
import {StatusBadge} from "../../styles/badge";

interface TransactionChangeStatusViewProps {
    initialValue: TransactionsModel | null;
    setModal: (isOpen: boolean) => void;
}

const TransactionChangeStatusView = observer(({initialValue, setModal}: TransactionChangeStatusViewProps) => {

    const {form, destroyStatusUpdate, setField, init, changeStatusReq, statusesOptions, loading} = UpdateStatusStore;
    const {transactionsReq} = TransactionsStore;
    const {simpleTableView, businessSettings, userData} = AppStore;

    useEffect(() => {
        const controller = new AbortController();
        void init(initialValue!);
        return () => {
            // destroyStatusUpdate()
            controller.abort();
        }
    }, [init, initialValue]);

    const {status_id, message} = form;

    const inputs = [
        {
            field: status_id,
            options: statusesOptions,
            fieldName: 'status_id',
            placeholder: 'Статус',
            type: 'select',
            required: true
        },
        {field: message, fieldName: 'message', placeholder: 'Детали', type: 'text', required: false},
    ]

    const handleSubmit = async () => {
        const resp = await changeStatusReq();
        if (resp) {
            destroyStatusUpdate();
            setModal(false);
            void transactionsReq();
        }

    }

    const disableButton = () => {
        return isValid(inputs) || loading
    }

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                <Layout centered>
                    <Text weight={'bold'}>Информация об операции</Text>
                </Layout>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Text color={modalTextColor} weight={'bold'} extent={'S'}>ID</Text>
                    <Text>{initialValue?.owner_transaction_id}</Text>
                </Layout>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Text color={modalTextColor} weight={'bold'} extent={'S'}>Подразделение</Text>
                    <Text>{initialValue?.business_unit}</Text>
                </Layout>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Text color={modalTextColor} weight={'bold'} extent={'S'}>Сотрудник</Text>
                    <Text>{initialValue?.user}</Text>
                </Layout>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Text color={modalTextColor} weight={'bold'} extent={'S'}>Клиент</Text>
                    <Text>{initialValue?.client}</Text>
                </Layout>
                {simpleTableView && (
                    <Layout hAlign={"space-between"} vAlign={"center"}>
                        <Text color={modalTextColor} weight={'bold'} extent={'S'}>Продукт</Text>
                        <Text>{initialValue?.product}</Text>
                    </Layout>
                )}
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Text color={modalTextColor} weight={'bold'} extent={'S'}>Сумма</Text>
                    <Text>{initialValue?.amount}</Text>
                </Layout>
                {businessSettings?.product_commissions && (
                    <>
                        <Layout hAlign={"space-between"} vAlign={"center"}>
                            <Text color={modalTextColor} weight={'bold'} extent={'S'}>Коммиссия</Text>
                            <Text>{initialValue?.commission}</Text>
                        </Layout>
                        <Layout hAlign={"space-between"} vAlign={"center"}>
                            <Text color={modalTextColor} weight={'bold'} extent={'S'}>Общая сумма</Text>
                            <Text>{initialValue?.amount! + initialValue?.commission!}</Text>
                        </Layout>
                    </>
                )}
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Text color={modalTextColor} weight={'bold'} extent={'S'}>Текущий статус</Text>
                    <Text>
                        <StatusBadge color={initialValue?.status_color!}>
                            {initialValue?.status}
                        </StatusBadge>
                    </Text>
                </Layout>
            </Layout>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setField)}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={loading}
                    onClick={handleSubmit}
                    disabled={disableButton()}
                >
                    {loading ? '' : 'Потвердить'}
                </Button>
            </Layout>
        </Layout>
    );
});

export default TransactionChangeStatusView;