import {Bank, Card, CardRegex, CardType} from "./cards.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../app.store";
import axios from "axios";
import {api} from "../../api/endpoints";
import {toast} from "react-toastify";

const loadingInitialState: boolean = false;
const cardsInitialState: Card[] = [];
const cardTypesInitialState: CardType[] = [];
const banksInitialState: Bank[] = [];
const cardRegexesInitialState: CardRegex[] = [];

class CardsStore {
    public cards = cardsInitialState;
    public cardTypes = cardTypesInitialState;
    public banks = banksInitialState;
    public regexes = cardRegexesInitialState;
    public isLoading = loadingInitialState;
    message: string = '';
    error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public cardInit = async (clientID: number, signal: AbortSignal) => {
        await this.cardReq(clientID, signal)
    }

    public destroy = () => {
        this.cards = cardsInitialState;
        this.cardTypes = cardTypesInitialState;
        this.banks = banksInitialState;
        this.regexes = cardRegexesInitialState;
        this.isLoading = loadingInitialState;
    }

    setCards(data: any) {
        const {cards} = data;
        this.cards = cards;
    }

    public cardReq = async (clientID: number, signal?: AbortSignal) => {
        this.isLoading = true;

        const headers = this.getHeaders();

        try {
            const response = await axios.get(`${api.cards}?client_id=${clientID}`, {headers, signal})
            this.setCards(response.data)
        } catch (e: any) {

        } finally {
            this.isLoading = false;
        }
    }

    private getHeaders = () => {
        const {getToken} = AppStore;
        return {
            Authorization: 'Bearer ' + getToken()!
        };
    }

    public deleteCard = async (cardID: number) => {
        this.isLoading = true
        const headers = this.getHeaders();

        try {
            const response = await axios.delete(`${api.cards}/${cardID}`, {headers});
            this.message = response?.data.message;
            toast.success(this.message);
            return response;
        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.isLoading = false;
        }
    }
}

export default new CardsStore();