import styled from 'styled-components';
// import { createGlobalStyle } from 'styled-components';

interface WrapperProps {
    withoutMaxWidth?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
    padding: 0 30px;
    max-width: ${({ withoutMaxWidth }: WrapperProps) => withoutMaxWidth
        ? `unset`
        : `1300px`
    }
`;

export const Main = styled.div`
    .main_modal {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 180;
    }
    .modal_title {
        font-size: 16;
        font-weight: bold;
    }
    .modal_body {
        font-size: 16;
    }
    .icon_notification {
        background: red;
        color: red;
        width: 20;
        height: 5;
    }
    .modal_data {
        margin: 10px;
    }
`;