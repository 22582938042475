import {observer} from "mobx-react";
import {useState} from "react";
import {Layout} from "../../styles/layout";
import {Text} from "../../styles/text";
import {Button} from "../../styles/button";
import {EyeImgIcon, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../styles/table";
import EyeIcon from "../../assets/icons/eye.svg";
import {primaryColor} from "../../utils/variables";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import ModalView from "../../components/modal/modal.view";
import {TBusinessStocksGetResponse} from "./stock.types";

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
    business_unit_id: number;
}

interface StocksTableMobileView {
    loading: boolean;
    stocks: TBusinessStocksGetResponse["stocks"] | undefined;
    setConfirmDeleteModal: ({isModalOpen, id, name, business_unit_id}: setConfirmDeleteModalProps) => void;
    tableHeaders: (key: string) => string;
}

const StocksTableMobileView = observer(({
                                            loading,
                                            stocks,
                                            setConfirmDeleteModal,
                                            tableHeaders
                                        }: StocksTableMobileView) => {

    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<{
        brand: string;
        business_unit: string;
        business_unit_id: number;
        category: string;
        description: string;
        goods_id: number;
        id: number;
        measure_unit: string;
        name: string;
        part_number: string;
        quantity: number;
        set_price: number;
    } | null>(null)

    const handleRowClick = (stock: {
        brand: string;
        business_unit: string;
        business_unit_id: number;
        category: string;
        description: string;
        goods_id: number;
        id: number;
        measure_unit: string;
        name: string;
        part_number: string;
        quantity: number;
        set_price: number;
    }) => {
        setModal(true);
        setInitialValue(stock);
    }

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    }

    const renderModalItems = (stock: {
        brand: string;
        business_unit: string;
        business_unit_id: number;
        category: string;
        description: string;
        goods_id: number;
        id: number;
        measure_unit: string;
        name: string;
        part_number: string;
        quantity: number;
        set_price: number;
    }) => (
        <Layout vertical between={10}>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('name')}</Text>
                <Text>{stock.name}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('business_unit')}</Text>
                <Text>{stock.business_unit}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('category')}</Text>
                <Text>{stock.category}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('brand')}</Text>
                <Text>{stock.brand}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('description')}</Text>
                <Text>{stock.description}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('measure_unit')}</Text>
                <Text>{stock.measure_unit}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('quantity')}</Text>
                <Text>{stock.quantity}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('set_price')}</Text>
                <Text>{stock.set_price}</Text>
            </Layout>
            <Layout hAlign={"center"} between={5}>
                <Button
                    background={'rgb(146,4,4)'}
                    onClick={() =>
                        setConfirmDeleteModal({
                            isModalOpen: true,
                            id: stock.id,
                            name: stock.name,
                            business_unit_id: stock.business_unit_id,
                        })
                    }
                >Удалить
                </Button>
            </Layout>
        </Layout>
    );

    const renderedItems = () => (
        <>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>{tableHeaders('name')}</THeadCell>
                        <THeadCell>{tableHeaders('business_unit')}</THeadCell>
                        <THeadCell>{tableHeaders('quantity')}</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                {!loading ? (
                    <TableBody>
                        {stocks?.length ? (
                            stocks?.map((stock, index) => (
                                <TRow key={index} menu>
                                    <TCell>{stock.name}</TCell>
                                    <TCell>{stock.business_unit}</TCell>
                                    <TCell>{stock.quantity}</TCell>
                                    <TCell relative>
                                        <EyeImgIcon
                                            src={EyeIcon}
                                            onClick={() => handleRowClick(stock)}
                                            height='18px'
                                            color={primaryColor}
                                        />
                                    </TCell>
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={4}/>
                        )}
                    </TableBody>
                ) : (
                    <TableSkeleton columns={4}/>
                )}
            </Table>
            {modal && (
                <ModalView
                    title={`Данные запаса ${initialValue?.name}`}
                    onClose={handleCloseModal}
                >
                    {renderModalItems(initialValue!)}
                </ModalView>
            )}
        </>
    );

    return (
        <Layout>
            {renderedItems()}
        </Layout>
    )
});

export default StocksTableMobileView;