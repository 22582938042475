import {BusinessServiceOperationModel} from "../business_service.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import {api} from "../../../api/endpoints";
import axios from "axios";
import {isFloat} from "../../../utils/isNumber";
import { toast } from "react-toastify";

const initialValues = {
    product_id: 0,
    type_id: 1,
    client: "",
    account: "",
    details: "",
    amount: "",
    measure_unit_id: 0,
    quantity: 0,
    service_name: ""
}

class BusinessServiceOperationStore {
    public form: BusinessServiceOperationModel = initialValues;
    public error = '';
    public message = '';
    public isLoading = false;

    constructor() {
        makeAutoObservable(this);
    }

    public init = async (initialValue: BusinessServiceOperationModel | null) => {
        this.form = initialValue ? initialValue : initialValues;
    }

    public createBusinessServiceOperation = async (edit?: boolean) => {
        this.isLoading = true;

        const payload = {
            type_id: 1,
            //client: this.form.client,
            client_id: 1,
            account_id: 1,
            account: this.form.account,
            details: this.form.details,
            amount: Number(this.form.amount),
            products: []
        }

        // @ts-ignore
        payload.products.push({
            amount: Number(this.form.amount),
            product_id: this.form.product_id,
            total_summ: Number(this.form.amount),
        })

        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: 'Bearer ' + getToken()!
            }
            let res: any;
            res = await axios.post(api.transactions, payload, {headers});
            // if (res?.code >= 400) {
            //     throw new Error(res)
            // }
            this.message = res?.data.message;
            toast.success(this.message);
            
            return res;
        } catch (err: any) {
            this.error = err?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.isLoading = false;
        }
    }

    public setField = (form: BusinessServiceOperationModel) => {
        if (!isFloat(form.amount) && form.amount !== "") {
            return;
        }

        this.form = form;
    }

    public destroy = () => {
        this.form = initialValues;
    }
}

export default new BusinessServiceOperationStore();