import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {BusinessUnitModel} from "../business_units/business_units.model";
import WorkingHoursStore from "./working.hours.store";
import React, {useEffect} from "react";
import {Text} from "../../styles/text";
import {WorkingHoursModel} from "./working.hours.model";
import {modalTextColor} from "../../utils/variables";
import {Switch, Tabs, TimePicker} from "antd";
import {Button} from "../../styles/button";
import {runInAction} from "mobx";
import dayjs from "dayjs";

interface WorkingHoursViewProps {
    businessUnitID: number;
    setInitialValue: (initialValue: BusinessUnitModel | null) => void;
    setModal: (isOpen: boolean) => void;
}

const WorkingHoursView = observer(({businessUnitID, setInitialValue, setModal}: WorkingHoursViewProps) => {

    const {
        init,
        createEditLoading,
        createEditWorkingHourReq,
        workingHours
    } = WorkingHoursStore;

    useEffect(() => {
        const controller = new AbortController();
        init(businessUnitID, controller.signal);
        return () => controller.abort();
    }, [init]);

    const handleChangeStartTime = (dayID: number, e: any) => {
        workingHours.forEach((day, index) => {
            if (day.day_id === dayID) {
                runInAction(() => {
                    workingHours[index].start_time = e.format('hh:mm');
                })
            }
        })
    }

    const handleChangeEndTime = (dayID: number, e: any) => {
        workingHours.forEach((day, index) => {
            if (day.day_id === dayID) {
                runInAction(() => {
                    workingHours[index].end_time = e.format('hh:mm');
                })
            }
        })
    }

    const handleWorkingDaySwitch = (dayID: number, e: boolean) => {
        workingHours.forEach((day, index) => {
            if (day.day_id === dayID) {
                runInAction(() => {
                    workingHours[index].working_day = e
                })
            }
        })
    }

    const handleRoundTheClockSwitch = (dayID: number, e: boolean) => {
        workingHours.forEach((day, index) => {
            if (day.day_id === dayID) {
                runInAction(() => {
                    workingHours[index].round_the_clock = e
                })
            }
        })
    }

    const handleCreateEditWorkingHour = async () => {
        const resp = await createEditWorkingHourReq(businessUnitID)

        if (resp) {
            setInitialValue(null);
            setModal(false);
        }
    }

    const getShortDay = (dayID: number) => {
        switch (dayID) {
            case 0:
                return 'Вс';
            case 1:
                return 'Пн';
            case 2:
                return 'Вт';
            case 3:
                return 'Ср';
            case 4:
                return 'Чт';
            case 5:
                return 'Пт';
            case 6:
                return 'Сб';
        }
    }

    const renderOneDayInfo = (day: WorkingHoursModel) => {
        return (
            <Layout vertical between={10}>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Layout widthSize={'35%'}>
                        <Text color={modalTextColor} extent={'S'}>Рабочий день</Text>
                    </Layout>
                    <Layout>
                        <Switch
                            checked={day.working_day}
                            onChange={(e) => handleWorkingDaySwitch(day.day_id!, e)}
                        />
                    </Layout>
                </Layout>
                {day.working_day && (
                    <Layout hAlign={"space-between"} vAlign={"center"}>
                        <Layout widthSize={'35%'}>
                            <Text color={modalTextColor} extent={'S'}>Круглосуточно</Text>
                        </Layout>
                        <Layout>
                            <Switch
                                checked={day.round_the_clock}
                                onChange={(e) => handleRoundTheClockSwitch(day.day_id!, e)}
                            />
                        </Layout>
                    </Layout>
                )}
                {(day.working_day && !day.round_the_clock) && (
                    <>
                        <Layout hAlign={"space-between"} vAlign={"center"}>
                            <Layout widthSize={'35%'}>
                                <Text color={modalTextColor} extent={'S'}>Начало рабочего дня</Text>
                            </Layout>
                            <Layout>
                                <TimePicker
                                    value={dayjs(day.start_time, 'hh:mm')}
                                    onChange={(e) => handleChangeStartTime(day.day_id!, e)}
                                    format={'HH:mm'}
                                    showNow={false}
                                />
                            </Layout>
                        </Layout>
                        <Layout hAlign={"space-between"} vAlign={"center"}>
                            <Layout widthSize={'35%'}>
                                <Text color={modalTextColor} extent={'S'}>Конец рабочего дня</Text>
                            </Layout>
                            <Layout>
                                <TimePicker
                                    value={dayjs(day.end_time, 'hh:mm')}
                                    onChange={(e) => handleChangeEndTime(day.day_id!, e)}
                                    format={'HH:mm'}
                                    showNow={false}
                                />
                            </Layout>
                        </Layout>
                    </>
                )}
            </Layout>
        );
    }

    return (
        <Layout vertical between={20}>
            <Tabs
                style={{
                    fontFamily: 'Open-Sans, Helvetica, Sans-Serif'
                }}
                tabBarStyle={{
                    fontWeight: 'bold'
                }}
                size='large'
                defaultActiveKey='0'
                centered
                items={workingHours.map((day, index) => {
                    return {
                        label: getShortDay(day.day_id),
                        key: `${index}`,
                        children: renderOneDayInfo(day)
                    };
                })}
            />
            <Layout centered>
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={createEditLoading}
                    onClick={handleCreateEditWorkingHour}
                >
                    {createEditLoading ? '' : 'Изменить'}
                </Button>
            </Layout>
        </Layout>
    )
        ;
});

export default WorkingHoursView;