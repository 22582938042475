import {AllowancesModel} from "./allowances.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import {toast} from "react-toastify";
import axios from "axios";
import {api} from "../../../api/endpoints";

const allowancesInitialValue: AllowancesModel[] = [];
const loadingInitialState: boolean = false;

class AllowancesStore {
    public allowances = allowancesInitialValue;
    public isLoading = loadingInitialState;
    public message: string = '';
    public error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    setAllowances(data: any) {
        this.allowances = data;
    }

    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    public init = async (signal?: AbortSignal) => {
        await this.allowancesReq(signal);
    }

    public allowancesReq = async (signal?: AbortSignal) => {
        this.setLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        try {
            const response = await axios.get(api.payrolls + '/allowances', {headers, signal});
            this.setAllowances(response.data);
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }
}

export default new AllowancesStore();