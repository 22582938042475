import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import { api } from '../../../api/endpoints';
import AppStore from '../../../app.store';
import { toast } from 'react-toastify';
import { ClientContactsModel } from './clients.contacts.model';
const initialValues = {
  address: '',
  client_id: 0,
  created_at: '',
  extra_phone: '',
  id: 0,
  name: '',
  patronymic: '',
  phone: '',
  relationship: '',
  surname: '',
  updated_at: '',
};

class ClientContctsCreateEditStore {
  private clientContactsCategories?: any[];
  public clietnContactsType: ClientContactsModel[] = [];
  public form: ClientContactsModel = initialValues;
  public error: string = '';
  public message: string = '';
  public isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }
  public init = async (initialValue: ClientContactsModel | null) => {
    if (initialValue) {
      this.form = initialValue;
      this.form.client_id = this.clientContactsntCategoryOptions?.find(
        (item) => this.form.phone === item.label
      )?.value;
    } else {
      this.form = initialValues;
    }
  };

  public createOrEditClientContacts = async (
    edit: boolean,
    entityId?: string
  ) => {
    this.isLoading = true;
    const payload = {
      address: String(this.form.address),
      client_id: Number(entityId),
      extra_phone: String(this.form.extra_phone),
      id: Number(this.form.id),
      name: String(this.form.name),
      patronymic: String(this.form.patronymic),
      phone: String(this.form.phone),
      relationship: String(this.form.relationship),
      surname: String(this.form.surname),
    };
    try {
      const { getToken } = AppStore;
      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };
      let res;
      if (edit) {
        res = await axios.put(api.client_contacts, payload, { headers });
      } else {
        res = await axios.post(api.client_contacts, payload, {
          headers,
        });
      }

      this.message = res?.data.message;
      toast.success(this.message);

      this.isLoading = false;
      return res;
    } catch (err: any) {
      this.error = err?.response.data.reason;
      toast.error(this.error);
    } finally {
      this.isLoading = false;
    }
  };

  public setField = (form: ClientContactsModel) => {
    this.form = form;
  };

  public destroy = () => {
    this.form = initialValues;
    this.isLoading = false;
  };

  public get clientContactsntCategoryOptions() {
    if (!this.clientContactsCategories) return [];
    return this.clientContactsCategories?.map((category: any) => ({
      label: category.name,
      value: category.id,
    }));
  }
}

export default new ClientContctsCreateEditStore();
