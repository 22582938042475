import {DictionaryModel} from "./dictionary.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../app.store";
import axios from "axios";
import {api} from "../../api/endpoints";
import {toast} from "react-toastify";

const dictionaryListInitialState: DictionaryModel[] = [];
const sidebarDictionaryInitialState: DictionaryModel[] = [];
const loadingInitialState: boolean = false;

class DictionaryStore {
    public dictionaryList = dictionaryListInitialState;
    public sidebarList = sidebarDictionaryInitialState;
    public isLoading = loadingInitialState;
    public pageTitleList = dictionaryListInitialState;
    public modalDictionaryList = dictionaryListInitialState;
    error: string = '';
    message: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    setDictionaryList(data: any) {
        this.dictionaryList = data;
    }

    setModalDictionaryList(data: any) {
        this.modalDictionaryList = data;
    }

    setPageTitleList(data: any) {
        this.pageTitleList = data;
    }

    setSidebarDictionaryList(data: any) {
        this.sidebarList = data;
    }

    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    public destroyDictionary = () => {
        this.dictionaryList = dictionaryListInitialState;
    }

    public destroyModalDictionary = () => {
        this.modalDictionaryList = dictionaryListInitialState;
    }

    public dictionaryListReq = async (entityCode: string, propertyCode: string, signal?: AbortSignal) => {
        this.setLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        try {

            const response = await axios.get(api.dictionaries + `?entity=${entityCode}&property=${propertyCode}`, {
                headers,
                signal
            });

            switch (propertyCode) {
                case 'MODAL':
                    this.setModalDictionaryList(response?.data);
                    break;
                default:
                    this.setDictionaryList(response?.data);
                    break;
            }

        } catch (e: any) {
            this.error = e?.response?.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false);
        }
    }

    public sidebarDictionaryReq = async (signal?: AbortSignal) => {
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        try {
            const response = await axios.get(api.dictionaries + `/sidebar`, {headers, signal});

            this.setSidebarDictionaryList(response.data);
        } catch (e: any) {
            this.error = e?.response?.data.reason;
            toast.error(this.error);
        } finally {
        }
    }

    public pageTitleDictionaryReq = async (signal?: AbortSignal) => {
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        try {
            const response = await axios.get(api.dictionaries + `/title`, {headers, signal});

            this.setPageTitleList(response?.data);
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
        }
    }

    public getSidebarLabel = (code: string) => {
        let value = '';
        this.sidebarList.forEach((item) => {
            if (item.code === code) {
                value = item.value
            }
        })
        return value
    }

    public getTableHeaders = (key: string) => {
        let value = '';
        this.dictionaryList.forEach((item) => {
            if (item.key === key) {
                value = item.value
            }
        })
        return value
    }

    public getModalPlaceholders = (key: string) => {
        let value = '';
        this.modalDictionaryList.forEach((item) => {
            if (item.key === key) {
                value = item.value
            }
        })
        return value
    }

    public getPageTitle = (key: string) => {
        let value = '';
        this.pageTitleList.forEach((item) => {
            if (item.code === key) {
                value = item.value
            }
        })
        return value
    }
}

export default new DictionaryStore();