import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './app'
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfigProvider } from 'antd';
import dayjs from "dayjs";
import "dayjs/locale/ru";
import updateLocale from "dayjs/plugin/updateLocale";
import locale from "antd/es/locale/ru_RU";
import ruRu from 'antd/locale/ru_RU';

dayjs.extend(updateLocale);
dayjs.updateLocale("ru_RU", {
    weekStart: 1,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Router>
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
        <ConfigProvider locale={ruRu}>
            <App />
        </ConfigProvider>
    </Router>
  </React.StrictMode>
)
