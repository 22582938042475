import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {StatusModel} from "../statuses.model";
import StatusesCreateEditStore from "./statuses.create.edit.store";
import StatusesStore from "../statuses.store";
import {useEffect, useState} from "react";
import {renderInputs} from "../../../components/form/render.inputs";
import {Button} from "../../../styles/button";
import {isValid} from "../../../utils/isValid";
import {Text} from "../../../styles/text";
import {modalTextColor, primaryColor} from "../../../utils/variables";
import {TextInput} from "../../../styles/form";
import {runInAction} from "mobx";
import {Image} from "../../../styles/image";
import InfoIcon from "../../../assets/icons/information.svg"
import {Tooltip} from "antd";
import {ApproveActionItem} from "../../../components/confirm/confirm.action";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";

interface StatusesCreateEditViewProps {
    setModal: (isOpen: boolean) => void;
    initialValue: StatusModel | null;
    statuses: StatusModel[];
}

const StatusesCreateEditView = observer(({setModal, initialValue, statuses}: StatusesCreateEditViewProps) => {

    const {
        loading,
        form,
        createEditStatusReq,
        init,
        destroyForm,
        setField
    } = StatusesCreateEditStore;
    const {statusesAfter, statusesAfterReq, statusesReq} = StatusesStore;
    const {name, code, description, can_update, can_execute, starting_status} = form;

    const handleSubmit = async () => {
        const res = await createEditStatusReq(!!initialValue, statuses);
        if (res) {
            handleStatusSelected();
            destroyForm();
            setModal(false);
            await statusesReq(false);
        }
    }

    const {destroyModalDictionary, dictionaryListReq, getModalPlaceholders} = DictionaryStore;

    useEffect(() => {
        destroyModalDictionary();
        const controller = new AbortController();
        void dictionaryListReq('STATUSES', 'MODAL', controller.signal);
        void init(initialValue);

        if (initialValue) {
            void statusesAfterReq(initialValue.id)
            setTimeout(() => {
                handleExistingStatuses()
            }, 1000)
        }

        return () => {
            destroyForm();
            controller.abort();
        }

    }, [destroyModalDictionary, dictionaryListReq, destroyForm, init, initialValue]);

    const handleExistingStatuses = () => {
        for (let i = 0; i < statuses.length; i++) {
            runInAction(() => {
                statuses[i].selected = contains(statusesAfter, statuses[i].id)
            })
        }
    }

    const contains = (after: StatusModel[], id: number) => {
        for (let i = 0; i < after.length; i++) {
            if (after[i].id === id) {
                return true;
            }
        }
        return false;
    }

    const handleStatusSelected = () => {
        for (let i = 0; i < statuses?.length!; i++) {
            statuses[i].selected = false;
        }
    }

    const handleCheckboxOnChange = (id: number) => {
        for (let i = 0; i < statuses.length; i++) {
            if (statuses[i].id === id) {
                statuses[i].selected = !statuses[i].selected
                break
            }
        }
    }

    const inputs = [
        {
            field: description,
            fieldName: 'description',
            placeholder: getModalPlaceholders('description'),
            type: 'text',
            required: true
        },
        {
            field: name,
            fieldName: 'name',
            placeholder: getModalPlaceholders('name'),
            type: 'text',
            required: false
        },
        {
            field: code,
            fieldName: 'code',
            placeholder: getModalPlaceholders('code'),
            type: 'text',
            required: false
        },
        {
            field: can_update,
            fieldName: 'can_update',
            placeholder: getModalPlaceholders('can_update'),
            type: 'switch',
            required: false,
            hasTooltip: true,
            tooltipText: 'Можно изменить данные операции когда операция находится в этом статусе',
        },
        {
            field: starting_status,
            fieldName: 'starting_status',
            placeholder: getModalPlaceholders('starting_status'),
            type: 'switch',
            required: false,
            hasTooltip: true,
            tooltipText: 'Статус который присваивается к операции при создании если опция потверждение транзакции включено',
        },
        {
            field: can_execute,
            fieldName: 'can_execute',
            placeholder: getModalPlaceholders('can_execute'),
            type: 'switch',
            required: false,
            hasTooltip: true,
            tooltipText: 'Можно поменять статус операции на Успешно (провести операцию) когда операция находится в этом статусе',
        },
    ]

    const handleColorPick = (e: any) => {
        setField({...form, ['color']: e.target.value});
    }

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setField)}
                <Layout key={inputs.length + 1} hAlign={"space-between"} vAlign={"center"}>
                    <Layout widthSize={'35%'}>
                        <Text extent='S' color={modalTextColor}> {getModalPlaceholders('color')} </Text>
                    </Layout>
                    <Layout widthSize={'65%'} hAlign={"flex-end"} vAlign={"center"}>
                        <input
                            defaultValue={initialValue ? initialValue.color : '#456186'}
                            type={'color'}
                            style={{
                                height: '30px',
                                width: '30px',
                            }}
                            onChange={(e) => handleColorPick(e)}
                        />
                    </Layout>

                </Layout>
            </Layout>
            <Layout hAlign={"center"} between={5}>
                <Text color={primaryColor} weight={'bold'}> {getModalPlaceholders('next_statuses')} </Text>
                <Tooltip
                    title={'Статусы на которых можно менять текущий статус'}
                    color={primaryColor}
                >
                    <Image
                        src={InfoIcon}
                        height={'18px'}
                        width={'18px'}
                        style={{
                            filter:
                                'brightness(0) saturate(100%) invert(17%) sepia(0%) saturate(1%) hue-rotate(224deg) brightness(96%) contrast(90%)',
                        }}
                    />
                </Tooltip>
            </Layout>
            <Layout vAlign={"center"} vertical between={3}>
                {statuses?.length! && statuses.filter((item) => !(item.id === initialValue?.id)).map((status, _) => (
                    <Layout
                        hAlign={'flex-start'}
                        between={15}
                        style={{
                            width: '100%',
                            boxShadow: '0 0 5px grey',
                            padding: '15px',
                            borderRadius: '5px',
                            marginBottom: '5px',
                        }}
                        onClick={() => handleCheckboxOnChange(status.id)}
                    >
                        <Layout widthSize={'5%'}>
                            <TextInput
                                type={'checkbox'}
                                checked={status.selected}
                                // onChange={() => handleCheckboxOnChange(status.id)}
                            />
                        </Layout>
                        <Layout hAlign={"space-between"} between={15} widthSize={'95%'}>
                            <Text extent={'S'} style={{width: '25%'}}>{status.description}</Text>
                            <Text extent={'S'} style={{width: '75%'}}> {status.name}</Text>
                        </Layout>

                    </Layout>
                ))}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={loading}
                    onClick={handleSubmit}
                    disabled={isValid([inputs])}
                >{loading ? '' : renderSubmitText}</Button>
            </Layout>
        </Layout>
    );
});

export default StatusesCreateEditView;