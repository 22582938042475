import styled from 'styled-components';
import { getKeyframes } from '../utils/getKeyframes';
import {
  headerMobileSize,
  headerSize,
  media,
  miniNavbarSize,
  navbarMinSize,
  navbarSize,
} from '../utils/variables';
import { Image } from './image';

export const HeaderLayout = styled.header<{
  isNavOpen: boolean;
}>`
  background-color: #ffffff;
  height: ${headerSize}px;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;
  animation: fadeIn 500ms forwards;
  transition: 250ms;
  z-index: 2;
  ${({ isNavOpen }) =>
    isNavOpen
      ? `
            width: calc(100vw - ${navbarSize});
            max-width: calc(100vw - ${navbarMinSize});
        `
      : `
            width: calc(100vw - ${miniNavbarSize});
            max-width: calc(100vw - ${miniNavbarSize});
        `}

  ${media.mobile} {
    width: 100vw;
    max-width: 100vw;
    height: ${headerMobileSize}px;
  }

  ${media.tablet} {
    width: 100vw;
    max-width: 100vw;
    height: ${headerMobileSize}px;
  }

  ${getKeyframes('fadeIn')}
`;
