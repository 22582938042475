import styled from 'styled-components';
import { getSize, SizeTypes } from '../utils/getSize';
import {imageSize, media} from '../utils/variables';

interface ImageProps {
  extent?: SizeTypes;
  height?: string;
  padding?: number;
  rotate?: number;
  bell?: boolean;
}

export const Image = styled.img<ImageProps>`
  padding: ${({ padding }) => (padding ? `${padding}px` : ``)};
  height: ${({ height }) => (height ? `${height}` : ``)};
  transform: rotate(${({ rotate }) => (rotate ? `${rotate}deg` : '0')});
  width: ${({ extent }) =>
    extent ? `${getSize(extent, imageSize)}` : 'unset'};
  ${media.mobile} {
    height: ${({bell}) => bell && '16px'};
    width: ${({bell}) => bell && '16px'};
    vertical-align: center;
  }

  ${media.tablet} {
    height: ${({bell}) => bell && '16px'};
    width: ${({bell}) => bell && '16px'};
    vertical-align: center;
  }
`;
