import {BusinessUnitModel} from "../business_units/business_units.model";
import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {Search, SearchLayout, Select} from "../../styles/table-header";
import {Image} from "../../styles/image";
import SearchIcon from "../../assets/icons/search.svg";
import {ProductCategoryModel} from "../product_categories/product_categories.model";
import {BrandModel} from "../brands/brands.model";
import {TMeasurementsGetResponse} from "./stock.types";

interface StocksFilterViewProps {
    handleKeyDown: (e: any) => void;
    cancelSearch: (e: any) => void;
    handleFilterChange: (e: any, key: string) => void;
    measureUnits: TMeasurementsGetResponse | undefined;
    categories: ProductCategoryModel[] | undefined;
    brands: BrandModel[] | undefined;
    businessUnits: BusinessUnitModel[] | undefined;
    inModal: boolean;
}

const StocksFiltersView = observer((
    {
        handleKeyDown,
        cancelSearch,
        handleFilterChange,
        measureUnits,
        categories,
        brands,
        businessUnits,
        inModal
    }: StocksFilterViewProps
) => {

    return (
        <>
            <Layout between={10} wrapped vAlign="center" vertical={inModal}>
                <SearchLayout>
                    <Search
                        type="search"
                        id="search"
                        placeholder="Поиск"
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={(e) => cancelSearch(e)}
                    ></Search>
                    <Image src={SearchIcon} height="16px" />
                </SearchLayout>
                <Select
                    id="measure-unit-filter"
                    onChange={(e) => handleFilterChange(e, "measure_unit_id")}
                >
                    <option value="">Все единицы</option>
                    {measureUnits?.map((measureUnit, _) => (
                        <option value={measureUnit.id} key={measureUnit.id}>
                            {measureUnit.name}
                        </option>
                    ))}
                </Select>
                <Select
                    id="category-filter"
                    onChange={(e) => handleFilterChange(e, "category_id")}
                >
                    <option value="">Все категории</option>
                    {categories?.map((category, _) => (
                        <option value={category.id} key={category.id}>
                            {category.name}
                        </option>
                    ))}
                </Select>
                <Select
                    id="brand-filter"
                    onChange={(e) => handleFilterChange(e, "brand_id")}
                >
                    <option value="">Все бренды</option>
                    {brands?.map((brand, _) => (
                        <option value={brand.id} key={brand.id}>
                            {brand.name}
                        </option>
                    ))}
                </Select>
                <Select
                    id="business_unit-filter"
                    onChange={(e) => handleFilterChange(e, "business_unit_id")}
                >
                    <option value="">Все подразделения</option>
                    {businessUnits?.map((businessUnit, _) => (
                        <option value={businessUnit.id} key={businessUnit.id}>
                            {businessUnit.name}
                        </option>
                    ))}
                </Select>
            </Layout>
        </>
    );
});

export default StocksFiltersView;