import {BusinessUnitModel} from "../business_units/business_units.model";
import {observer} from "mobx-react";
import { Layout } from '../../styles/layout';
import {Search, SearchLayout, Select} from "../../styles/table-header";
import {Image} from "../../styles/image";
import SearchIcon from "../../assets/icons/search.svg";

interface ProductSerialNumberFilterViewProps {
    handleKeyDown: (e: any) => void;
    cancelSearch: (e: any) => void;
    handleFilterChange: (e: any, key: string) => void;
    businessUnits: BusinessUnitModel[] | undefined;
    inModal: boolean;
}

const ProductSerialNumberFilter = observer(
    ({
         handleKeyDown,
         cancelSearch,
         handleFilterChange,
         businessUnits,
         inModal,
     }: ProductSerialNumberFilterViewProps) =>{
        return (
            <>
                <Layout between={10} wrapped vAlign="center" vertical={inModal}>
                    <SearchLayout>
                        <Search
                            type="search"
                            id="search"
                            placeholder="Поиск"
                            onKeyDown={(e) => handleKeyDown(e)}
                            onChange={(e) => cancelSearch(e)}
                        ></Search>
                        <Image src={SearchIcon} height="16px" />
                    </SearchLayout>

                    <Select
                        id="status-filter"
                        onChange={(e) => handleFilterChange(e, 'sold')}
                    >
                        <option value="">Все статусы</option>
                        <option value="true">Продан</option>
                        <option value="false">В наличии</option>
                    </Select>
                </Layout>
            </>
        )
    }
)

export default ProductSerialNumberFilter;