import { observer } from 'mobx-react';
import { Text } from '../../styles/text';
import { Image } from '../../styles/image';
import { primaryColor } from '../../utils/variables';

import Xmark from '../../assets/icons/xmark.svg';
import {
  ModalBody,
  ModalContainer,
  ModalGlobalStyles,
  ModalHeader,
  ModalInner,
} from './modal.style';

interface ModalProps {
  children: JSX.Element;
  title?: string;
  onClose: () => void;
  mobile?: boolean;
}

const ModalView = observer(({ children, title, onClose, mobile }: ModalProps) => {
  return (
    <ModalContainer onClick={onClose} mobile={mobile}>
      <ModalInner onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <Text extent="20px" weight="700" color={primaryColor}>
            {title}
          </Text>
          <Image src={Xmark} extent="18px" onClick={onClose} />
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </ModalInner>
      <ModalGlobalStyles />
    </ModalContainer>
  );
});

export default ModalView;
