import styled from 'styled-components';
import { media, primaryColor } from '../../utils/variables';
import { Image } from '../../styles/image';

interface BurgerProps {
  open: boolean;
}

export const BurgerBar = styled.div<BurgerProps>`
  color: ${primaryColor};
  width: 20px;
  height: 3.5px;
  border-radius: 2px;
  transform-origin: 1px;
  background-color: ${({ open }) => (open ? '#ccc' : `${primaryColor}`)};
  transition: all 0.3s linear;

  &:nth-child(1) {
    transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
  }

  &:nth-child(2) {
    transform: ${({ open }) => (open ? 'translateX(-100%)' : 'translateX(0)')};
    opacity: ${({ open }) => (open ? 0 : 1)};
  }

  &:nth-child(3) {
    transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
  }

  ${({ open }) => (open ? `display: none;` : ``)}
`;

export const Burger = styled.div<BurgerProps>`
  width: 20px;
  height: 21px;
  text-align: center;
  align-content: center;
  position: fixed;
  top: 14px;
  left: 10px;
  display: none;
  z-index: 6;

  ${media.tablet} {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  ${media.mobile} {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
`;

export const BurgerContainer = styled.div`
  ${media.desktop} {
    display: none;
  }
`;

export const BurgerXMark = styled(Image)<BurgerProps>`
  position: fixed;
  top: 12px;
  left: 12px;
  display: none;
  z-index: 6;

  ${media.tablet} {
    display: ${({ open }) => (open ? `flex` : `none`)};
    justify-content: space-around;
    flex-flow: column nowrap;
    filter: brightness(0) saturate(100%) invert(87%) sepia(0%) saturate(0%)
      hue-rotate(336deg) brightness(95%) contrast(90%);
  }

  ${media.mobile} {
    display: ${({ open }) => (open ? `flex` : `none`)};
    justify-content: space-around;
    flex-flow: column nowrap;
    filter: brightness(0) saturate(100%) invert(87%) sepia(0%) saturate(0%)
      hue-rotate(336deg) brightness(95%) contrast(90%);
  }
`;
