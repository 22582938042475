import Database from '../../assets/icons/database.svg';
import {
  Image,
  NoResultTCell,
  NoResultText,
  NoResultTRow,
} from './no_result_title.style';

const NoResultTitle = ({ colspan }: { colspan: number }) => {
  return (
    <NoResultTRow>
      <NoResultTCell colSpan={colspan}>
        <Image src={Database} alt="Database Icon" />
        <NoResultText>Нет данных</NoResultText>
      </NoResultTCell>
    </NoResultTRow>
  );
};

export default NoResultTitle;
