import {observer} from 'mobx-react';
import {StatusBadge} from '../../styles/badge';
import {Layout} from '../../styles/layout';
import {Button} from '../../styles/button';
import NoResultTitle from '../../components/no_result_title/no_result_title.view';
import {Permission} from '../../app.model';
import {TransactionsModel} from './transactions.model';
import {hasPermission} from '../../utils/hasPermission';
import {PermissionGroup} from '../../utils/permission';
import {handleDownloadFile} from '../../utils/handleDownloadFile';
import {api} from '../../api/endpoints';
import {useState} from 'react';
import TransactionsStore from './transactions.store';
import {Text} from '../../styles/text';
import ModalView from '../../components/modal/modal.view';
import {primaryColor} from '../../utils/variables';
import {
    EyeImgIcon,
    Table,
    TableBody,
    TableHead,
    TCell,
    THeadCell,
    TRow,
} from '../../styles/table';
import TableSkeleton from '../../utils/skeleton/table.skeleton';
import EyeIcon from '../../assets/icons/eye.svg';
import AppStore from '../../app.store';

interface TransactionsSimpleTableMobileViewProps {
    loading: boolean;
    permissions: Permission[] | undefined;
    transactions: TransactionsModel[] | undefined;
    getToken: () => string | null;
    transactionJournalReq: (id: number) => Promise<void> | undefined;
    setJournalModal: (isOpen: boolean) => void;
    tableHeaders: (key: string) => string;
    handleChangeStatusClick: (transaction: TransactionsModel) => void;
    handleChangeStatusAccess: (transaction: TransactionsModel) => boolean;
}

const TransactionsSimpleTableMobileView = observer(
    ({
         loading,
         permissions,
         transactions,
         getToken,
         transactionJournalReq,
         setJournalModal,
         tableHeaders,
         handleChangeStatusClick,
         handleChangeStatusAccess
     }: TransactionsSimpleTableMobileViewProps) => {
        const [modal, setModal] = useState(false);
        const [initialValue, setInitialValue] = useState<TransactionsModel | null>(
            null
        );
        const {
            transactionProductsReq,
            transactionServices,
            transactionGoods,
            destroyProds,
        } = TransactionsStore;
        const {businessSettings, simpleTableView} = AppStore;
        const [transactionProdsModal, setTransactionProdsModal] = useState(false);

        const handleRowClick = (transaction: TransactionsModel) => {
            setModal(true);
            setInitialValue(transaction);
        };

        const handleCloseModal = () => {
            setModal(false);
            setInitialValue(null);
        };

        const handleTranProdsButtonClick = async (
            transaction: TransactionsModel
        ) => {
            setInitialValue(transaction);
            setTransactionProdsModal(true);
            await transactionProductsReq(transaction.id);
        };

        const handleCloseTranProdsModal = () => {
            destroyProds();
            setTransactionProdsModal(false);
        };

        const handleTransactionJournalReq = async (id: number) => {
            setJournalModal(true);
            await transactionJournalReq(id);
        };

        const renderTranProdItems = () => (
            <Layout vertical between={10}>
                {transactionGoods?.length && (
                    <Layout vertical between={5}>
                        <Layout hAlign={'center'}>
                            <Text weight={'bold'} color={primaryColor}>
                                Товары
                            </Text>
                        </Layout>
                        <Layout
                            vertical
                            between={10}
                            style={{
                                maxWidth: '100%',
                                display: 'flex',
                                borderRadius: '5px',
                                width: '100%',
                            }}
                        >
                            {transactionGoods.map((goods, _) => (
                                <Layout
                                    between={3}
                                    vertical
                                    style={{
                                        maxWidth: '100%',
                                        display: 'flex',
                                        boxShadow: '0 0 5px grey',
                                        padding: '15px',
                                        borderRadius: '5px',
                                        marginBottom: '5px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Layout hAlign={'space-between'}>
                                        <Text weight={'bold'}>Назввание</Text>
                                        <Text>{goods.name}</Text>
                                    </Layout>
                                    <Layout hAlign={'space-between'}>
                                        <Text weight={'bold'}>Бренд</Text>
                                        <Text>{goods.brand}</Text>
                                    </Layout>
                                    <Layout hAlign={'space-between'}>
                                        <Text weight={'bold'}>Единица измерения</Text>
                                        <Text>{goods.measure_unit}</Text>
                                    </Layout>
                                    <Layout hAlign={'space-between'}>
                                        <Text weight={'bold'}>Количество</Text>
                                        <Text>{goods.quantity}</Text>
                                    </Layout>
                                    <Layout hAlign={'space-between'}>
                                        <Text weight={'bold'}>Цена</Text>
                                        <Text>{goods.price}</Text>
                                    </Layout>
                                    <Layout hAlign={'space-between'}>
                                        <Text weight={'bold'}>Общая сумма</Text>
                                        <Text>{goods.total}</Text>
                                    </Layout>
                                </Layout>
                            ))}
                        </Layout>
                    </Layout>
                )}
                {transactionServices?.length && (
                    <Layout vertical between={5}>
                        <Layout hAlign={'center'}>
                            <Text weight={'bold'} color={primaryColor}>
                                Сервисы
                            </Text>
                        </Layout>
                        <Layout
                            vertical
                            between={10}
                            style={{
                                maxWidth: '100%',
                                display: 'flex',
                                borderRadius: '5px',
                                width: '100%',
                            }}
                        >
                            {transactionServices.map((service, _) => (
                                <Layout
                                    vertical
                                    between={3}
                                    style={{
                                        maxWidth: '100%',
                                        display: 'flex',
                                        boxShadow: '0 0 5px grey',
                                        padding: '15px',
                                        borderRadius: '5px',
                                        marginBottom: '5px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Layout hAlign={'space-between'}>
                                        <Text weight={'bold'}>Назввание</Text>
                                        <Text>{service.name}</Text>
                                    </Layout>
                                    <Layout hAlign={'space-between'}>
                                        <Text weight={'bold'}>Счет</Text>
                                        <Text>{service.account}</Text>
                                    </Layout>
                                    <Layout hAlign={'space-between'}>
                                        <Text weight={'bold'}>Сумма</Text>
                                        <Text>{service.total}</Text>
                                    </Layout>
                                </Layout>
                            ))}
                        </Layout>
                    </Layout>
                )}
            </Layout>
        );

        const renderModalItems = (transaction: TransactionsModel) => (
            <Layout vertical between={10}>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('owner_transaction_id')}</Text>
                    <Text>{transaction.owner_transaction_id}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('business_unit')}</Text>
                    <Text>{transaction.business_unit}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('user')}</Text>
                    <Text>{transaction.user}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('client')}</Text>
                    <Text>{transaction.client}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('payment_method')}</Text>
                    <Text>{transaction.payment_method}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('trans_type')}</Text>
                    <Text>{transaction.trans_type}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('details')}</Text>
                    <Text>{transaction.details}</Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('amount')}</Text>
                    <Text>{transaction.amount}</Text>
                </Layout>
                {businessSettings?.product_commissions &&
                    <Layout hAlign="space-between">
                        <Text weight={'bold'}>
                            <Text>{tableHeaders('commission')}</Text>
                        </Text>
                        <Text>{transaction.commission}</Text>
                    </Layout>}
                {businessSettings?.product_commissions &&
                    <Layout hAlign="space-between">
                        <Text weight={'bold'}>
                            <Text>Общая сумма</Text>
                        </Text>
                        <Text>{transaction.amount + transaction.commission}</Text>
                    </Layout>
                }
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('status')}</Text>
                    <Text>
                        <StatusBadge color={transaction.status_color}>
                            {transaction.status}
                        </StatusBadge>
                    </Text>
                </Layout>
                <Layout hAlign={'space-between'}>
                    <Text weight={'bold'}>{tableHeaders('formatted_date')}</Text>
                    <Text>{transaction.formatted_date}</Text>
                </Layout>
                <Layout hAlign={'center'} between={10} vertical>
                    <Layout hAlign={'center'} between={5}>
                        {handleChangeStatusAccess(transaction) && (
                            <Button
                                onClick={() => handleChangeStatusClick(transaction)}
                            >
                                Изменить статус
                            </Button>
                        )}
                    </Layout>
                    <Layout hAlign={'center'} between={5}>
                        {hasPermission(
                            permissions!,
                            PermissionGroup.TRANSACTIONS,
                            'DOWNLOAD'
                        ) && (
                            <Button
                                onClick={() => {
                                    void handleDownloadFile(
                                        api.download_transaction_cheque + '?id=' + transaction.id,
                                        getToken(),
                                        'operation_' + transaction.id,
                                        'pdf'
                                    );
                                }}
                            >
                                Скачать PDF
                            </Button>
                        )}
                        <Button onClick={() => handleTransactionJournalReq(transaction.id)}>
                            История изменений
                        </Button>
                        {simpleTableView ? (
                            <Button onClick={() => handleTranProdsButtonClick(transaction)}>
                                Продукты
                            </Button>
                        ) : (
                            ''
                        )}
                    </Layout>
                </Layout>
            </Layout>
        );

        const renderedItem = () => (
            <>
                <Table>
                    <TableHead>
                        <TRow tableHead>
                            <THeadCell>{tableHeaders('business_unit')}</THeadCell>
                            <THeadCell>{tableHeaders('amount')}</THeadCell>
                            <THeadCell>{tableHeaders('status')}</THeadCell>
                            <THeadCell></THeadCell>
                        </TRow>
                    </TableHead>
                    {loading ? (
                        <TableSkeleton columns={4}/>
                    ) : (
                        <TableBody>
                            {transactions?.length ? (
                                transactions?.map((transaction, index) => (
                                    <TRow key={index} menu>
                                        <TCell>{transaction.business_unit}</TCell>
                                        <TCell>{transaction.amount}</TCell>
                                        <TCell style={{position: 'sticky'}}>
                                            <StatusBadge color={transaction.status_color}>
                                                {transaction.status}
                                            </StatusBadge>
                                        </TCell>
                                        <TCell relative>
                                            <EyeImgIcon
                                                src={EyeIcon}
                                                height="18px"
                                                onClick={() => handleRowClick(transaction)}
                                            />
                                        </TCell>
                                    </TRow>
                                ))
                            ) : (
                                <NoResultTitle colspan={4}/>
                            )}
                        </TableBody>
                    )}
                </Table>
                {modal && (
                    <ModalView
                        title={`Данные операции ${initialValue?.owner_transaction_id}`}
                        onClose={handleCloseModal}
                    >
                        {renderModalItems(initialValue!)}
                    </ModalView>
                )}
                {transactionProdsModal && (
                    <ModalView
                        title={`Продукты операции ${initialValue?.owner_transaction_id}`}
                        onClose={handleCloseTranProdsModal}
                    >
                        {renderTranProdItems()}
                    </ModalView>
                )}
            </>
        );

        return <Layout>{renderedItem()}</Layout>;
    }
);

export default TransactionsSimpleTableMobileView;
