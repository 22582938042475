import React, {useState, useRef, FormEvent, KeyboardEvent} from 'react';
import {Layout} from '../../styles/layout';
import {Button} from "../../styles/button";
import {TextInput} from "../../styles/form";
import SerialNumbersStore from "./product_serial_numbers.store";
import {observer} from "mobx-react";

interface InputField {
    value: string;
}

interface SerialNumbersCreateViewProps {
    setModal: (isOpen: boolean) => void;
}

const SerialNumbersCreateView = observer(
    ({setModal}:SerialNumbersCreateViewProps) => {
        const {
            serialNumbersCreateReq,
            serialNumbersReq,
            loading,
        } = SerialNumbersStore;
        const [inputs, setInputs] = useState<InputField[]>([{value: ''}]);
        const inputRefs = useRef([]);

        const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
            const newInputs = [...inputs];
            newInputs[index].value = event.target.value;
            setInputs(newInputs);
        };

        const handleAddInput = () => {
            setInputs((prevInputs) => {
                const newInputs = [...prevInputs, { value: '' }];
                // Create a new ref for the new input
                inputRefs.current = newInputs.map((_, index) => inputRefs.current[index] || React.createRef());
                return newInputs;
            });

            // Focus on the new input
            setTimeout(() => {
                const lastInputIndex = inputs.length; // This is the current length before the update
                // @ts-ignore
                inputRefs.current[lastInputIndex].current.focus();
            }, 0);
        };

        const handleRemoveInput = (index: number) => {
            // Prevent removal if it's the last input
            if (inputs.length > 1) {
                const newInputs = inputs.filter((_, i) => i !== index);
                setInputs(newInputs);
                // Remove the ref for the removed input
                inputRefs.current.splice(index, 1);
            }
        };

        const handleKeyDown = (index: number, event: any) => {
            if (event.key === 'Enter') {
                event.preventDefault(); // Prevent form submission
                handleAddInput();
            }

            // Handle 'Ctrl + V' or 'Command + V' (macOS) for paste action
            else if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
                // You can let the paste happen and capture the value later
                setTimeout(() => {
                    const values = event.target.value.trim().split(' ')

                    setInputs((prevInputs) => {
                        // Create new inputs based on the pasted values
                        const newInputs = [...prevInputs];

                        const insertedInputs = [];
                        // Add the pasted values as new inputs
                        for (let i = 0; i < values.length; i++) {
                            if (values[i] !== '') {
                                insertedInputs.push({ value: values[i] }); // Add each value from the paste as a new input
                            }
                        }

                        // Insert the pasted values starting from the given index
                        newInputs.splice(index, 1, ...insertedInputs);


                        // Update the inputRefs for new inputs
                        inputRefs.current = newInputs.map((_, index) => inputRefs.current[index] || React.createRef());

                        return newInputs;
                    });

                }, 1000);
            }
        };

        const handleSubmit = (event: any) => {
            event.preventDefault();
            const valuesArray = getInputValues();
            serialNumbersCreateReq(valuesArray)
            setModal(false)
            serialNumbersReq()
        };

        const getInputValues = () => {
            return inputs
                .map(input => input.value) // Extract values
                .filter(value => value.trim() !== ''); // Filter out empty strings
        };

        return (
            <Layout vertical between={20}>
                <Layout vertical between={10}>
                    {inputs.map((input, index) => (
                        <Layout between={5}>
                            <Layout widthSize="65%">
                                <TextInput
                                    ref={inputRefs.current[index]}
                                    maxLength={36}
                                    type={'text'}
                                    value={input.value}
                                    disabled={false}
                                    onChange={(event) => handleChange(index, event)}
                                    onKeyDown={(event) => handleKeyDown(index, event)}
                                />
                            </Layout>
                            <Button
                                extent="ES"
                                heightSize="35px"
                                isLoading={false}
                                onClick={() => handleRemoveInput(index)}
                            >
                                {'-'}
                            </Button>
                        </Layout>
                    ))}
                    <Button
                        extent="S"
                        heightSize="35px"
                        isLoading={false}
                        onClick={handleAddInput}
                    >
                        {'+'}
                    </Button>
                </Layout>
                <Layout hAlign="center">
                    <Button
                        extent="S"
                        heightSize="35px"
                        isLoading={loading}
                        onClick={(e) => handleSubmit(e)}
                    >
                        {loading ? '' : 'Подтвердить'}
                    </Button>
                </Layout>
            </Layout>
        );
    })

export default SerialNumbersCreateView;