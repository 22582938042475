import styled from 'styled-components';
import {media} from "../utils/variables";

interface HeadlineProps {
    extent?: number;
    color?: string;
}

export const Headline = styled.h1<HeadlineProps>`
    margin: 0;
    color: ${({ color }) => color || 'inherit'};
    font-size: ${({ extent }) => extent || '24'}px;
    ${media.mobile} {
      font-size: 18px;
    }
`;