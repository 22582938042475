import {observer} from "mobx-react";
import PayrollsItemsStore from "./payrolls.items.store";
import {useEffect} from "react";
import {Layout} from "../../../../styles/layout";
import {Text} from "../../../../styles/text";
import {modalTextColor, primaryColor} from "../../../../utils/variables";
import {Table, TableContainer, TableContainerMobile, TableHead, TCell, THeadCell, TRow} from "../../../../styles/table";

interface PayrollsItemsViewProps {
    payrollID: number;
}

const PayrollsItemsView = observer(({payrollID}: PayrollsItemsViewProps) => {

    const {payrollItemsReq, payrollItems} = PayrollsItemsStore;

    useEffect(() => {
        const controller = new AbortController();
        payrollItemsReq(payrollID, controller.signal);

        return () => {
            controller.abort();
        }
    }, [payrollItemsReq]);

    return (
        <Layout vertical between={10}>
            <Layout vertical between={10}>
                <Layout hAlign={"flex-start"} vAlign={"center"} between={20}>
                    <Layout widthSize={'30%'}>
                        <Text weight={'bold'} extent={'S'} color={modalTextColor}>Оклад</Text>
                    </Layout>
                    <Text extent={'S'}>{payrollItems.salary}</Text>
                </Layout>
                <Layout hAlign={"flex-start"} vAlign={"center"} between={20}>
                    <Layout widthSize={'30%'}>
                        <Text weight={'bold'} extent={'S'} color={modalTextColor}>Подоходный налог</Text>
                    </Layout>
                    <Text extent={'S'}>{payrollItems.tax_amount}</Text>
                </Layout>
                <Layout hAlign={"flex-start"} vAlign={"center"} between={20}>
                    <Layout widthSize={'30%'}>
                        <Text weight={'bold'} extent={'S'} color={modalTextColor}>Пособия</Text>
                    </Layout>
                    <Text extent={'S'}>{payrollItems.total_allowances}</Text>
                </Layout>
                <Layout hAlign={"flex-start"} vAlign={"center"} between={20}>
                    <Layout widthSize={'30%'}>
                        <Text weight={'bold'} extent={'S'} color={modalTextColor}>Удержания</Text>
                    </Layout>
                    <Text extent={'S'}>{payrollItems.total_deductions}</Text>
                </Layout>
                <Layout hAlign={"flex-start"} vAlign={"center"} between={20}>
                    <Layout widthSize={'30%'}>
                        <Text weight={'bold'} extent={'S'} color={modalTextColor}>К оплате</Text>
                    </Layout>
                    <Text extent={'S'}>{payrollItems.total_amount}</Text>
                </Layout>
            </Layout>
            {payrollItems?.allowances?.length && (
                <p>
                    <p
                        style={{
                            textAlign: 'center',
                            color: primaryColor,
                            fontWeight: 'bold',
                        }}
                    >
                        Пособия
                    </p>
                    <TableContainer>
                        <Table inModal>
                            <TableHead>
                                <TRow tableHead>
                                    <THeadCell>Название</THeadCell>
                                    <THeadCell>Сумма</THeadCell>
                                </TRow>
                            </TableHead>
                            {payrollItems.allowances.length && (
                                payrollItems.allowances.map((allowance, index) => (
                                    <TRow key={index}>
                                        <TCell>{allowance.name}</TCell>
                                        <TCell>{allowance.amount}</TCell>
                                    </TRow>
                                ))
                            )}
                        </Table>
                    </TableContainer>
                    <TableContainerMobile>
                        <Table inModal>
                            <TableHead>
                                <TRow tableHead>
                                    <THeadCell>Название</THeadCell>
                                    <THeadCell>Сумма</THeadCell>
                                </TRow>
                            </TableHead>
                            {payrollItems.allowances.length && (
                                payrollItems.allowances.map((allowance, index) => (
                                    <TRow key={index}>
                                        <TCell>{allowance.name}</TCell>
                                        <TCell>{allowance.amount}</TCell>
                                    </TRow>
                                ))
                            )}
                        </Table>
                    </TableContainerMobile>
                </p>
            )}
            {payrollItems?.deductions?.length && (
                <p>
                    <p
                        style={{
                            textAlign: 'center',
                            color: primaryColor,
                            fontWeight: 'bold',
                        }}
                    >
                        Удержания
                    </p>
                    <TableContainer>
                        <Table inModal>
                            <TableHead>
                                <TRow tableHead>
                                    <THeadCell>Название</THeadCell>
                                    <THeadCell>Сумма</THeadCell>
                                </TRow>
                            </TableHead>
                            {payrollItems.deductions.length && (
                                payrollItems.deductions.map((allowance, index) => (
                                    <TRow key={index}>
                                        <TCell>{allowance.name}</TCell>
                                        <TCell>{allowance.amount}</TCell>
                                    </TRow>
                                ))
                            )}
                        </Table>
                    </TableContainer>
                    <TableContainerMobile>
                        <Table inModal>
                            <TableHead>
                                <TRow tableHead>
                                    <THeadCell>Название</THeadCell>
                                    <THeadCell>Сумма</THeadCell>
                                </TRow>
                            </TableHead>
                            {payrollItems.deductions.length && (
                                payrollItems.deductions.map((allowance, index) => (
                                    <TRow key={index}>
                                        <TCell>{allowance.name}</TCell>
                                        <TCell>{allowance.amount}</TCell>
                                    </TRow>
                                ))
                            )}
                        </Table>
                    </TableContainerMobile>
                </p>
            )}
        </Layout>
    );
});

export default PayrollsItemsView;