import { observer } from 'mobx-react';
import { Layout } from '../../../styles/layout';
import { ClientContactsModel } from './clients.contacts.model';
import clientsContactsCreateEditeStore from './clients.contacts.create.edite.store';
import ClientContactsStore from './../clients.contacts.store';
import { useEffect } from 'react';
import { renderInputs } from '../../../components/form/render.inputs';
import { Button } from '../../../styles/button';
import { isValid } from '../../../utils/isValid';
import DictionaryStore from "../../../utils/dictionary/dictionary.store";

interface CreateEditAccountProps {
  initialValue: ClientContactsModel | null;
  setModal: (isOpen: boolean) => void;
  entityId: string;
}

const ClientContactsCreateEditView = observer(
  ({ initialValue, setModal, entityId }: CreateEditAccountProps) => {
    const {
      form,
      setField,
      init,
      createOrEditClientContacts,
      isLoading,
      destroy,
    } = clientsContactsCreateEditeStore;

    const {
      name,
      surname,
      patronymic,
      phone,
      extra_phone,
      address,
      relationship,
    } = form;

    const { clientContactReq } = ClientContactsStore;
    const {destroyModalDictionary, dictionaryListReq, getModalPlaceholders} = DictionaryStore;

    useEffect(() => {
      destroyModalDictionary();
      const controller = new AbortController();
      dictionaryListReq('CLIENT CONTACTS', 'MODAL', controller.signal);
      init(initialValue);

      return () => {
        destroy();
        controller.abort();
      };
    }, [destroyModalDictionary, dictionaryListReq, destroy, init, initialValue]);

    const inputs = [
      {
        field: name,
        fieldName: 'name',
        placeholder: getModalPlaceholders('name'),
        type: 'text',
        required: true,
      },
      {
        field: surname,
        fieldName: 'surname',
        placeholder: getModalPlaceholders('surname'),
        type: 'text',
        required: true,
      },

      {
        field: patronymic,
        fieldName: 'patronymic',
        placeholder: getModalPlaceholders('patronymic'),
        type: 'text',
        required: false,
      },
      {
        field: phone,
        fieldName: 'phone',
        placeholder: getModalPlaceholders('phone'),
        type: 'text',
        required: true,
      },
      {
        field: extra_phone,
        fieldName: 'extra_phone',
        placeholder: getModalPlaceholders('extra_phone'),
        type: 'text',
      },
      {
        field: address,
        fieldName: 'address',
        placeholder: getModalPlaceholders('address'),
        type: 'text',
      },
      {
        field: relationship,
        fieldName: 'relationship',
        placeholder: getModalPlaceholders('relationship'),
        type: 'text',
      },
    ];

    const handleCreateEditAccount = async () => {
      const res = await createOrEditClientContacts(!!initialValue, entityId);
      if (res) {
        void clientContactReq(entityId);
        setModal(false);
      }
    };

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
      <Layout vertical between={20}>
        <Layout vertical between={10}>
          {renderInputs(form, inputs, setField)}
        </Layout>
        <Layout hAlign="center">
          <Button
            extent="S"
            heightSize="35px"
            isLoading={isLoading}
            onClick={handleCreateEditAccount}
            disabled={isValid([inputs])}
          >
            {isLoading ? '' : renderSubmitText}
          </Button>
        </Layout>
      </Layout>
    );
  }
);

export default ClientContactsCreateEditView;
