import {observer} from "mobx-react";
import {ProductBrandModel, ProductCategoryModel, ProductTypeModel} from "./products.model";
import {Layout} from "../../styles/layout";
import {Search, SearchLayout, Select} from "../../styles/table-header";
import {Image} from "../../styles/image";
import SearchIcon from "../../assets/icons/search.svg";

interface ProductsFilterViewProps {
    handleKeyDown: (e: any) => void;
    cancelSearch: (e: any) => void;
    handleFilterChange: (e: any, key: string) => void;
    productBrands: ProductBrandModel[] | undefined;
    productCategories: ProductCategoryModel[] | undefined;
    productTypes: ProductTypeModel[] | undefined;
    inModal: boolean
}

const ProductsFilterView = observer(({
                                         handleKeyDown,
                                         cancelSearch,
                                         handleFilterChange,
                                         productBrands,
                                         productCategories,
                                         productTypes,
                                         inModal
                                     }: ProductsFilterViewProps) => {
    return (
        <>
            <Layout between={10} wrapped vAlign="center" vertical={inModal}>
                <SearchLayout>
                    <Search type="search" id="search" placeholder="Поиск"
                            onKeyDown={e => handleKeyDown(e)}
                            onChange={e => cancelSearch(e)}>
                    </Search>
                    <Image src={SearchIcon} height="16px"/>
                </SearchLayout>
                <Select id="type-filter"
                        onChange={e => handleFilterChange(e, 'type_id')}>
                    <option value="">Все типы</option>
                    {productTypes?.map((productType, index) => <option value={productType.id}
                                                                       key={productType.id}>{productType.name}</option>)}
                </Select>
                <Select id="product-categories-filter"
                        onChange={e => handleFilterChange(e, 'category_id')}>
                    <option value="">Все категории</option>
                    {productCategories?.map((productCategory, index) =>
                        <option value={productCategory.id}
                                key={productCategory.id}>{productCategory.name}</option>)}
                </Select>
                <Select id="product-brands-filter"
                        onChange={e => handleFilterChange(e, 'brand_id')}>
                    <option value="">Все бренды</option>
                    {productBrands?.map((productBrand, index) =>
                        <option value={productBrand.id}
                                key={productBrand.id}>{productBrand.name}</option>)}
                </Select>
            </Layout>
        </>
    )
});

export default ProductsFilterView;