import {TransactionType} from "./transactions.model";
import AppStore from "../../app.store";
import axios, { GenericAbortSignal } from "axios";
import {api} from "../../api/endpoints";
import {makeAutoObservable} from "mobx";

class TransactionTypeStore {
    public transactionTypes ?: TransactionType[];

    public transactionTypesInit = (signal: GenericAbortSignal) => {
        void this.transactionTypeReq(signal);
    }

    constructor() {
        makeAutoObservable(this);
    }

    setTransactionTypes(data: any) {
        const {transaction_types} = data;
        this.transactionTypes = transaction_types;
    }

    public transactionTypeReq = async (signal?: GenericAbortSignal) => {

        try {
            const {getToken} = AppStore

            const headers = {
                Authorization: "Bearer " + getToken()!
            }

            let response: any;
            response = await axios.get(api.transaction_types, { headers, signal });

            this.setTransactionTypes(response.data)
        } catch (err) {
        }
    }
}

export default new TransactionTypeStore();