import styled from "styled-components";
import { skeleton } from "../variables";

export const WrapperSkeleton = styled.div`
  .wrapper {
    overflow: hidden;
    position: relative;
    margin-top: 50px;
  }
  .div-top {
    width: 100%;
    height: 60px;
    display: flex;
    padding-top: 5px;
  }
  .div-top .input-1{
    overflow: hidden;
    position: relative;
    width: 20%;
    height: 40px;
    margin-right: 10px;
    border-radius: 12px;
    background: #fff;
  }
  .div-middle {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 350px;
    background: #fff;
    border-radius: 12px;
    margin-bottom: 20px;
  }
  .div-row {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 24px;
    background: #fff;
    border-radius: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .div-column {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 24px;
    background: #fff;
    border-radius: 12px;
    gap: 15px;
  }
  
  .div-bottom {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .div-bottom .left{
    width: 30%;
    height: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .div-bottom .left .back{
    overflow: hidden;
    position: relative;
    width: 80px;
    height: 30px;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 12px;
  }
  .div-bottom .left .number{
    overflow: hidden;
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 12px;
  }
  .div-bottom .left .next{
    overflow: hidden;
    position: relative;
    width: 80px;
    height: 30px;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 12px;
  }
  .div-bottom .right{
    width: 5%;
    height: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .div-bottom .right .div2{
    overflow: hidden;
    position: relative;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 12px;
  }
  .div-top .input-1:before {
    width: ${skeleton.Width};
    height: ${skeleton.Height};
    position: absolute;
    top: ${skeleton.PositionTop};
    content: "";
    transform: ${skeleton.Transform};
    background: ${skeleton.BackgroundColor};
    background: ${skeleton.BackgroundColorLineGradient};
    animation: ${skeleton.Animation};
  }
  .div-middle:before {
    width: ${skeleton.Width};
    height: ${skeleton.Height};
    position: absolute;
    top: ${skeleton.PositionTop};
    content: "";
    transform: ${skeleton.Transform};
    background: ${skeleton.BackgroundColor};
    background: ${skeleton.BackgroundColorLineGradient};
    animation: ${skeleton.Animation};
  }
  
  .div-row:before {
    width: ${skeleton.Width};
    height: ${skeleton.Height};
    position: absolute;
    top: ${skeleton.PositionTop};
    content: "";
    transform: ${skeleton.Transform};
    background: ${skeleton.BackgroundColor};
    background: ${skeleton.BackgroundColorLineGradient};
    animation: ${skeleton.Animation};
  }
  
  .div-column:before {
    width: ${skeleton.Width};
    height: ${skeleton.Height};
    position: absolute;
    top: ${skeleton.PositionTop};
    content: "";
    transform: ${skeleton.Transform};
    background: ${skeleton.BackgroundColor};
    background: ${skeleton.BackgroundColorLineGradient};
    animation: ${skeleton.Animation};
  }

  @keyframes shining {
    0% {
      left: -100%;
    }
    100% {
      left: 130%;
    }
  }
`;

export const TableCellSkeleton = styled.div`
  .cell-skeleton {
    position: relative;
    height: 15px;
    width: 100%;
    border-radius: 8px;
    background: #dbdbdb
  }
  
  .cell-skeleton:before {
    position: absolute;
    content: "";
    transform: skew(45deg);
    background: ${skeleton.BackgroundColor};
    background: ${skeleton.BackgroundColorLineGradient};
    animation: ${skeleton.Animation};
    height: 40px;
    width: 70px;
    top: 0;
    bottom: 0;
    filter: blur(10px);
    background: #ffffff
  }

  @keyframes shining {
    0% {
      left: -100%;
    }
    100% {
      left: 130%;
    }
  }
`;
