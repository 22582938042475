export const api = process.env.REACT_APP_API;

export const borderRadius = '12px';
export const inputPadding = '8px 12px';
export const inputColor = '#333';
export const errorColor = 'rgba(247,19,19,1)';
export const inputColorDarkMode = '#f6f6f6';
export const textColorLight = '#f6f6f6';
export const textColorDark = '#333';
export const modalTextColor = '#aaa';

export const primaryColor = 'rgb(69, 97, 134)';
export const primaryColorTransparent = 'rgba(69, 97, 134, .2)';
export const primaryColorGradient =
  'linear-gradient(90deg,rgb(30 64 109) 0%,rgb(89 136 197) 100%)';
export const deleteButton =
  'linear-gradient(90deg, rgba(157,32,32,1) 0%, rgba(247,19,19,1) 100%)';
export const darkBackground = '#272c3b';
export const graphBoxBackground = 'rgb(255, 255, 255)';
export const graphBoxBackgroundDarkMode = 'rgb(0, 0, 0, 3)';
export const inputBackground = 'rgba(0, 0, 0, .03)';
export const inputBackgroundDarkMode = 'rgba(255, 255, 255, .3)';
export const inputBackgroundFocus = 'rgba(69, 97, 134, .3)';

export const navbarSize = '15vw';
export const miniNavbarSize = '50px';
export const navbarMinSize = '200px';
export const headerSize = 60;
export const headerMobileSize = 50;

export const modalSize = "30vw";

export const textSize = {
  S: '14px',
  M: '18px',
  L: '24px',
};

export const inputSize = {
  ES: '90px',
  S: '160px',
  M: '250px',
  L: '350px',
};

export const imageSize = {
  S: '120px',
  M: '250px',
  L: '400px',
};

export const MainPageTableSizes = {
  S: '400px',
  M: '450px',
  L: '500px'
}

export const skeleton = {
  Width: '100%',
  Height: '800%',
  PositionTop: '-400%',
  BackgroundColor: 'rgb(2, 0, 36)',
  BackgroundColorLineGradient:
    'linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(128, 128, 128, 0.4) 50%, rgba(128, 128, 128, 0) 100%)',
  Animation: 'shining 2s infinite',
  Transform: 'rotate(45deg)',
};

export const sizes = {
  mobile: 575,
  tablet: 1024,
  desktop: 1024,
}

export const media = {
  mobile: `@media (max-width: ${sizes.mobile}px)`,
  tablet: `@media (max-width: ${sizes.tablet}px)`,
  desktop: `@media (min-width: ${sizes.tablet + 1}px)`,
}
