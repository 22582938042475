import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {useState} from "react";
import {Text} from "../../styles/text";
import {Button} from "../../styles/button";
import {EyeImgIcon, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../styles/table";
import {Image} from "../../styles/image";
import EyeIcon from "../../assets/icons/eye.svg";
import {primaryColor} from "../../utils/variables";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import ModalView from "../../components/modal/modal.view";
import {BrandModel} from "./brands.model";

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface BrandsMobileViewProps {
    brands: BrandModel[] | undefined;
    loading: boolean;
    handleEditClick: (brand: BrandModel) => void;
    setConfirmDeleteModal: ({isModalOpen, id, name}: setConfirmDeleteModalProps) => void;
    tableHeaders: (key: string) => string;
}

const BrandsMobileView = observer(({brands, loading, handleEditClick, setConfirmDeleteModal, tableHeaders}: BrandsMobileViewProps) => {
    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<BrandModel | null>(null)

    const handleRowClick = (brand: BrandModel) => {
        setModal(true);
        setInitialValue(brand);
    }

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    }

    const renderModalItems = (brand: BrandModel) => (
        <Layout vertical between={10}>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('id')}</Text>
                <Text>{brand.id}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('name')}</Text>
                <Text>{brand.name}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('description')}</Text>
                <Text>{brand.description}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('country_of_origin')}</Text>
                <Text>{brand.country_of_origin}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('img')}</Text>
                <Text>{brand.img}</Text>
            </Layout>
            <Layout hAlign={"center"} between={5}>
                <Button onClick={() => handleEditClick(brand)}>Изменить</Button>
                <Button
                    background={'rgb(146,4,4)'}
                    onClick={() =>
                        setConfirmDeleteModal({
                            isModalOpen: true,
                            id: brand.id,
                            name: brand.name,
                        })
                    }
                >Удалить
                </Button>
            </Layout>
        </Layout>
    );

    const renderedItems = () => (
        <>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>{tableHeaders('name')}</THeadCell>
                        <THeadCell>{tableHeaders('country_of_origin')}</THeadCell>
                        <THeadCell>{tableHeaders('img')}</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                {!loading ? (
                    <TableBody>
                        {brands?.length ? (
                            brands?.map((brand, index) => (
                                <TRow key={index} menu>
                                    <TCell>{brand.name}</TCell>
                                    <TCell>{brand.country_of_origin}</TCell>
                                    <TCell>{brand.img}</TCell>
                                    <TCell relative>
                                        <EyeImgIcon
                                            src={EyeIcon}
                                            onClick={() => handleRowClick(brand)}
                                            height="18px"
                                            color={primaryColor}
                                        />
                                    </TCell>
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={4} />
                        )}
                    </TableBody>
                ): (
                    <TableSkeleton columns={4}/>
                )}
            </Table>
            {modal && (
                <ModalView
                    title={`Данные бренда ${initialValue?.name}`}
                    onClose={handleCloseModal}
                >
                    {renderModalItems(initialValue!)}
                </ModalView>
            )}
        </>
    );

    return (
        <Layout>
            {renderedItems()}
        </Layout>
    )
});

export default BrandsMobileView;