import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {GoodsMovementModel} from "./goods_movement.model";
import { ClockCircleOutlined } from '@ant-design/icons';
import { Timeline } from 'antd';
import GoodsMovementsStore from "./goods_movement.store";
import {useEffect} from "react";
import {Badge} from "../../styles/badge";
import {Text} from "../../styles/text";

interface GoodsMovementHistoryTrackerViewProps {
    initialValue: GoodsMovementModel | null;

}

const GoodsMovementHistoryTrackerView = observer(({initialValue}: GoodsMovementHistoryTrackerViewProps) => {

    const {trackingHistoryBySerialReq, destroyTrackingHistory, trackingHistory} = GoodsMovementsStore;

    useEffect(() => {
        destroyTrackingHistory();
        void trackingHistoryBySerialReq(initialValue?.serial_number!)
    }, [trackingHistoryBySerialReq]);

    return (
        <Layout vertical between={10}>
            {trackingHistory?.map((history, _) => (
                <div
                    style={{
                        maxWidth: '100%',
                        display: 'flex',
                        boxShadow: '0 0 5px grey',
                        padding: '15px',
                        borderRadius: '5px',
                        marginBottom: '5px',
                    }}
                >
                    <p
                        style={{
                            width: '250px',
                            fontSize: '14px',
                        }}
                    >
                        {history.user_name}
                    </p>
                    <p
                        style={{
                            width: '250px',
                            fontSize: '14px',
                            textAlign: 'center',
                        }}
                    >
                            <Badge online={!history.inflow}>
                               <Text weight={'bold'}> {history.movement_type_name}</Text>
                            </Badge>
                        <p style={{fontSize: '12px'}}> {history.business_unit_name} </p>
                    </p>
                    <p
                        style={{
                            width: '250px',
                            fontSize: '14px',
                        }}
                    >
                        {history.details}
                    </p>
                    <p
                        style={{
                            width: '250px',
                            fontSize: '14px',
                            textAlign: 'center',
                        }}
                    >
                        {history.created_at_text}
                    </p>
                </div>
            ))}
            {/*<Timeline
                mode="alternate"
                items={handleTimelineItems()}
                items={[
                    {
                        children: 'Create a services site 2015-09-01',
                    },
                    {
                        children: 'Solve initial network problems 2015-09-01',
                        color: primaryColor,
                    },
                    {
                        dot: (
                            <ClockCircleOutlined
                                style={{
                                    fontSize: '16px',
                                }}
                            />
                        ),
                        children: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
                    },
                    {
                        color: 'red',
                        children: 'Network problems being solved 2015-09-01',
                    },
                    {
                        children: 'Create a services site 2015-09-01',
                    },
                    {
                        children: 'Technical testing 2015-09-01',
                    },
                ]}
            />*/}
        </Layout>
    );
});

export default GoodsMovementHistoryTrackerView;