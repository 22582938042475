import {observer} from "mobx-react";
import {useState} from "react";
import {Layout} from "../../styles/layout";
import {Text} from "../../styles/text";
import {Button} from "../../styles/button";
import {EyeImgIcon, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../styles/table";
import {Image} from "../../styles/image";
// @ts-ignore
import EyeIcon from "../../assets/icons/eye.svg";
import {primaryColor} from "../../utils/variables";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import ModalView from "../../components/modal/modal.view";
import {SuppliersModel} from "./suppliers.model";

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface SuppliersTableMobileViewProps {
    loading: boolean;
    suppliers: SuppliersModel[] | undefined;
    handleEditClick: (supplier: SuppliersModel) => void;
    handleBrandsModalClick: (supplier: SuppliersModel) => void;
    setConfirmDeleteModal: ({isModalOpen, id, name}: setConfirmDeleteModalProps) => void;
    tableHeaders: (key: string) => string;
}

const SuppliersTableMobileView = observer(({
                                               loading,
                                               suppliers,
                                               handleEditClick,
                                               handleBrandsModalClick,
                                               setConfirmDeleteModal,
                                               tableHeaders
                                           }: SuppliersTableMobileViewProps) => {

    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<SuppliersModel | null>(null)

    const handleRowClick = (supplier: SuppliersModel) => {
        setModal(true);
        setInitialValue(supplier);
    }

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    }

    const renderModalItems = (supplier: SuppliersModel) => (
        <Layout vertical between={10}>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('name')}</Text>
                <Text>{supplier.name}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('phone')}</Text>
                <Text>{supplier.phone}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('contact_name')}</Text>
                <Text>{supplier.contact_name}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('country')}</Text>
                <Text>{supplier.country}</Text>
            </Layout>
            <Layout hAlign={"center"} between={5}>
                <Button onClick={() => handleBrandsModalClick(supplier)}>Бренды</Button>
                <Button onClick={() => handleEditClick(supplier)}>Изменить</Button>
                <Button
                    background={'rgb(146,4,4)'}
                    onClick={() =>
                        setConfirmDeleteModal({
                            isModalOpen: true,
                            id: supplier.id,
                            name: supplier.name,
                        })
                    }
                >Удалить
                </Button>
            </Layout>
        </Layout>
    );

    const renderedItems = () => (
        <>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>{tableHeaders('name')}</THeadCell>
                        <THeadCell>{tableHeaders('contact_name')}</THeadCell>
                        <THeadCell>{tableHeaders('country')}</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                {!loading ? (
                    <TableBody>
                        {suppliers?.length ? (
                            suppliers?.map((supplier, index) => (
                                <TRow key={index} menu>
                                    <TCell>{supplier.name}</TCell>
                                    <TCell>{supplier.contact_name}</TCell>
                                    <TCell>{supplier.country}</TCell>
                                    <TCell relative>
                                        <EyeImgIcon
                                            src={EyeIcon}
                                            onClick={() => handleRowClick(supplier)}
                                            height="18px"
                                            color={primaryColor}
                                        />
                                    </TCell>
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={4}/>
                        )}
                    </TableBody>
                ) : (
                    <TableSkeleton columns={4}/>
                )}
            </Table>
            {modal && (
                <ModalView
                    title={`Данные сотрудника ${initialValue?.name}`}
                    onClose={handleCloseModal}
                >
                    {renderModalItems(initialValue!)}
                </ModalView>
            )}
        </>
    );

    return (
        <Layout>
            {renderedItems()}
        </Layout>
    )
});

export default SuppliersTableMobileView;