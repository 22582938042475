import {observer} from "mobx-react";
import AppStore from "../../../app.store";
import {Page} from "../../../styles/page";
import {Headline} from "../../../styles/headline";
import {primaryColor} from "../../../utils/variables";
import {useEffect, useState} from "react";
import {EmployeeAllowancesFilterModel, EmployeeAllowancesModel} from "./employee.allowances.model";
import ModalView from "../../../components/modal/modal.view";
import EmployeeAllowancesCreateEditView from "./employee.allowances.modal/employee.allowances.create.edit.view";
import EmployeeAllowancesStore from "./employee.allowances.store";
import {AddButton, TableHeader} from "../../../styles/table-header";
import {Filters} from "../../../styles/filters";
import EmployeeAllowancesFilterView from "./employee.allowances.filter.view";
import {Button, FilterButton} from "../../../styles/button";
import {Layout} from "../../../styles/layout";
import EmployeeAllowancesCreateEditStore from "./employee.allowances.modal/employee.allowances.create.edit.store";
import {TableContainer, TableContainerMobile} from "../../../styles/table";
import EmployeeAllowancesDesktopView from "./employee.allowances.desktop.view";
import TablePaginationView from "../../../utils/table.pagination.view";
import EmployeeAllowancesMobileView from "./employee.allowances.mobile.view";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";

const EmployeeAllowancesView = observer(() => {
    const {
        employeeAllowances,
        isLoading,
        employeeAllowancesFilters,
        setEmployeeAllowancesFilter,
        clearFilter,
        init
    } = EmployeeAllowancesStore;
    const {destroy} = EmployeeAllowancesCreateEditStore;
    const {isNavOpen} = AppStore;
    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<EmployeeAllowancesModel | null>(null);
    const [filterModal, setFilterModal] = useState(false);
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        dictionaryListReq('EMPLOYEE ALLOWANCES', 'TABLE HEADERS', controller.signal);
        init(controller.signal);

        return () => {
            controller.abort();
        }

    }, [destroyDictionary, dictionaryListReq, init]);

    const handleClearFilter = () => {
        clearFilter();
        setFilterModal(false);
    };

    const handleConfirmFilter = (e: any) => {
        setFilterModal(false);
    };

    const handleCloseCreateEditModal = () => {
        setModal(false);
        destroy();
    }

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === 'page' && index !== undefined) {
            setEmployeeAllowancesFilter({page: index + 1} as EmployeeAllowancesFilterModel);
            return;
        }
        setEmployeeAllowancesFilter({[objectKey]: options[selectedIndex].value} as EmployeeAllowancesFilterModel);
    };

    const cancelSearch = (e: any) => {
        if (e.target.value === '' && employeeAllowancesFilters.search !== '') {
            setEmployeeAllowancesFilter({search: ''} as EmployeeAllowancesFilterModel);
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            setEmployeeAllowancesFilter({search: e.target.value} as EmployeeAllowancesFilterModel);
        }
    };

    const prevPage = () => {
        if (employeeAllowancesFilters.page > 1) {
            setEmployeeAllowancesFilter({page: employeeAllowancesFilters.page - 1} as EmployeeAllowancesFilterModel);
        }
    };

    const nextPage = () => {
        if (employeeAllowancesFilters.page < employeeAllowancesFilters.pages) {
            setEmployeeAllowancesFilter({page: employeeAllowancesFilters.page + 1} as EmployeeAllowancesFilterModel);
        }
    };

    const pageLimits = [25, 100, 500, 1000];

        return (
            <Page isNavOpen={isNavOpen} vertical between={20}>
                <Headline color={primaryColor}>{getPageTitle('EMPLOYEE ALLOWANCES')}</Headline>
                <TableHeader>
                    <Filters hAlign="space-between" wrapped between={10}>
                        <EmployeeAllowancesFilterView
                            handleFilterChange={handleFilterChange}
                            handleKeyDown={handleKeyDown}
                            cancelSearch={cancelSearch}
                            inModal={false}
                        />
                    </Filters>
                    <FilterButton
                        id={'employee-allowances-filters'}
                        onClick={() => setFilterModal(true)}
                    >
                        Фильтры
                    </FilterButton>
                    <AddButton type="button" id="add-employee-allowance" onClick={() => setModal(true)}>
                        Создать
                    </AddButton>
                </TableHeader>
                <TableContainer>
                    <EmployeeAllowancesDesktopView
                        loading={isLoading}
                        allowances={employeeAllowances}
                        tableHeaders={getTableHeaders}
                    />
                </TableContainer>
                <TableContainerMobile>
                    <EmployeeAllowancesMobileView
                        loading={isLoading}
                        allowances={employeeAllowances}
                        tableHeaders={getTableHeaders}
                    />
                </TableContainerMobile>
                <TablePaginationView
                    prevPage={prevPage}
                    nextPage={nextPage}
                    pages={employeeAllowancesFilters.pages}
                    currentPage={employeeAllowancesFilters.page}
                    pageLimits={pageLimits}
                    handleFilterChange={handleFilterChange}
                />
                {modal && (
                    <ModalView
                        title={initialValue ? 'Изменить' : 'Создать'}
                        onClose={handleCloseCreateEditModal}
                    >
                        <EmployeeAllowancesCreateEditView
                            setModal={setModal}
                            initialValue={initialValue}
                        />
                    </ModalView>
                )}
                {filterModal && (
                    <ModalView
                        title={'Фильтры'}
                        onClose={() => setFilterModal(false)}
                    >
                        <Layout vertical between={10}>
                            <EmployeeAllowancesFilterView
                                handleFilterChange={handleFilterChange}
                                handleKeyDown={handleKeyDown}
                                cancelSearch={cancelSearch}
                                inModal={true}
                            />
                            <Layout hAlign={'space-between'} between={10}>
                                <Button
                                    extent={'L'}
                                    id={'clear-filter-button'}
                                    onClick={handleClearFilter}
                                    background={'#333'}
                                    color={'red'}
                                >
                                    Очистить
                                </Button>
                                <Button
                                    extent={'L'}
                                    id={'confirm-filter-button'}
                                    onClick={(e) => handleConfirmFilter(e)}
                                >
                                    Потвердить
                                </Button>
                            </Layout>
                        </Layout>
                    </ModalView>
                )}
            </Page>
        );
    }
)
    ;

    export default EmployeeAllowancesView;