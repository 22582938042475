import {observer} from "mobx-react";
import AppStore from "../../../app.store";
import {Page} from "../../../styles/page";
import {Headline} from "../../../styles/headline";
import {primaryColor} from "../../../utils/variables";
import {AddButton, TableHeader} from "../../../styles/table-header";
import {Filters} from "../../../styles/filters";
import {Button, FilterButton} from "../../../styles/button";
import {TableContainer, TableContainerMobile} from "../../../styles/table";
import ExpenseOperationsStore from "./expense.operations.store";
import {useEffect, useState} from "react";
import ExpenseOperationsDesktopView from "./expense.operations.desktop.view";
import ExpenseOperationsMobileView from "./expense.operations.mobile.view";
import {ExpenseOperationsFilterModel} from "./expense.operations.model";
import TablePaginationView from "../../../utils/table.pagination.view";
import UsersStore from "../../users/users.store";
import ExpenseCategoriesStore from "../expense.categories/expense.categories.store";
import BusinessUnitsStore from "../../business_units/business_units.store";
import ExpenseOperationsFiltersView from "./expense.operations.filters.view";
import {ExpenseCategoriesFiltersModel} from "../expense.categories/expense.categories.model";
import {Layout} from "../../../styles/layout";
import ModalView from "../../../components/modal/modal.view";
import ExpensesCreateEditView from "../expenses/expenses.modal/expenses.create.edit.view";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";

const ExpenseOperationsView = observer(() => {
    const {isNavOpen} = AppStore;
    const {
        expenseOperations,
        isLoading,
        expenseOperationFilters,
        expenseOperationsPageInit,
        setExpenseOperationsFilter,
        confirmExpenseOperationReq,
        clearFilter
    } = ExpenseOperationsStore;
    const {users, setUsersFilter} = UsersStore;
    const {categories, setCategoriesFilter} = ExpenseCategoriesStore;
    const {businessUnits, setBusinessUnitFilter} = BusinessUnitsStore
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterModal, setFilterModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        void expenseOperationsPageInit(controller.signal);
        dictionaryListReq('EXPENSE OPERATIONS', 'TABLE HEADERS', controller.signal);
        setUsersFilter({page_limit: 999999});
        setCategoriesFilter({page_limit: 999999} as ExpenseCategoriesFiltersModel);
        setBusinessUnitFilter({page_limit: 999999});

        return () => {
            controller.abort();
        }

    }, [destroyDictionary, dictionaryListReq, expenseOperationsPageInit]);

    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector('.context-menu-focused');
        if (e.target.classList.contains('context-menu-focused')) {
            e.target.classList.remove('context-menu-focused');
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            e.target.classList.add('context-menu-focused');
        }
    };

    const handleConfirmOperation = async (operationID: number) => {
        await confirmExpenseOperationReq(operationID);
    }

    const prevPage = () => {
        if (expenseOperationFilters.page > 1) {
            setExpenseOperationsFilter({page: expenseOperationFilters.page - 1} as ExpenseOperationsFilterModel);
        }
    };

    const nextPage = () => {
        if (expenseOperationFilters.page < expenseOperationFilters.pages) {
            setExpenseOperationsFilter({page: expenseOperationFilters.page + 1} as ExpenseOperationsFilterModel);
        }
    };

    const handleClearFilter = () => {
        clearFilter();
        setFilterModal(false);
    };

    const handleConfirmFilter = () => {
        setFilterModal(false);
    };

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === 'page' && index !== undefined) {
            setExpenseOperationsFilter({page: index + 1} as ExpenseOperationsFilterModel);
            return;
        }
        setExpenseOperationsFilter({[objectKey]: options[selectedIndex].value} as ExpenseOperationsFilterModel);
    };

    const cancelSearch = (e: any) => {
        if (e.target.value === '' && expenseOperationFilters.search !== '') {
            setExpenseOperationsFilter({search: ''} as ExpenseOperationsFilterModel);
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            setExpenseOperationsFilter({search: e.target.value} as ExpenseOperationsFilterModel);
        }
    };

    const handleCloseCreateModal = () => {
        setCreateModal(false);
    }

    const pageLimits = [5, 10, 25, 100, 500];

    return (
        <Page isNavOpen={isNavOpen} fadeIn vertical between={20}>
            <Headline color={primaryColor}>{getPageTitle('EXPENSE OPERATIONS')}</Headline>
            <TableHeader>
                <Filters>
                    <ExpenseOperationsFiltersView
                        handleKeyDown={handleKeyDown}
                        cancelSearch={cancelSearch}
                        handleFilterChange={handleFilterChange}
                        users={users}
                        businessUnits={businessUnits}
                        categories={categories}
                        inModal={false}
                    />
                </Filters>
                <FilterButton
                    type='button'
                    id='expense-operations-filters'
                    onClick={() => setFilterModal(true)}
                >
                    Фильтры
                </FilterButton>
                <AddButton
                    type='button'
                    id='create-expense-operation'
                    onClick={() => setCreateModal(true)}
                >
                    Создать
                </AddButton>
            </TableHeader>
            <TableContainer>
                <ExpenseOperationsDesktopView
                    loading={isLoading}
                    operations={expenseOperations}
                    handleConfirmOperation={handleConfirmOperation}
                    handleContext={handleContext}
                    anchorEl={anchorEl}
                    tableHeaders={getTableHeaders}
                />
            </TableContainer>
            <TableContainerMobile>
                <ExpenseOperationsMobileView
                    loading={isLoading}
                    operations={expenseOperations}
                    handleConfirmOperation={handleConfirmOperation}
                    handleContext={handleContext}
                    anchorEl={anchorEl}
                    tableHeaders={getTableHeaders}
                />
            </TableContainerMobile>
            <TablePaginationView
                prevPage={prevPage}
                nextPage={nextPage}
                pages={expenseOperationFilters.pages}
                currentPage={expenseOperationFilters.page}
                pageLimits={pageLimits}
                handleFilterChange={handleFilterChange}
            />
            {createModal && (
                <ModalView
                    title={'Создать операцию вручную'}
                    onClose={handleCloseCreateModal}
                >
                    <ExpensesCreateEditView
                        setModal={setCreateModal}
                        initialValue={null}
                        createOperation
                    />
                </ModalView>
            )}
            {filterModal && (
                <ModalView
                    title={'Фильтры'}
                    onClose={() => setFilterModal(false)}
                >
                    <Layout vertical between={10}>
                        <ExpenseOperationsFiltersView
                            handleKeyDown={handleKeyDown}
                            cancelSearch={cancelSearch}
                            handleFilterChange={handleFilterChange}
                            users={users}
                            businessUnits={businessUnits}
                            categories={categories}
                            inModal={true}
                        />
                        <Layout hAlign={'space-between'} between={10}>
                            <Button
                                extent={'L'}
                                id={'clear-filter-button'}
                                onClick={handleClearFilter}
                                background={'#333'}
                                color={'red'}
                            >
                                Очистить
                            </Button>
                            <Button
                                extent={'L'}
                                id={'confirm-filter-button'}
                                onClick={handleConfirmFilter}
                            >
                                Потвердить
                            </Button>
                        </Layout>
                    </Layout>
                </ModalView>
            )}
        </Page>
    );
});

export default ExpenseOperationsView;