import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import {renderInputs} from "../../components/form/render.inputs";
import {Button} from "../../styles/button";
import {isValid} from "../../utils/isValid";
import React from "react";
import MainStore from "./main.store";
import AppStore from "../../app.store";
import {MainActions} from "./main.actions";
import {hasPermission} from "../../utils/hasPermission";
import {Option} from "./main.model";

interface MainAddComponentModalViewProps {
    setModal: (isOpen: boolean) => void;
}

const MainAddComponentModalView = observer(({setModal}: MainAddComponentModalViewProps) => {

    const {component, setComponentFields, addComponentToItemList, destroyComponent} = MainStore;
    const {
        type,
        subtype,
        entity,
        title,
        description,
        size
    } = component
    const {userData, permissions, businessSettings} = AppStore;

    const componentTypeOptions: Option[] = [
        {
            label: 'Кнопка',
            value: 'BUTTON',
        },
        {
            label: 'Таблица',
            value: 'TABLE',
        },
        {
            label: 'Виджет',
            value: 'WIDGET',
        },
    ];

    const buttonSubTypeOptions: Option[] = [
        {
            label: 'Создать',
            value: 'CREATE',
        },
        /* {
             label: 'Скачать',
             value: 'DOWNLOAD',
         },*/
    ];

    const tableSubTypeOptions: Option[] = [
        {
            label: 'Данные',
            value: 'DATA',
        },
        {
            label: 'Отчеты по продажам',
            value: 'SALES_REPORT',
        },
        {
            label: 'Финансовые отчеты',
            value: 'FIN_REPORT',
        },
    ];

    const widgetSubTypeOptions: Option[] = [
        {
            label: 'Информация',
            value: 'INFO',
        },
    ];

    const tableBuLabel = component.subtype === 'DATA' ? 'Подразделение' : 'По подразеделниям'
    const tableUsersLabel = component.subtype === 'DATA' ? 'Сотрудники' : 'По сотрудникам'

    const tableEntityOptions: Option[] = [
        {
            label: tableUsersLabel,
            value: 'USER',
        },
    ]

    const handleTableEntityOptions = () => {
        const ownerOnlyOptions: Option[] = [
            {
                label: tableBuLabel,
                value: 'BUSINESS_UNITS',
            },
        ]

        const dataOptions: Option[] = [
            {
                label: 'Последние 5 закоченных операций',
                value: 'LAST_FIVE_FINISHED',
            },
            {
                label: 'Последние 5 незаконченных операций',
                value: 'LAST_FIVE_UNFINISHED',
            }
        ]

        if (userData?.role_id === 1) {
            tableEntityOptions.push(...ownerOnlyOptions)
        }

        if (component.subtype == 'DATA') {
            tableEntityOptions.push(...dataOptions)
        }
    }

    let buttonEntityInitialOptions: Option[] = []

    const handleButtonEntityOptions = () => {
        switch (component.subtype) {
            case 'CREATE':
                Object.entries(MainActions).map(([key, label]) => {
                    let access: boolean
                    if (['PRODUCT_SINGLE', 'PRODUCT_MASS'].includes(key)) {
                        access = hasPermission(permissions!, 'PRODUCTS', 'CREATE')
                    } else if (['EXPENSE_CATEGORY', 'EXPENSE_OPERATION'].includes(key)) {
                        access = hasPermission(permissions!, 'EXPENSES', 'CREATE')
                    } else {
                        if (key === 'TRANSACTIONS') {
                            access = (hasPermission(permissions!, key, 'CREATE') ||
                                hasPermission(permissions!, 'ALL_BU_TRANSACTIONS', 'CREATE'))
                        } else {
                            access = hasPermission(permissions!, key, 'CREATE')
                        }
                    }

                    if (access) {
                        const option: Option = {
                            label: label,
                            value: key
                        }
                        buttonEntityInitialOptions.push(option)
                    }
                })
                break
            case 'DOWNLOAD':
                Object.entries(MainActions).map(([key, label]) => {
                    let access: boolean
                    if (['PRODUCT_SINGLE', 'PRODUCT_MASS'].includes(key)) {
                        access = hasPermission(permissions!, 'PRODUCTS', 'DOWNLOAD')
                    } else if (['EXPENSE_CATEGORY', 'EXPENSE_OPERATION'].includes(key)) {
                        access = hasPermission(permissions!, 'EXPENSES', 'DOWNLOAD')
                    } else {
                        if (key === 'TRANSACTIONS') {
                            access = (hasPermission(permissions!, key, 'DOWNLOAD') ||
                                hasPermission(permissions!, 'ALL_BU_TRANSACTIONS', 'DOWNLOAD'))
                        } else {
                            access = hasPermission(permissions!, key, 'DOWNLOAD')
                        }
                    }

                    if (access) {
                        const option: Option = {
                            label: label,
                            value: key
                        }
                        buttonEntityInitialOptions.push(option)
                    }
                })
                break
        }
    }

    const widgetEntityOptions = [
        {
            label: 'Итоговый оборот',
            value: 'SALES',
        },
        {
            label: 'Всего исходящих операций',
            value: 'OUTFLOW',
        },
        {
            label: 'Всего входящих операций',
            value: 'INFLOW',
        },
        {
            label: 'ФИО сотрудника',
            value: 'USER',
        },
        {
            label: 'Название организации',
            value: 'OWNER',
        },
        {
            label: 'Наличность',
            value: 'CASH',
        },
    ]

    const handleWidgetEntityOptions = () => {
        if (businessSettings?.employee_bonus) {
            const bonusOption: Option = {
                label: 'Бонус',
                value: 'BONUS',
            }

            widgetEntityOptions.push(bonusOption)
        }

        if (userData?.role_id !== 1) {
            const buOption: Option = {
                label: 'Подразделение',
                value: 'BUSINESS_UNIT',
            }

            widgetEntityOptions.push(buOption)
        }
    }

    const handleSubtypeOptions = () => {
        switch (component.type) {
            case 'BUTTON':
                return buttonSubTypeOptions
            case 'TABLE':
                return tableSubTypeOptions
            case 'WIDGET':
                return widgetSubTypeOptions
        }
    }

    const handleEntityOptions = () => {
        switch (component.type) {
            case 'WIDGET':
                handleWidgetEntityOptions()
                return widgetEntityOptions
            case 'BUTTON':
                handleButtonEntityOptions()
                return buttonEntityInitialOptions
            case 'TABLE':
                handleTableEntityOptions()
                return tableEntityOptions
        }
    }

    const sizeOptions = [
        {
            label: 'Маленький',
            value: 'S',
        },
        {
            label: 'Средний',
            value: 'M',
        },
        {
            label: 'Большой',
            value: 'L',
        }
    ]

    const inputs = [
        {
            field: type,
            options: componentTypeOptions,
            fieldName: 'type',
            placeholder: 'Тип компонента',
            type: 'select',
            selectValueType: 'string',
            required: true
        },
        component.type === 'TABLE' && {
            field: size,
            options: sizeOptions,
            fieldName: 'size',
            placeholder: 'Размер таблицы',
            type: 'select',
            selectValueType: 'string',
            required: true
        },
        component.type !== 'WIDGET' && {
            field: subtype,
            options: handleSubtypeOptions(),
            fieldName: 'subtype',
            placeholder: 'Подтип компонента',
            type: 'select',
            selectValueType: 'string',
            required: true
        },
        {
            field: entity,
            fieldName: 'entity',
            options: handleEntityOptions(),
            placeholder: 'Подразделение',
            type: 'select',
            required: true,
            selectValueType: 'string',
        },
        {
            field: title,
            fieldName: 'title',
            placeholder: 'Заголовок',
            type: 'text',
            required: true
        },
        {
            field: description,
            fieldName: 'description',
            placeholder: 'Описание',
            type: 'text',
            required: false
        },
    ]

    const handleConfirm = () => {
        addComponentToItemList(component);
        setModal(false);
        destroyComponent();
    }

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(component, inputs, setComponentFields)}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    onClick={handleConfirm}
                    disabled={isValid(inputs)}
                >
                    Потвердить
                </Button>
            </Layout>
        </Layout>
    );
});

export default MainAddComponentModalView;