import {makeAutoObservable} from "mobx";
import axios, {GenericAbortSignal} from "axios";
import {api} from "../../api/endpoints";
import AppStore from "../../app.store";
import {FirebaseModel} from "./firebase.model";

const usersInitialState: FirebaseModel[] = [];

class FirebaseStore {
    public firebaseapi ?: FirebaseModel[] | undefined = usersInitialState;

    public firebasePageInit = async (signal: GenericAbortSignal) => {
        void this.firebaseReq(signal);
    }

    constructor() {
        makeAutoObservable(this);
    }

    setFirebase(data: FirebaseModel[]) {
        this.firebaseapi = data;
    }

    sendNotesIds = async (ids:any) => {
        try {
            const {getToken} = AppStore

            const headers = {
                Authorization: 'Bearer ' + getToken()!,
            }
            const payload = {
                id: ids
            }
        await axios.put(api.put_notifications, payload, {headers});
        this.firebaseReq()
        } catch (err) {
            console.error(err);
        }
    }
    
    public firebaseReq = async (signal?: GenericAbortSignal) => {
        try {
            const {getToken} = AppStore

            const headers = {
                Authorization: 'Bearer ' + getToken()!,
            }

            const response = await axios.get(api.get_notifications , {headers, signal});
            this.setFirebase(response.data.resp);
        } catch (err) {
        }
    }

    public registerNotificationToken = async (token: string) => {
        try {
            const {getToken} = AppStore

            const headers = {
                Authorization: 'Bearer ' + getToken()!,
            }

            const device_uuid = localStorage.getItem('device_uuid')

            const payload = {
                token: token,
                device_uuid: device_uuid,
            }

            const response = await axios.post(api.register_notification_token, payload, {headers});
        } catch (err) {
        }
    }

    // public getNotifications = () => {
    //     console.log("firebaseReq start")
    //
    //     try {
    //         const {getToken} = AppStore
    //
    //         const headers = {
    //             Authorization: 'Bearer ' + getToken()!,
    //         }
    //
    //         const response = axios.get(api.get_notifications , {headers, signal});
    //         this.setFirebase(response.data.resp);
    //     } catch (err) {
    //         console.error(err);
    //     }
    // }
}

export default new FirebaseStore();