import {UsersModel} from "./users.model";
import {observer} from "mobx-react";
import {ContextMenu, ContextMenuItem, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../styles/table";
import {Badge} from "../../styles/badge";
import {Image} from "../../styles/image";
import ContextMenuIcon from "../../assets/icons/context-menu.svg";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../utils/skeleton/table.skeleton";

interface setConfirmDeleteModalProps {
    isModalOpen: boolean;
    id: number;
    name: string;
}

interface UsersTableMobileViewProps {
    loading: boolean;
    users: UsersModel[] | null
    handleAccountClick: (user: UsersModel) => void;
    handleEditClick: (user: UsersModel) => void;
    setConfirmDeleteModal: ({isModalOpen, id, name}: setConfirmDeleteModalProps) => void;
    handleContext: (e: any) => void;
    anchorEl: any;
    tableHeaders: (key: string) => string;
}

const UsersTableDesktopView = observer(({
                                            loading,
                                            users,
                                            handleAccountClick,
                                            handleEditClick,
                                            setConfirmDeleteModal,
                                            handleContext,
                                            anchorEl,
                                            tableHeaders
                                        }: UsersTableMobileViewProps) => {
    return (
        <Table>
            <TableHead>
                <TRow tableHead>
                    <THeadCell>{tableHeaders('full_name')}</THeadCell>
                    <THeadCell>{tableHeaders('login')}</THeadCell>
                    <THeadCell>{tableHeaders('phone')}</THeadCell>
                    <THeadCell>{tableHeaders('role')}</THeadCell>
                    <THeadCell>{tableHeaders('business_unit')}</THeadCell>
                    <THeadCell>{tableHeaders('salary')}</THeadCell>
                    <THeadCell>{tableHeaders('active')}</THeadCell>
                    <THeadCell></THeadCell>
                </TRow>
            </TableHead>
            {!loading ? (
                <TableBody>
                    {users?.length ? (
                        users?.map((user, index) => (
                            <TRow key={index} menu>
                                <TCell>{user.full_name}</TCell>
                                <TCell>{user.login}</TCell>
                                <TCell>{user.phone}</TCell>
                                <TCell>{user.role}</TCell>
                                <TCell>{user.business_unit}</TCell>
                                <TCell>{user.salary}</TCell>
                                <TCell>
                                    <Badge online={user.active}>
                                        {user.active ? "Активный" : "Заблокирован"}
                                    </Badge>
                                </TCell>
                                <TCell relative>
                                    <Image
                                        src={ContextMenuIcon}
                                        height="18px"
                                        onClick={handleContext}
                                    />
                                    <ContextMenu anchorEl={anchorEl}>
                                        <ContextMenuItem onClick={() => handleAccountClick(user)}>
                                            Счета
                                        </ContextMenuItem>
                                        <ContextMenuItem onClick={() => handleEditClick(user)}>
                                            Изменить
                                        </ContextMenuItem>
                                        <ContextMenuItem
                                            onClick={() =>
                                                setConfirmDeleteModal({
                                                    isModalOpen: true,
                                                    id: user.id,
                                                    name: user.full_name,
                                                })
                                            }
                                        >
                                            Удалить
                                        </ContextMenuItem>
                                    </ContextMenu>
                                </TCell>
                            </TRow>
                        ))
                    ) : (
                        <NoResultTitle colspan={8}/>
                    )}
                </TableBody>
            ) : (
                <TableSkeleton columns={8}/>
            )}
        </Table>
    )
});

export default UsersTableDesktopView;