import {ExpenseCategoriesModel} from "../expense.categories.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../../app.store";
import {toast} from "react-toastify";
import axios from "axios";
import {api} from "../../../../api/endpoints";

const formInitialValues: ExpenseCategoriesModel = {
    id: 0,
    name: '',
    description: ''
}

const loadingInitialValue: boolean = false;

class ExpenseCategoriesCreateEditStore {
    public form = formInitialValues;
    public loading = loadingInitialValue;
    message: string = '';
    error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public init = async (initialValue: ExpenseCategoriesModel | null) => {
        if (initialValue) {
            this.form = initialValue;
        } else {
            this.form = formInitialValues;
        }
    }

    public setField = (form: ExpenseCategoriesModel) => {
        this.form = form;
    }

    public destroy = () => {
        this.form = formInitialValues;
    }

    public createOrEditExpenseCategoryReq = async (edit?: boolean) => {
        this.loading = true;

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        const payload = {
            id: this.form.id,
            name: this.form.name,
            description: this.form.description
        }

        try {
            let response;

            if (edit) {
                response = await axios.put(api.expenses + `/categories`, payload, {headers});
            } else {
                response = await axios.post(api.expenses + `/categories`, payload, {headers});
            }

            this.message = response?.data.message;
            toast.success(this.message);
            return response;
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error)
        } finally {
            this.loading = false;
        }
    }
}

export default new ExpenseCategoriesCreateEditStore();