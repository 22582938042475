import {observer} from "mobx-react";
import {Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import FinReportsStore from "./fin.reports.store";
import {Layout} from "../../../styles/layout";
import {Text} from "../../../styles/text";
import {Image} from "../../../styles/image";
import ArrowIcon from "../../../assets/icons/caret-right.svg";
import {useState} from "react";
import TableSkeleton from "../../../utils/skeleton/table.skeleton";
import NoResultTitle from "../../../components/no_result_title/no_result_title.view";

interface FinReportsDesktopViewProps {
    getTableHeaders: (key: string) => string;
    handleOrder: (column: string, asc: boolean) => void;
}

const FinReportsDesktopView = observer(({getTableHeaders, handleOrder}: FinReportsDesktopViewProps) => {
    const {finReports, finReportsFilters, loading} = FinReportsStore;
    const [asc, setAsc] = useState(false);
    const [activeColumn, setActiveColumn] = useState('')

    const handleArrowClick = (key: string) => {
        setActiveColumn(key);
        setAsc(!asc)
        handleOrder(key, asc)
    }

    return (
        <Table>
            <TableHead>
                <TRow tableHead>
                    <THeadCell>
                        <Layout
                            hAlign={"space-between"}
                            vAlign={"center"}
                            onClick={() => handleArrowClick('entity_name')}
                            style={{
                                cursor: "pointer"
                            }}
                        >
                            <Text>{getTableHeaders('entity_name')}</Text>
                            <Image
                                src={ArrowIcon}
                                height="10px"
                                rotate={activeColumn === 'entity_name' && asc ? 90 : -90}
                                style={{
                                    opacity: activeColumn === 'entity_name' ? '' : '50%'
                                }}
                            />
                        </Layout>
                    </THeadCell>
                    <THeadCell>
                        <Layout
                            hAlign={"space-between"}
                            vAlign={"center"}
                            onClick={() => handleArrowClick('sales')}
                            style={{
                                cursor: "pointer"
                            }}
                        >
                            <Text>{getTableHeaders('sales')}</Text>
                            <Image
                                src={ArrowIcon}
                                height="10px"
                                rotate={activeColumn === 'sales' && asc ? 90 : -90}
                                onClick={() => handleArrowClick('sales')}
                                style={{
                                    opacity: activeColumn === 'sales' ? '' : '50%'
                                }}
                            />
                        </Layout>
                    </THeadCell>
                    <THeadCell>{getTableHeaders('cost')}</THeadCell>
                    <THeadCell>
                        <Layout
                            hAlign={"space-between"}
                            vAlign={"center"}
                            onClick={() => handleArrowClick('sales_profit')}
                            style={{
                                cursor: "pointer"
                            }}
                        >
                            <Text>{getTableHeaders('sales_profit')}</Text>
                            <Image
                                src={ArrowIcon}
                                height="10px"
                                rotate={activeColumn === 'sales_profit' && asc ? 90 : -90}
                                onClick={() => handleArrowClick('sales_profit')}
                                style={{
                                    opacity: activeColumn === 'sales_profit' ? '' : '50%'
                                }}
                            />
                        </Layout>
                    </THeadCell>
                    {(finReportsFilters.report_type != 3 && finReportsFilters.report_type != 0) && (
                        <THeadCell>
                            <Layout
                                hAlign={"space-between"}
                                vAlign={"center"}
                                onClick={() => handleArrowClick('salary')}
                                style={{
                                    cursor: "pointer"
                                }}
                            >
                                <Text>{getTableHeaders('salary')}</Text>
                                <Image
                                    src={ArrowIcon}
                                    height="10px"
                                    rotate={activeColumn === 'salary' && asc ? 90 : -90}
                                    onClick={() => handleArrowClick('salary')}
                                    style={{
                                        opacity: activeColumn === 'salary' ? '' : '50%'
                                    }}
                                />
                            </Layout>
                        </THeadCell>
                    )}
                    <THeadCell>{getTableHeaders('bonuses')}</THeadCell>
                    <THeadCell>{getTableHeaders('other_expenses')}</THeadCell>
                    <THeadCell>{getTableHeaders('saldo')}</THeadCell>
                </TRow>
            </TableHead>
            {loading ? (<TableSkeleton
                columns={finReportsFilters.report_type != 3 && finReportsFilters.report_type != 0 ? 8 : 7}/>) : (
                <TableBody>
                    {finReports?.length ? (
                        finReports.map((report, index) => (
                            <TRow key={index} menu>
                                <TCell>{report.entity_name}</TCell>
                                <TCell>{report.sales}</TCell>
                                <TCell>{report.cost}</TCell>
                                <TCell>{report.sales_profit}</TCell>
                                {(finReportsFilters.report_type != 3 && finReportsFilters.report_type != 0) &&
                                    <TCell>{report.salary}</TCell>}
                                <TCell>{report.bonuses}</TCell>
                                <TCell>{report.other_expenses}</TCell>
                                <TCell>{report.saldo}</TCell>
                            </TRow>
                        ))
                    ) : (
                        <NoResultTitle
                            colspan={finReportsFilters.report_type != 3 && finReportsFilters.report_type != 0 ? 8 : 7}/>
                    )}
                </TableBody>
            )}
        </Table>
    );
});

export default FinReportsDesktopView;