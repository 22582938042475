import styled from "styled-components";
import {borderRadius, media, primaryColor} from "../utils/variables";
import { Image } from "./image";
import { Layout } from "./layout";

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`;

export const TableHeaderTransaction = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`;

export const SearchLayout = styled(Layout)`
  position: relative;
  ${Image} {
    position: absolute;
    left: 12px;
    top: calc(50% - 8px);
  }
  width: 220px;
`;

export const Search = styled.input`
  width: 220px;
  height: 40px;
  border-radius: ${borderRadius};
  border: 1px #e5e5e5;
  font-size: 14px;
  color: #374151;
  background-size: 18px;
  background-color: #ffffff;
  padding: 12px 14px 12px 40px;
`;

export const Select = styled.select`
  width: 180px;
  height: 40px;
  border-radius: ${borderRadius};
  border: 1px #e5e5e5;
  background-color: #ffffff;
  font-size: 14px;
  color: #374151;

  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  padding: 0 15px;

  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 8px;
  cursor: pointer;
`;

export const AddButton = styled.button`
  width: 120px;
  height: 40px;
  font-size: 14px;
  color: #ffffff;
  background-color: ${primaryColor};
  border-radius: 12px;
  border: 1px #e5e5e5;
  cursor: pointer;
  :hover {
    filter: brightness(1.2);
  }
  :active {
    filter: brightness(.8);
  }

  :disabled {
    background: #999;
    cursor: not-allowed;
    :hover {
      filter: brightness(1);
    }
  }
  
  ${media.mobile} {
    font-size: 11px;
    width: 80px;
    height: 30px;
  }

  ${media.tablet} {
    font-size: 11px;
    width: 80px;
    height: 30px;
  }
`;
