export const PermissionGroup = {
  BUSINESS_UNITS: 'BUSINESS_UNITS',
  BUSINESS_STOCKS: 'BUSINESS_STOCKS',
  BUSINESS_SERVICES: 'BUSINESS_SERVICES',
  ROLES: 'ROLES',
  ACCESSES: 'ACCESSES',
  USERS: 'USERS',
  CLIENTS: 'CLIENTS',
  PRODUCTS: 'PRODUCTS',
  PRODUCT_CATEGORIES: 'PRODUCT_CATEGORIES',
  TRANSACTIONS: 'TRANSACTIONS',
  REPORTS: 'REPORTS',
  STATISTICS: 'STATISTICS',
  ACCESS_GROUPS: 'ACCESS_GROUPS',
  ROLE_ACCESSES: 'ROLE_ACCESSES',
  PRODUCT_BRANDS: 'PRODUCT_BRANDS',
  COUNTRIES: 'COUNTRIES',
  MEASURE_UNITS: 'MEASURE_UNITS',
  PRODUCT_TYPES: 'PRODUCT_TYPES',
  PROFIT: 'PROFIT',
  SUPPLIERS: 'SUPPLIERS',
  TRANSFERS: 'TRANSFERS',
  SETTINGS: 'SETTINGS',
  STATUSES: 'STATUSES',
  ALL_BU_TRANSACTIONS: 'ALL_BU_TRANSACTIONS',
  PAYROLLS: 'PAYROLLS',
  EXPENSES: 'EXPENSES',
};
