import {observer} from "mobx-react";
import {
    PageButton,
    PageLimit,
    PageLimitDiv,
    PagesButtonStyle,
    TablePages,
    TablePagination
} from "../styles/table-pagination";
import {Select} from "../styles/form";
import {useEffect, useState} from "react";
import {sizes} from "./variables";
import {Layout} from "../styles/layout";

interface TablePaginationViewProps {
    prevPage: () => void;
    nextPage: () => void;
    pages: number | undefined;
    currentPage: number;
    pageLimits: number[];
    handleFilterChange: (e: any, objectKey: string, index?: number) => void;
}

const TablePaginationView = observer(({prevPage, nextPage, currentPage, pages, pageLimits, handleFilterChange}: TablePaginationViewProps) => {

    const [width, setWidth] = useState(window.innerWidth);
    const options: number[] = [...Array(pages)];
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const handleSelectChange = (e: any) => {
        handleFilterChange(e, "page", Number(e.target.value) - 1)
    }

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);

    const prevText = () => (
        width <= sizes.mobile ? '<' : 'Назад'
    );

    const nextText = () => (
        width <= sizes.mobile ? '>' : 'Вперед'
    );

    const pageNumbersLimit = () => (
        width <= sizes.mobile ? 0 : 10
    );


    const renderPages = () => (
        pages! > pageNumbersLimit() ? (
            <PageLimit
                onChange={(e) => handleSelectChange(e)}
                value={selectedOption}
            >

                {options.map((page, index) => (
                    <option
                        key={page}
                        value={index+1}>
                            {index + 1}
                    </option>
                ))}
            </PageLimit>
        ) : (
            <>
                {options.map((_, i) => (
                    <PageButton
                        onClick={(e) => handleFilterChange(e, "page", i)}
                        id="page"
                        key={i}
                        disabled={i + 1 === currentPage}
                        style={
                            i + 1 === currentPage
                                ? PagesButtonStyle.active
                                : PagesButtonStyle.not_active
                        }
                    >
                        {pages! > pageNumbersLimit() ? (
                            <Select>
                                <option key={i + 1} value={i + 1}>
                                    {currentPage}
                                </option>
                            </Select>
                        ) : (
                            <>
                                {i + 1}
                            </>
                        )}
                    </PageButton>
                ))}
            </>
        )
    );


    return (
        <TablePagination>
            <TablePages>
                <PageButton id="prev-page" onClick={prevPage}>
                    {prevText()}
                </PageButton>
                <Layout>
                    {renderPages()}
                </Layout>
                <PageButton id="next-page" onClick={nextPage}>
                    {nextText()}
                </PageButton>
            </TablePages>
            <PageLimitDiv>
                <p>Kол-во строк:</p>
                <PageLimit
                    onChange={(e) => handleFilterChange(e, "page_limit")}
                    defaultValue={25}
                >
                    {pageLimits.map((pageLimit) => (
                        <option key={pageLimit} value={pageLimit}>
                            {pageLimit}
                        </option>
                    ))}
                </PageLimit>
            </PageLimitDiv>
        </TablePagination>
    );
});

export default TablePaginationView;