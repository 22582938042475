import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import CardsStore from "./cards.store";
import {useEffect, useState} from "react";
import {Card} from "./cards.model";
import {AddButton} from "../../styles/table-header";
import {
    ContextMenu,
    ContextMenuItem,
    Table,
    TableBody,
    TableContainer, TableContainerMobile,
    TableHead,
    TCell,
    THeadCell,
    TRow
} from "../../styles/table";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import ModalView from "../../components/modal/modal.view";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import CardsCreateEditView from "./cards.modal/cards.modal.create.edit.view";
import {Image} from "../../styles/image";
import ContextMenuIcon from "../../assets/icons/context-menu.svg";
import ConfirmDelete, {DeleteItem} from "../../components/confirm/confirm.delete";
import Modal from "../../components/modal/modal.view";
import ClientsCardsDocumentsStore from "./clients.cards.documents.store";
import ClientDocumentsStore from "../client.documents/client.documents.store";
import CardsMobileView from "./cards.mobile.view";

interface CardsModelViewProps {
    client: any
}

const CardsView = observer(({client}: CardsModelViewProps) => {

    const {isLoading, cards, cardInit, deleteCard, cardReq} = CardsStore;
    const [createEditModal, setCreateEditModal] = useState(false);
    const [initialValue, setInitialValue] = useState<Card | null>(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<DeleteItem>({
        isModalOpen: false,
    });

    const {loadAndModifyDocx} = ClientsCardsDocumentsStore;
    const {documents, documentsReq} = ClientDocumentsStore;

    useEffect(() => {
        const controller = new AbortController();
        void cardInit(client.id, controller.signal);
        void documentsReq(client.id, 1, controller.signal);
        return () => {
            controller.abort();
        };

    }, [cardInit]);

    const handleAddButtonClick = () => {
        setCreateEditModal(true);
    }

    const handleCloseCreateEditModal = () => {
        setCreateEditModal(false);
        setInitialValue(null);
    }

    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector('.context-menu-focused');
        if (e.target.classList.contains('context-menu-focused')) {
            e.target.classList.remove('context-menu-focused');
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            e.target.classList.add('context-menu-focused');
        }
    };

    const handleEditClick = (card: Card) => {
        setInitialValue(card);
        setCreateEditModal(true);
    }

    const handleDelete = async () => {
        const resp = await deleteCard(confirmDeleteModal.id!);
        if (resp) {
            setConfirmDeleteModal({ isModalOpen: false });
            await cardReq(client.id)
        }
    };

    const handleClickDownloadCardDocument = async (card: Card, docType: string) => {
       await loadAndModifyDocx(card, documents, docType, client.phone);
    }

    return (
        <Layout vertical between={10}>
            <Layout hAlign={"flex-end"}>
                <AddButton onClick={handleAddButtonClick}>Создать</AddButton>
            </Layout>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TRow tableHead>
                            <THeadCell>Номер карты</THeadCell>
                            <THeadCell>Тип</THeadCell>
                            <THeadCell>Банк</THeadCell>
                            <THeadCell></THeadCell>
                        </TRow>
                    </TableHead>
                    {isLoading ? (<TableSkeleton columns={4}/>) : (
                        <TableBody>
                            {cards.length ? (cards?.map((card, index) => (
                                    <TRow key={index} menu>
                                        <TCell>{card.pan}</TCell>
                                        <TCell>{card.card_type}</TCell>
                                        <TCell>{card.bank}</TCell>
                                        <TCell relative>
                                            <Image
                                                src={ContextMenuIcon}
                                                height="18px"
                                                onClick={handleContext}
                                            />

                                            <ContextMenu anchorEl={anchorEl}>
                                                <ContextMenuItem
                                                    onClick={() => handleClickDownloadCardDocument(card, 'request')}
                                                >
                                                    Заявление
                                                </ContextMenuItem>
                                                <ContextMenuItem
                                                    onClick={() => handleClickDownloadCardDocument(card, 'agreement')}
                                                >
                                                    Договор
                                                </ContextMenuItem>
                                                <ContextMenuItem
                                                    onClick={() => handleEditClick(card)}
                                                >
                                                    Изменить
                                                </ContextMenuItem>
                                                <ContextMenuItem
                                                    onClick={() =>
                                                        setConfirmDeleteModal({
                                                            isModalOpen: true,
                                                            id: card.id,
                                                            name: card.pan,
                                                        })
                                                    }
                                                >
                                                    Удалить
                                                </ContextMenuItem>
                                            </ContextMenu>
                                        </TCell>
                                    </TRow>
                                ))
                            ) : (
                                <NoResultTitle colspan={4}/>
                            )}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <TableContainerMobile>
                <CardsMobileView
                    loading={isLoading}
                    cards={cards}
                    handleEditClick={handleEditClick}
                    setConfirmDeleteModal={setConfirmDeleteModal}
                    handleClickDownloadCardDocument={handleClickDownloadCardDocument}
                />
            </TableContainerMobile>
            {createEditModal && (
                <ModalView
                    title={initialValue ? `Изменить` : `Создать`}
                    onClose={handleCloseCreateEditModal}
                >
                    <CardsCreateEditView clientID={client.id} initialValue={initialValue} setModal={setCreateEditModal}/>
                </ModalView>
            )}
            {confirmDeleteModal.isModalOpen && (
                <Modal
                    title="Удалить карту"
                    onClose={() => {
                        setConfirmDeleteModal({ isModalOpen: false });
                    }}
                >
                    <ConfirmDelete
                        warningText={`Вы уверены, что хотите удалить карту ${confirmDeleteModal.name}?`}
                        setModal={setConfirmDeleteModal}
                        callback={handleDelete}
                        isLoading={isLoading}
                    />
                </Modal>
            )}
        </Layout>
    );
});

export default CardsView;