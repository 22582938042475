import styled, { css } from "styled-components";
import { getKeyframes } from "../utils/getKeyframes";
import { miniNavbarSize, navbarMinSize, navbarSize } from "../utils/variables";

type FlexAlign = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
type Position = 'sticky' | 'absolute' | 'fixed' | 'relative';

interface LoginAnimation {
  isLoggedIn: boolean;
  isNavOpen: boolean;
}
interface LayoutProps {
  background?: string;
  heightSize?: number;
  widthSize?: string;
  padding?: string;
  between?: number;
  vertical?: boolean;
  fullPage?: boolean;
  centered?: boolean;
  fadeIn?: boolean;
  wrapped?: boolean;
  position?: Position;
  vAlign?: FlexAlign;
  hAlign?: FlexAlign;
  loginAnimation?: LoginAnimation;
}

export const Layout = styled.div<LayoutProps>`
  display: flex;
  transition: ${({ fullPage }) => (fullPage ? "500ms" : "")};
  max-width: 100vw;
  width: 100%;
  background: ${({ background }) => (background ? background : "unset")};
  height: ${({ heightSize }) => (heightSize ? `${heightSize}px` : "unset")};
  width: ${({ widthSize }) => (widthSize ? `${widthSize}` : "unset")};
  padding: ${({ padding }) => padding ? `${padding}` : '0'};
  position: ${({ position }) => position ? `${position}` : 'unset'};
  gap: ${({ between }) => between ? `${between}px` : `unset`};
  flex-wrap: ${({ wrapped }) => wrapped ? `wrap` : `unset`};
  flex-direction: ${({ vertical }) => vertical ? 'column' : 'row'};

  ${({ fullPage }) => fullPage
    ? css`
        height: 100vh !important;
        width: 100vw;
      `
    : ``
  }

  ${({ centered }) => centered
    ? css`
        align-items: center;
        justify-content: center;
      `
    : ``
  }

  ${({ vAlign, vertical }) => vAlign
    ? vertical
      ? `justify-content: ${vAlign};`
      : `align-items: ${vAlign};`
    : ``
  }
  
  ${({ hAlign, vertical }) => hAlign
    ? vertical
      ? `align-items: ${hAlign};`
      : `justify-content: ${hAlign};`
    : ``
  }

  ${({ loginAnimation }) => loginAnimation?.isLoggedIn
    ? css`
        min-width: ${loginAnimation?.isNavOpen
          ? navbarMinSize
          : miniNavbarSize};
        animation: loginNavbarWidth 1s forwards 0.5s;
        > * {
          animation: fadeOut 0.5s forwards;
        }
      `
    : ``
  }

  ${({ fadeIn }) => fadeIn
    ? `
          opacity: 0;
          animation: fadeIn 0.5s forwards;
      `
    : ``
  }

  @keyframes loginNavbarWidth {
    to {
      max-width: ${({ loginAnimation }) =>
        loginAnimation?.isNavOpen ? navbarSize : miniNavbarSize};
    }
  }

  ${getKeyframes("fadeIn")}
  ${getKeyframes("fadeOut")}
`;
