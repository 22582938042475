import {observer} from "mobx-react";
import {useState} from "react";
import {Layout} from "../../styles/layout";
import {Text} from "../../styles/text";
import {EyeImgIcon, Table, TableBody, TableHead, TCell, THeadCell, TRow} from "../../styles/table";
import {Image} from "../../styles/image";
import EyeIcon from "../../assets/icons/eye.svg";
import {primaryColor} from "../../utils/variables";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import ModalView from "../../components/modal/modal.view";
import {TBusinessGoodsGetResponse} from "./products_transactions.types";

interface ProductTransactionTableMobileViewProps {
    loading: boolean;
    stocks: TBusinessGoodsGetResponse["stocks"] | undefined;
    tableHeaders: (key: string) => string;
}

const ProductsTransactionTableMobileView = observer(({
                                                         loading,
                                                         stocks,
                                                         tableHeaders
                                                     }: ProductTransactionTableMobileViewProps) => {

    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<
        {
            brand: string;
            business_unit: string;
            category: string;
            goods_id: number;
            measure_unit: string;
            name: string;
            outflow: string;
            part_number: string;
            quantity: number;
            set_price: number;
            client: number
        } | null>(null)

    const handleRowClick = (stock: {
        brand: string;
        business_unit: string;
        category: string;
        goods_id: number;
        measure_unit: string;
        name: string;
        outflow: string;
        part_number: string;
        quantity: number;
        set_price: number;
        client: number
    }) => {
        setModal(true);
        setInitialValue(stock);
    }

    const handleCloseModal = () => {
        setModal(false);
        setInitialValue(null);
    }

    const renderModalItems = (stock: {
        brand: string;
        business_unit: string;
        category: string;
        goods_id: number;
        measure_unit: string;
        name: string;
        outflow: string;
        part_number: string;
        quantity: number;
        set_price: number;
        client: number
    }) => (
        <Layout vertical between={10}>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('name')}</Text>
                <Text>{stock.name}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('part_number')}</Text>
                <Text>{stock.part_number}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('brand')}</Text>
                <Text>{stock.brand}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('category')}</Text>
                <Text>{stock.category}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('set_price')}</Text>
                <Text>{stock.set_price}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('quantity')}</Text>
                <Text>{stock.quantity}</Text>
            </Layout>
            <Layout hAlign={"space-between"}>
                <Text weight={'bold'}>{tableHeaders('measure_unit')}</Text>
                <Text>{stock.measure_unit}</Text>
            </Layout>
        </Layout>
    );

    const renderedItems = () => (
        <>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        <THeadCell>{tableHeaders('name')}</THeadCell>
                        <THeadCell>{tableHeaders('brand')}</THeadCell>
                        <THeadCell>{tableHeaders('set_price')}</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                </TableHead>
                {!loading ? (
                    <TableBody>
                        {stocks?.length ? (
                            stocks?.map((stock, index) => (
                                <TRow key={index} menu>
                                    <TCell>{stock.name}</TCell>
                                    <TCell>{stock.brand}</TCell>
                                    <TCell>{stock.set_price}</TCell>
                                    <TCell relative>
                                        <EyeImgIcon
                                            src={EyeIcon}
                                            onClick={() => handleRowClick(stock)}
                                            height="18px"
                                            color={primaryColor}
                                        />
                                    </TCell>
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={4}/>
                        )}
                    </TableBody>
                ) : (
                    <TableSkeleton columns={4}/>
                )}
            </Table>
            {modal && (
                <ModalView
                    title={`Данные продукта ${initialValue?.name}`}
                    onClose={handleCloseModal}
                >
                    {renderModalItems(initialValue!)}
                </ModalView>
            )}
        </>
    );

    return (
        <Layout>
            {renderedItems()}
        </Layout>
    )
});

export default ProductsTransactionTableMobileView;