export type SizeTypes = string;

interface Size {
    S: string,
    M: string,
    L: string,
}

export const getSize = (extent: SizeTypes, size: Size) => {
    switch (extent) {
        case 'S':
            return size.S;
        case 'M':
            return size.M;
        case 'L':
            return size.L;
        default:
            return extent
    }
}
