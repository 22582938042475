import {ProductBrandModel} from "./products.model";
import AppStore from "../../app.store";
import axios, { GenericAbortSignal } from "axios";
import {api} from "../../api/endpoints";
import { makeAutoObservable } from "mobx";

class ProductBrandStore {
    public productBrands ?: ProductBrandModel[];

    public productBrandsInit = (signal: GenericAbortSignal) => {
        void this.productBrandReq(signal);
    }

    constructor() {
        makeAutoObservable(this);
    }

    setProductBrands(data: any) {
        const {brands} = data;
        this.productBrands = brands;
    }

    public productBrandReq = async (signal?: GenericAbortSignal) => {

        try {
            const {getToken} = AppStore

            const headers = {
                Authorization: "Bearer " + getToken()!
            }

            let response:any = await axios.get(api.product_brands, {headers, signal});

            this.setProductBrands(response.data)
        } catch (err) {
        }
    }
}

export default new ProductBrandStore();