import {observer} from "mobx-react";
import {Layout} from "../../styles/layout";
import ClientDocumentsStore from "./client.documents.store";
import {useEffect, useState} from "react";
import {AddButton} from "../../styles/table-header";
import {
    ContextMenu,
    ContextMenuItem,
    Table,
    TableContainer, TableContainerMobile,
    TableHead,
    TCell,
    THeadCell,
    TRow
} from "../../styles/table";
import ModalView from "../../components/modal/modal.view";
import {ClientDocumentsModel} from "./client.documents.model";
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import ClientDocumentCreateEditView from "./client.document.modal/client.document.create.edit.view";
import {Image} from "../../styles/image";
import ContextMenuIcon from "../../assets/icons/context-menu.svg";
import ConfirmDelete, {DeleteItem} from "../../components/confirm/confirm.delete";
import ClientDocumentsMobileView from "./client.documents.mobile.view";

interface ClientDocumentsViewProps {
    client: any
}

const ClientDocumentsView = observer(({client}: ClientDocumentsViewProps) => {
    const {initDocumentsPage, loading, documents, deleteDocumentReq, documentsReq, destroy} = ClientDocumentsStore;
    const [createEditModal, setCreateEditModal] = useState(false);
    const [initialValue, setInitialValue] = useState<ClientDocumentsModel | null>(null);
    const {getTableHeaders, dictionaryListReq} = DictionaryStore;
    const [anchorEl, setAnchorEl] = useState(null);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<DeleteItem>({
        isModalOpen: false,
    });

    useEffect(() => {
        const controller = new AbortController();
        void initDocumentsPage(client.id, controller.signal);
        void dictionaryListReq('CLIENT DOCUMENTS', 'TABLE HEADERS', controller.signal);
        return () => {
            controller.abort();
        }
    }, [initDocumentsPage]);

    const handleCloseCreateEditModal = () => {
        setInitialValue(null);
        setCreateEditModal(false);
    }

    const handleDocumentType = (typeID: number) => {
        switch (typeID) {
            case 1: return 'Паспорт';
            case 2: return 'Сведетельство о рождении';
            case 3: return 'Водительское удостоверение';
            case 4: return 'Военный билет';
            case 5: return 'Пенсионное удостоверение';
            case 6: return 'Другое';
            default: return '';
        }
    }

    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector('.context-menu-focused');
        if (e.target.classList.contains('context-menu-focused')) {
            e.target.classList.remove('context-menu-focused');
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            e.target.classList.add('context-menu-focused');
        }
    };

    const handleEditClick = (doc: ClientDocumentsModel) => {
        setInitialValue(doc);
        setCreateEditModal(true);
    }

    const handleDelete = async () => {
        const res = await deleteDocumentReq(confirmDeleteModal.id!)

        if (res) {
            destroy()
            setConfirmDeleteModal({isModalOpen: false});
            await documentsReq(client.id);
        }
    }

    return (
        <Layout vertical between={10}>
            <Layout hAlign={"flex-end"}>
                <AddButton onClick={() => setCreateEditModal(true)}>Создать</AddButton>
            </Layout>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TRow tableHead>
                            <THeadCell>{getTableHeaders('type_id')}</THeadCell>
                            <THeadCell>{getTableHeaders('number')}</THeadCell>
                            <THeadCell>{getTableHeaders('inn')}</THeadCell>
                            <THeadCell>{getTableHeaders('address')}</THeadCell>
                            <THeadCell>{getTableHeaders('issued_by')}</THeadCell>
                            <THeadCell>{getTableHeaders('issue_date_text')}</THeadCell>
                            <THeadCell>{getTableHeaders('expiration_date_text')}</THeadCell>
                            <THeadCell></THeadCell>
                        </TRow>
                    </TableHead>
                    {loading ? (<TableSkeleton columns={8}/>) : (
                        documents.length ? (
                            documents.map((doc, index) => (
                                <TRow key={index} menu>
                                    <TCell>{handleDocumentType(doc.type_id)}</TCell>
                                    <TCell>{`${doc.series}${doc.number}`}</TCell>
                                    <TCell>{doc.inn}</TCell>
                                    <TCell>{doc.address}</TCell>
                                    <TCell>{doc.issued_by}</TCell>
                                    <TCell>{doc.issue_date_text}</TCell>
                                    <TCell>{doc.expiration_date_text}</TCell>
                                    <TCell relative>
                                        <Image
                                            src={ContextMenuIcon}
                                            height="18px"
                                            onClick={handleContext}
                                        />

                                        <ContextMenu anchorEl={anchorEl}>
                                            <ContextMenuItem
                                                onClick={() => handleEditClick(doc)}
                                            >
                                                Изменить
                                            </ContextMenuItem>
                                            <ContextMenuItem
                                                onClick={() =>
                                                    setConfirmDeleteModal({
                                                        isModalOpen: true,
                                                        id: doc.id,
                                                        name: `${handleDocumentType(doc.type_id)} ${doc.series}${doc.number}`,
                                                    })
                                                }
                                            >
                                                Удалить
                                            </ContextMenuItem>
                                        </ContextMenu>
                                    </TCell>
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={8}/>
                    ))}
                </Table>
            </TableContainer>
            <TableContainerMobile>
                <ClientDocumentsMobileView
                    loading={loading}
                    clientDocs={documents}
                    handleEditClick={handleEditClick}
                    setConfirmDeleteModal={setConfirmDeleteModal}
                    tableHeaders={getTableHeaders}
                    handleDocumentType={handleDocumentType}
                    />
            </TableContainerMobile>
            {createEditModal && (
                <ModalView
                    title={initialValue ? 'Изменить' : 'Создать'}
                    onClose={handleCloseCreateEditModal}
                >
                    <ClientDocumentCreateEditView clientID={client.id} initialValue={initialValue} setModal={setCreateEditModal}/>
                </ModalView>
            )}
            {confirmDeleteModal.isModalOpen && (
                <ModalView
                    title="Удалить документ клиента"
                    onClose={() => {
                        setConfirmDeleteModal({ isModalOpen: false });
                    }}
                >
                    <ConfirmDelete
                        warningText={`Вы уверены, что хотите удалить документ ${confirmDeleteModal.name}?`}
                        setModal={setConfirmDeleteModal}
                        callback={handleDelete}
                        isLoading={loading}
                    />
                </ModalView>
            )}
        </Layout>
    );
});

export default ClientDocumentsView;