import {observer} from 'mobx-react';
import {
    Search,
    SearchLayout,
    TableHeader,
} from '../../../styles/table-header';
import {Layout} from '../../../styles/layout';
import {
    TableBody,
    TableContainerTransaction,
    TableHead,
    TableView,
    TCell,
    THeadCell,
    TRow,
} from '../../../styles/table';
import {Image} from '../../../styles/image';
import SearchIcon from '../../../assets/icons/search.svg';
import CreateOperationStore from './create.operation.store';
import NoResultTitle from '../../../components/no_result_title/no_result_title.view';
import TableSkeleton from '../../../utils/skeleton/table.skeleton';
import {useEffect} from 'react';
import AppStore from "../../../app.store";

interface ServicesModalViewProps {
    businessUnitID: number;
    setModal: (isOpen: boolean) => void;
}

const ServicesModalView = observer(({setModal}: ServicesModalViewProps) => {
    const {
        setSearch,
        services,
        servicesReq,
        selectSubmit,
        selectedProducts,
        serviceLoading,
    } = CreateOperationStore;

    const { businessSettings} = AppStore;

    useEffect(() => {
        const controller = new AbortController();
        void servicesReq(controller.signal);
    }, [servicesReq]);

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            setSearch(e.target.value, 'service');
        }
    };

    const cancelSearch = (e: any) => {
        if (e.target.value === '') {
            setSearch('', 'service');
        }
    };

    const handleSelectProduct = (object: any, type: string) => {
        object.account = '';
        object.quantity = 1;
        object.total_amount = 0;
        object.type = type;
        object.id = object.service_id;
        object.name = object.service_name;
        object.commission_amount = 0;

        if (businessSettings?.product_min_max) {
            object.amount = object.min;
        } else {
            object.amount = 0;
        }
        selectSubmit(object);
        setModal(false);
    };

    return (
        <>
            <TableHeader>
                <Layout>
                    <SearchLayout>
                        <Search
                            type="search"
                            id="search"
                            placeholder="Поиск"
                            onKeyDown={(e) => handleKeyDown(e)}
                            onChange={(e) => cancelSearch(e)}
                        ></Search>
                        <Image src={SearchIcon} height="16px"/>
                    </SearchLayout>
                </Layout>
            </TableHeader>
            <TableContainerTransaction style={{maxWidth: '100%'}}>
                <TableView style={{maxWidth: '100%'}}>
                    <TableHead>
                        <TRow tableHead>
                            <THeadCell>Наименование</THeadCell>
                            <THeadCell>Описание</THeadCell>
                            <THeadCell>Категория</THeadCell>
                        </TRow>
                    </TableHead>
                    {serviceLoading ? (
                        <TableSkeleton columns={3}/>
                    ) : (
                        <TableBody>
                            {services!.length ? (
                                services!
                                    ?.filter(
                                        (item) =>
                                            !selectedProducts.find(
                                                (item1: any) => item.service_id === item1.id
                                            )
                                    )
                                    .map((product, index) => (
                                        <TRow
                                            key={index}
                                            menu
                                            onClick={() => handleSelectProduct(product, 'service')}
                                        >
                                            <TCell>{product.service_name}</TCell>
                                            <TCell>{product.service_description}</TCell>
                                            <TCell>{product.category}</TCell>
                                        </TRow>
                                    ))
                            ) : (
                                <NoResultTitle colspan={3}/>
                            )}
                        </TableBody>
                    )}
                </TableView>
            </TableContainerTransaction>
        </>
    );
});

export default ServicesModalView;
