import styled from "styled-components";
import {Layout} from "../../styles/layout";
import {
    borderRadius,
    graphBoxBackground, MainPageTableSizes,
    media,
    miniNavbarSize,
    navbarMinSize,
    navbarSize,
    primaryColor
} from "../../utils/variables";
import {getSize, SizeTypes} from "../../utils/getSize";

interface IPercentage {
    profit?: boolean;
}

export const Graph = styled.div`
  background: #fff;
  padding: 15px;
 // margin: 15px;
  border-radius: ${borderRadius};
  height: 100%;
  width: 100%;
  @media (min-width: 610px) {
    & {
      flex-shrink: 1;
    }
  }

  ${media.mobile} {
    width: 100%;
    max-width: 100vh;
  }
`;

export const ButtonContainer = styled(Layout)<{
    edit: boolean;
}>`
  padding: 10px;
  position: absolute;
  border-radius: ${borderRadius};
  background-color: ${graphBoxBackground};
  cursor: ${({edit}) => edit ? `move` : ``};
  border: ${({edit}) => edit ? `0.5px solid ${primaryColor}` : ``};
  max-width: 200px;
`;

export const TableContainerMainPage = styled(Layout)<{
    edit: boolean;
    extent: SizeTypes;
}>`
//  width: 100%;
  max-width: 100%;
 // overflow-x: auto;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  border-radius: ${borderRadius};
  padding: 10px;
  position: absolute;
  border-radius: ${borderRadius};
  background-color: ${graphBoxBackground};
  cursor: ${({edit}) => edit ? `move` : ``};
  border: ${({edit}) => edit ? `0.5px solid ${primaryColor}` : ``};
  width: ${({extent}) =>
          extent ? `${getSize(extent, MainPageTableSizes)}` : 'unset'};
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  right: 0;
`;

/*export const InfoBoxLayout = styled(Layout)`
  flex: 1 0 26%;
  gap: 10px;
  flex-wrap: wrap;
`;*/

export const InfoBoxLayout = styled(Layout)<{
    edit: boolean;
}>`
  // padding: 10px;
  position: absolute;
  border-radius: ${borderRadius};
  background-color: ${graphBoxBackground};
  display: flex;
  flex-direction: column;
  cursor: ${({edit}) => edit ? `move` : ``};
  border: ${({edit}) => edit ? `0.5px solid ${primaryColor}` : ``};
  width: 180px;
  height: 125px;
`;

export const DeleteElementButton = styled.button<{
    display: boolean
}>`
  display: ${({display}) => display ? 'inherit' : 'none'};
`;

export const InfoBox = styled.div`
  padding: 10px;
  flex: 1 1 calc(50% - 5px);
  border-radius: ${borderRadius};
  background-color: ${graphBoxBackground};
  display: flex;
  flex-direction: column;
`;

export const InfoBoxTitle = styled.h6`
  margin: 0;
  font-size: 0.95rem;
  font-weight: normal;
  margin-bottom: 5px;
`;

export const InfoBoxValue = styled.p`
  margin: 0;
  font-size: 1.5rem;
  margin-bottom: 5px;
`;

export const InfoBoxIncomePercent = styled.span<IPercentage>`
  font-size: 0.85rem;
  position: relative;

  & .arrow {
    display: block;
    margin-right: 5px;
    height: 20px;
  }

  color: #24bd5e;
  ${({profit}) => (profit ? `color: #24bd5e;` : `color: #e45757`)}
`;

export const StatisticsLayout = styled(Layout)`
  margin-top: 10px;
`;

export const StatisticsContainer = styled.div`
  position: relative;
  border-radius: ${borderRadius};
  background-color: ${graphBoxBackground};
  background-color: #fff;
  flex: 1 1 60%;

  &:before {
    content: "";
    background-color: #33333325;
    position: absolute;
    top: 10px;
    left: 10px;
    border-radius: ${borderRadius};
    width: calc(60% - 20px);
    height: calc(100% - 20px);
  }

  &:after {
    content: "";
    background-color: #33333325;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: ${borderRadius};
    width: calc(40% - 10px);
    height: calc(100% - 20px);
  }
`;
export const PieContainer = styled.div`
  border-radius: ${borderRadius};
  background-color: ${graphBoxBackground};
  flex: 1 1 auto;
  max-width: max(40%, 600px);
`;
export const WrapperSkeleton = styled.div`
  .wrapper {
    display: flex;
    gap: 10px;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
  }

  .columns {
    width: 50%;
    height: 440px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .card {
    background: #fff;
    height: 100%;
    width: 100%;
    border-radius: 12px;
  }

  .right-columns .card {
    width: calc(50% - 10px);
    height: calc(33.33% - 10px);
  }

  .left {
    width: 100%;
  }

  .right {
    width: 40%;
  }

  .wrapper:before {
    width: 50%;
    height: 500%;
    position: absolute;
    top: -140%;
    content: "";
    transform: rotate(45deg);
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg,
    rgba(2, 0, 36, 0) 0%,
    rgba(128, 128, 128, 0.4) 50%,
    rgba(128, 128, 128, 0) 100%);
    animation: shining 2s infinite;
  }

  @keyframes shining {
    0% {
      left: -100%;
    }
    100% {
      left: 130%;
    }
  }
`;
