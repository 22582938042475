import styled, { css } from 'styled-components';
import { getKeyframes } from '../utils/getKeyframes';
import { getSize, SizeTypes } from '../utils/getSize';
import {
  borderRadius,
  inputColorDarkMode,
  inputPadding,
  inputSize, media,
  primaryColor,
  primaryColorGradient,
} from '../utils/variables';

interface ImageProps {
  padding?: number;
  rotate?: number;
  background?: string;
  isLoading?: boolean;
  heightSize?: string;
}

interface ButtonProps {
  extent?: SizeTypes;
  isLoading?: boolean;
  heightSize?: string;
  background?: string;
  dropdown?: boolean;
}
interface LinkProps {
  extent?: SizeTypes;
  isLoading?: boolean;
  heightSize?: string;
  background?: string;
  dropdown?: boolean;
}
export const Link = styled.a<LinkProps>`
  cursor: pointer;
  padding: ${inputPadding};
  color: ${primaryColor};
  width: ${({ extent }) =>
    extent ? `${getSize(extent, inputSize)}` : 'unset'};
  :hover {
    filter: brightness(1.4);
  }
  :active {
    filter: brightness(0.8);
  }
  :disabled {
    background: #999;
    cursor: not-allowed;
    :hover {
      filter: brightness(1);
    }
  }

  ${({ isLoading }) =>
    isLoading
      ? css`
          display: flex;
          justify-content: center;
          align-items: center;
          :before {
            content: '';
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border-top: 2px solid #fff;
            animation: infiniteRotate 1s infinite linear;
          }
        `
      : ``}

  height: ${({ heightSize }) => (heightSize ? `${heightSize}` : 'unset')};

  ${getKeyframes('rotate')}
`;

export const Button = styled.button<ButtonProps>`
  border: unset;
  transition: 300ms;
  cursor: pointer;
  border-radius: ${borderRadius};
  background: ${({ background }) =>
    background ? background : primaryColor};
  padding: ${inputPadding};
  color: ${inputColorDarkMode};
  width: ${({ extent }) =>
    extent ? `${getSize(extent, inputSize)}` : 'unset'};
  :hover {
    filter: brightness(1.4);
  }
  :active {
    filter: brightness(0.8);
  }
  :disabled {
    background: #999;
    cursor: not-allowed;
    :hover {
      filter: brightness(1);
    }
  }

  ${({ isLoading }) =>
    isLoading
      ? css`
          display: flex;
          justify-content: center;
          align-items: center;
          :before {
            content: '';
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border-top: 2px solid #fff;
            animation: infiniteRotate 1s infinite linear;
          }
        `
      : ``}

  height: ${({ heightSize }) => (heightSize ? `${heightSize}` : 'unset')};

  ${getKeyframes('rotate')}
  
  ${media.mobile} {
    font-size: 11px;
  }

  ${media.tablet} {
    font-size: 11px;
  }
`;

export const FilterButton = styled.button<ButtonProps>`
    border: unset;
    transition: 300ms;
    cursor: pointer;
    border-radius: ${borderRadius};
    background: ${({background}) =>
            background ? background : primaryColor};
    padding: ${inputPadding};
    color: ${inputColorDarkMode};

    :hover {
      filter: brightness(1.4);
    }

    :active {
      filter: brightness(0.8);
    }

    :disabled {
      background: #999;
      cursor: not-allowed;

      :hover {
        filter: brightness(1);
      }
    }

    ${getKeyframes('rotate')}
  
  font-size: 11px;
  width: 80px;
  height: 30px;

  ${media.desktop} {
    display: none;
  }
`;

export const ButtonImgArrowIconDiv = styled.div<ImageProps>`
  width: 42px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
  cursor: pointer;
  transition: 300ms;
  transform: rotate(90deg);
  background: transparent;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  :hover {
    background: ${({ background }) => (background ? background : primaryColor)};
    filter: brightness(1.4);
  }
  :active {
    filter: brightness(0.8);
  }
  :disabled {
    background: #999;
    cursor: not-allowed;
    :hover {
      filter: brightness(1);
    }
  }
`;
export const ButtonImgArrowIcon = styled.img<ImageProps>`
  width: 25px;
  height: 25px;
`;
export const ButtonCreateExcel = styled.button<ButtonProps>`

  width: 120px;
  height: 40px;
  font-size: 14px;
  color: #ffffff;
  background-color: ${primaryColor};
  border-radius: 12px;
  border: 1px #e5e5e5;
  cursor: pointer;
  :hover {
    filter: brightness(1.2);
  }
  :active {
    filter: brightness(.8);
  }

  ${media.mobile} {
    font-size: 11px;
    width: 80px;
    height: 30px;
  }

  ${media.tablet} {
    font-size: 11px;
    width: 80px;
    height: 30px;
  }
  
  padding: 4px;
  text-align: center;
  justify-content: center;
  color: ${inputColorDarkMode};
  
  :disabled {
    background: #999;
    cursor: not-allowed;
    :hover {
      filter: brightness(1);
    }
  }

  ${({ isLoading }) =>
    isLoading
      ? css`
          display: flex;
          justify-content: center;
          align-items: center;
          :before {
            content: '';
            border-radius: 50%;
            border-top: 2px solid #fff;
            animation: infiniteRotate 1s infinite linear;
          }
        `
      : ``}

  ${getKeyframes('rotate')}
`;
export const ButtonCreateExcelDiv = styled.div<ButtonProps>`
  display: flex;
  //width: 173px;
  height: 40px;
  border-radius: 12px;
  background: ${({ background }) => (background ? background : primaryColor)};

  ${({ dropdown }) =>
    dropdown !== true
      ? `
            width: 140;
        `
      : `
            width: 173px;
        `}
  ${media.mobile} {
    width: 80px;
    height: 30px;
    text-align: center;
    vertical-align: center;
  }

  ${media.tablet} {
    width: 80px;
    height: 30px;
    text-align: center;
    vertical-align: center;
  }
`;
export const SubMenuExcel = styled.div`
  .excelDropDownList {
    width: 80%;
    text-decoration: none;
    color: #525252;
    cursor: pointer;
    border-radius: 4px;
    list-style: none;
    margin: 8px 12px;
    padding: 3px 6px;
  }
  .excelDropDownList:hover {
    background: #cccccc;
  }
`;
