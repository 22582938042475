import axios from "axios";
import { makeAutoObservable } from "mobx";
import { api } from "../../api/endpoints";
import AppStore from "../../app.store";
import { TBusinessUnitsGetResponse } from "../stock/stock.types";
import { TBusinessGoodsGetResponse } from "./products_transactions.types";

class ProductsTransactionsStore {
  page = 1;
  pages = 0;
  page_limit = 25;
  loading = false;
  product_title = "";
  filter = {
    search: "",
  };
  business_units: TBusinessUnitsGetResponse["business_units"] = [];

  stocks: TBusinessGoodsGetResponse["stocks"] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public clearFilter = () => {
    this.page = 1;
    this.pages = 0;
    this.page_limit = 25;
    this.filter.search = '';
  }

  getBusinessUnits = async (signal: AbortSignal) => {
    const url = new URL(api.get_business_units);
    url.searchParams.set("page_limit", "999");
    const headers = {
      Authorization: "Bearer " + AppStore.getToken(),
    };
    try {
      const response = await axios.get<TBusinessUnitsGetResponse>(
        url.toString(),
        { headers, signal }
      );
      const { business_units } = response.data;
      this.business_units = business_units;
    } catch (error) {

    }
  };

  changeTitle = (title: string) => {
    this.product_title = title;
  }

  getProducts = async (signal?: AbortSignal) => {
    const { getToken } = AppStore;
    const url = new URL(api.get_business_goods);
    const headers = { Authorization: "Bearer " + getToken() };

    url.searchParams.set("page", this.page.toString());
    url.searchParams.set("page_limit", this.page_limit.toString());
    url.searchParams.set("order_descending", "false");

    if (this.filter.search.trim().length > 0) {
      url.searchParams.set("search", this.filter.search.toString());
    }

    try {
      this.loading = true;
      const response = await axios.get<TBusinessGoodsGetResponse>(
        url.toString(),
        { headers, signal }
      );
      this.stocks = response.data.stocks;
      this.page = response.data.page;
      this.pages = response.data.pages;
    } catch (error) {

    } finally {
      this.loading = false;
    }
  };

  changeFilter = <
    KEY extends keyof typeof this.filter,
    OBJ extends typeof this.filter
  >(
    name: KEY,
    value: OBJ[KEY]
  ) => {
    this.filter[name] = value;
  };

  changePage = (page: number) => {
    this.page = page;

    this.getProducts();
  };

  changePageLimit = (pageLimit: number) => {
    this.page_limit = pageLimit;

    this.getProducts();
  };
}

export default new ProductsTransactionsStore();
