import {ProductTypeModel} from "./products.model";
import AppStore from "../../app.store";
import axios, { GenericAbortSignal } from "axios";
import {api} from "../../api/endpoints";
import {makeAutoObservable} from "mobx";

class ProductTypeStore {
    public productTypes ?: ProductTypeModel[];

    public productTypesInit = (signal: GenericAbortSignal) => {
        void this.productTypeReq(signal);
    }

    constructor() {
        makeAutoObservable(this);
    }

    setProductTypes(data: any) {
        const {product_types} = data;
        this.productTypes = product_types;        
    }

    public productTypeReq = async (signal?: GenericAbortSignal) => {

        try {
            const {getToken} = AppStore

            const headers = {
                Authorization: "Bearer " + getToken()!
            }

            const response:any = await axios.get(api.product_types, {headers, signal});

            this.setProductTypes(response.data)
        } catch (err) {
        }
    }
}

export default new ProductTypeStore();